import React, { useRef, useState } from 'react';

// language
import { injectIntl, IntlShape } from 'react-intl';

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// redux
import { useDispatch, useSelector } from 'react-redux';

// alerts
import { useSnackbar } from "notistack";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from '@mui/material/Divider';

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";
import LoadingComponent from 'components/LoadingComponent';

// Enums
import { containerStatusEnum } from 'common/enums/DevicesStateEnum';
import { setRefresh } from 'redux/actions/pageActions';

// types
import { ContainerData, } from 'types/typesRedux';

interface Props {
  intl: IntlShape,
}

function ContainerInfo( { intl } :Props ): JSX.Element {

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const statusInput = useRef<HTMLInputElement>(null)

  const container = useSelector( (state: ContainerData) => state.container.container )

  const [ statusLoading, setStatusLoading ] = useState( false )

  const [ containerStatus, setContainerStatus ] = useState<any>(null);
  
  // Handle enabled status in API
  const handleContainerStatus = async ( id: number, enabled: true | false ) => {
    const cancelToken = axios.CancelToken.source()
    
    try {
      const response = await apiClient.patch(`/v1/containers/${id}`,{
        isEnabled: enabled,
        cancelToken:cancelToken.token
      });
      if (response) {
        // console.log( response.data )
        dispatch( setRefresh( { refreshContainer: true } ));
        setStatusLoading( false )
        enqueueSnackbar(`${intl.formatMessage({id:'containerAlert.editEnabled.true'})}`, { variant: 'success' });        
      }
    } catch ( error ){
      setStatusLoading( false )
      console.log( 'err' );
      enqueueSnackbar(`${intl.formatMessage({id:'containerAlert.editEnabled.false'})}`, { variant: 'success' });        
    }
    return () => cancelToken.cancel()
  } 

  // Handle enabled status
  const handleContainerEnabled = ( event: React.SyntheticEvent<Element, Event>, newValue: any ) => {
    let isEnabled = newValue === intl.formatMessage({id:'tableContainers.isEnabledTrue'})
    ? true : false;
    if ( newValue !== container.isEnabled ){
      setStatusLoading( true )
      handleContainerStatus( container.id, isEnabled );
      setContainerStatus(null);
    }
  }
     
  return (
    <>
    { statusLoading && (
      <LoadingComponent />
    )}

    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >

      <MDBox        
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >    

        <MDBox p={1} pb={0}
          sx= {{ flex: "1 100%", }} > 

          {/* Header */}
          <MDBox 
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <MDTypography variant="h5">
              {intl.formatMessage({id:'containerInfo.info'})}
            </MDTypography>
          </MDBox>

          <MDTypography variant="button" >
            {intl.formatMessage({id:'containerInfo.id'})}
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{ container.id ? container.id : '-'}
          </MDTypography>
        </MDBox>


        <Grid container pb={3} px={1} pt={0} mt={0}
          sx={{ width:"100%", }} >
          
          {/* Name of container */}
          <Grid 
            container
            xs={12} sm={12}>            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "0.6rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                     
                } } 
                variant="button"                   
                >
                {intl.formatMessage({id:'containerInfo.name'}).toUpperCase()}
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                { container.name ? container.name : '-'}
              </MDTypography>
            </MDBox>
          </Grid> 

          {/* door A + door B */}
          <Grid 
            container
            xs={12} sm={12} p={0} mt={0}
            sx={ { 
              display: "flex",
              flexDirection: "row",
              // alignContent:'space-between',
            } } 
            >   
            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
                width:'50%',
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "0.6rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                     
                } } 
                variant="button"                   
                >
                {intl.formatMessage({id:'tableContainers.doorStatusA'}).toUpperCase()}
              </MDTypography>              
              <MDTypography variant="button" 
                sx={ { marginTop: "0.2rem",} }
                fontWeight="regular" 
                color="text">
                  { container.doorStatusA ?? '-'}
              </MDTypography>
            </MDBox>

            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
            } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "0.6rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                     
                } } 
                variant="button"                   
                >
                {intl.formatMessage({id:'tableContainers.doorStatusB'}).toUpperCase()}
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                { container.doorStatusB ?? '-'}
              </MDTypography>
            </MDBox>

          </Grid>  

          {/* Status of container */}
          <Grid 
            container
            xs={12} sm={12} p={0} mt={0}>            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                width:"80%",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "0.6rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                     
                } } 
                variant="button"                   
                >
                {intl.formatMessage({id:'containerInfo.status'}).toUpperCase()}
              </MDTypography>
          
                <Autocomplete             
                  options={containerStatusEnum(intl)}
                  onChange={ handleContainerEnabled }
                  sx={{ marginTop: "-0.5rem"  }}
                  clearIcon 
                  value= { containerStatus ? containerStatus : container.isEnabled }
                  // value= { '' }
                  // blurOnSelect={ true }
                  renderInput={(params) => (
                    <FormField {...params} 
                      InputLabelProps={{ shrink: true }}
                      inputRef={ statusInput } 
                    /> )}
                />
            </MDBox>
          </Grid>   

          <Divider sx={{ width:"100%", opacity:"1" }} />

          <Grid 
            container
            xs={12} sm={12} pt={1}>            
            <MDTypography variant="button" >
              {intl.formatMessage({id:'containerInfo.inventory'})}
            </MDTypography>
          </Grid> 
          {/* Number of kayaks */}
          <Grid 
            container
            xs={12} sm={12} >       
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "row",
                justifyContent:"space-between",
                width:"70%",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "1.2rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                       
                } } 
                variant="button"                   
                >
                {intl.formatMessage({id:'containerInfo.kayak'}).toUpperCase()}
              </MDTypography>              
              <MDTypography variant="button" 
              // sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                { container.boatsCount }
              </MDTypography>
            </MDBox>
          </Grid> 

          {/* Number of equipments */}
          <Grid 
            container
            xs={12} sm={12} >       
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "row",
                justifyContent:"space-between",
                width:"70%",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "1.2rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                       
                } } 
                variant="button"                   
                >
                {intl.formatMessage({id:'containerInfo.equipments'}).toUpperCase()}
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                { container.equipmentCount }
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
    </>

  );
}

export default injectIntl(ContainerInfo);

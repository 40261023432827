import { ActionTypes } from 'redux/actions/actionType';

// types
import { 
    PricesState, 
    PricesFormType, 
    PricesAction, 
    SetPricesAction, 
    SetPricesFormAction, 
    DeletePricesFormAction,
} from 'types/typesRedux';
import { 
    PricesEquipmentType,
} from 'types/types';

type DeletePriceFormAction = {
    type: string;
    payload: keyof PricesFormType;
    filterName: string | number;
    categoryIdToDelete: string | number;
};

const pricesState: PricesState = {
    pricesForm:{
        equipment: [],
        fines: {},
    },

    pricesEquipment: [],
    pricesFines: {},
};


export const pricesReducer = (state= pricesState, action: PricesAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_PRICES:
            return { 
                ...state, 
                pricesEquipment: (action as SetPricesAction).payload.pricesEquipment, 
                pricesFines: (action as SetPricesAction).payload.pricesFines, 
            };
        case ActionTypes.SET_PRICES_FORM:
            return { ...state, pricesForm: { ...state.pricesForm, ...(action as SetPricesFormAction).payload } };

        case ActionTypes.DELETE_PRICE_FORM:
            const { payload, filterName, categoryIdToDelete } = action as DeletePriceFormAction;
            
            const updatedData = (state.pricesForm[payload] as PricesEquipmentType[]).map((item) => {
                if (item.categoryId === categoryIdToDelete && item[filterName] !== undefined) {
                    const newItem = { ...item };
                    delete newItem[filterName];
                    return newItem;
                }
                return item;
            });
            return {
                ...state,
                pricesForm: {
                ...state.pricesForm,
                [payload]: updatedData,
            },
        };

        case ActionTypes.DELETE_PRICES_FORM:
            return {
                ...state,
                pricesForm: {
                    ...state.pricesForm,
                    [(action as DeletePricesFormAction).payload]: [],
                }
            };
        case ActionTypes.DELETE_FORM_ITEMS:
            return {
                ...state,
                pricesForm: {
                ...state.pricesForm,
                equipment: (state.pricesForm.equipment).filter((item) => item.categoryId !== action.payload),
                fines: {
                    ...state.pricesForm.fines,
                    ...(action.payload === 'doorLefOpen' && { doorLeftOpened: undefined }),
                    ...(action.payload === 'reservationLateCancellation' && { reservationLateCancellation: undefined }),
                },
                },
            };
        default:
            return state;
    }
};


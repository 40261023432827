
// redux
import { useDispatch, useSelector } from 'react-redux';
import { setFinesOffset, setFinesLimit } from 'redux/actions/finesActions';

import { injectIntl, IntlShape } from 'react-intl';

// components
import QueryTableDevices from 'components-lib/QueryTable/QueryTableDevices';

import { Grid } from '@mui/material';

// types
import { FinesData } from 'types/typesRedux';

type Props = {
  intl: IntlShape;
  orderId: number;
  initialRowLimitFines: number;
}

const FinesList = ( { intl, orderId, initialRowLimitFines }: Props ) => {

  const dispatch = useDispatch();

  // redux
  const data = useSelector( (state: FinesData) => state.allFines )

  const tableName = intl.formatMessage( {id: 'fines.detail.title'} );

  // Handle offset
  const handleOffset = ( tableOffset: number ) => {
    dispatch(setFinesOffset( tableOffset ));
  }
  
  // Handle limit
  const handleLimit = ( tableLimit: number ) => {
    dispatch(setFinesLimit( tableLimit ));
    localStorage.setItem('rowLimitFines', tableLimit.toString());
  }    

  return (

    <Grid container>
      <Grid 
        // item xs={12} sm={12} md={12} lg={12}
      >

        <QueryTableDevices
          title= { tableName } 
          tableData={ data.fines }
          pagePagination={true}
          totalCount={ data.totalCount }
          offset={ data.offset }
          rowLimit={ initialRowLimitFines }  
          marginLeft= { false } 
          columns= {[
            { Header: intl.formatMessage( {id: 'fines.detail.id'} ), 
            accessor: "id", 
            width: "auto",
            minWidth: "3%",
            columnFilter: 'number',
            input: 'title',
            },
            { Header: intl.formatMessage( {id: 'fines.detail.status'} ), 
            accessor: "status", 
            width: "auto",
            minWidth: "9%",
            columnFilter: 'string',
            input: 'title',
            },
            { Header: intl.formatMessage( {id: 'fines.detail.amount'} ), 
            accessor: "amount", 
            width: "auto",
            minWidth: "4%",
            columnFilter: 'string',
            input: 'title',
            },
            { Header: intl.formatMessage( {id: 'fines.detail.description'} ), 
            accessor: "description", 
            width: "auto",
            minWidth: "25%",
            columnFilter: 'string',
            input: 'title',
            clearIcon: true,
            },
            ]}
          button={ [{
              add: null ,
              detail: false,
              edit: false,
              delete: false,
              block: false,
          }] }
          icon= { false }
          onOffset={ handleOffset }
          onLimit={ handleLimit }
          onAddItem={ null }
          onEditBtn={ null }
          onBlockItem={ null }
          onRemoveBtn={ null }
        />

      </Grid>
    </Grid>
  );
};

export default injectIntl(FinesList);

const authentification = {
    'authentification.signIn': 'Prihlásenie',
    'authentification.email': 'Email',
    'authentification.password': 'Heslo',
}

const sideNav = {
    'sideNav.home': 'Domov / Používatelia',
    'sideNav.userEdit': 'Zmena osobných údajov',
    'sideNav.inventory': 'Inventár',
    'sideNav.containersTable': 'Kontajnery',
    'sideNav.containerDetail': 'Kontajner detail',
    'sideNav.paymentsTable': 'Platby',
    'sideNav.ordersTable': 'Objednávky',
    'sideNav.orderDetail': 'Objednávka detail',
    'sideNav.reservationsTable': 'Rezervácie',
    'sideNav.reservationDetail': 'Rezervácia detail',
    'sideNav.priceList': 'Cenník',
    'sideNav.logOut': 'Odhlásiť sa',
    'sideNav.signIn': 'Prihlásenie',
}

const tableUsers = {
    'tableUsers.title': 'Používatelia',
    'tableUsers.id': 'Id',
    'tableUsers.firstName': 'Meno',
    'tableUsers.lastName': 'Priezvisko',
    'tableUsers.email': 'Email',
    'tableUsers.phone': 'Telefón',
    'tableUsers.country': 'Krajina',
    'tableUsers.blockedAt': 'Status',
    'tableUsers.blockedAt.null': 'Aktívny',
    'tableUsers.blockedAt.block': 'Blokovaný',
    'tableUsers.blockedAt.clear': 'Zrušiť výber',
}

const userProfile = {
    'userProfile.title': 'Profil používateľa',
    'userProfile.personalInfo': 'Osobné Údaje',
    'userProfile.id': 'Id používateľa:',
    'userProfile.firstname': 'MENO',
    'userProfile.surname': 'PRIEZVISKO',
    'userProfile.email': 'EMAIL',
    'userProfile.mobil': 'TELEFÓNNE ČÍSLO',
    'userProfile.street': 'ULICA',
    'userProfile.city': 'MESTO',
    'userProfile.postal': 'PSČ',
    'userProfile.country': 'KRAJINA',
    'userProfile.status': 'STAV',
    'userProfile.freeOfCharge': 'BEZPLATNÝ REŽIM',
    'userProfile.freeOfCharge.false': 'Neaktívny',
    'userProfile.freeOfCharge.true': 'Aktívny',
}

const userEdit = {
    'userEdit.personalInfo': 'Osobné Údaje',
    'userEdit.id': 'Id používateľa:',
    'userEdit.name': 'Meno',
    'userEdit.surname': 'Priezvisko',
    'userEdit.street': 'Ulica',
    'userEdit.city': 'Mesto',
    'userEdit.postal': 'PSČ',
}

const tableInventory = {
    'tableInventory.title': 'Inventár',
    'tableInventory.id': 'Id',
    'tableInventory.type': 'Typ',
    'tableInventory.variant': 'Veľkosť',
    'tableInventory.containerId': 'Kontajner-Id',    
    'tableInventory.containerName': 'Kontajner-Meno',
    'tableInventory.status': 'Stav',
    'tableInventory.statusTrue': 'V prevádzke',
    'tableInventory.statusFalse': 'Mimo prevádzky',
    'tableInventory.notes': 'Poznámka',
    'tableInventory.kayak': 'Loď',
    'tableInventory.vest': 'Vesta',
    'tableInventory.helmet': 'Prilba',
    'tableInventory.bag': 'Vak',
    'tableInventory.paddle': 'Pádlo',
    'tableInventory.batteryStatusTitle': 'Batéria',
    'tableInventory.batteryStatusFull': 'Nabitá',
    'tableInventory.batteryStatusEmpty': 'Vybitá',
    'tableInventory.noImage': 'Žiadny obrázok',
}

const tableItems = {
    'tableItems.title': 'Položky kontajneru',
    'tableItems.id': 'Id',
    'tableItems.type': 'Typ',
    'tableItems.notes': 'Poznámka',
}

const inventory = {
    'inventory.edit.title': 'Upraviť položku',
    'inventory.edit.type': 'Typ',
    'inventory.edit.status': 'Stav',
    'inventory.edit.variant': 'Veľkosť',
    'inventory.edit.notes': 'Poznámka',
}

const tableContainers = {
    'tableContainers.title': 'Kontajnery',
    'tableContainers.id': 'Id',
    'tableContainers.name': 'Meno',
    'tableContainers.isEnabled': 'Prevádzka',
    'tableContainers.isEnabledTrue': 'V prevádzke',
    'tableContainers.isEnabledFalse': 'Mimo prevádzky',
    'tableContainers.capacity': 'Kapacita',
    'tableContainers.equipmentCount': 'Vybavenie',
    'tableContainers.boatsCount': 'Lode',
    'tableContainers.doorStatusA': 'Dvere A',
    'tableContainers.doorStatusB': 'Dvere B',
    'tableContainers.door.open': 'Otvorené',
    'tableContainers.door.close': 'Zatvorené',
    'tableContainers.status': 'Stav',
    'tableContainers.status.online': 'Online',
    'tableContainers.status.offline': 'Offline',
}

const detailContainer = {
    'containerInfo.info': 'Informácie',
    'containerInfo.id': 'Id kontajneru:',
    'containerInfo.name': 'MENO KONTAJNERU',
    'containerInfo.status': 'STAV KONTAJNERU',
    'containerInfo.pin': 'PIN',
    'containerInfo.inventory': 'Inventár kontajneru',
    'containerInfo.kayak': 'POČET KAJAKOV',
    'containerInfo.equipments': 'POČET VYBAVENIA',
}

const tablePayments = {
    'tablePayments.title': 'Platby',
    'tablePayments.user.title': 'Platby používateľa',
    'tablePayments.id': 'Id',
    'tablePayments.user': 'Používateľ',
    'tablePayments.status': 'Stav',
    'tablePayments.createdAt': 'Vytvorená',
    'tablePayments.finishedAt': 'Ukončená',
    'tablePayments.type': 'Typ',
    'tablePayments.amount': 'Suma',
    'tablePayments.price': 'Cena',
    'tablePayments.penalty': 'Pokuta',
    'tablePayments.exportPayments': 'Exportovať platby',

    'tablePayments.status.pending': 'Čaká',
    'tablePayments.status.processing': 'Prebieha platba',
    'tablePayments.status.successful': 'Úspešná',
    'tablePayments.status.expired': 'Platnosť vypršala',
    'tablePayments.status.failed': 'Neúspešná',    

    'tablePayments.type.standard': 'Štandardná',
    'tablePayments.type.verification': 'Overenie',    
    'tablePayments.type.new_card': 'Nová karta',    
    'tablePayments.type.fine': 'Pokuta',    
}


const tableOrders = {
    'tableOrders.title': 'Objednávky',
    'tableOrders.user.title': 'Objednávky používateľa',
    'tableOrders.id': 'Id',
    'tableOrders.user': 'Používateľ',
    'tableOrders.createdAt': 'Vytvorená',
    'tableOrders.completedAt': 'Dokončená',
    'tableOrders.status': 'Stav objednávky',
    'tableOrders.paymentStatus': 'Stav platby',
    'tableOrders.rezervation': 'Rezervácie',
    'tableOrders.price': 'Cena',
    'tableOrders.penalty': 'Pokuta',
    'tableOrders.amountFine': 'Výška pokuty',
}

const orderDetail = {
    'order.detail.title': 'Objednávka',
    'order.detail.id': 'Id objednávky',
    'order.detail.idOrder': 'Id Objednávky',
    'order.detail.user': 'Používateľ',
    'order.detail.status': 'STAV OBJEDNÁVKY',
    'order.detail.price': 'CENA',
    'order.detail.startPlace': 'ZAČIATOK PLAVBY',
    'order.detail.finishPlace': 'KONIEC PLAVBY',
    'order.detail.isFreeOfCharge': 'BEZPLATNÝ REŽIM',
    'order.detail.createdAt': 'Vytvorená',
    'order.detail.cancel': 'Zrušiť platbu',
    'order.detail.close': 'Uzavrieť platbu',
    
    'order.detail.in_progress': 'Spracováva sa',
    'order.detail.awaiting_payment': 'Čaká na platbu',
    'order.detail.completed': 'Dokončená',
    'order.detail.cancelled': 'Zrušená',
}

const tableReservations = {
    'tableReservations.title': 'Rezervácie',
    'tableReservations.id': 'Id',
    'tableReservations.user': 'Používateľ',
    'tableReservations.startAt': 'Začiatok',
    'tableReservations.boatsCount': 'Počet lodí',
    'tableReservations.place': 'Začiatok plavby',
    'tableReservations.status': 'Stav',
    
    'tableReservations.pending': 'Čaká',
    'tableReservations.approved': 'Potvrdená',
    'tableReservations.rejected': 'Zamietnutá',
    'tableReservations.cancelled': 'Zrušená',
}

const reservationDetail = {
    'reservationDetail.title': 'Rezervácia',
    'reservationDetail.id': 'ID',
    'reservationDetail.userFirstName': 'MENO',
    'reservationDetail.userLastName': 'PRIEZVISKO',
    'reservationDetail.mobil': 'TELEFÓN',
    'reservationDetail.email': 'EMAIL',
    'reservationDetail.place': 'ZAČIATOK PLAVBY',
    'reservationDetail.boatsCount': 'POČET LODÍ',
    'reservationDetail.statusReservation': 'STAV REZERVÁCIE',
    
    'reservationDetail.createdAt': 'VYTVORENÁ',
    'reservationDetail.startAt': 'ZAČIATOK',
    'reservationDetail.cancelledAt': 'ČAS ZRUšENIA',
    'reservationDetail.status': 'Stav',
    
    'reservationDetailFine.title': 'Pokuta',
    'reservationDetailFine.status': 'STAV POKUTY',
    'reservationDetailFine.amount': 'VÝŠKA POKUTY',
    'reservationDetailFine.description': 'POPIS POKUTY',
    'reservationDetailFine.createdAt': 'VYTVORENÁ',
    
    'reservationEdit.title': 'Upraviť rezerváciu',
    'reservationEdit.id': 'Id',
    'reservationEdit.pending': 'Čaká',
    'reservationEdit.rejected': 'Zamietnuť',
    'reservationEdit.approved': 'Potvrdiť',
    'reservationEdit.cancelled': 'Zrušiť',
    'reservationEdit.addFine': 'Áno',
    'reservationEdit.noAddFine': 'Nie',
    'reservationEdit.fine': 'Pridať pokutu',
    
    'reservationDetailModal.fine': 'Naozaj chcete pridať pokutu'
}

const finesTable = {
    'fines.detail.title': 'Pokuty',
    'fines.detail.id': 'Id',
    'fines.detail.status': 'Stav',
    'fines.detail.amount': 'Cena',
    'fines.detail.description': 'Popis',

    'fines.detail.awaiting_payment': 'Čaká na platbu',
    'fines.detail.paid': 'Zaplatená',
    
    'fines.addFine': 'Pridať pokutu',  ////////////////////////////////////
}

const equipmentsOrderTable = {
    'equipmentsOrder.title': 'Vybavenie',
    'equipmentsOrder.id': 'Id',
    'equipmentsOrder.name': 'Typ',
    'equipmentsOrder.status': 'Stav',
    'equipmentsOrder.price': 'Cena',
    'equipmentsOrder.rentPlace': 'Požičané',
    'equipmentsOrder.returnPlace': 'Vrátené',

    'equipmentsOrder.rented': 'Požičané',
    'equipmentsOrder.returned': 'Vrátené',
}

const priceList = {
    'priceList.title': 'Cenník',
    'priceList.kayak': 'Loď 2-miestna',
    'priceList.cayakThreePerson': 'Loď 3-miestna',
    'priceList.paddle': 'Pádlo',
    'priceList.helmet': 'Prilba',
    'priceList.vest': 'Vesta',
    'priceList.bag': 'Vak',
    'priceList.openContainer': 'Otvorený kontajner',
    'priceList.reservationFine': 'Zrušená rezervácia',
    'priceList.item': 'Položka',
    'priceList.rent': 'Požičovné',
    'priceList.rentOneHour': 'Prvá hodina',
    'priceList.rentNextHour': 'Ďalšia hodina',
    'priceList.lost': 'Strata',
    'priceList.damage': 'Poškodenie',
}

const appAnnouncement = {
    'appAnnouncement.title': 'Upozornenia v aplikácii',
    'appAnnouncement.titleAdd': 'Pridať upozornenie',
    'appAnnouncement.titleEdit': 'Upraviť upozornenie',
    'appAnnouncement.remove': 'Naozaj chcete odstrániť upozornenie?',
}

const table = {
    'table.actions': 'Akcie',
    'table.showing': 'Zobrazuje sa',
    'table.to': 'až',
    'table.of': 'z',
    'table.items': 'položiek',
    'table.itemsPerPage': 'položiek na stránku',
}


const button = {
    'button.addUser': 'PRIDAŤ POUŽÍVATEĽA',
    'button.addItem': 'PRIDAŤ POLOŽKU',
    'button.addFine': 'PRIDAŤ POKUTU',
    'button.add': 'PRIDAŤ',
    'button.change': 'Upraviť',
    'button.delete': 'Odstrániť',
    'button.picture': 'Obrázok',
    'button.block': 'Blokovať',
    'button.unblock': 'Odblokovať',
    'button.active': 'Aktivovať',
    'button.deactive': 'Deaktivovať',
    'button.blockUser': 'Blokovať používateľa',
    'button.unblockUser': 'Odblokovať používateľa',
    'button.blockCharges': 'Aktivovať bezplatný režim',
    'button.unblockCharges': 'Deaktivovať bezplatný režim',
    'button.detail': 'Detail',
    'button.cancel': 'ZRUŠIŤ',
    'button.select': 'ZVOLIŤ',
    'button.save': 'ULOŽIŤ',
    'button.back': 'NASPÄŤ',
    'button.photo': 'Fotografia',
    'button.dateRange': 'Potvrdiť dátum',
    'button.addAnnouncement': 'Pridať upozornenie',
    'button.editAnnouncement': 'Upraviť upozornenie',
    'button.removeAnnouncement': 'Odstrániť upozornenie',
}

const alerts = {
    'userAlert.signIn.invalidToken': 'Neplatný token',
    'userAlert.signIn.expiredToken': 'Platnosť prihlásenia vypršala',
    'userAlert.signIn.server': ' Žiadna odozva servera',
    'userAlert.signIn.failed': 'Prihlásenie zlyhalo',
    'userAlert.signIn.wrongRole': 'Na prihlásenie je potrebný email administrátora',
    'userAlert.signIn.alert': 'Na email vám bol odoslaný link, cez ktorý sa prihlásite do aplikácie.',
    'userAlert.block': 'Používateľ bol zablokovaný',
    'userAlert.blockError': 'Používateľa sa nepodarilo zablokovať',
    'userAlert.unblock': 'Používateľ bol odblokovaný',    
    'userAlert.unblockError': 'Používateľa sa nepodarilo odblokovať',
    'userAlert.freeCharge': 'Bezplatný režim bol deaktivovaný',
    'userAlert.freeChargeError': 'Bezplatný režim sa nepodarilo deaktivovať',
    'userAlert.charge': 'Bezplatný režim bol aktivovaný',    
    'userAlert.chargeError': 'Bezplatný režim sa nepodarilo aktivovať',
    'userAlert.editUser.true': 'Používateľ bol zmenený',
    'userAlert.editUser.false': 'Používateľa sa nepodarilo zmeniť',
    'userAlert.editUser.emptyField': 'Vyplňte prosím všetky poľia',
    'userAlert.removeUser': 'Účet používateľa sa podarilo vymazať',
    'userAlert.removeUser.false': 'Účet používateľa sa nepodarilo vymazať',
    
    'inventoryAlert.status.true': 'Stav položky bol zmenený',
    'inventoryAlert.status.false': 'Stav položky sa nepodarilo zmeniť',

    'inventoryAlert.edit.true': 'Položka bola zmenená',
    'inventoryAlert.edit.false': 'Položku sa nepodarilo zmeniť',
    'inventoryAlert.edit.empty': 'Nebolo nič pridané alebo zmenené',
    
    'containerAlert.editEnabled.true': 'Stav kontajneru bol zmenený',
    'containerAlert.editEnabled.false': 'Stav kontajneru sa nepodarilo zmeniť',
    
    'pricesAlert.edit.true': 'Cenník bol úspešne zmenený',
    'pricesAlert.edit.false': 'Cenník sa nepodarilo zmeniť',
    
    'finesAlert.add.true': 'Pokuta bola úspešne pridaná',
    'finesAlert.add.false': 'Pokutu sa nepodarilo pridať',
    'finesAlert.addAmount.false': 'Výška pokuty musí byť vyplnená',
    'finesAlert.addDescription.false': 'Popis musí byť vyplnený',
    'finesAlert.addDescriptionAmount.false': 'Popis a výška pokuty musia byť vyplnené',
    
    'orderAlert.closeOrder.true': 'Objednávka bola uzavretá',
    'orderAlert.closeOrder.false': 'Objednávku sa nepodarilo uzavtvoriť',
    'orderAlert.cancelOrder.true': 'Objednávka bola zrušená',
    'orderAlert.cancelOrder.false': 'Objednávku sa nepodarilo zrušiť',
    
    'reservationEditAlert.success': 'Rezervácia bola upravená',
    'reservationEditAlert.error': 'Rezerváciu sa nepodarilo upraviť',
    'reservationEditAlert.empty': 'Vyplňte prosím stav rezervácie',
    'reservationEditAlert.emptyFine': 'Vyplňte prosím pridanie pokuty',
    'reservationEditAlert.fine': 'Pokuta bola pridaná',
    'reservationEditAlert.fineError': 'Pokutu sa nepodarilo pridať',

    'appAnnouncementAlert.add.true': 'Upozornenie bolo úspešne pridané',
    'appAnnouncementAlert.add.false': 'Upozornenie sa nepodarilo pridať',
    'appAnnouncementAlert.remove.true': 'Upozornenie bolo úspešne odstránené',
    'appAnnouncementAlert.remove.false': 'Upozornenie sa nepodarilo odstrániť',
    'appAnnouncementAlert.emptyInput': 'Vyplňte prosím text upozornenia',
} 

const others = {
    'user.remove': 'Naozaj chcete odstrániť používateľa?',
    'loading': 'Načítavam...',
    'exportPaymentsTitle': 'Zvoľte obdobie za ktoré chcete exportovať platby',
    'exportPayments': 'Exportovať platby',
    'blockUser': 'Naozaj chcete blokovať používateľa ?',
    'unblockUser': 'Naozaj chcete aktivovať používateľa ?',
    'blockCharges': 'Naozaj chcete aktivovať bezplatný režim ?',
    'unblockCharges': 'Naozaj chcete deaktivovať bezplatný režim ?',
    'datePicker': 'Vyberte časové obdobie',
    'currency': '\u20AC',
    'errorLoadData': 'Nepodarilo sa načítať dáta. Skúste to prosím neskôr.',
}

const datePicker = {
    'datePicker.startDate': 'Dátum od',
    'datePicker.endDate': 'Dátum do',
    'datePicker.today': 'Dnes',
    'datePicker.yesterday': 'Včera',
    'datePicker.thisWeek': 'Tento týždeň',
    'datePicker.lastWeek': 'Minulý týždeň',
    'datePicker.last7days': 'Posledných 7 dní',
    'datePicker.thisMonth': 'Tento mesiac',
    'datePicker.lastMonth': 'Minulý mesiac',
    'datePicker.jan': 'Jan',
    'datePicker.feb': 'Feb',
    'datePicker.mar': 'Mar',
    'datePicker.apr': 'Apr',
    'datePicker.may': 'Máj',
    'datePicker.june': 'Jún',
    'datePicker.july': 'Júl',
    'datePicker.aug': 'Aug',
    'datePicker.sept': 'Sept',
    'datePicker.okt': 'Okt',
    'datePicker.nov': 'Nov',
    'datePicker.dec': 'Dec',
}

const messages = {
    ...authentification,
    ...sideNav,
    ...tableUsers,
    ...userProfile,
    ...userEdit,
    ...tableInventory,
    ...inventory,
    ...tableItems,
    ...tableContainers,
    ...detailContainer,
    ...tablePayments,
    ...tableOrders,
    ...orderDetail,
    ...tableReservations,
    ...reservationDetail,
    ...finesTable,
    ...equipmentsOrderTable,
    ...priceList,
    ...datePicker,
    ...table,
    ...appAnnouncement,
    ...button,
    ...alerts,
    ...others,
};

export default messages;
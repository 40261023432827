
import { apiClient } from "api-client"
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'paths';
import { processLogout } from 'utils';

import LoadingComponent from 'components/LoadingComponent';

const LogoutPage = () => {

    const navigate = useNavigate();

    const [localLoading, setLocalLoading] = useState<boolean>(true);

    useEffect(() => {
        logOut()    
    },[]);
    
    const logOut = async () => {
        try {
          await apiClient.post( '/v1/auth/signout' )
          .then( response => {
            navigate(`/authentication${paths.auth.signIn}`);
            processLogout();
            setLocalLoading(false)
          })      
        } catch {
          console.log( 'err' );
        }
    }

    return (
        <>   
         { localLoading ? (
      <LoadingComponent /> ) : (

      null
    )}     
        </>
    );
};

export default LogoutPage;

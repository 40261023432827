import axios from "axios";
import { getAPIUrl, getDefaultLang } from "config";


export const apiClient = axios.create({
  baseURL: getAPIUrl(),
  // baseURL: `http://${window.location.hostname}:3000`,
  // artcode.sk@gmail.com
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth_token');

  if (token){
    config.headers['Authorization'] = 'Bearer ' + token;
  }

  return config;
});

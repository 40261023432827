import { ActionTypes } from "redux/actions/actionType";
import { OrdersTableType, ExtractOrderDetailType } from "types/types";

export const setOrders = ( orders: OrdersTableType, totalCount: number ) => {
    return {
        type: ActionTypes.SET_ORDERS,
        payload: { orders, totalCount }
    }
};

export const setOrdersByUserId = ( orders: OrdersTableType, totalCount: number ) => {
    return {
        type: ActionTypes.SET_ORDERS_BY_USER_ID,
        payload: { orders, totalCount }
    }
};

export const setOrdersOffset = ( offsetChange: number )  => {
    return {
        type: ActionTypes.SET_ORDERS_OFFSET,
        payload: { offset: offsetChange }
    }
};
  
export const setOrdersLimit = ( limitChange: number ) => {
    return {
        type: ActionTypes.SET_ORDERS_LIMIT,
        payload: { limit: limitChange }
    }                    
};

export const setOrdersUserOffset = ( offsetChange: number )  => {
    return {
        type: ActionTypes.SET_ORDERS_USER_OFFSET,
        payload: { offset: offsetChange }
    }
};
  
export const setOrdersUserLimit = ( limitChange: number ) => {
    return {
        type: ActionTypes.SET_ORDERS_USER_LIMIT,
        payload: { limit: limitChange }
    }                    
};

export const setOrder = ( order: ExtractOrderDetailType ) => {
    return {
        type: ActionTypes.SET_ORDER,
        payload: order,
    }
};


export const setOrderForm = ( updatedOrderForm: { status: string } ) => {
    return {
      type: ActionTypes.SET_ORDER_FORM,
      payload: updatedOrderForm,
    };
  };

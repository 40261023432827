import React, { useState, useEffect } from 'react'

import { useNavigate, useParams, } from "react-router-dom";

// axios
import axios from 'axios';
import { apiClient } from "api-client"

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setRefresh } from 'redux/actions/pageActions';
import { setOrder } from 'redux/actions/ordersActions';
import { setOrderForm } from 'redux/actions/ordersActions';
import { setFinesForm, setFines } from 'redux/actions/finesActions';
import { setItemsOrder } from 'redux/actions/inventoryActions';
// Functions
import { handleDeleteFilters } from 'context/tableContext';

// components
import LoadingComponent from 'components/LoadingComponent';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// components-lib
import OrderInfo from "components-lib/OrderInfo";
import FinesList from 'components-lib/FinesList';
import ItemsInOrder from 'components-lib/ItemsInOrder';
import AddFine from 'components-lib/AddFine';

// language
import { injectIntl, IntlShape } from 'react-intl';

// types
import { FinesTableType, ItemsInOrderType } from 'types/types';
import { 
  Refresh, 
  FilterState, 
  FinesData, 
  ItemsInOrderData, 
  OrderData 
} from 'types/typesRedux';

// exracted data
import { 
  extractDataOrder, 
  extractDataFines, 
  extractDataItemsOrder,
} from 'common/extractData';

// css
import '../../../css/mediaQuery.css'

type Props = {
  intl: IntlShape,
}

const OrderProfile = ( { intl, }: Props) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  // data from redux
  const refresh = useSelector( (state: Refresh) => state.handleRefresh );
  const order = useSelector( (state: OrderData) => state.orderDetail.order )
  const fines = useSelector( (state: FinesData) => state.allFines )
  const items = useSelector( (state: ItemsInOrderData) => state.itemsInOrder )
  const filters = useSelector( (state: FilterState) => state.filters);

  const [ localLoading, setLocalLoading ] = useState({
    order: true,
    fine: true, 
    items: true,
    }
  );

  // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
  const storedRowLimitOrder = parseInt(localStorage.getItem('rowLimitItemsOrder'));
  const initialRowLimitOrder = storedRowLimitOrder && !isNaN(storedRowLimitOrder) ? storedRowLimitOrder : items.limit;

  // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
  const storedRowLimitFines = parseInt(localStorage.getItem('rowLimitFines'));
  const initialRowLimitFines = storedRowLimitFines && !isNaN(storedRowLimitFines) ? storedRowLimitFines : fines.limit;

  const [ isFine, setIsFine ] = useState<boolean>(false);
  const [ orderId, setOrderId ] = useState<number>(null);

  const tableNameFines = intl.formatMessage( {id: 'fines.detail.title'} );
  const tableNameItems = intl.formatMessage( {id: 'equipmentsOrder.title'} );
  

  // Get order
  const getOrder = async ( id: number ) => {

    const cancelToken = axios.CancelToken.source()

    try {
      const response = await apiClient.get(`/v1/orders/${id}`,{
        cancelToken:cancelToken.token
      });

      if ( response ) {
        const responseData = extractDataOrder(intl)( response.data );
        dispatch(setOrder( responseData ));
        setLocalLoading(prevLoading => ({
          ...prevLoading,
          order: false,
        }));
      }
    } catch ( error ){
      console.log( 'err' )
    }
    return () => cancelToken.cancel()
  }
 
  // get fines
  const getFines = async ( params = {}, id: number = orderId ) => {

    const cancelToken = axios.CancelToken.source()

    const defaultParams = {
      offset: fines ? fines.offset : 0,
      limit: initialRowLimitFines,
      sort: ( filters[`${tableNameFines} sort`] &&
        filters[`${tableNameFines} sort`].sort !== undefined ) 
        ? filters[`${tableNameFines} sort`].sort 
        : fines.sort,
    };

    // Merge provided params with default params
    const mergedParams = { ...defaultParams, ...params };

    try{
      const response = await apiClient.get(`/v1/orders/${id}/fines`,{
        params: mergedParams,
        cancelToken:cancelToken.token
      });
      setLocalLoading(prevLoading => ({
        ...prevLoading,
        fine: false,
      }));

      if (response) {
        const responseData = response.data.items.map( 
        ( fines: unknown ) => extractDataFines( intl )( fines as FinesTableType ));
        const totalCount = response.data.totalCount;
        dispatch(setFines( responseData, totalCount ));
        dispatch(setRefresh( { refresh: false } ))
        setLocalLoading(prevLoading => ({
          ...prevLoading,
          fine: false,
        }));
      } else {
        console.log("No response received.");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        // todo:handle error
      }
    }      
    return () => cancelToken.cancel()
  };

  // get items
  const getItemsOrder = async ( params = {}, id: number = orderId ) => {

    const cancelToken = axios.CancelToken.source()

    const defaultParams = {
      offset: items ? items.offset : 0,
      limit: initialRowLimitOrder,
      sort: ( filters[`${tableNameItems} sort`] &&
        filters[`${tableNameItems} sort`].sort !== undefined ) 
        ? filters[`${tableNameItems} sort`].sort 
        : items.sort,
    };

    // Merge provided params with default params
    const mergedParams = { ...defaultParams, ...params };

    try{
      const response = await apiClient.get(`/v1/orders/${id}/items`,{
        params: mergedParams,
        cancelToken:cancelToken.token
      });

      if (response) {
        const responseData = response.data.items.map( 
          ( fines: unknown ) => extractDataItemsOrder( intl )( fines as ItemsInOrderType ));
          const totalCount = response.data.totalCount;
          dispatch(setItemsOrder( responseData, totalCount ));
          dispatch(setRefresh( { refresh: false } ))
          setLocalLoading(prevLoading => ({
            ...prevLoading,
            items: false,
          }));
      } else {
        console.log("No response received.");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        // todo:handle error
      }
    }      
    return () => cancelToken.cancel()
  };

  // after open page order detail, set id and get order data from api
  useEffect(() => {
    dispatch(setOrderForm( { status: '' } ));
    let orderId = parseInt(id);
    setOrderId( orderId );
    getOrder( orderId );
    getFines( {}, orderId );
    getItemsOrder( {}, orderId );
  },[id]);

  useEffect(() => {
    if ( refresh.refresh === true ) {
      dispatch(setRefresh( { refresh: false } ))
      getItemsOrder( filters[tableNameItems] )
    }
  }, [ refresh.refresh ])

  useEffect(() => {
    if ( refresh.refreshOrder === true ) {
      dispatch(setRefresh( { refreshOrder: false } ))
      getOrder( orderId );
    }
  }, [ refresh.refreshOrder ])

  useEffect(() => {
    if ( refresh.refreshFines === true ) {
      dispatch(setRefresh( { refreshFines: false } ))
      getFines();
    }
  }, [ refresh.refreshFines ])

  useEffect(() => {
    const intervalId = setInterval(() => {
      getOrder(orderId);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [orderId]);

  // handle close modal window
  const handleCloseWindow = ( bool: boolean ) => {
    if (bool === false){ 
      setIsFine(false);
    }
  }

  // Open modal window
  const handleOpenModalFine = ( bool: boolean ) => {
    // before open modal remove form data
    dispatch(setFinesForm( {
      amount: null,
      description: '',
    }
    ));
    setIsFine( true );
  }

  // Receive from modal fine is added close modal 
  const handleAddFine = ( bool: boolean ) => {
    setIsFine( false );
  }

  // go to orders table
  const handleBtnBack = () => {
    // navigate( `/admin/orders`);
    handleDeleteFilters( dispatch, tableNameItems );
  }

  return (
    <MDBox
      sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", width: "auto",
      display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "1rem",
      maxWidth: "82rem",
      }}
    >
      <MDBox container
        sx={{
          pb: "1rem", display: "flex", paddingRight: "1rem",
          direction: "row", gap: "1rem", width: "100%", 
          maxWidth: "60.7rem",
        }} >

        <MDBox item width={7} xs={1.3}
          sx= {{
            display: "flex",    
            justifyContent: "flex-start",  
            width: "33%",                      
          }} >
          <MDButton 
            variant="outlined" 
            color="success" 
            onClick={ () => { handleBtnBack(); navigate(-1); } }
            >
            {intl.formatMessage({id:'button.back'})}
          </MDButton>
        </MDBox>

        { order.status === `${intl.formatMessage({id:'order.detail.completed'})}` || 
          order.status === `${intl.formatMessage({id:'order.detail.cancelled'})}` ? (
        <MDBox 
          sx= {{
          display: "flex", justifyContent: "flex-end", 
          gap: "1rem", width: "67%",           
        }} >

          <MDButton 
            variant="gradient" 
            color="success" 
            // sx={{ backgroundColor:"#F5A524", color:"#fff"}}
            onClick={() => handleOpenModalFine( true )}
          >
            { intl.formatMessage({id:'button.addFine'}) }                  
          </MDButton>                    
        </MDBox>
            ) : null }

      </MDBox>       
      
      {/* <MDBox
        sx={{
          display: "flex",
          flexDirections: "column",
          width: "100%"
          }} > */}

        <MDBox 
          sx={{
            display: "flex", flexDirection: "column", 
            width: "100%",
            marginRight: "0rem", pb: "1rem",           
            }} >

          { ( localLoading.order ) ? (
          <LoadingComponent /> ) : (
          <MDBox 
            sx={{ 
              // minWidth:"80%",
              width: "100%",
              maxWidth:"60.7rem",
              pb: "1rem", 
              pr: "1rem" }}
          >
            <OrderInfo />          
          </MDBox>
          )} 

          <MDBox
            className= "containerOrderDetail"
            sx={{
              width: "100%",
              marginRight: "0rem", pb: "1rem",           
            }}
          >
            { ( localLoading.items ) ? (
            <LoadingComponent /> ) : (
            <MDBox 
              sx={{ 
                // minWidth: "57%", 
                width: "auto", 
                maxWidth: "55rem",
                // pb: "1rem",
              }}>
              <ItemsInOrder 
                orderId = { orderId }
                initialRowLimitOrder = { initialRowLimitOrder }
              /> 
            </MDBox>
            )}

            { ( localLoading.fine ) ? (
            <LoadingComponent /> ) : (
            <MDBox 
              sx={{
                // minWidth: "43%", 
                width: "auto",
                maxWidth: "35rem", 
                pb: "1rem", 
                }}>
              { ( fines && fines.totalCount > 0 ) && (
                <FinesList 
                  orderId = { orderId }
                  initialRowLimitFines = { initialRowLimitFines }
                />
              )} 
            </MDBox>
            )}
          </MDBox>
        </MDBox>

        { isFine && (
          <AddFine
            onCloseWindow={ handleCloseWindow }
            onAddFine={ handleAddFine }
            orderId={ orderId }
          />
        )}

      {/* </MDBox> */}
    </MDBox>
  )
}

export default injectIntl(OrderProfile)
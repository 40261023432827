/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { ReactNode } from "react";

import { truncate } from 'lodash';

// @mui material components
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Declaring prop types for DataTableBodyCell
interface Props {
  children: ReactNode;
  noBorder?: boolean;
  align?: "left" | "right" | "center";
  cell?: any;
}

function DataTableBodyCell({ noBorder, align, children, cell }: Props): JSX.Element {

  const shortenText = ( str: string, len = 10 ): string => {
    return truncate(str, { length: len })
  }

  const renderContent = (content: React.ReactNode): React.ReactNode => {
    if (typeof content === 'string') {
      // Truncate text content
      return shortenText(content);
    } else if (React.isValidElement(content)) {
      // Leave other elements unchanged
      return content;
    } else {
      // Handle other content types as needed
      return String(content); // Convert to string, you can customize this behavior
    }
  };

  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      // px={0.5}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }: Theme) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
        paddingLeft: "1.1rem",
      })}
      className={cell.column.id}
    >
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}        
      >
        {renderContent(children)}
        
      </MDBox>
    </MDBox>
  );
}

// Declaring default props for DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

export default DataTableBodyCell;

import { ActionTypes } from 'redux/actions/actionType';
import { OrdersState, SetOrdersAction, OrderDetailState, SetOrderAction } from 'types/typesRedux';


// orders data
const ordersState: OrdersState = {
    orders: [],
    totalCount: 0,
    offset: 0,
    limit: 10,
    sort: 'id:desc',
};

export const ordersReducer = (state= ordersState, action: SetOrdersAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_ORDERS:
            return { 
                ...state, 
                orders: action.payload.orders,
                totalCount: action.payload.totalCount};
        case ActionTypes.SET_ORDERS_OFFSET:
            return { ...state, offset: action.payload.offset };
        case ActionTypes.SET_ORDERS_LIMIT:
            return { ...state, limit: action.payload.limit };
        case ActionTypes.RESET_OFFSET:
            return { ...state, offset: 0 };
        default:
            return state;
    }
};


// orders data by providing user id
const ordersByUserIdState: OrdersState = {
    orders: [],
    totalCount: 0,
    offset: 0,
    limit: 10,
    sort: 'id:desc',
};

export const ordersByUserIdReducer = (state= ordersByUserIdState, action: SetOrdersAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_ORDERS_BY_USER_ID:
            return { 
                ...state, 
                orders: action.payload.orders,
                totalCount: action.payload.totalCount};
        case ActionTypes.SET_ORDERS_USER_OFFSET:
            return { ...state, offset: action.payload.offset };
        case ActionTypes.SET_ORDERS_USER_LIMIT:
            return { ...state, limit: action.payload.limit };
        default:
            return state;
    }
};

// order data
const orderState: OrderDetailState = {
    order: [],

    orderForm: {
        status: '',
    }
};


export const orderReducer = (state = orderState, { type, payload }: SetOrderAction )  => {
    switch ( type ) {
        case ActionTypes.SET_ORDER:
            return { ...state, order: payload };
        case ActionTypes.SET_ORDER_FORM:
            return { ...state, orderForm: payload };
        default:
            return state;
    }
};
import React, { useEffect } from 'react'

import { useNavigate } from "react-router-dom";

import { apiClient } from "api-client"

import queryString from "query-string";

//  Props
type Props = {
}

const Magic = (props: Props) => {

  const navigate = useNavigate()

    useEffect( () => {
      signin();      
    },[]) 

  const homePage = () => {
    navigate('/admin/users');
  };

  const getToken = (): string | null => {
      return queryString.parse(window.location.search).token as string;
  }


  const signin = async () => {
    try {
      const token = getToken();
      const response = await apiClient.post('/v1/auth/signin', { token });
      const queryToken = response.data.token as string;
      const queryExpires = response.data.expiresAt as string;
      // console.log(response.data);
      // console.log(response);
      localStorage.setItem('auth_token', queryToken);
      localStorage.setItem('expires_token', queryExpires);
      // localStorage.setItem('expires_token', '2023-11-05T08:18:25.706Z');
      homePage();
    } catch (error) {
      console.log('err');
    }
  }
    
  return (
    <div>
        ''
    </div>
  )
}

export default Magic
import { ActionTypes } from "redux/actions/actionType";
import { FinesTableType, FinesForm } from "types/types";

export const setFines = ( fines: FinesTableType, totalCount: number ) => {
    return {
        type: ActionTypes.SET_FINES,
        payload: { fines, totalCount }
    }
};

export const setFinesOffset = ( offsetChange: number )  => {
    return {
        type: ActionTypes.SET_FINES_OFFSET,
        payload: { offset: offsetChange }
    }
};
  
export const setFinesLimit = ( limitChange: number ) => {
    return {
        type: ActionTypes.SET_FINES_LIMIT,
        payload: { limit: limitChange }
    }                    
};

export const setFinesForm = ( updatedUserForm: FinesForm ) => {
    return {
      type: ActionTypes.SET_FINES_FORM,
      payload: updatedUserForm,
    };
};



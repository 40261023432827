import React from 'react'

import { Theme } from "@mui/material/styles";

import { useSelector } from 'react-redux';

import ClearIcon from '@mui/icons-material/Clear';

import MDBox from "components/MDBox";

// types
import { FilterState } from 'types/typesRedux';

interface Props {
    tableName: string,
    align: "left" | "center" | "right",
    inputLabel: string,
    clearIcon: boolean,
    onClearInputs?: ( bool: boolean ) => void;
}

const TitleTable = ({tableName, align, inputLabel, clearIcon, onClearInputs}: Props) => {

  const filterState = useSelector((state: FilterState) => state.filters);

  const handleClear = () => {
    onClearInputs(true);    
  }

  return (
    <MDBox
      className={inputLabel}
      display="flex" flexDirection="row"
      right={align !== "right" ? "16px" : 0}
      left={align === "right" ? "-5px" : "unset"}
      sx={({ typography: { size } }: any) => ({
        fontSize: size.lg,
      })}
    >
      <MDBox
        py={1.5}
        paddingLeft="0.5rem"
        sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({ 
        borderBottom: "0",   
        display:"flex", 
        flexDirection:"row",
        alignItems:"center",
        // position: "relative",
        textAlign: "left",
        opacity: "0.7",
        background: "transparent",
        color: "#7b809a",
        fontSize: "0.75rem",
        fontWeight: "700",
        wordWrap:"unset",
        width: "100%",
        height:"100%",
        })}
      >
        <MDBox>
          { inputLabel }
        </MDBox>
        { clearIcon === true && (
          <MDBox 
            sx={{ fontSize:"1.5rem", paddingLeft:"0.4rem", marginBottom:"0.2rem",height:"0.5rem", }}
          >
            <ClearIcon sx={{ 
              opacity: "0.6",
              marginTop: "-0.35rem",
              marginRight: "-0.7rem",
              marginBottom:"0.7rem", 
              // paddingBottom: "0.3rem",
              border: "1px solid transparent",
              borderRadius: "50%",
              transition: "opacity 0.5s ease, border-color 0.5s ease",
              visibility: clearIcon === true && filterState[tableName] && Object.keys(filterState[tableName]).length > 0 ? 'visible' : 'hidden',
              "&:hover": {
                opacity: 1, // Change opacity on hover
                cursor: "pointer",
                borderColor: '#7b809a',                 
        },}}
              onClick={handleClear}
            />
          </MDBox>
        )}
      </MDBox>    
    </MDBox>
  )
};

export default TitleTable;
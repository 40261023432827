import React, { useState, useEffect } from 'react'

import { useNavigate, } from "react-router-dom";

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setContainers } from 'redux/actions/containersActions';
import { setRefresh } from 'redux/actions/pageActions';
import { setItemsOffset } from 'redux/actions/inventoryActions';

// language
import { injectIntl, IntlShape } from 'react-intl';

// components
import QueryTableDevices from 'components-lib/QueryTable/QueryTableDevices';
import LoadingComponent from 'components/LoadingComponent';
import ErrorComponent from 'components/ErrorComponent';

// Material UI
import { Grid } from '@mui/material';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';

// extracted data
import { extractDataContainers } from 'common/extractData';

// types
import { ContainersTableType, ColumnWidths } from 'types/types';
import { ContainersData, Refresh } from 'types/typesRedux';

type Props = {
  intl: IntlShape;
}

const ContainersTable = ( {intl}: Props ) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const currentLocale = intl.locale;
  
  // data from redux
  const data = useSelector( (state: ContainersData ) => state.containers )
  const refresh = useSelector( (state: Refresh) => state.handleRefresh);

  const [localLoading, setLocalLoading] = useState<boolean>(true);
  const [isError, setIsError ] = useState<boolean>(false);

  const getContainers = async () => {
    const cancelToken = axios.CancelToken.source()
    
    try{
      const response = await apiClient.get("/v1/containers",{
        cancelToken:cancelToken.token
      });

      if (response) {
        const responseData = response.data.items.map( 
          ( containers: unknown ) => extractDataContainers(intl)(containers as ContainersTableType) );
        const totalCount = response.data.totalCount;
        dispatch(setContainers( responseData, totalCount ));
        if (isError === true) {setIsError(false)};
      } else {
        console.log("No response received.");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        // todo:handle error
      }
      setIsError(true)
    }
    setLocalLoading(false);
    return () => cancelToken.cancel()   
  };
  
  // Refresh when open page
  useEffect(() => {
    if ( refresh.refresh === true ) {
      dispatch(setRefresh( { refresh: false } ));
    }

    getContainers();

    const interval = setInterval(getContainers, 120000);
    
    return () => clearInterval(interval);
  },[] )


  // If click on detail button set offset 0 and go to page detail container
  const handleDetailItem = ( id: number ) => {
    dispatch(setItemsOffset( 0 ));
    navigate( (`/admin/containers/container-detail/${id}`), { state: {
      id: id,
      }} 
    );
  }

  const getColumnMinWidth = (columnId: string, locale: string): string => {
    const minWidths: ColumnWidths = {
      doorStatusA: { default: "10%", hu: "12%" },
      doorStatusB: { default: "10%", hu: "12%" },
    };
  
    const columnConfig = minWidths[columnId];
    if (columnConfig) {
      return columnConfig[locale] || columnConfig.default;
    } else {
      return "auto";
    }
  };

  const handleRefresh = () => {
    getContainers();
  }

  if ( isError ) {
    return <ErrorComponent onPress={handleRefresh} />;
  }

  return (
    <Grid >
        { localLoading ? (
            <LoadingComponent /> ) : (

            <Grid 
            sx={{ maxWidth: "90rem", }}
            >
                <QueryTableDevices
                title= {intl.formatMessage( {id: 'tableContainers.title'} )}
                tableData={ data.containers }
                pagePagination={false}
                totalCount={ data.totalCount }
                columns= {[
                    { Header: intl.formatMessage( {id: 'tableUsers.id'} ), 
                    accessor: "id", 
                    width: "auto",
                    minWidth: "3%",
                    input: 'title',
                    },
                    { Header: intl.formatMessage( {id: 'tableContainers.name'} ), 
                    accessor: "name", 
                    width: "auto",
                    minWidth: "10%",
                    input: 'title',
                    },
                    { Header: intl.formatMessage( {id: 'tableContainers.status'} ), 
                    accessor: "status", 
                    width: "auto",
                    minWidth: "10%",
                    input: 'title',
                    },
                    { Header: intl.formatMessage( {id: 'tableContainers.boatsCount'} ), 
                    accessor: "boatsCount", 
                    width: "auto",
                    minWidth: "8%",
                    input: 'title',
                    },
                    { Header: intl.formatMessage( {id: 'tableContainers.equipmentCount'} ), 
                    accessor: "equipmentCount", 
                    width: "auto",
                    minWidth: "8%",
                    input: 'title',
                    },
                    { Header: intl.formatMessage( {id: 'tableContainers.capacity'} ), 
                    accessor: "capacity", 
                    width: "auto",
                    minWidth: "8%",
                    input: 'title',
                    },
                    { Header: intl.formatMessage( {id: 'tableContainers.isEnabled'} ), 
                    accessor: "isEnabled", 
                    width: "auto",
                    minWidth: "10%",
                    input: 'title',
                    },
                    { Header: intl.formatMessage( {id: 'tableContainers.doorStatusA'} ), 
                    accessor: "doorStatusA", 
                    width: "auto",
                    minWidth: getColumnMinWidth("doorStatusA", currentLocale),
                    input: 'title',
                    },
                    { Header: intl.formatMessage( {id: 'tableContainers.doorStatusB'} ), 
                    accessor: "doorStatusB", 
                    width: "auto",
                    minWidth: getColumnMinWidth("doorStatusB", currentLocale),
                    input: 'title',
                    },
                    { Header: intl.formatMessage({id: 'table.actions'}), 
                    accessor: "Action", 
                    width: "auto",
                    minWidth: "4%",
                    input: 'title',
                    clearIcon: false,
                    },
                ]}
                button={ [{
                  add: null,
                  detail: true,
                  edit: false,
                  delete: false,
                  block: false,
                }] }
                icon= {<CalendarViewWeekIcon />}
                onOffset={ null }
                onLimit={ null }
                onAddItem={ null }
                onDetailItem={ handleDetailItem }
                onEditBtn={ null }
                onBlockItem={ null }
                onRemoveBtn={ null }
                />

            </Grid>
        )}
    </Grid>
  );
};

export default injectIntl(ContainersTable);
import React, { useState } from 'react'

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setItemsOrderOffset, setItemsOrderLimit } from 'redux/actions/inventoryActions';

import { injectIntl, IntlShape } from 'react-intl';

// components
import QueryTableDevices from 'components-lib/QueryTable/QueryTableDevices';
import MDTypography from 'components/MDTypography';

import { Grid } from '@mui/material';

// icon
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

// Enums
import { itemsOrderEnum, } from 'common/enums/DevicesStateEnum';

// css
import '../../css/image.css'

// types
import { ColumnWidths } from 'types/types';
import { ItemsInOrderData } from 'types/typesRedux';


type Props = {
  intl: IntlShape;
  orderId: number;
  initialRowLimitOrder: number;
}

const ItemsInOrder = ( { intl, orderId, initialRowLimitOrder }: Props ) => {

  const dispatch = useDispatch();

  const currentLocale = intl.locale;

  // redux
  const data = useSelector( (state: ItemsInOrderData) => state.itemsInOrder )

  const isPicture = data.itemsInOrder.filter( item => item.name === 'Loď' ).map(item => item.photoUrl);;

  const [ isImgsViewer, setIsImgsViewer ] = useState<boolean>(false);
  const tableName = intl.formatMessage( {id: 'equipmentsOrder.title'} );

  // Handle offset
  const handleOffset = ( tableOffset: number ) => {
    dispatch(setItemsOrderOffset( tableOffset ));
  }
  
  // Handle limit
  const handleLimit = ( tableLimit: number ) => {
    dispatch(setItemsOrderLimit( tableLimit ));
    localStorage.setItem('rowLimitItemsOrder', tableLimit.toString());
  }

  //
  // */ Handle image
  // 

  const [ imgs, setImgs ] = useState('');

  const handleImgsViewer = ( photoUrl: string ) => {
    setImgs( photoUrl );
    setIsImgsViewer( true )    
  }

  const handleCloseImgsViewer = () => {
      setIsImgsViewer( false )
  }    

  const getColumnMinWidth = (columnId: string, locale: string): string => {
    const minWidths: ColumnWidths = {
      id: { default: "5.6rem", hu: "7rem" },
      name: { default: "5.6rem", hu: "7rem" },
      rentPlace: { default: "5.6rem", hu: "7rem" },
    };
  
    const columnConfig = minWidths[columnId];
    if (columnConfig) {
      return columnConfig[locale] || columnConfig.default;
    } else {
      return "auto";
    }
  };


  return (
    <Grid container>

      <Grid >

        <QueryTableDevices
          title= { tableName } 
          tableData={ data.itemsInOrder }
          pagePagination={true}
          totalCount={ data.totalCount }
          offset={ data.offset }
          rowLimit={ initialRowLimitOrder }  
          marginLeft= { false } 
          isPicture={ isPicture }
          columns= {[
            { Header: intl.formatMessage( {id: 'equipmentsOrder.id'} ), 
            accessor: "id", 
            width: "auto",
            minWidth: getColumnMinWidth("id", currentLocale),
            maxWidth: "6rem",
            columnFilter: 'number',
            input: 'input',
            },
            { Header: intl.formatMessage({id: 'tableInventory.type'}), 
            accessor: "name", 
            width: "auto",
            minWidth: getColumnMinWidth("name", currentLocale),
            input: 'autocomplete',
            filterOptions: Object.values(itemsOrderEnum(intl)).map((item) => {
              return ({
                value: item.filterKey,
                label: item.label,
                key: item.filterValue
              })
            })
            },
            { Header: intl.formatMessage( {id: 'equipmentsOrder.rentPlace'} ), 
            accessor: "rentPlace", 
            width: "auto",
            minWidth: "20%",
            columnFilter: 'string',
            input: 'title',
            },
            { Header: intl.formatMessage( {id: 'equipmentsOrder.returned'} ), 
            accessor: "returnPlace", 
            width: "auto",
            minWidth: "20%",
            input: 'title',
            },
            { Header: intl.formatMessage( {id: 'equipmentsOrder.price'} ), 
            accessor: "price", 
            minWidth: "10%",
            width: "auto",
            columnFilter: 'number',
            input: 'title',
            },
            { Header: intl.formatMessage({id: 'table.actions'}), 
            accessor: "Action", 
            width: "auto",
            minWidth: "5%",
            input: 'title',
            clearIcon: true,
            },
            ]}
          button={ [{
              add: null,
              detail: false,
              edit: false,
              delete: false,
              block: false,
              picture: true,
          }] }
          icon= { false }
          onOffset={ handleOffset }
          onLimit={ handleLimit }
          onAddItem={ null }
          onEditBtn={ null }
          onBlockItem={ null }
          onRemoveBtn={ null }
          onPictureItem={ handleImgsViewer }
        />

      </Grid>

        { ( isImgsViewer ) && 
          <div className="lightbox" >
            { ( imgs ) ? (
              <>
                <img src={ imgs } alt="Kayak" className="lightbox-image" />
                <MDTypography 
                variant="button" color="secondary"
                fontSize="0.9rem" fontWeight="regular" mt="0.5rem"
              >{intl.formatMessage({id: 'tableInventory.kayak'})}</MDTypography>
              </>
            ) : (
              <MDTypography 
                variant="button" color="secondary"
                fontSize="2.5rem" fontWeight="medium"
              >{intl.formatMessage({id: 'tableInventory.noImage'})}</MDTypography>
              )
            }
            <IconButton onClick={handleCloseImgsViewer}   
              style={{ position: 'absolute', top: 20, 
              right: 20, color: 'white'
            }}>
              <CloseIcon />
            </IconButton>
          </div>
        }
      
    </Grid>
  );
};

export default injectIntl(ItemsInOrder);
import messages from 'locales/messages/en';

interface LanguageConfig {
    locale: string;
    messages: Record<string, string>;
};
  
const enConfig: LanguageConfig = {
    locale: 'en',
    messages
};
  
export default enConfig;
import React, { useEffect, useRef, useMemo, useState } from 'react';

import DOMPurify from "dompurify";

import _debounce from 'lodash/debounce';

// redux
import { useSelector } from 'react-redux';

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { IconButton, InputAdornment } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

// css
import '../../css/input.css';

// types
import { FilterState } from 'types/typesRedux';

interface Props {
    tableName: string;
    align: "left" | "center" | "right",
    inputLabel: string,
    columnId: string,
    columnFilter: string,
    activeColumn: string,
    sortDirection: string,

    onInputBounce: ( bool: boolean ) => void,
    onSearchString: (search: string | number, title: string ) => void,
    onAscDesc: ( column: string, sort: 'asc' | 'desc' ) => void,
    onClearInput?: ( bool: boolean, column: string | number ) => void;
}

const Input = ({ 
    tableName,
    align, 
    inputLabel, 
    columnId, 
    columnFilter,
    activeColumn, 
    sortDirection,
    onInputBounce, 
    onSearchString,
    onAscDesc,
    onClearInput,
    }: Props) => {

    const filters = useSelector((state: FilterState) => state.filters);

    const inputValue = useRef<HTMLInputElement>(null)
    
    const [ filterValue, setFilterValue ] = useState(null)

    // Handle which input is number or string
    const inputContents = () => {

        if ( columnFilter === 'number' ) {
            let inputContentNumber = filters[tableName] && filters[tableName]?.[columnId];
            return inputContentNumber;
        } else {
            let inputContent = filters[tableName] && filters[tableName][columnId + '[contains]'];
            return inputContent;
        }
    }


    const debounceFunction = useMemo(
        () => _debounce((filterValueParam) => {
            onInputBounce( true );
            setFilterValue(null)
        }, 500),
        []
    );

    useEffect(() => {
        if ( filterValue !== null ) {
            debounceFunction( filterValue )
        }
    },[ filterValue ] )

    
    const handleInput = () => {
        let searchString = DOMPurify.sanitize( inputValue.current?.value );
       

        if ( columnFilter === 'number' ) {
            let searchValue = parseInt(searchString, 10);

            if (!isNaN(searchValue)) {
                onSearchString( searchValue, columnId ); 
            } else if (searchString === '') {
                onSearchString('', columnId); // Handle empty input case
        }
        } else {
            onSearchString( searchString, columnId + '[contains]' );
        }
    };

    const handleClearFilter = ( column: string ) => {
        
        if ( columnFilter === 'number' ) {
            onClearInput( true, column );
        } else {
            onClearInput( true, column + '[contains]' );
            // onClearInput( true, column );
        }
    }

    return (
        <MDBox      
            className={`${columnId} CustomInput`}
            display="flex" flexDirection="row"
            right={align !== "right" ? "16px" : 0}
            left={align === "right" ? "-5px" : "unset"}
            sx={({ typography: { size } }: any) => ({
                fontSize: size.lg,         
            })}      
            >
            <MDInput            
                right={align !== "right" ? "16px" : 0}
                left={align === "right" ? "-5px" : "unset"}
                sx={({ typography: { size } }: any) => ({
                fontSize: size.lg,
                width: "100%",
                paddingRight: "0rem",
                // '& .css-qnqh4t-MuiInputBase-input-MuiOutlinedInput-input': {
                //     // paddingRight: "0rem !important", 
                //     // width: "90%"                  
                //     },
                // '& .css-fnfwl6-MuiInputBase-root-MuiOutlinedInput-root': {
                //     // paddingRight: "0rem !important", 
                //     // width: "90%"                  
                //     },
                // '& .MuiInputLabel-root': {
                //     transform: 'translate(8px, 9px) scale(1)',
                // transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                //     },
                })
                }
                size="small"
                label={inputLabel}
                variant="outlined"
                inputRef={ inputValue }
                value={ inputContents() && inputContents() !== undefined ? inputContents() : '' }
                // inputValue={ inputContents() && inputContents() !== undefined ? inputContents() : '' }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleInput(); 
                    setFilterValue( e.target.value )
                    // debouncedHandleInput();
                }}
                InputProps={{
                    endAdornment: (
                        // <InputAdornment position="end"
                        // sx={{ paddingLeft: "-2rem", paddingRight: "0rem", fontSize: "1.1rem"}}
                        // >
                        <>
                        { inputValue.current?.value && ( 
                            <IconButton 
                                sx={{ paddingLeft: "0rem", paddingRight: "0.9rem", fontSize: "1.1rem"}}
                                edge="end" onClick={() => handleClearFilter( columnId )}>
                                <ClearIcon>clear_icon_component</ClearIcon>
                            </IconButton>
                        )}
                        </>
                        // </InputAdornment>
                    ),
                }}
            />

            <MDBox
                sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0rem",
                lineHeight: "0rem",
                scale: '0.9',
                }}
            >
                <MDBox
                    sx={{ '&:hover': { opacity: 1, }, }}
                    color={activeColumn === String(columnId) && sortDirection === 'asc' ? 'text' : 'secondary'}
                    opacity={activeColumn === String(columnId) && sortDirection === 'asc' ? 1 : 0.5}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onAscDesc(String(columnId), 'asc')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                    </svg>
                {/* <Icon>arrow_drop_up</Icon> */}
                </MDBox>
                <MDBox
                    sx={{ '&:hover': { opacity: 1, }, }}
                    color={activeColumn === String(columnId) && sortDirection === 'desc' ? 'text' : 'secondary'}
                    opacity={activeColumn === String(columnId) && sortDirection === 'desc' ? 1 : 0.5}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onAscDesc(String(columnId), 'desc')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                    </svg>
                {/* <Icon>arrow_drop_down</Icon> */}
                </MDBox>
            </MDBox>
        </MDBox>
    )
}

export default Input;

import React from 'react';

import { injectIntl, IntlShape } from 'react-intl';

// components
import MDButton from 'components/MDButton';

import RefreshIcon from '@mui/icons-material/Refresh';

interface Props {
    intl: IntlShape,
    onPress: () => void,
    message?: string,
}

const ErrorComponent = ( { intl, onPress, message }: Props ) => {

    return (
        <div className="loading-container"
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: "10",
        }}>
            <p
                style= {{ display:"flex", flexDirection:"row", justifyContent:"center",
                fontSize:"1.1rem", marginTop:"1.5rem"}}>
                {intl.formatMessage({id: 'errorLoadData'})}
            </p>
            <div
                style={{ display:"flex", width: "100%", flexDirection:"row", justifyContent:"center",}}
            >
                <MDButton 
                    onClick= {onPress} 
                    style= {{ display:"flex", flexDirection:"row", justifyContent:"center",
                    marginTop:"1.5rem"}} 
                    variant="contained" 
                    color="success"    
                >
                    <RefreshIcon 
                        style= {{ width: "1.5rem", height: "1.5rem", }}
                    />
                </MDButton>
            </div>
        </div>
    );
};

export default injectIntl(ErrorComponent);
import { ActionTypes } from "redux/actions/actionType";

// types
import { 
    SetRefreshAction,
} from "types/typesRedux";

export const setRefresh = ( refresh: SetRefreshAction['payload'] ) => {
    return {
        type: ActionTypes.SET_REFRESH,
        payload: refresh,
    }
}



import { ReactNode, useEffect } from "react";

// @mui material components
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";

import { injectIntl, IntlShape } from 'react-intl';

// components
import AutoComplete from 'components/Autocomplete';
import Input from 'components/Input';
import TitleTable from 'components/titleTable';
import TitleDatum from 'components/TitleDatum';

// Types for children
type Column = {
  input: 'autocomplete' | 'title' | 'input' | 'datum';
  filterOptions?: [];
  Header: string;
  id?: string;
  align?: 'left' | 'center';
  columnFilter?: string;
  clearIcon?: boolean;
}

// Declaring props types for DataTableHeadCell
type Props = {
  intl: IntlShape;
  tableName: string;
  width?: string | number;
  minWidth?: string | number;  
  maxWidth?: string | number;  
  children: Column;
  sorted?: false | "none" | "asce" | "desc";
  align?: "left" | "right" | "center";
  activeColumn:string; 
  sortDirection:string; 
  onSortColumn?: ( child: any, ascDesc: string ) => void;
  onClearInput?: ( bool: boolean, column: string | number ) => void;
  onClearDateTime?: ( bool: boolean, column: string | number ) => void;
  onClearInputs?: ( bool: boolean ) => void;
  onInputBounce: ( bool: any ) => void;
  onSearch: ( search: string | number, column: string ) => void;
  onAutocomplete: ( label: string | number, column: string ) => void;
  onDateRangePicker?: ( bool: boolean, column: string ) => void;
}

function DataTableHeadCell({ 
  intl,
  tableName,
  width, 
  minWidth,
  maxWidth,
  children, 
  sorted, 
  align, 
  activeColumn, 
  sortDirection, 
  onSortColumn,
  onClearInput,
  onClearDateTime,
  onClearInputs,
  onInputBounce,
  onSearch,
  onAutocomplete,
  onDateRangePicker,
  }: Props): JSX.Element {
        
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;  

  const getAutoComplete = ( column: Column ) => {
    // Check if the column has filterOptions, if true return autocomplete
    if ( column.input === 'autocomplete' ) {
      return <AutoComplete 
                filterOptions={column.filterOptions} 
                tableName={ tableName }
                align={'left'}
                inputLabel={column.Header}
                columnId={column.id}
                activeColumn={ activeColumn }
                sortDirection={ sortDirection }
                onAscDesc={ handleAscDesc }
                onSearchString={ handleSearchString }
                onAutocomplete={ onAutocomplete }
                onClearInput={ onClearInput }
              />;
    } else if ( column.input === 'title' ) {
      return <TitleTable 
                tableName={ tableName }
                align={'center'}
                inputLabel={ column.Header } 
                clearIcon= { column.clearIcon }
                onClearInputs={ onClearInputs }              
              />;
    } else if ( column.input === 'input' ){
      return <Input 
                tableName={ tableName }
                align={'left'}
                inputLabel={column.Header}
                columnId={column.id}
                columnFilter={column.columnFilter}
                activeColumn={ activeColumn }
                sortDirection={ sortDirection }
                onInputBounce={ onInputBounce }
                onAscDesc={ handleAscDesc }
                onSearchString={ handleSearchString }
                onClearInput={ onClearInput }
              />;
    } else if ( column.input === 'datum' ){
      return <TitleDatum 
                tableName={ tableName }
                align={'left'}
                inputLabel={column.Header}
                columnId={column.id}
                activeColumn={ activeColumn }
                sortDirection={ sortDirection }
                onAscDesc={ handleAscDesc }
                onDateRangePicker={ onDateRangePicker }
                onClearDateTime={ onClearDateTime }
              />;
    }
  };

  const handleSearchString = ( search: string | number, column: string ) => {
    onSearch( search, column )
  }

  const handleAscDesc = ( column: string | number, sort: 'asc' | 'desc' ) => {
    onSortColumn( column, sort )
  }

  return (

    <MDBox
      component="th"
      width={width}
      py={1.5}
      px={1.1}
      sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,     
      })}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      { getAutoComplete( children ) }
     
    </MDBox>
  
  );
}

export default injectIntl(DataTableHeadCell);
import { ActionTypes } from "redux/actions/actionType";
import { Announcement, AnnouncementForm } from "types/types";

export const setAnnouncement = ( announcement: Announcement ) => {
    return {
        type: ActionTypes.SET_ANNOUNCEMENT,
        payload: announcement
    }
};

export const setAnnouncementForm = ( updatedAnnouncementForm: AnnouncementForm ) => {
    return {
      type: ActionTypes.SET_ANNOUNCEMENT_FORM,
      payload: updatedAnnouncementForm,
    };
};



import { ActionTypes } from 'redux/actions/actionType';

// types
import { 
    ContainersState,
    SetContainersAction,
    ContainerState,
    SetContainerAction,
} from 'types/typesRedux';

const containersState: ContainersState = {
    containers: [],
    totalCount: 0,
};

export const containersReducer = (state= containersState, { type, payload }: SetContainersAction ) => {
    switch ( type ) {
        case ActionTypes.SET_CONTAINERS:
            return { 
                ...state, 
                containers: payload.containers,
                totalCount: payload.totalCount,
            };
        default:
            return state;
    }
};


const containerState: ContainerState = {
    container: [],
};

export const containerReducer = (state= containerState, { type, payload }: SetContainerAction ) => {
    switch ( type ) {
        case ActionTypes.SET_CONTAINER:
            return { ...state, container: payload };
        default:
            return state;
    }
};
import { ActionTypes } from 'redux/actions/actionType';
import { 
    PaymentsState, 
    SetPaymentsAction, 
    PaymentsByUserState,
    SetPaymentsByUserAction,
} from 'types/typesRedux';



const paymentsState: PaymentsState = {
    payments: [],
    totalCount: 0,
    offset: 0,
    limit: 10,
    sort: 'id:desc',
};

export const paymentsReducer = (state= paymentsState, action: SetPaymentsAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_PAYMENTS:
            return { 
                ...state, 
                payments: action.payload.payments,
                totalCount: action.payload.totalCount};
        case ActionTypes.SET_PAYMENTS_OFFSET:
            return { ...state, offset: action.payload.offset };
        case ActionTypes.SET_PAYMENTS_LIMIT:
            return { ...state, limit: action.payload.limit };
        case ActionTypes.RESET_OFFSET:
            return { ...state, offset: 0 };
        default:
            return state;
    }
};

const paymentsByUserState: PaymentsByUserState = {
    payments: [],
    totalCount: 0,
    offset: 0,
    limit: 10,
    sort: 'id:desc',
};

export const paymentsByUserReducer = (state= paymentsByUserState, action: SetPaymentsByUserAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_PAYMENTS_BY_USER:
            return { 
                ...state, 
                payments: action.payload.payments,
                totalCount: action.payload.totalCount};
        case ActionTypes.SET_PAYMENTS_USER_OFFSET:
            return { ...state, offset: action.payload.offset };
        case ActionTypes.SET_PAYMENTS_USER_LIMIT:
            return { ...state, limit: action.payload.limit };
        default:
            return state;
    }
};


import { useEffect, useState } from 'react';

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setAnnouncement } from 'redux/actions/announcementActions';
import { setRefresh } from 'redux/actions/pageActions';

// components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import AddAnnouncement from 'components-lib/AddAnnouncement';
import RemoveItem from 'components-lib/RemoveItem';

import Card from "@mui/material/Card";

// language
import { injectIntl, IntlShape } from 'react-intl';

// notifications
import { useSnackbar } from "notistack";

// types
import { Refresh, AnnouncementData } from 'types/typesRedux';

type Props = {
    intl: IntlShape,
}

const AppAnnouncement = ({ intl }: Props) => {

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    // redux hook
    const announcement = useSelector( (state: AnnouncementData) => state.appAnnouncement.announcement );
    const refresh = useSelector( (state: Refresh) => state.handleRefresh);

    // hooks
    const [ isAnnouncement, setIsAnnouncement ] = useState( false );
    const [ isRemove, setIsRemove ] = useState( false );

    // Get announcement
    const getAnnouncement = async () => {
        const cancelToken = axios.CancelToken.source()
        try{
            const response = await apiClient.get(`/v1/announcement`,{
                cancelToken:cancelToken.token
            });

            if (response) {
                // const responseData = extractDataContainer(intl)(response.data);
                dispatch(setAnnouncement( response.data ));
                // console.log(response.data)
            } else {
                console.log("No response received.");
            }
        } catch (err) {
            if (axios.isCancel(err)) {
                console.log("canceled")
            } else {
                // todo:handle error
            }
        }
        if ( refresh.refreshAnnouncement === true ) dispatch(setRefresh( { refreshAnnouncement: false } ));
        return () => cancelToken.cancel()
    }

    // Handle delete user
    const deleteAnnouncement = async () => {
        const cancelToken = axios.CancelToken.source();
        try {  
            const response = await apiClient.delete(`/v1/announcement`,{
            cancelToken:cancelToken.token
            });
            if ( response ) {
                dispatch(setRefresh( { refreshAnnouncement: true } ));
                enqueueSnackbar(`${intl.formatMessage({id:'appAnnouncementAlert.remove.true'})}`, { variant: 'success' });
            }
        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar(`${intl.formatMessage({id:'appAnnouncementAlert.remove.false'})}`, { variant: 'error' });
        }
        return () => cancelToken.cancel()
    }

    useEffect(() => {
        getAnnouncement();
    }, []);
    
    useEffect(() => {
        if ( refresh.refreshAnnouncement === true ) {
            getAnnouncement();
        }
    }, [ refresh.refreshAnnouncement ]);

    // handle open modal window add announcement
    const handleAddAnnouncement = () => {
        setIsAnnouncement( true );
    }

    // handle open modal window remove announcement
    const handleOpenRemoveModal = () => {
        setIsRemove( true );
    }
    
    // handle delete announcement and close modal window
    const handleRemoveAnnouncement = ( bool: boolean ) => {
        if ( bool === true ) {
            deleteAnnouncement();
            setIsRemove( false );
        }
    }

    // handle close modal windows if click cancel
    const handleCloseWindow = ( bool: boolean ) => {
        if ( bool === false ) {
            setIsAnnouncement( false );
            setIsRemove( false );
        }
    }

    return (
        <>
            <MDBox 
                sx={{ display:"flex", flexDirection:"row", justifyContent:"flex-end",
                    marginLeft: "max(18.7rem)",
                }}>

                    <Card 
                        sx={{ width: "auto", display:"flex", flexDirection:"row", gap:"1rem",
                            alignItems:"center", marginTop:"1rem", marginRight:"1rem", 
                            padding: "1rem",
                        }}>
                        <MDTypography 
                            variant= "button"
                            sx={{ 
                                fontWeight:"bold", fontSize:"1.2rem"     
                            }} >
                            {intl.formatMessage( {id: 'appAnnouncement.title'} )}
                        </MDTypography>
                    

                { announcement && ( announcement.content || announcement.content === null ) &&
                    <MDButton 
                        variant="gradient" 
                        color="success"
                        onClick={handleAddAnnouncement}
                        sx={{ width: "14rem"}}>
                        { announcement?.content ? intl.formatMessage( {id: 'button.editAnnouncement'} ) : 
                            intl.formatMessage( {id: 'button.addAnnouncement'} ) }
                    </MDButton>
                }           

                { announcement?.content && 
                    <MDButton 
                        variant="gradient" 
                        color="error"
                        onClick={handleOpenRemoveModal}
                        sx={{ width: "14rem" }}>
                        {intl.formatMessage( {id: 'button.removeAnnouncement'} )}
                    </MDButton>
                }
                </Card>
            </MDBox>

            { isAnnouncement && 
            <AddAnnouncement 
                onCloseWindow={ handleCloseWindow }
            /> }

            { isRemove && 
            <RemoveItem
                title={ 'announcement' }
                onCloseWindow={ handleCloseWindow }
                onRemoveItem={ handleRemoveAnnouncement }
            /> }
        </>
    )
}

export default injectIntl(AppAnnouncement)
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// import UsersList from "layouts/pages/user/users-list";
// import AddUser from "layouts/pages/user/addUser";
// import UserProfile from "layouts/pages/user/userProfile";
// import EditUser from "layouts/pages/user/editUser";

// import ReportsList from "layouts/pages/report/reports-list";
// import ReportProfile from "layouts/pages/report/reportProfile";
// import AddReport from "layouts/pages/report/addReport";
import { injectIntl, IntlShape } from 'react-intl';

import SignInBasic from "pages/authentication/sign-in";
import Magic from "pages/authentication/sign-in/magicLink";
import LogoutPage from "pages/authentication/log-out";
// import ResetPassword from "pages/authentication/reset-password";

// import DevicesPage from "pages/devices/DevicesPage";
// import DeviceDetail from "pages/devices/DeviceDetail";
import HomePage from "pages/home/HomePage";
import UserProfile from "pages/user/UserProfile";
import UserEdit from "pages/user/UserEdit";

import InventoryTable from "pages/inventory";
import ContainersTable from "pages/containers/containersTable";
import ContainerDetail from "pages/containers/ContainerDetail";
import PaymentsTable from "pages/paymentsTable";
import OrdersTable from "pages/orders/OrdersTable";
import OrderDetail from "pages/orders/OrderDetail";
import ReservationsTable from "pages/reservations/ReservationsTable";
import ReservationDetail from "pages/reservations/ReservationDetail";

import PriceList from "pages/priceList";
// import Magic from "layouts/authentication/sign-in/magiclink";

// @mui icons
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import EuroIcon from '@mui/icons-material/Euro';
import ListIcon from '@mui/icons-material/List';
import KayakingIcon from '@mui/icons-material/Kayaking';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import paths from "paths";

type Props = {
  intl: IntlShape,
}

const getProjectRoutes = ( { intl, } :Props ) => ([
  {
    type: "collapse",
    route: `${paths.admin.home}`,
    key: "users",
    name: `${intl.formatMessage({id:'sideNav.home'})}`,
    noCollapse: true,
    icon: <PeopleIcon fontSize="medium">PeopleIcon</PeopleIcon>,
    component: <HomePage />,
    adress: '/admin'
  },
  {
    // type: "collapse",
    route: `${paths.admin.userProfile}`,
    key: "users",
    name: `${intl.formatMessage({id:'userProfile.title'})}`,
    // noCollapse: true,
    component: <UserProfile />,
    adress: '/admin'
  },
  {
    // type: "collapse",
    route: `${paths.admin.userEdit}`,
    key: "users",
    name: `${intl.formatMessage({id:'sideNav.userEdit'})}`,
    // noCollapse: true,
    component: <UserEdit />,
    adress: '/admin'
  },
  {
    type: "collapse",
    route: `${paths.admin.inventory}`,
    key: "inventory",
    name: `${intl.formatMessage({id:'sideNav.inventory'})}`,
    noCollapse: true,
    icon: <CategoryIcon fontSize="medium">CategoryIcon</CategoryIcon>,
    component: <InventoryTable />,
    adress: '/admin'
  },
  {
    type: "collapse",
    route: `${paths.admin.containers}`,
    key: "containers",
    name: `${intl.formatMessage({id:'sideNav.containersTable'})}`,
    noCollapse: true,
    icon: <CalendarViewWeekIcon fontSize="medium">CalendarViewWeekIcon</CalendarViewWeekIcon>,
    component: <ContainersTable />,
    adress: '/admin'
  },
  {
    // type: "collapse",
    route: `${paths.admin.containerDetail}`,
    key: "containers",
    name: `${intl.formatMessage({id:'sideNav.containerDetail'})}`,
    noCollapse: true,
    icon: <CalendarViewWeekIcon fontSize="medium">CalendarViewWeekIcon</CalendarViewWeekIcon>,
    component: <ContainerDetail />,
    adress: '/admin'
  },
  {
    type: "collapse",
    route: `${paths.admin.payments}`,
    key: "payments",
    name: `${intl.formatMessage({id:'sideNav.paymentsTable'})}`,
    noCollapse: true,
    icon: <EuroIcon fontSize="medium">EuroIcon</EuroIcon>,
    component: <PaymentsTable />,
    adress: '/admin'
  },
  {
    type: "collapse",
    route: `${paths.admin.orders}`,
    key: "orders",
    name: `${intl.formatMessage({id:'sideNav.ordersTable'})}`,
    noCollapse: true,
    icon: <ListIcon fontSize="medium">ListIcon</ListIcon>,
    component: <OrdersTable />,
    adress: '/admin'
  },
  {
    // type: "collapse",
    route: `${paths.admin.orderDetail}`,
    key: "orders",
    name: `${intl.formatMessage({id:'sideNav.orderDetail'})}`,
    noCollapse: true,
    component: <OrderDetail />,
    adress: '/admin'
  },
  {
    type: "collapse",
    route: `${paths.admin.reservations}`,
    key: "reservations",
    name: `${intl.formatMessage({id:'sideNav.reservationsTable'})}`,
    noCollapse: true,
    icon: <MenuBookIcon fontSize="medium">MenuBookIcon</MenuBookIcon>,
    component: <ReservationsTable />,
    adress: '/admin'
  },
  {
    // type: "collapse",
    route: `${paths.admin.reservationDetail}`,
    key: "reservations",
    name: `${intl.formatMessage({id:'sideNav.reservationDetail'})}`,
    noCollapse: true,
    component: <ReservationDetail />,
    adress: '/admin'
  },
  {
    type: "collapse",
    route: `${paths.admin.priceList}`,
    key: "price-list",
    name: `${intl.formatMessage({id:'sideNav.priceList'})}`,
    noCollapse: true,
    icon: <KayakingIcon fontSize="medium">KayakingIcon</KayakingIcon>,
    component: <PriceList />,
    adress: '/admin'
  },

  ////////////////////// authentication //////////////////////
  {
    // type: "collapse",
    route: paths.auth.signIn,
    // route: 'authentication/sign-in',
    key: "sign-in",
    name: `${intl.formatMessage({id:'sideNav.signIn'})}`,
    // noCollapse: true,
    component: <SignInBasic />,
    adress: '/authentication'
  },

  {
    name: "magic",
    key: "magic",
    route: paths.auth.magic,
    // route: "/authentication/sign-in/magiclink",
    component: <Magic />,
    adress: '/authentication'
  },  

  { type: "divider", key: "divider-0" },
  {
    route: paths.auth.logout,
    type: "collapse",
    name: `${intl.formatMessage({id:'sideNav.logOut'})}`,
    key: "logout",
    icon: <LogoutIcon fontSize="medium">LogoutIcon</LogoutIcon>,
    component: <LogoutPage />,
    noCollapse: true,
    adress: '/admin',
  },
  ]
)

export default getProjectRoutes as any;


const authentification = {
    'authentification.signIn': 'Bejelentkezés',
    'authentification.email': 'E-mail',
    'authentification.password': 'Jelszó',
    }

const sideNav = {
    'sideNav.home': 'Kezdőlap / Felhasználók',
    'sideNav.userEdit': 'Személyes adatok módosítása',
    'sideNav.inventory': 'Leltár',
    'sideNav.containersTable': 'Konténerek',
    'sideNav.containerDetail': 'Konténer részletei',
    'sideNav.paymentsTable': 'Fizetések',
    'sideNav.ordersTable': 'Megrendelések',
    'sideNav.orderDetail': 'Megrendelés részletei',
    'sideNav.reservationsTable': 'Foglalások',
    'sideNav.reservationDetail': 'Foglalás részletei',
    'sideNav.priceList': 'Árlista',
    'sideNav.logOut': 'Kijelentkezés',
    'sideNav.signIn': 'Bejelentkezés',
}

const tableUsers = {
    'tableUsers.title': 'Felhasználók',
    'tableUsers.id': 'Azonosító',
    'tableUsers.firstName': 'Név',
    'tableUsers.lastName': 'Vezetéknév',
    'tableUsers.email': 'E-mail',
    'tableUsers.phone': 'Telefonszám',
    'tableUsers.country': 'Ország',
    'tableUsers.blockedAt': 'Státusz',
    'tableUsers.blockedAt.null': 'Aktív',
    'tableUsers.blockedAt.block': 'Zárolt',
    'tableUsers.blockedAt.clear': 'Kiválasztás visszavonása',
}

const userProfile = {
    'userProfile.title': 'Felhasználói profil',
    'userProfile.personalInfo': 'Személyes adatok',
    'userProfile.id': 'Felhasználói azonosító',
    'userProfile.firstname': 'NÉV',
    'userProfile.surname': 'VEZETÉKNÉV',
    'userProfile.email': 'E-MAIL',
    'userProfile.mobil': 'TELEFONSZÁM',
    'userProfile.street': 'UTCA',
    'userProfile.city': 'VÁROS',
    'userProfile.postal': 'IRÁNYÍTÓSZÁM',
    'userProfile.country': 'ORSZÁG',
    'userProfile.status': 'STÁTUSZ',
    'userProfile.freeOfCharge': 'DÍJMENTES ÜZEMMÓD',
    'userProfile.freeOfCharge.false': 'Inaktív',
    'userProfile.freeOfCharge.true': 'Aktív',
}

const userEdit = {
    'userEdit.personalInfo': 'Személyes adatok',
    'userEdit.id': 'Felhasználói azonosító',
    'userEdit.name': 'Név',
    'userEdit.surname': 'Vezetéknév',
    'userEdit.street': 'Utca',
    'userEdit.city': 'Város',
    'userEdit.postal': 'Irányítószám',
}

const tableInventory = {
    'tableInventory.title': 'Leltár',
    'tableInventory.id': 'Azonosító',
    'tableInventory.type': 'Típus',
    'tableInventory.variant': 'Méret',
    'tableInventory.containerId': 'Konténer azonosító    ',
    'tableInventory.containerName': 'Konténer neve',
    'tableInventory.status': 'Státusz',
    'tableInventory.statusTrue': 'Üzemben',
    'tableInventory.statusFalse': 'Raktár',
    'tableInventory.notes': 'Megjegyzés',
    'tableInventory.kayak': 'Hajó',
    'tableInventory.vest': 'Mellény',
    'tableInventory.helmet': 'Sisak',
    'tableInventory.bag': 'Táska',
    'tableInventory.paddle': 'Evező',
    'tableInventory.batteryStatusTitle': 'Akkumulátor',
    'tableInventory.batteryStatusFull': 'Feltöltött',
    'tableInventory.batteryStatusEmpty': 'Lemerült',
    'tableInventory.noImage': 'Nincs kép',
}

const tableItems = {
    'tableItems.title': 'A konténer tételei',
    'tableItems.id': 'Azonosító',
    'tableItems.type': 'Típus',
    'tableItems.notes': 'Megjegyzés',
}

const inventory = {
    'inventory.edit.title': 'Tétel szerkesztése',
    'inventory.edit.type': 'Típus',
    'inventory.edit.status': 'Státusz',
    'inventory.edit.variant': 'Méret',
    'inventory.edit.notes': 'Megjegyzés',
}

const tableContainers = {
    'tableContainers.title': 'Konténerek',
    'tableContainers.id': 'Azonosító',
    'tableContainers.name': 'Név',
    'tableContainers.isEnabled': 'Üzemeltetés',
    'tableContainers.isEnabledTrue': 'Üzemben',
    'tableContainers.isEnabledFalse': 'Üzemen kívül',
    'tableContainers.capacity': 'Kapacitás',
    'tableContainers.equipmentCount': 'Felszerelés',
    'tableContainers.boatsCount': 'Hajók',
    'tableContainers.doorStatusA': 'Ajtó állapota A',
    'tableContainers.doorStatusB': 'Ajtó állapota B',
    'tableContainers.door.open': 'Nyitva',
    'tableContainers.door.close': 'Zárva',
    'tableContainers.status': 'Státusz',
    'tableContainers.status.online': 'Online',
    'tableContainers.status.offline': 'Offline',
}

const detailContainer = {
    'containerInfo.info': 'Információk',
    'containerInfo.id': 'Konténer azonosító',
    'containerInfo.name': 'KONTÉNER NÉV',
    'containerInfo.status': 'KONTÉNER STÁTUSZA',
    'containerInfo.pin': 'PIN',
    'containerInfo.inventory': 'Konténer leltár',
    'containerInfo.kayak': 'A hajók száma',
    'containerInfo.equipments': 'FELSZERELÉSEK SZÁMA',
}

const tablePayments = {
    'tablePayments.title': 'Fizetések',
    'tablePayments.user.title': 'Felhasználói fizetések',
    'tablePayments.id': 'Azonosító',
    'tablePayments.user': 'Felhasználó',
    'tablePayments.status': 'Státusz',
    'tablePayments.createdAt': 'Létrehozott',
    'tablePayments.finishedAt': 'Lezárt',
    'tablePayments.type': 'Típus',
    'tablePayments.amount': 'Összeg',
    'tablePayments.price': 'Ár',
    'tablePayments.penalty': 'Bírság',
    'tablePayments.exportPayments': 'Fizetések exportálása',

    'tablePayments.status.pending': 'Várakozó',
    'tablePayments.status.processing': 'Fizetés folyamatban',
    'tablePayments.status.successful': 'Sikeres',
    'tablePayments.status.expired': 'Érvényesség lejárt',
    'tablePayments.status.failed': 'Sikertelen',

    'tablePayments.type.standard': 'Szabványos',
    'tablePayments.type.verification': 'Ellenőrzés',
    'tablePayments.type.new_card': 'Új kártya',
    'tablePayments.type.fine': 'Bírság',
}

const tableOrders = {
    'tableOrders.title': 'Megrendelések',
    'tableOrders.user.title': 'Felhasználói megrendelések',
    'tableOrders.id': 'Azonosító',
    'tableOrders.user': 'Felhasználó',
    'tableOrders.createdAt': 'Létrehozott',
    'tableOrders.completedAt': 'Befejezett',
    'tableOrders.status': 'Státusz',
    'tableOrders.paymentStatus': 'Fizetés státusza',
    'tableOrders.rezervation': 'Foglalások',
    'tableOrders.price': 'Ár',
    'tableOrders.penalty': 'Bírság',
    'tableOrders.amountFine': 'A kötbér mértéke',
}

const orderDetail = {
    'order.detail.title': 'Megrendelés',
    'order.detail.id': 'Megrendelés azonosító',
    'order.detail.idOrder': 'Megrendelés azonosító',
    'order.detail.user': 'FELHASZNÁLÓ',
    'order.detail.status': 'MEGRENDELÉS STÁTUSZA',
    'order.detail.price': 'ÁR',
    'order.detail.startPlace': 'A HAJÓÚT KEZDETE',
    'order.detail.finishPlace': 'A HAJÓÚT VÉGE',
    'order.detail.isFreeOfCharge': 'DÍJMENTES ÜZEMMÓD',
    'order.detail.createdAt': 'Létrehozott',
    'order.detail.completedAt': 'Befejezett',
    'order.detail.cancel': 'Kifizetés törlése',
    'order.detail.close': 'Fizetés lezárása',

    'order.detail.in_progress': 'Feldolgozás alatt',
    'order.detail.awaiting_payment': 'Várakozás a fizetésre',
    'order.detail.completed': 'Befejezett',
    'order.detail.cancelled': 'Törölt',
}

const tableReservations = {
    'tableReservations.title': 'Foglalások',
    'tableReservations.id': 'Azonosító',
    'tableReservations.user': 'Felhasználó',
    'tableReservations.startAt': 'Kezdet',
    'tableReservations.boatsCount': 'A hajók száma',
    'tableReservations.place': 'A hajóút kezdete',
    'tableReservations.status': 'Státusz',

    'tableReservations.pending': 'Várakozó',
    'tableReservations.approved': 'Jóváhagyott',
    'tableReservations.rejected': 'Elutasított',
    'tableReservations.cancelled': 'Törölt',
}

const reservationDetail = {
    'reservationDetail.title': 'Foglalás',
    'reservationDetail.id': 'azonosító',
    'reservationDetail.userFirstName': 'NÉV',
    'reservationDetail.userLastName': 'VEZETÉKNÉV',
    'reservationDetail.mobil': 'TELEFONSZÁM',
    'reservationDetail.email': 'EMAIL',
    'reservationDetail.place': 'A HAJÓÚT KEZDETE',
    'reservationDetail.boatsCount': 'A HAJÓK SZÁMA',
    'reservationDetail.statusReservation': 'FOGLALÁS STÁTUSZA',

    'reservationDetail.createdAt': 'LÉTREHOZOTT',
    'reservationDetail.startAt': 'KEZDET',
    'reservationDetail.cancelledAt': 'TÖRLÉSI IDŐ',
    'reservationDetail.status': 'Státusz',

    'reservationDetailFine.title': 'Bírság',
    'reservationDetailFine.status': 'A BÍRSÁG STÁTUSZA',
    'reservationDetailFine.amount': 'A BÍRSÁG ÖSSZEGE',
    'reservationDetailFine.description': 'A BÍRSÁG LEÍRÁSA',
    'reservationDetailFine.createdAt': 'LÉTREHOZOTT',

    'reservationEdit.title': 'Foglalás módosítása',
    'reservationEdit.id': 'Azonosító',
    'reservationEdit.pending': 'Várakozó',
    'reservationEdit.rejected': 'Elutasít',
    'reservationEdit.approved': 'Jóváhagy',
    'reservationEdit.cancelled': 'Visszavon',
    'reservationEdit.addFine': 'Igen',
    'reservationEdit.noAddFine': 'Nem',
    'reservationEdit.fine': 'Bírság hozzáadása',
}

const finesTable = {
    'fines.detail.title': 'Bírságok',
    'fines.detail.id': 'Azonosító',
    'fines.detail.status': 'Státusz',
    'fines.detail.amount': 'Ár',
    'fines.detail.description': 'Leírás',

    'fines.detail.awaiting_payment': 'Várakozás a fizetésre',
    'fines.detail.paid': 'Megfizetve',
}

const equipmentsOrderTable = {
    'equipmentsOrder.title': 'Felszerelés',
    'equipmentsOrder.id': 'Azonosító',
    'equipmentsOrder.name': 'Típus',
    'equipmentsOrder.status': 'Státusz',
    'equipmentsOrder.price': 'Ár',
    'equipmentsOrder.rentPlace': 'Kikölcsönzött',
    'equipmentsOrder.returnPlace': 'Visszahozott',

    'equipmentsOrder.rented': 'Kikölcsönzött',
    'equipmentsOrder.returned': 'Visszahozott',
}

const priceList = {
    'priceList.title': 'Árlista',
    'priceList.kayak': '2-üléses hajó',
    'priceList.cayakThreePerson': '3-üléses hajó',
    'priceList.paddle': 'Evező',
    'priceList.helmet': 'Sisak',
    'priceList.vest': 'Mellény',
    'priceList.bag': 'Táska',
    'priceList.openContainer': 'Nyitott konténer',
    'priceList.reservationFine': 'Lemondott foglalások',
    'priceList.item': 'Tétel',
    'priceList.rent': 'Kölcsönzési díj',
    'priceList.rentOneHour': 'Első óra',
    'priceList.rentNextHour': 'Következő óra',
    'priceList.lost': 'Elvesztés',
    'priceList.damage': 'Sérülés',
}

const appAnnouncement = {
    'appAnnouncement.title': 'Alkalmazáson belüli figyelmeztetések',
    'appAnnouncement.titleAdd': 'Figyelmeztetés hozzáadása',
    'appAnnouncement.titleEdit': 'Figyelmeztetés módosítása',
    'appAnnouncement.remove': 'Valóba el akarja távolítani a figyelmeztetést?',
}

const table = {
    'table.actions': 'Akciók',
    'table.showing': 'Megjelenik',
    'table.to': 'akár',
    'table.of': 'ebből:',
    'table.items': 'tétel',
    'table.itemsPerPage': 'tétel oldalanként',
}


const button = {
    'button.addUser': 'FELHASZNÁLÓ HOZZÁADÁSA',
    'button.addItem': 'TÉTEL HOZZÁADÁSA',
    'button.addFine': 'BÍRSÁG HOZZÁADÁSA',
    'button.add': 'HOZZÁADNI',
    'button.change': 'Módosít',
    'button.delete': 'Eltávolít',
    'button.picture': 'Kép',
    'button.block': 'Letilt',
    'button.unblock': 'A letiltás feloldása',
    'button.active': 'Aktiválás',
    'button.deactive': 'Deaktiválás',
    'button.blockUser': 'Felhasználó letiltása',
    'button.unblockUser': 'Felhasználó letiltásának feloldása',
    'button.blockCharges': 'Díjmentes üzemmód aktiválása',
    'button.unblockCharges': 'Díjmentes üzemmód kikapcsolása',
    'button.detail': 'Részlet',
    'button.cancel': 'TÖRÖL',
    'button.select': 'KIVÁLASZT',
    'button.save': 'MENTÉS',
    'button.back': 'VISSZA',
    'button.photo': 'Fénykép',
    'button.dateRange': 'Dátum megerősítése',
    'button.addAnnouncement': 'Figyelmeztetés hozzáadása',
    'button.editAnnouncement': 'Figyelmeztetés módosítása',
    'button.removeAnnouncement': 'Figyelmeztetés eltávolítása',
}

const alerts = {
    'userAlert.signIn.invalidToken': 'Érvénytelen zseton',
    'userAlert.signIn.expiredToken': 'A bejelentkezés érvényessége lejárt',
    'userAlert.signIn.server': ' Nincs szerverválasz',
    'userAlert.signIn.failed': 'A bejelentkezés sikertelen',
    'userAlert.signIn.wrongRole': 'A bejelentkezéshez a rendszergazda e-mail címe szükséges',
    'userAlert.signIn.alert': 'Az alkalmazásba való bejelentkezéshez egy linket küldtünk az ön e-mail címére.',
    'userAlert.block': 'A felhasználót letiltották',
    'userAlert.blockError': 'A felhasználót nem lehetett letiltani',
    'userAlert.unblock': 'A felhasználó letiltása feloldásra került    ',
    'userAlert.unblockError': 'A felhasználó letiltását nem sikerült feloldani',
    'userAlert.freeCharge': 'A díjmentes üzemmód ki lett kapcsolva',
    'userAlert.freeChargeError': 'A díjmentes üzemmódot nem lehetett kikapcsolni',
    'userAlert.charge': 'A díjmentes üzemmód be lett kapcsolva    ',
    'userAlert.chargeError': 'A díjmentes üzemmódot nem lehetett bekapcsolni',
    'userAlert.editUser.true': 'A felhasználó módosult',
    'userAlert.editUser.false': 'Felhasználót nem sikerült módosítani',
    'userAlert.editUser.emptyField': 'Kérem töltse ki az összes mezőt',
    'userAlert.removeUser': 'A felhasználó fiókját sikerült törölni',
    'userAlert.removeUser.false': 'A felhasználói fiókját nem sikerült törölni',
    
    'inventoryAlert.status.true': 'A tétel státusza módosult',
    'inventoryAlert.status.false': 'A tétel státuszát nem sikerült módosítani',

    'inventoryAlert.edit.true': 'A tétel módosult',
    'inventoryAlert.edit.false': 'A tételt nem sikerült módosítani',
    'inventoryAlert.edit.empty': 'Nem történt hozzáadás vagy változtatás',    //////////////////
    
    'containerAlert.editEnabled.true': 'A konténer státusza módosult',
    'containerAlert.editEnabled.false': 'A konténer státuszát nem sikerült módosítani',

    'pricesAlert.edit.true': 'Az árlistát sikerült módosítani',
    'pricesAlert.edit.false': 'Az árlistát nem sikerült módosítani',

    'finesAlert.add.true': 'A bírságot sikerült hozzáadni',
    'finesAlert.add.false': 'A bírságot nem sikerült hozzáadni',
    'finesAlert.addAmount.false': 'A bírság összegét ki kell tölteni',
    'finesAlert.addDescription.false': 'A leírást ki kell tölteni',
    'finesAlert.addDescriptionAmount.false': 'A bírság leírását és összegét ki kell tölteni',

    'orderAlert.closeOrder.true': 'A megrendelés lezárult',
    'orderAlert.closeOrder.false': 'A megrendelést nem sikerült lezárni',
    'orderAlert.cancelOrder.true': 'A megrendelés törlésre került',
    'orderAlert.cancelOrder.false': 'A megrendelést nem sikerült törölni',

    'reservationEditAlert.success': 'A foglalás módosításra került',
    'reservationEditAlert.error': 'A foglalást nem sikerült módosítani',
    'reservationEditAlert.empty': 'Kérjük, töltse ki a foglalás státuszát',
    'reservationEditAlert.emptyFine': 'Kérjük, töltse ki a bírság hozzáadását',
    'reservationEditAlert.fine': 'A bírság hozzáadásra került',
    'reservationEditAlert.fineError': 'A bírságot nem sikerült hozzáadni ',

    'appAnnouncementAlert.add.true': 'A figyelmeztetést sikerült hozzáadni',
    'appAnnouncementAlert.add.false': 'A figyelmeztetést nem sikerült hozzáadni',
    'appAnnouncementAlert.remove.true': 'A figyelmeztetés sikeresen eltávolításra került',
    'appAnnouncementAlert.remove.false': 'A figyelmeztetést nem sikerült eltávolítani',
    'appAnnouncementAlert.emptyInput': 'Kérjük, töltse ki a figyelmeztetés szövegét',
}

const others = {
    'user.remove': 'Valóban törölni szeretné a felhasználót?',
    'loading': 'Betöltés...',
    'exportPaymentsTitle': 'Válassza ki azt az időszakot, amelyre vonatkozóan exportálni kívánja a fizetéseket',
    'exportPayments': 'Fizetések exportálása',
    'blockUser': 'Valóban le szeretné tiltani a felhasználót?',
    'unblockUser': 'Valóban aktiválni szeretné a felhasználót?',
    'blockCharges': 'Valóban aktiválni szeretné a díjmentes üzemmódot?',
    'unblockCharges': 'Valóban deaktiválni szeretné a díjmentes üzemmódot?',
    'datePicker': 'Válasszon időszakot',
    'currency': 'Ft',
    'errorLoadData': 'Az adatok betöltése nem sikerült. Kérjük, próbálja meg később.',
}

const datePicker = {
    'datePicker.startDate': 'Dátum innen',
    'datePicker.endDate': 'Dátum ig',
    'datePicker.today': 'Ma',
    'datePicker.yesterday': 'Tegnap',
    'datePicker.thisWeek': 'Ezen a héten',
    'datePicker.lastWeek': 'Előző héten',
    'datePicker.last7days': 'Az elmúlt 7 nap',
    'datePicker.thisMonth': 'Ebben a hónapban',
    'datePicker.lastMonth': 'Előző hónapban',
    'datePicker.jan': 'Jan',
    'datePicker.feb': 'Febr',
    'datePicker.mar': 'Márc',
    'datePicker.apr': 'Ápr',
    'datePicker.may': 'Máj',
    'datePicker.june': 'Jún',
    'datePicker.july': 'Júl',
    'datePicker.aug': 'Aug',
    'datePicker.sept': 'Szept',
    'datePicker.okt': 'Okt',
    'datePicker.nov': 'Nov',
    'datePicker.dec': 'Dec',
}

const messages = {
    ...authentification,
    ...sideNav,
    ...tableUsers,
    ...userProfile,
    ...userEdit,
    ...tableInventory,
    ...inventory,
    ...tableItems,
    ...tableContainers,
    ...detailContainer,
    ...tablePayments,
    ...tableOrders,
    ...orderDetail,
    ...tableReservations,
    ...reservationDetail,
    ...finesTable,
    ...equipmentsOrderTable,
    ...priceList,
    ...datePicker,
    ...table,
    ...appAnnouncement,
    ...button,
    ...alerts,
    ...others,
};

export default messages;
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setUsers } from 'redux/actions/usersActions';
import { setRefresh } from 'redux/actions/pageActions';

// axios
import axios from 'axios';
import { apiClient } from "api-client"

// helper
import { generateSearchQuery } from 'utils';

// language
import { injectIntl, IntlShape } from 'react-intl';

// compoments
import LoadingComponent from 'components/LoadingComponent';
import ErrorComponent from 'components/ErrorComponent';
import AppNotification from 'components-lib/AppAnnouncement';
import UsersTable from 'pages/home/UsersTable';

// MUI components
import { Grid } from '@mui/material';

// // style table
// import { useStyleContext } from 'context/styleContext';

// extracted data
import { extractDataUsers } from 'common/extractData';

// types
import { Users } from 'types/types';
import { usersData, FilterState, Refresh } from 'types/typesRedux';


type Props = {
  intl: IntlShape,
}

const HomePage = ( {intl}: Props ) => {

  const dispatch = useDispatch();

  const navigate = useNavigate(); 

  const { refreshUsers } = useParams();

  const [localLoading, setLocalLoading] = useState<boolean>(true);
  const [isError, setIsError ] = useState<boolean>(false);

  // take data from redux
  const data = useSelector( (state: usersData) => state.allUsers );
  const filters = useSelector( (state: FilterState) => state.filters);
  const refresh = useSelector( (state: Refresh) => state.handleRefresh);

  const tableName = intl.formatMessage( {id: 'tableUsers.title'} ); 

    // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
    const storedRowLimit = parseInt(localStorage.getItem('rowLimitUsers'));
    const initialRowLimit = storedRowLimit && !isNaN(storedRowLimit) ? storedRowLimit : data.limit;

  // Refresh when open page
  useEffect(() => {
    handleSearch();
    const transformedFilters = transformFilters(filters[tableName]);
    getAllUsers( transformedFilters );
  },[] )

   // Refresh when change language
   useEffect(() => {
    if ( refresh.refreshLanguage === true ) {
      const transformedFilters = transformFilters(filters[tableName]);
      getAllUsers( transformedFilters );
    }
  },[ refresh.refreshLanguage ] )

  // Refresh page if refresh is true
  useEffect(() => {
    if ( refresh.refresh === true ) {
      handleSearch();
      const transformedFilters = transformFilters(filters[tableName]);
      getAllUsers( transformedFilters );
    }
  }, [ refresh.refresh ])

  useEffect(() => {
    if ( refresh.refreshUsers === true ) {
      getAllUsers();
    }
  }, [ refresh.refreshUsers ])
  
  useEffect(() => {
    if ( refreshUsers === 'refresh' ) {
      getAllUsers();
    }
  }, [ refreshUsers ])

  // If refresh is true set them to false
  const handleRefreshPage = () => {
    if ( refresh.refresh === true ) { dispatch(setRefresh( { refresh: false } )) };
    if ( refresh.refreshUsers === true ) { dispatch(setRefresh( { refreshUsers: false } )) };
    if ( refresh.refreshLanguage === true ) { dispatch(setRefresh( { refreshLanguage: false } )) };
  }

  // Handle if search country change header for API
  const transformFilters = ( filters: FilterState = {} ) => {
    const transformed: FilterState = {};
  
    // Check all keys in object filters
    Object.keys(filters).forEach(key => {
      if (key === 'countryName[contains]') {
        // If find 'countryName[contains]', change to 'country[name][contains]'
        transformed['country[name][contains]'] = filters[key];
      } else {
        // For all other keys, we just copy the key and value into a new object
        transformed[key] = filters[key];
      }
    });  
    return transformed;
  };

   // Handle transformed sorting ( inventory )
   const transformSort = (sort: string) => {
    if ( sort.includes('countryName') ) {
      // Split the string using ":" as the delimiter
      let parts = sort.split(":");
      // parts will be an array with two elements: ["countryName", "asc"]
      let propertyName = parts[0];
      let sortOrder = parts[1];
      // split name: [ "country", "Name" ]
      let namePart = propertyName.split("country");
      let name = namePart[1];
      name = name.toLowerCase();
      return `country[${name}]:${sortOrder}`;
    } else { 
      return sort 
    }
  }

  // Handle all users from API
  const getAllUsers = async ( params = {} ) => {

    const cancelToken = axios.CancelToken.source()
    const sortType = ( filters[`${tableName} sort`] && 
    filters[`${tableName} sort`].sort !== undefined ) 
    ? filters[`${tableName} sort`].sort 
    : data.sort;
    
    const defaultParams = {
      offset: data ? data.offset : 0,
      limit: initialRowLimit,
      sort: transformSort( sortType ),
    };
    
    // Merge provided params with default params
    const mergedParams = { ...defaultParams, ...params };

    try{
      const response = await apiClient.get("/v1/users",{
        params: mergedParams,
        cancelToken:cancelToken.token
      });

      if (response) {
        const responseData = response.data.items.map( 
          ( users: unknown ) => extractDataUsers( intl )( users as Users ));
        const totalCount = response.data.totalCount;
        dispatch(setUsers( responseData, totalCount ));
        if (isError === true) {setIsError(false)};
        // console.log( response.data );
      } else {
        console.log("No response received.");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        // todo:handle error
      }
      setIsError(true)
    }
    setLocalLoading( false );
    handleRefreshPage();
      
    return () => cancelToken.cancel()
  };

  // Handle URL when search 
  const handleSearch = () => {
    const searchQuery = generateSearchQuery(filters[tableName]);
    const url = `/admin/users${searchQuery ? `?search:${searchQuery}` : ''}`;
    navigate(url);
  };

  const handleRefresh = () => {
    dispatch(setRefresh( { refresh: true } ));
  }

  if ( isError ) {
    return <ErrorComponent onPress={handleRefresh} />;
  }

  return (
    <Grid
      sx={{ 
        maxWidth: "100rem", 
        width: 'auto',
      }}
    >
      { localLoading === true ? (
        <LoadingComponent /> ) : 
        <>
          <AppNotification />
          <UsersTable
            initialRowLimit={initialRowLimit}
          />
        </>
      }    
    </Grid>        
  )
}

export default injectIntl(HomePage);
import { format, parse } from 'date-fns';

import { getDefaultLang } from 'config';

export const userIsLogged = () => !!window.localStorage.getItem('auth_token');

export const expireDateToken = () => {
    const date = window.localStorage.getItem('expires_token');
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    return ( formattedDate <= date )
} 

// export const getSelectedLanguage = () => window.localStorage.getItem('lang') || 'sk';
export const getSelectedLanguage = () => getDefaultLang() || window.localStorage.getItem('lang');

export const processLogin = ( response: any ) => {
    const token = response.data.token;
    window.localStorage.setItem('auth_token', token);
};

export const processLogout = () => {
    window.localStorage.removeItem('auth_token');
    window.localStorage.removeItem('expires_token');
    window.localStorage.removeItem('lang');    
};

export const formatDate = ( inputDate: string ) => {
    const date = parse(inputDate, 'yyyy-MM-dd HH:mm:ss.SSS XXX', new Date());
    const formattedDate = format(date, 'dd.MM.yyyy');

    return formattedDate;
}

export const formatDateTime = ( originalDateTime: string ) => {
    // Convert the original string ('yyyy-MM-dd\'T\'HH:mm:ss.SSSX') to a Date object
    const date = new Date(originalDateTime);

    // Format the date to 'dd.MM.yyyy HH:MM'
    const formattedDateTime = format(date, 'dd.MM.yyyy HH:mm');

    return formattedDateTime;
}

export const handleContainerStatus = ( date: string ) => {
    const containerDate = new Date( date );
    const actualDate = new Date();
    const fiveMinutes = 5 * 60 * 1000;
    const dateMinusFiveMinutes = new Date(actualDate.getTime() - fiveMinutes);

    if ( containerDate > dateMinusFiveMinutes ) {
      return true // online
    } else {
      return false //offline
    }
}

type Filter = {
    [key: string]: string | undefined;
}

export const generateSearchQuery = (filters: Filter) => {
    const searchParams = new URLSearchParams();
    
    for (const key in filters) {
      if (filters[key]) {
        searchParams.append(key, filters[key]);
      }
    }
    
    return searchParams.toString()|| '';
  };  


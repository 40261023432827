
import { ActionTypes } from 'redux/actions/actionType'

// types
import { 
    FilterState, SetFiltersAction, DeleteFilterAction, DeleteFiltersAction,
} from 'types/typesRedux';


// filters data
const filterState: FilterState = {};

export const filterReducer = (state= filterState, action: SetFiltersAction | DeleteFilterAction | DeleteFiltersAction ) => {

    switch ( action.type ) {
        case ActionTypes.SET_FILTERS:
            return { ...state, ...(action as SetFiltersAction ).payload };
        case ActionTypes.DELETE_FILTER:
       
            const filterName = (action as DeleteFilterAction).filterName;
            const updatedData = { ...state[(action as DeleteFilterAction).payload] };
            delete updatedData[filterName];
            return {
                ...state,
                [(action as DeleteFilterAction).payload]: updatedData,
            };
        case ActionTypes.DELETE_FILTERS:
            return {
                ...state,
                [(action as DeleteFiltersAction).payload]: {},
            };
        case ActionTypes.DELETE_ALL_FILTERS:
            return {};
        default:
            return state;
    }
};


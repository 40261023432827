import React from 'react';
import ReactLoading from 'react-loading';
import { injectIntl, IntlShape } from 'react-intl';

interface Props {
    intl: IntlShape,
}

const LoadingComponent = ( { intl }: Props ) => {

    return (
        <div className="loading-container"
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: "10",
        }}>
            <div style= {{ display:"flex", flexDirection:"row", justifyContent:"center" }} >
                <ReactLoading 
                    type= "spinningBubbles"
                    color="#17C063" 
                    height={90} 
                    width={90} 
                    />
            </div>
            <p
                style= {{ display:"flex", flexDirection:"row", justifyContent:"center",
                fontSize:"1.1rem", marginTop:"1.5rem"}}>
                {intl.formatMessage({id: 'loading'})}
            </p>
    </div>
    );
};

export default injectIntl(LoadingComponent);


const paths = {
    profile: '/profile',
    dashboard: '/dashboard',

    auth: {
        signIn: '/sign-in',
        magic:'/authentication/sign-in/magiclink',
        logout: '/log-out',
        
        register: '/register',
        confirm: '/confirm',
        activate: '/activate',
        resetPassword: '/reset-password',
        forgottenPassword: '/forgotten-password',
    },

    admin: {
        home: '/users',
        userProfile: '/users/user-profile/:id',  
        userEdit: '/users/user-edit/:id',  

        inventory: '/inventory',

        containers: '/containers',   
        containerDetail: '/containers/container-detail/:id',

        payments: '/payments',   
        
        orders: '/orders',   
        orderDetail: '/orders/order-detail/:id',  
        
        reservations: '/reservations',
        reservationDetail: '/reservations/reservation-detail/:id',  
        
        priceList: '/price-list',     
    },
}

export default paths;


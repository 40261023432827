import React, { useState, useEffect } from 'react'

import { parseISO } from 'date-fns';

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

import { useNavigate, } from "react-router-dom";

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setReservations, setReservationsOffset, setReservationsLimit } from 'redux/actions/reservationsActions';
import { setItemsOrderOffset } from 'redux/actions/inventoryActions';
import { setFinesOffset } from 'redux/actions/finesActions';
import { setRefresh } from 'redux/actions/pageActions';

// language
import { injectIntl, IntlShape } from 'react-intl';

// components
import QueryTableDevices from 'components-lib/QueryTable/QueryTableDevices';
import LoadingComponent from 'components/LoadingComponent';
import ErrorComponent from 'components/ErrorComponent';

import { Grid } from '@mui/material';

// icon
import MenuBookIcon from '@mui/icons-material/MenuBook';

// extracted data
import { extractDataReservations } from 'common/extractData';

import { reservationsStatusEnum } from 'common/enums/DevicesStateEnum';

// types
import { ReservationsTableType, ColumnWidths } from 'types/types';
import { Refresh, AllReservationData, FilterState } from 'types/typesRedux';

type Props = {
  intl: IntlShape;
}

const ReservationTable = ( {intl}: Props ) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const currentLocale = intl.locale;

  // redux
  const data = useSelector( (state: AllReservationData) => state.allReservations )
  const filters = useSelector( (state: FilterState) => state.filters);
  const refresh = useSelector( (state: Refresh) => state.handleRefresh);


  // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
  const storedRowLimit = parseInt(localStorage.getItem('rowLimitReservations'));
  const initialRowLimit = storedRowLimit && !isNaN(storedRowLimit) ? storedRowLimit : data.limit;

  const [localLoading, setLocalLoading] = useState<boolean>(true);
  const [isError, setIsError ] = useState<boolean>(false);
  const tableName = intl.formatMessage( {id: 'tableReservations.title'} );

  const getReservations = async ( params = {} ) => {

    const cancelToken = axios.CancelToken.source()

    const defaultParams = {
      offset: data ? data.offset : 0,
      limit: initialRowLimit,
      sort: ( filters[`${tableName} sort`] &&
        filters[`${tableName} sort`].sort !== undefined ) 
        ? filters[`${tableName} sort`].sort 
        : data.sort,
    };

    // Merge provided params with default params
    const mergedParams = { ...defaultParams, ...params };

    try{
      const response = await apiClient.get("/v1/reservations",{
        params: mergedParams,
        cancelToken:cancelToken.token
      });

      if (response) {
        const responseData = response.data.items.map( 
          ( reservations: unknown ) => extractDataReservations( intl )( reservations as ReservationsTableType ));
          const totalCount = response.data.totalCount;
          dispatch(setReservations( responseData, totalCount ));
          // console.log( response.data );
          if (isError === true) {setIsError(false)};
      } else {
        console.log("No response received.");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        // todo:handle error
      }
      setIsError(true)
    }    
    setLocalLoading( false );
    handleRefreshPage()    
    return () => cancelToken.cancel()
  };

  // If refresh is true set them to false
  const handleRefreshPage = () => {
    if ( refresh.refresh === true ) { dispatch(setRefresh( { refresh: false } )) };
    if ( refresh.refreshLanguage === true ) { dispatch(setRefresh( { refreshLanguage: false } )) };
  }

  useEffect(() => {
    getReservations( filters[tableName] );

    // refresh every 2 minutes
    const interval = setInterval(getReservations, 120000);
    
    return () => clearInterval(interval);
  },[] ) 

  useEffect(() => {
    if ( refresh.refresh === true ) {
      getReservations( filters[tableName] );
    }
  }, [ refresh.refresh ])

  // Refresh when change language
  useEffect(() => {
    if ( refresh.refreshLanguage === true ) {
      getReservations( filters[tableName] );
    }
  },[ refresh.refreshLanguage ] )

  // go to profile page of order and set offset to 0
  const handleDetailBtn = ( id: number ) => {
    navigate( (`/admin/reservations/reservation-detail/${id}`), { state: {
      id: id,
      }} 
    );
    dispatch(setItemsOrderOffset( 0 ));
    dispatch(setFinesOffset( 0 ));
  }

  // Handle offset
  const handleOffset = ( tableOffset: number ) => {
    dispatch(setReservationsOffset( tableOffset ));
  }
  
  // Handle limit
  const handleLimit = ( tableLimit: number ) => {
    dispatch(setReservationsLimit( tableLimit ));
    localStorage.setItem('rowLimitReservations', tableLimit.toString());
  }

  const getColumnMinWidth = (columnId: string, locale: string): string => {
    const minWidths: ColumnWidths = {
      id: { default: "5.6rem", hu: "7rem" },
      user: { default: "11rem", hu: "11rem" },
      startAt: { default: "8rem", hu: "8rem" },
      boatsCount: { default: "7rem", hu: "8.6rem" },
      place: { default: "10rem", hu: "10rem" },
      status: { default: "8.5rem", hu: "8.5rem" },
      Action: { default: "3%", hu: "2%" },
    };
  
    const columnConfig = minWidths[columnId];
    if (columnConfig) {
      return columnConfig[locale] || columnConfig.default;
    } else {
      return "auto";
    }
  };

  const handleRefresh = () => {
    dispatch(setRefresh( { refresh: true } ));
  }

  if ( isError ) {
    return <ErrorComponent onPress={handleRefresh} />;
  }

  return (
    <Grid >
      { localLoading ? (
      <LoadingComponent /> ) : (

        <Grid
        //  item xs={12} sm={12} md={12} lg={10}
         sx={{ maxWidth: "83rem", }}
        >

          <QueryTableDevices
            title= { tableName }
            tableData={ data.reservations }
            pagePagination={ true }
            totalCount={ data.totalCount }
            offset={ data.offset }
            rowLimit={ initialRowLimit }  
            columns= {[
              { Header: intl.formatMessage( {id: 'tableReservations.id'} ), 
              accessor: "id", 
              width: "auto",
              minWidth: getColumnMinWidth("id", currentLocale),
              maxWidth: "6rem",
              columnFilter: 'number',
              input: 'input',
              },
              { Header: intl.formatMessage( {id: 'tableReservations.user'} ), 
              accessor: "user", 
              width: "auto",
              minWidth: getColumnMinWidth("user", currentLocale),
              columnFilter: 'string',
              input: 'input',
              },
              { Header: intl.formatMessage( {id: 'tableReservations.startAt'} ), 
              accessor: "startAt", 
              width: "auto",
              minWidth: getColumnMinWidth("startAt", currentLocale),
              columnFilter: 'string',
              input: 'datum',
              },
              { Header: intl.formatMessage( {id: 'tableReservations.boatsCount'} ), 
              accessor: "boatsCount", 
              width: "auto",
              minWidth: getColumnMinWidth("boatsCount", currentLocale),
              maxWidth: "5rem",
              columnFilter: 'number',
              input: 'input',
              },
              { Header: intl.formatMessage( {id: 'tableReservations.place'} ), 
              accessor: "place", 
              width: "auto",
              minWidth: getColumnMinWidth("place", currentLocale),
              columnFilter: 'string',
              input: 'input',
              },
              { Header: intl.formatMessage({id: 'tableReservations.status'}), 
                accessor: "status", 
                width: "auto",
                minWidth: getColumnMinWidth("status", currentLocale),
                input: 'autocomplete',
                filterOptions: Object.values(reservationsStatusEnum(intl)).map((item) => {
                  return ({
                    value: item.filterKey,
                    label: item.label,
                    key: item.filterValue
                  })
                })                  
              },
              { Header: intl.formatMessage({id: 'table.actions'}), 
              accessor: "Action", 
              width: "auto",
              minWidth: getColumnMinWidth("Action", currentLocale),
              input: 'title',
              clearIcon: true,
              },
            ]}
            button={ [{
                add: null ,
                detail: true,
                edit: false,
                delete: false,
                block: false,
                picture: false,
            }] }
            icon= { <MenuBookIcon /> }
            onOffset={ handleOffset }
            onLimit={ handleLimit }
            onAddItem={ null }
            onDetailItem={ handleDetailBtn } 
            onEditBtn={ null }
            onBlockItem={ null }
            onRemoveBtn={ null }
            onPictureItem={ null }
            marginLeft={ true }
            maxDate={ false }
          />

        </Grid>
      )}     
    </Grid>
  );
};

export default injectIntl(ReservationTable);
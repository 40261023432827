import { ActionTypes } from 'redux/actions/actionType';

// types
import { RefreshState, SetRefreshAction, } from 'types/typesRedux';

const refreshState: RefreshState = {
    refresh: false, 
    refreshAnnouncement: false, 
    refreshUsers: false, 
    refreshUser: false, 
    refreshInventory: false, 
    refreshContainers: false, 
    refreshContainer: false, 
    refreshPayments: false, 
    refreshFines: false, 
    refreshOrder: false, 
    refreshLanguage: false, 
};

export const refreshReducer = (state= refreshState, action: SetRefreshAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_REFRESH:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
import React, { useEffect, useState } from 'react';

import { injectIntl, IntlShape } from 'react-intl';

import { Routes, Route, Navigate } from "react-router-dom";

import { userIsLogged, expireDateToken } from 'utils';

import Auth from "layouts/Auth";

interface Props {
    intl: IntlShape,
}
  

function PublicRoute ( { intl, } :Props ) : JSX.Element {

  const [isLogged, setIsLogged ] = useState(false);

    useEffect(() => {
      if (userIsLogged() && expireDateToken()) setIsLogged(true);
    }, [isLogged]);

    return ( 
      
      <Routes>
      {isLogged ? (
        <Route path="*" element={<Navigate to="/admin/users" />} />
        ) : (
          <Route  path="*" element={<Auth />} />
      )}
        </Routes>
    );
};

export default injectIntl(PublicRoute);



 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setInventory, setInventoryOffset, setInventoryLimit, } from 'redux/actions/inventoryActions';
import { setRefresh } from 'redux/actions/pageActions';

// helper
import { generateSearchQuery } from 'utils';

// language
import { injectIntl, IntlShape } from 'react-intl';

// components
import LoadingComponent from 'components/LoadingComponent';
import ErrorComponent from 'components/ErrorComponent';
import EditItem from 'components-lib/EditItem'
import QueryTableDevices from 'components-lib/QueryTable/QueryTableDevices';

// Material UI
import { Grid } from '@mui/material';

// icon
import CategoryIcon from '@mui/icons-material/Category';

// Enums
import { itemsEnum, isActiveEnum } from 'common/enums/DevicesStateEnum';

// extracted data
import { extractDataInventory } from 'common/extractData';

// types
import { InventoryTableType, ColumnWidths } from 'types/types';
import { inventoryData, FilterState, Refresh } from 'types/typesRedux';

interface Params {
  [key: string]: string | number | boolean;
}

type Props = {
  intl: IntlShape;
}

const InventoryTable = ({intl}: Props) => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentLocale = intl.locale;

  // take data from redux
  const data = useSelector( (state: inventoryData) => state.allInventory )
  const filters = useSelector( (state: FilterState) => state.filters);
  const refresh = useSelector( (state: Refresh) => state.handleRefresh);

  // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
  const storedRowLimit = parseInt(localStorage.getItem('rowLimitInventory'));
  const initialRowLimit = storedRowLimit && !isNaN(storedRowLimit) ? storedRowLimit : data.limit;

  const [localLoading, setLocalLoading] = useState<boolean>(true);
  const [isError, setIsError ] = useState<boolean>(false);

  const tableName = intl.formatMessage( {id: 'tableInventory.title'} );
  const [ isEditItem, setIsEditItem ] = useState<boolean>( false );

  const [ itemId, setItemId ] = useState<number>(null);

  // Refresh when open page
  useEffect(() => {
    handleSearch();
    getInventory( filters[tableName] );
  },[] )

  // Refresh page if refresh is true
  useEffect(() => {
    if ( refresh.refresh === true ) {
      handleSearch()        
      getInventory( filters[tableName] );
    }
  }, [ refresh.refresh ])

  // Refresh page if refreshInventory is true
  useEffect(() => {
    if ( refresh.refreshInventory === true ) {
      handleSearch()        
      getInventory( filters[tableName] );
    }
  }, [ refresh.refreshInventory ])

  // Refresh when change language
  useEffect(() => {
    if ( refresh.refreshLanguage === true ) {
      getInventory( filters[tableName] );
    }
  },[ refresh.refreshLanguage ] )
  
  // Handle URL when search
  const handleSearch = () => {
    const searchQuery = generateSearchQuery(filters[tableName]);
    const url = `/admin/inventory${searchQuery ? `?search:${searchQuery}` : ''}`;
    navigate(url);
  };

  // Handle transformed filters ( inventory )
  const transformParams = (params: unknown): {[key in keyof Params]?: unknown} => {
    return Object.entries(params).reduce((acc: {[key in keyof Params]?: unknown}, [key, value]) => {
      let transformedKey: keyof Params;
  
      // Check for specific patterns and transform accordingly
      if (key.includes('containerId[contains]')) {
        transformedKey = key.replace('containerId[contains]', 'container[id][eq]') as keyof Params;
      } else if (key.includes('containerName[contains]')) {
        transformedKey = key.replace('containerName[contains]', 'container[name][contains]') as keyof Params;
      } else {
        transformedKey = key as keyof Params;
      }
  
      acc[transformedKey] = value;
      return acc;
    }, {});
  };

  // Handle transformed sorting ( inventory )
  const transformSort = (sort: string) => {
    if ( sort.includes('container') ) {
      // Split the string using ":" as the delimiter
      let parts = sort.split(":");
      // parts will be an array with two elements: ["containerId", "asc"]
      let propertyName = parts[0];
      let sortOrder = parts[1];
      // split name: [ "container", "Id" ]
      let namePart = propertyName.split("container");
      let name = namePart[1];
      name = name.toLowerCase();
      return `category[${name}]:${sortOrder}`;
    } else if (sort.includes('name')) {
      return `category[name]:${sort.split(":")[1]}`;

    } else { 
      return sort }
  }

  // If refresh is true set them to false
  const handleRefreshPage = () => {
    if ( refresh.refresh === true ) { dispatch(setRefresh( { refresh: false } ))};
    if ( refresh.refreshInventory === true ) { dispatch(setRefresh( { refreshInventory: false } )) };
    if ( refresh.refreshLanguage === true ) { dispatch(setRefresh( { refreshLanguage: false } )) };
  }

  // handle inventory data from API /////////////////////////////
  const getInventory = async ( params = {} ) => {
    const cancelToken = axios.CancelToken.source()
    const sortType = ( filters[`${tableName} sort`] && 
      filters[`${tableName} sort`].sort !== undefined ) 
      ? filters[`${tableName} sort`].sort 
      : data.sort;

    const defaultParams = {
      offset: data ? data.offset : 0,
      limit: initialRowLimit,
      sort: transformSort(sortType),
    };

    const transformedParams = transformParams(params);
    const mergedParams = { ...defaultParams, ...transformedParams };

    try{
      const response = await apiClient.get("/v1/items",{
        params: mergedParams,
        cancelToken:cancelToken.token
      });

      if (response) {
        // console.log( response.data.items );
        const responseData = response.data.items.map( 
          ( inventory: unknown ) => extractDataInventory(intl)( inventory as InventoryTableType ));
        const totalCount = response.data.totalCount;
        dispatch(setInventory( responseData, totalCount ));
        if (isError === true) {setIsError(false)};
      } else {
        console.log("No response received.");
      }
    } catch (err) {
        if (axios.isCancel(err)) {
          console.log("canceled")
        } else {
          // todo:handle error
        }
      setIsError(true)
    }        
    setLocalLoading( false );
    handleRefreshPage();
    return () => cancelToken.cancel();
  };


  // Handle icons in table/////////////////////////////////
  ///////////////////////////////

  // If click icon edit open modal window
  const handleEditBtn = ( id: number ) => {
    // delete item form if is not
    setIsEditItem( true );
    setItemId( id );
  }

  // If submit btn edit close modal window and refresh table
  const handleEditItem = ( bool: boolean ) => {
    if ( bool === true ) {
      setIsEditItem( false );
      dispatch(setRefresh( { refreshInventory: true } ))
    }
  }
 
  // If click btn cancel close modal window
  const handleCloseWindow = ( bool: boolean ) => {
    if (bool === false){ 
      setIsEditItem( false )
    }
  }

  // Handle offset
  const handleOffset = ( tableOffset: number ) => {
    dispatch(setInventoryOffset( tableOffset ));
  }
  
  // Handle limit
  const handleLimit = ( tableLimit: number ) => {
    dispatch(setInventoryLimit( tableLimit ));
    localStorage.setItem('rowLimitInventory', tableLimit.toString());
  }

  
  const getColumnMinWidth = (columnId: string, locale: string): string => {
    const minWidths: ColumnWidths = {
      id: { default: "5.6rem", hu: "7rem" },
      isActive: { default: "10rem", hu: "8rem" },
      name: { default: "4rem", hu: "6.8rem" },
      batteryStatus: { default: "4rem", hu: "5rem" },
      containerName: { default: "9.2rem", hu: "9.2rem" },
      notes: { default: "8.9rem", hu: "8.9rem" },
      Action: { default: "3%", hu: "1%" },
    };
  
    const columnConfig = minWidths[columnId];
    if (columnConfig) {
      return columnConfig[locale] || columnConfig.default;
    } else {
      return "auto";
    }
  };

  const handleRefresh = () => {
    dispatch(setRefresh( { refresh: true } ));
  }

  if ( isError ) {
    return <ErrorComponent onPress={handleRefresh} />;
  }

  return (
    <Grid
    // container 
    >
    { localLoading ? (
      <LoadingComponent /> ) : (
        <Grid item 
        sx={{ 
          maxWidth: "82rem", 
          width: 'auto',
        }}
        >
          <QueryTableDevices
            title= {tableName}
            tableData={ data.inventory }
            pagePagination={true}
            totalCount={ data.totalCount }
            offset={ data.offset }
            rowLimit={ initialRowLimit }        
            columns= {[
              { Header: intl.formatMessage( {id: 'tableInventory.id'} ), 
                accessor: "id", 
                width: "auto",
                minWidth: getColumnMinWidth("id", currentLocale),
                maxWidth: "6rem",
                columnFilter: 'number',
                input: 'input',
              },
              { Header: intl.formatMessage({id: 'tableInventory.status'}), 
              accessor: "isActive", 
              width: "auto",
              minWidth: getColumnMinWidth("isActive", currentLocale),
              maxWidth: "10rem",
              input: 'autocomplete',
              filterOptions: Object.values(isActiveEnum(intl)).map((item) => {
                return ({
                  value: item.filterKey,
                  label: item.label,
                  key: item.filterValue
                })
              })
              },
              { Header: intl.formatMessage({id: 'tableInventory.type'}), 
              accessor: "name", 
              width: "auto",
              minWidth: getColumnMinWidth("name", currentLocale),
              input: 'autocomplete',
              filterOptions: Object.values(itemsEnum(intl)).map((item) => {
                return ({
                  value: item.filterKey,
                  label: item.label,
                  key: item.filterValue
                })
              })
              },
              { Header: intl.formatMessage( {id: 'tableInventory.variant'} ), 
                accessor: "variant", 
                width: "auto",
                minWidth: "2%",
                columnFilter: 'string',
                input: 'title',
              },
              { Header: intl.formatMessage( {id: 'tableInventory.batteryStatusTitle'} ), 
                accessor: "batteryStatus", 
                width: "auto",
                minWidth: getColumnMinWidth("batteryStatus", currentLocale),
                input: 'title',
              },
              { Header: intl.formatMessage( {id: 'tableInventory.containerName'} ), 
                accessor: "containerName", 
                width: "auto", 
                minWidth: getColumnMinWidth("containerName", currentLocale),
                input: 'input',
              },
              { Header: intl.formatMessage( {id: 'tableInventory.notes'} ), 
                accessor: "notes", 
                width: "auto",
                minWidth: getColumnMinWidth("notes", currentLocale),
                input: 'title',
              },
              { Header: intl.formatMessage({id: 'table.actions'}), 
                accessor: "Action", 
                width: "auto",
                minWidth: getColumnMinWidth("Action", currentLocale),
                input: 'title',
                clearIcon: true,
              },
            ] }

            button={ [{
              // add: `${intl.formatMessage({id: 'button.addItem'})}`,  // null | string 
              add: null,  // null | string 
              detail: false, // boolean
              edit: true, // boolean
              delete: false, // boolean
              block: false, // boolean
            }] }

            icon= {<CategoryIcon />}
            onOffset={ handleOffset }
            onLimit={ handleLimit }

            onAddItem={ null }
            onRemoveBtn={ null }
            onEditBtn={ handleEditBtn }
          />

          { isEditItem && (
            <EditItem 
              onCloseWindow={ handleCloseWindow }
              onEditItem={ handleEditItem }
              itemId={ itemId }
            />
          )}

        </Grid>
    )}
      </Grid>
  );
};

export default injectIntl(InventoryTable);

type Types = {
    [key: string] : String;
};


const authentication = {
  'authentification.signIn': 'Login',
  'authentification.email': 'Email',
  'authentification.password': 'Password',
}

const sideNav = {
  'sideNav.home': 'Home / Users',
  'sideNav.userEdit': 'Edit Personal Information',
  'sideNav.inventory': 'Inventory',
  'sideNav.containersTable': 'Containers',
  'sideNav.containerDetail': 'Container Detail',
  'sideNav.paymentsTable': 'Payments',
  'sideNav.ordersTable': 'Orders',
  'sideNav.priceList': 'Price List',
  'sideNav.logOut': 'Log Out',
  'sideNav.signIn': 'Login',
}

const tableUsers = {
  'tableUsers.title': 'Users',
  'tableUsers.id': 'Id',
  'tableUsers.firstName': 'First Name',
  'tableUsers.lastName': 'Last Name',
  'tableUsers.email': 'Email',
  'tableUsers.phone': 'Phone',
  'tableUsers.country': 'Country',
  'tableUsers.blockedAt': 'Status',
  'tableUsers.blockedAt.null': 'Active',
  'tableUsers.blockedAt.block': 'Blocked',
  'tableUsers.blockedAt.clear': 'Clear Selection',
}

const userProfile = {
  'userProfile.title': 'User Profile',
  'userProfile.personalInfo': 'Personal Information',
  'userProfile.id': 'User Id:',
  'userProfile.firstname': 'FIRST NAME',
  'userProfile.surname': 'LAST NAME',
  'userProfile.email': 'EMAIL',
  'userProfile.mobile': 'PHONE NUMBER',
  'userProfile.street': 'STREET',
  'userProfile.city': 'CITY',
  'userProfile.number': 'NUMBER',
  'userProfile.postal': 'ZIP CODE',
  'userProfile.country': 'COUNTRY',
  'userProfile.status': 'STATUS',
  'userProfile.freeOfCharge': 'FEES',
  'userProfile.freeOfCharge.true': 'Deactivated',
  'userProfile.freeOfCharge.false': 'Active',
  'userProfile.isFreeOfCharge.false': 'Activate Fees',
  'userProfile.isFreeOfCharge.true': 'Deactivate Fees',
}

const userEdit = {
  'userEdit.personalInfo': 'Personal Information',
  'userEdit.id': 'User Id:',
  'userEdit.name': 'Name',
  'userEdit.surname': 'Surname',
  'userEdit.street': 'Street',
  'userEdit.city': 'City',
  'userEdit.postal': 'ZIP Code',
}

const tableInventory = {
  'tableInventory.title': 'Inventory',
  'tableInventory.id': 'Id',
  'tableInventory.type': 'Type',
  'tableInventory.variant': 'Size',
  'tableInventory.containerId': 'Container-Id',    
  'tableInventory.containerName': 'Container-Name',
  'tableInventory.status': 'Status',
  'tableInventory.statusTrue': 'In Operation',
  'tableInventory.statusFalse': 'Storage',
  'tableInventory.notes': 'Note',
  'tableInventory.kayak': 'Kayak',
  'tableInventory.vest': 'Vest',
  'tableInventory.helmet': 'Helmet',
  'tableInventory.bag': 'Bag',
  'tableInventory.paddle': 'Paddle',
}

const tableItems = {
  'tableItems.title': 'Container Items',
  'tableItems.id': 'Id',
  'tableItems.type': 'Type',
  'tableItems.notes': 'Note',
}

const inventory = {
  'inventory.edit.title': 'Edit Item',
  'inventory.edit.type': 'Type',
  'inventory.edit.status': 'Status',
  'inventory.edit.variant': 'Size',
  'inventory.edit.notes': 'Note',
}

const tableContainers = {
  'tableContainers.title': 'Containers',
  'tableContainers.id': 'Id',
  'tableContainers.name': 'Name',
  'tableContainers.isEnabled': 'Operation',
  'tableContainers.isEnabledTrue': 'In Operation',
  'tableContainers.isEnabledFalse': 'Out of Operation',
  'tableContainers.capacity': 'Capacity',
  'tableContainers.equipmentCount': 'Equipment Count',
  'tableContainers.boatsCount': 'Number of Kayaks',
}

const detailContainer = {
  'containerInfo.info': 'Information',
  'containerInfo.id': 'Container Id:',
  'containerInfo.name': 'CONTAINER NAME',
  'containerInfo.status': 'CONTAINER STATUS',
  'containerInfo.pin': 'PIN',
  'containerInfo.inventory': 'Container Inventory',
  'containerInfo.kayak': 'NUMBER OF KAYAKS',
  'containerInfo.equipments': 'NUMBER OF EQUIPMENTS',
}

const tablePayments = {
  'tablePayments.title': 'Payments',
  'tablePayments.id': 'Id',
  'tablePayments.user': 'User',
  'tablePayments.status': 'Status',
  'tablePayments.amount': 'Amount',
  'tablePayments.price': 'Price',
  'tablePayments.penalty': 'Penalty',
  'tablePayments.exportPayments': 'Export Payments',

  'tablePayments.status.pending': 'Pending',
  'tablePayments.status.processing': 'Processing',
  'tablePayments.status.successful': 'Successful',
  'tablePayments.status.expired': 'Expired',
  'tablePayments.status.failed': 'Failed',    
}

const tableOrders = {
  'tableOrders.title': 'Orders',
  'tableOrders.id': 'Id',
  'tableOrders.user': 'User',
  'tableOrders.status': 'Status',
  'tableOrders.paymentStatus': 'Payment Status',
  'tableOrders.reservation': 'Reservations',
  'tableOrders.price': 'Price',
  'tableOrders.penalty': 'Penalty',
}

const orderDetail = {
  'order.detail.title': 'Order',
  'order.detail.id': 'Id',
  'order.detail.idOrder': 'Order Id',
  'order.detail.user': 'User',
  'order.detail.status': 'Order Status',
  'order.detail.price': 'Price',
  'order.detail.startPlace': 'Start of Journey',
  'order.detail.finishPlace': 'End of Journey',
  'order.detail.cancel': 'Cancel Payment',
  'order.detail.close': 'Close Payment',
  
  'order.detail.in_progress': 'Processing',
  'order.detail.awaiting_payment': 'Awaiting Payment',
  'order.detail.completed': 'Completed',
  'order.detail.cancelled': 'Cancelled',
}

const finesTable = {
  'fines.detail.title': 'Fines',
  'fines.detail.id': 'Id',
  'fines.detail.status': 'Status',
  'fines.detail.amount': 'Price',
  'fines.detail.description': 'Description',

  'fines.detail.awaiting_payment': 'Awaiting Payment',
  'fines.detail.paid': 'Paid',
  
  'fines.addFine': 'Add Fine',
}

const priceList = {
  'priceList.title': 'Price List',
  'priceList.kayak': '2-person Boat',
  'priceList.cayakThreePerson': '3-person Boat',
  'priceList.paddle': 'Paddle',
  'priceList.helmet': 'Helmet',
  'priceList.vest': 'Vest',
  'priceList.bag': 'Bag',
  'priceList.openContainer': 'Open Container',
  'priceList.item': 'Item',
  'priceList.rent': 'Rental',
  'priceList.rentOneHour': 'First Hour',
  'priceList.rentNextHour': 'Next Hour',
  'priceList.lost': 'Lost',
  'priceList.damage': 'Damage',
}

const table = {
  'table.actions': 'Actions',
  'table.showing': 'Showing',
  'table.to': 'to',
  'table.of': 'of',
  'table.items': 'items',
  'table.itemsPerPage': 'items per page',
}

const button = {
  'button.addUser': 'ADD USER',
  'button.addItem': 'ADD ITEM',
  'button.addFine': 'ADD FINE',
  'button.change': 'Change',
  'button.delete': 'Delete',
  'button.block': 'Block',
  'button.unblock': 'Unblock',
  'button.active': 'Activate',
  'button.deactive': 'Deactivate',
  'button.blockUser': 'Block User',
  'button.unblockUser': 'Unblock User',
  'button.blockCharges': 'Block Charges',
  'button.unblockCharges': 'Activate Charges',
  'button.detail': 'Detail',
  'button.cancel': 'CANCEL',
  'button.select': 'SELECT',
  'button.save': 'SAVE',
  'button.back': 'BACK',
}

const alerts = {
  'userAlert.signIn.invalidToken': 'Invalid token',
  'userAlert.signIn.expiredToken': 'Login validity expired',
  'userAlert.signIn.server': 'No server response',
  'userAlert.signIn.failed': 'Login failed',
  'userAlert.signIn.wrongRole': 'Administrator email required for login',
  'userAlert.signIn.alert': 'We have sent a login link to your email.',
  'userAlert.block': 'User has been blocked',
  'userAlert.blockError': 'Failed to block user',
  'userAlert.unblock': 'User has been unblocked',    
  'userAlert.unblockError': 'Failed to unblock user',
  'userAlert.freeCharge': 'Charges have been deactivated',
  'userAlert.freeChargeError': 'Failed to deactivate charges',
  'userAlert.charge': 'Charges have been activated',    
  'userAlert.chargeError': 'Failed to activate charges',
  'userAlert.editUser.true': 'User has been changed',
  'userAlert.editUser.false': 'Failed to change user',
  'userAlert.removeUser': 'User account successfully deleted',
  'userAlert.removeUser.false': 'Failed to delete user account',
  
  'inventoryAlert.status.true': 'Item status changed',
  'inventoryAlert.status.false': 'Failed to change item status',

  'inventoryAlert.edit.true': 'Item has been changed',
  'inventoryAlert.edit.false': 'Failed to change item',
  
  'containerAlert.editEnabled.true': 'Container status changed',
  'containerAlert.editEnabled.false': 'Failed to change container status',
  
  'pricesAlert.edit.true': 'Price list successfully changed',
  'pricesAlert.edit.false': 'Failed to change price list',
  
  'finesAlert.add.true': 'Fine successfully added',
  'finesAlert.add.false': 'Failed to add fine',
  'finesAlert.addAmount.false': 'Fine amount must be filled in',
  'finesAlert.addDescription.false': 'Description must be filled in',
  'finesAlert.addDescriptionAmount.false': 'Description and fine amount must be filled in',
  
  'orderAlert.closeOrder.true': 'Order closed',
  'orderAlert.closeOrder.false': 'Failed to close order',
  'orderAlert.cancelOrder.true': 'Order cancelled',
  'orderAlert.cancelOrder.false': 'Failed to cancel order',
};

const others = {
  'user.remove': 'Do you really want to remove the user?',
  'loading': 'Loading...',
  'exportPaymentsTitle': 'Select the period for which you want to export payments',
  'exportPayments': 'Export payments',
  'blockUser': 'Do you really want to block the user?',
  'unblockUser': 'Do you really want to unblock the user?',
  'blockCharges': 'Do you really want to deactivate charges?',
  'unblockCharges': 'Do you really want to activate charges?',
  'currency': '\u20AC',
};
    
const messages = {
  ...authentication,
  ...sideNav,
  ...tableUsers,
  ...userProfile,
  ...userEdit,
  ...tableInventory,
  ...tableItems,
  ...inventory,
  ...tableContainers,
  ...detailContainer,
  ...tablePayments,
  ...tableOrders,
  ...priceList,
  ...table,
  ...button,
  ...alerts,
  ...others,
};

export default messages;


import { useEffect, useState } from 'react';
// language
import { injectIntl, IntlShape } from 'react-intl';

// redux
import { useSelector } from 'react-redux';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import RemoveItem from 'components-lib/RemoveItem'

// utils
import { formatDateTime } from 'utils'

// types
import { ReservationData, } from 'types/typesRedux';

interface Props {
  intl: IntlShape,
  onModalEdit?: ( data: string ) => void,
  onSubmitEdit?: ( bool: boolean ) => void,
}

function ReservationInfo( { intl, onModalEdit, onSubmitEdit } :Props ): JSX.Element {

  const reservation = useSelector( (state: ReservationData) => state.reservationDetail.reservation )
  const [ isfine, setIsFine ] = useState<boolean>(false);

  const handleButtonEdit = () => {
    if (reservation.status !== intl.formatMessage({id:'tableReservations.cancelled'}) && 
        reservation.status !== intl.formatMessage({id:'tableReservations.rejected'}) ) {
      return true;
    } else if ( reservation.status === intl.formatMessage({id:'tableReservations.cancelled'}) && 
    !reservation.fineStatus ) {
      return true;
    }
    return false;
  }

  const handleButtonText = () => {
    if (reservation.status !== intl.formatMessage({id:'tableReservations.cancelled'}) && 
      reservation.status !== intl.formatMessage({id:'tableReservations.rejected'})) {
      return `${intl.formatMessage({id:'button.change'})}`;
    } else if (reservation.status === intl.formatMessage({id:'tableReservations.cancelled'}) && 
      !reservation.fineStatus) {
      return `${intl.formatMessage({id:'button.addFine'})}`;
    }
  }

  // handle close modal window
  const handleCloseWindow = ( bool: boolean ) => {
    if (bool === false){ 
      setIsFine(false);
    }
  }

  // Handle remove user btn in modal window
  const handleAddFine = ( bool: boolean ) => {
    if ( bool === true ) {
      onModalEdit(handleButtonText())
      setIsFine( false );
    }
  }

  const handleButtonAction = () => {
    if (reservation.status === intl.formatMessage({id:'tableReservations.cancelled'}) && 
      !reservation.fineStatus) {
        setIsFine( true );
    } else {
      onModalEdit(handleButtonText())
    }
  }
     
  return (
    <>
      <Card
        sx={{
          borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
          position: "sticky",
          top: "1%",
        }}
      >
        <MDBox        
          display="flex"
          flexDirection="column"
          p={2}
          m={0}
          sx={{ listStyle: "none" }}
        >    

          <MDBox p={1} pb={0}
            sx= {{ flex: "1 100%", }} > 

            {/* Header */}
            <MDBox 
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}>
              <MDTypography variant="h5">
                {intl.formatMessage({id:'reservationDetail.title'})}
              </MDTypography>
            </MDBox>

            <MDTypography variant="button" >
              {intl.formatMessage({id:'reservationDetail.id'})}:
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{ reservation.id ? reservation.id : '-'}
            </MDTypography>
          </MDBox>


          <Grid container pb={3} px={1} pt={0} mt={0}
            sx={{ width:"100%", }} >

            {/* first name + last name */}
            <Grid 
              container
              xs={12} sm={12} p={0} mt={0}
              sx={ { 
                display: "flex",
                flexDirection: "row",
              } } 
              >   
              
              <MDBox 
                sx={ { 
                  display: "flex",
                  flexDirection: "column",
                  mt: "1.2rem",
                  lineHeight: "1.625",
                  letterSpacing: "0.00938em",
                  width:'50%',
                } } 
              >
                <MDTypography 
                  sx={ {
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                  } } 
                  variant="button"                   
                  >
                  {intl.formatMessage({id:'reservationDetail.userFirstName'})}
                </MDTypography>              
                <MDTypography variant="button" 
                  sx={ { marginTop: "0.2rem",} }
                  fontWeight="regular" 
                  color="text">
                    { reservation.userFirstName ?? '-'}
                </MDTypography>
              </MDBox>

              <MDBox 
                sx={ { 
                  display: "flex",
                  flexDirection: "column",
                  mt: "1.2rem",
                  lineHeight: "1.625",
                  letterSpacing: "0.00938em",
              } } 
              >
                <MDTypography 
                  sx={ {                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                  } } 
                  variant="button"                   
                  >
                  {intl.formatMessage({id:'reservationDetail.userLastName'})}
                </MDTypography>              
                <MDTypography variant="button" 
                sx={ { marginTop: "0.2rem",} }
                fontWeight="regular" 
                color="text">
                  { reservation.userLastName ?? '-'}
                </MDTypography>
              </MDBox>

            </Grid>   

            {/* phone + email */}
            <Grid 
              container
              xs={12} sm={12} p={0} mt={0}
              sx={ { 
                display: "flex",
                flexDirection: "row",
                // alignContent:'space-between',
              } } 
              >   
              
              <MDBox 
                sx={ { 
                  display: "flex",
                  flexDirection: "column",
                  mt: "1.2rem",
                  lineHeight: "1.625",
                  letterSpacing: "0.00938em",
                  width:'50%',
                } } 
              >
                <MDTypography 
                  sx={ {                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                  } } 
                  variant="button"                   
                  >
                  {intl.formatMessage({id:'reservationDetail.mobil'})}
                </MDTypography>              
                <MDTypography variant="button" 
                  sx={ { marginTop: "0.2rem",} }
                  fontWeight="regular" 
                  color="text">
                    { reservation.userPhone ?? '-'}
                </MDTypography>
              </MDBox>

              <MDBox 
                sx={ { 
                  display: "flex",
                  flexDirection: "column",
                  mt: "1.2rem",
                  lineHeight: "1.625",
                  letterSpacing: "0.00938em",
                  width:'50%',
                } } 
              >
                <MDTypography 
                  sx={ {                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                  } } 
                  variant="button"                   
                  >
                  {intl.formatMessage({id:'reservationDetail.email'})}
                </MDTypography>              
                <MDTypography variant="button" 
                  sx={ { marginTop: "0.2rem",} }
                  fontWeight="regular" 
                  color="text">
                    { reservation.userEmail ?? '-'}
                </MDTypography>
              </MDBox>

            </Grid>  

            {/* created at + start at */}
            <Grid 
              container
              xs={12} sm={12} p={0} mt={0}
              sx={ { 
                display: "flex",
                flexDirection: "row",
                // alignContent:'space-between',
              } } 
              >   
              
              <MDBox 
                sx={ { 
                  display: "flex",
                  flexDirection: "column",
                  mt: "1.2rem",
                  lineHeight: "1.625",
                  letterSpacing: "0.00938em",
                  width:'50%',
                } } 
              >
                <MDTypography 
                  sx={ {                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                  } } 
                  variant="button"                   
                  >
                  {intl.formatMessage({id:'reservationDetail.createdAt'})}
                </MDTypography>              
                <MDTypography variant="button" 
                  sx={ { marginTop: "0.2rem",} }
                  fontWeight="regular" 
                  color="text">
                    { reservation.createdAt ?? '-'}
                </MDTypography>
              </MDBox>

              <MDBox 
                sx={ { 
                  display: "flex",
                  flexDirection: "column",
                  mt: "1.2rem",
                  lineHeight: "1.625",
                  letterSpacing: "0.00938em",
              } } 
              >
                <MDTypography 
                  sx={ {                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                  } } 
                  variant="button"                   
                  >
                  {intl.formatMessage({id:'reservationDetail.startAt'})}
                </MDTypography>              
                <MDTypography variant="button" 
                sx={ { marginTop: "0.2rem",} }
                fontWeight="regular" 
                color="text">
                  { reservation.startAt ?? '-'}
                </MDTypography>
              </MDBox>

            </Grid> 

            {/* place + boatsCount */}
            <Grid 
              container
              xs={12} sm={12} p={0} mt={0}
              sx={ { 
                display: "flex",
                flexDirection: "row",
              } } 
              >   
              
              <MDBox 
                sx={ { 
                  display: "flex",
                  flexDirection: "column",
                  mt: "1.2rem",
                  lineHeight: "1.625",
                  letterSpacing: "0.00938em",
                  width:'50%',
                } } 
              >
                <MDTypography 
                  sx={ {                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                  } } 
                  variant="button"                   
                  >
                  {intl.formatMessage({id:'reservationDetail.place'})}
                </MDTypography>              
                <MDTypography variant="button" 
                  sx={ { marginTop: "0.2rem",} }
                  fontWeight="regular" 
                  color="text">
                    { reservation.place ?? '-'}
                </MDTypography>
              </MDBox>

              <MDBox 
                sx={ { 
                  display: "flex",
                  flexDirection: "column",
                  mt: "1.2rem",
                  lineHeight: "1.625",
                  letterSpacing: "0.00938em",
              } } 
              >
                <MDTypography 
                  sx={ {                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                  } } 
                  variant="button"                   
                  >
                  {intl.formatMessage({id:'reservationDetail.boatsCount'})}
                </MDTypography>              
                <MDTypography variant="button" 
                sx={ { marginTop: "0.2rem",} }
                fontWeight="regular"
                color="text">
                  { reservation.boatsCount ?? '-'}
                </MDTypography>
              </MDBox>

            </Grid> 

            {/* status +  canceled at*/}
            <Grid 
              container
              xs={12} sm={12} p={0} mt={0}
              sx={ { 
                display: "flex",
                flexDirection: "row",
              } }
              >
              
              <MDBox 
                sx={ {
                  display: "flex",
                  flexDirection: "column",
                  mt: "1.2rem",
                  lineHeight: "1.625",
                  letterSpacing: "0.00938em",
                  width:'50%',
                } }
              >
                <MDTypography 
                  sx={ {                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                  } } 
                  variant="button"                   
                  >
                  {intl.formatMessage({id:'reservationDetail.statusReservation'})}
                </MDTypography>              
                <MDTypography variant="button" 
                  sx={ { marginTop: "0.2rem",} }
                  fontWeight="regular" 
                  color="text">
                    { reservation.status ?? '-'}
                </MDTypography>
              </MDBox>

              { reservation.status === intl.formatMessage({id:'tableReservations.cancelled'}) &&
                <MDBox
                  sx={ {
                    display: "flex",
                    flexDirection: "column",
                    mt: "1.2rem",
                    lineHeight: "1.625",
                    letterSpacing: "0.00938em",
                } }
                >
                  <MDTypography
                    sx={ {
                      fontWeight: "500",
                      fontSize:"0.7rem",                     
                    } } 
                    variant="button"                   
                  >
                    {intl.formatMessage({id:'reservationDetail.cancelledAt'})}
                  </MDTypography>              
                  <MDTypography variant="button"
                  sx={ { marginTop: "0.2rem",} }
                  fontWeight="regular"
                  color="text">
                    { reservation.cancelledAt ?? '-'}
                  </MDTypography>
                </MDBox>
              }

              { handleButtonEdit() &&   
              
              <>
              { reservation.status === intl.formatMessage({id:'tableReservations.cancelled'}) && 
                !reservation.fineStatus && 
                <MDBox 
                  sx={ {
                    display: "flex",
                    flexDirection: "column",
                    mt: "1.2rem",
                    lineHeight: "1.625",
                    letterSpacing: "0.00938em",
                    width:'50%',
                    visibility: "hidden",
                  } }
                >
                  <MDTypography 
                    sx={ {                         
                      // mt: "0.6rem", 
                      fontWeight: "500",
                      fontSize:"0.7rem",                     
                    } } 
                    variant="button"                   
                    >
                    -
                  </MDTypography>              
                  <MDTypography variant="button" 
                    sx={ { marginTop: "0.2rem",} }
                    fontWeight="regular" 
                    color="text">
                      -
                  </MDTypography>
                </MDBox>
              }

                <MDBox item 
                  sx= {{
                    display: "flex",    
                    width:  "46%",  
                    mt: "1.4rem",                                      
                  }} >
                  <MDButton 
                    variant="gradient" 
                    color="light" 
                    onClick={ (e) => handleButtonAction()}
                    sx={{
                        // visibility: handleButtonEdit() ? "visible" : "hidden", 
                      }}
                    >
                    { handleButtonText() }
                  </MDButton>
                </MDBox>

                </>
              }

            </Grid> 

          </Grid>        
        </MDBox>
      </Card>
        { isfine && (
          <RemoveItem 
            onCloseWindow={ handleCloseWindow }
            onRemoveItem={ handleAddFine }
            title={ 'fine' }
          />
        )}
    </>
  );
}

export default injectIntl(ReservationInfo);

import { ActionTypes } from "redux/actions/actionType";
import { PaymentsTableType } from "types/types";

export const setPayments = ( payments: PaymentsTableType, totalCount: number ) => {
    return {
        type: ActionTypes.SET_PAYMENTS,
        payload: { payments, totalCount }
    }
};

export const setPaymentsOffset = ( offsetChange: number )  => {
    return {
        type: ActionTypes.SET_PAYMENTS_OFFSET,
        payload: { offset: offsetChange }
    }
};
  
export const setPaymentsLimit = ( limitChange: number ) => {
    return {
        type: ActionTypes.SET_PAYMENTS_LIMIT,
        payload: { limit: limitChange }
    }                    
};

export const setPaymentsByUser = ( payments: PaymentsTableType, totalCount: number ) => {
    return {
        type: ActionTypes.SET_PAYMENTS_BY_USER,
        payload: { payments, totalCount }
    }
};


export const setPaymentsUserOffset = ( offsetChange: number )  => {
    return {
        type: ActionTypes.SET_PAYMENTS_USER_OFFSET,
        payload: { offset: offsetChange }
    }
};
  
  export const setPaymentsUserLimit = ( limitChange: number ) => {
    return {
        type: ActionTypes.SET_PAYMENTS_USER_LIMIT,
        payload: { limit: limitChange }
    }                    
};

/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */

// import * as React from 'react';
import React, { useEffect } from 'react';
import {
  addMonths,
  isSameDay,
  isWithinInterval,
  isAfter,
  isBefore,
  isSameMonth,
  addYears,
  max,
  min,
} from 'date-fns';

// eslint-disable-next-line no-unused-vars
import { DateRange, NavigationAction, DefinedRange } from '../types';
import { getValidatedMonths, parseOptionalDate } from '../utils';

import { getDefaultRanges } from '../defaults';
import { IntlShape } from 'react-intl';

import Menu from './Menu';
import { useIntl } from 'react-intl';

type Marker = symbol;

export const MARKERS: { [key: string]: Marker } = {
  FIRST_MONTH: Symbol('firstMonth'),
  SECOND_MONTH: Symbol('secondMonth'),
};

interface DateRangePickerProps {
  open: boolean;
  initialDateRange?: DateRange;
  definedRanges?: DefinedRange[];
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange: (dateRange: DateRange) => void;
  intl?: IntlShape;
}

const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = (
  props: DateRangePickerProps,
) => {
  const today = new Date();
  const intl = useIntl();

  const now = new Date();
  const dateAtMidnight = new Date(now.setHours(0, 0, 0, 0));

  const dateEndDay = new Date(now.setHours(23, 59, 59, 999));

  const {
    open,
    onChange,
    initialDateRange,
    minDate,
    maxDate,
    // definedRanges = getDefaultRanges(new Date(), intl),
    definedRanges = getDefaultRanges(dateAtMidnight, dateEndDay, intl),
  } = props;

  const minDateValid = parseOptionalDate(minDate, addYears(today, -10));
  const maxDateValid = maxDate ? parseOptionalDate(maxDate, addYears(today, 0)) : parseOptionalDate(maxDate, addYears(today, 1));
  const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(
    initialDateRange || {},
    minDateValid,
    maxDateValid,
  );

  const [dateRange, setDateRange] = React.useState<DateRange>({ ...initialDateRange });
  const [hoverDay, setHoverDay] = React.useState<Date>();
  const [firstMonth, setFirstMonth] = React.useState<Date>(intialFirstMonth || today);
  const [secondMonth, setSecondMonth] = React.useState<Date>(
    initialSecondMonth || addMonths(firstMonth, 1),
  );

  const { startDate, endDate } = dateRange;

  // handlers
  const setFirstMonthValidated = (date: Date) => {
    if (isBefore(date, secondMonth)) {
      setFirstMonth(date);
    }
  };

  const setSecondMonthValidated = (date: Date) => {
    if (isAfter(date, firstMonth)) {
      setSecondMonth(date);
    }
  };

  const setDateRangeValidated = (range: DateRange) => {
    let { startDate: newStart, endDate: newEnd } = range;
    // let newStart = new Date('2023-08-01');
    // let newEnd = new Date('2023-08-05');

    if (newStart && newEnd) {
      range.startDate = newStart = max([newStart, minDateValid]);
      range.endDate = newEnd = min([newEnd, maxDateValid]);

      setDateRange(range);
      onChange(range);

      setFirstMonth(newStart);
      setSecondMonth(isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd);
    } else {
      const emptyRange = {};

      setDateRange(emptyRange);
      onChange(emptyRange);

      setFirstMonth(today);
      setSecondMonth(addMonths(firstMonth, 1));
    }
  };

  const onDayClick = (day: Date) => {
    if (startDate && !endDate && !isBefore(day, startDate)) {
      const newRange = { startDate, endDate: day };
      onChange(newRange);
      setDateRange(newRange);
    } else {
      setDateRange({ startDate: day, endDate: undefined });
      onChange({ startDate: day, endDate: undefined });
    }
    setHoverDay(day);
  };

  // const onDayClick = (day: Date) => {
  //   if (startDate && !endDate && !isBefore(day, startDate)) {
  //     const newRange = { startDate, endDate: day };
  //     onChange(newRange);
  //     setDateRange(newRange);
  //   } else {
  //     setDateRange({ startDate: day, endDate: undefined });
  //   }
  //   setHoverDay(day);
  // };

  const onMonthNavigate = (marker: Marker, action: NavigationAction) => {
    if (marker === MARKERS.FIRST_MONTH) {
      const firstNew = addMonths(firstMonth, action);
      if (isBefore(firstNew, secondMonth)) setFirstMonth(firstNew);
    } else {
      const secondNew = addMonths(secondMonth, action);
      if (isBefore(firstMonth, secondNew)) setSecondMonth(secondNew);
    }
  };

  const onDayHover = (date: Date) => {
    if (startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  const inHoverRange = (day: Date) => {
    if (startDate && !endDate && hoverDay) {
      const interval = { start: startDate, end: hoverDay };
      return isAfter(hoverDay, startDate) && isWithinInterval(day, interval);
    }
    return false;
  };

  const helpers = {
    inHoverRange,
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  };

  return open ? (
    <Menu
      dateRange={dateRange}
      minDate={minDateValid}
      maxDate={maxDateValid}
      ranges={definedRanges}
      firstMonth={firstMonth}
      secondMonth={secondMonth}
      setFirstMonth={setFirstMonthValidated}
      setSecondMonth={setSecondMonthValidated}
      setDateRange={setDateRangeValidated}
      helpers={helpers}
      handlers={handlers}
    />
  ) : null;
};

export default DateRangePicker;

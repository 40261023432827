import React, { useEffect, useState } from 'react';

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

import { injectIntl, IntlShape } from 'react-intl';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { 
    setPrices, 
    setPricesForm, 
    deleteFormItem, 
    deleteFormItems, 
    deleteForm, 
} from 'redux/actions/priceActions';
import { setRefresh } from 'redux/actions/pageActions';

// alerts
import { useSnackbar } from "notistack";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "components/FormField";
import LoadingComponent from 'components/LoadingComponent';
import ErrorComponent from 'components/ErrorComponent';

// extract data
import { extractPricesEquipment } from 'common/extractData';

import { toNumber } from 'lodash';

// css
import '../../css/priceList.css';

// types
import { 
    PricesEquipmentType, 
    PricesEquipmentExtractType, 
    PricesEquipmentArray, 
    PricesFinesType } from 'types/types';
import { 
    Refresh, 
    pricesEquipmentData, 
    pricesFinesData, 
    pricesFormData,
} from 'types/typesRedux';

const styles = {
    visibleButton: {
      opacity: 1,
      transition: 'opacity 0.5s ease-in-out',
    },
    hiddenButton: {
      opacity: 0,
      transition: 'opacity 0.5s ease-in-out',
    },
};
  

type Props = {
    intl: IntlShape;
}

const PriceList = ({ intl }: Props) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const pricesEquipment = useSelector( (state: pricesEquipmentData) => state.pricesList.pricesEquipment )  
    const pricesFines = useSelector( (state: pricesFinesData) => state.pricesList.pricesFines )  
    const pricesForm = useSelector( (state: pricesFormData) => state.pricesList.pricesForm )  
    const refresh = useSelector( (state: Refresh) => state.handleRefresh);

    const [ localLoading, setLocalLoading ] = useState( true )
    const [isError, setIsError ] = useState<boolean>(false);

    // Handle value of input equipments
    const inputContentsEquipments = ( id: number, column: string ) => {

        let foundItem = pricesForm.equipment.find(
            (item: PricesEquipmentType) => {
                return item.categoryId === id && item[column] !== undefined;
            }
        );
        return foundItem ? foundItem[column] : '';     
    }

    // Handle value of input fines
    const inputContentsFines = ( column: string ): number => {
        
        let foundItem = (pricesForm.fines && pricesForm.fines[column] !== undefined) ?? pricesForm.fines[column] ;
        return foundItem ? foundItem[column] : ''; 
    }

    const handleInputPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = event.target.value;
        const name = event.target.name;
    
        const [categoryId, categoryValue] = name.split('.');
        const categoryIdInt = Number(categoryId);
    
        // Exit early if the input is not a valid decimal number or empty.
        if (!(/^\d*\.?\d*$/.test(rawValue)) || rawValue === '') {
            if (rawValue === '') {
                dispatch(deleteFormItem('equipment', categoryValue, categoryIdInt));
            }
            return;
        }
    
        let categoryFound = false;
        const updatedEquipment = pricesForm.equipment.map((item: PricesEquipmentType) => {
            if (item.categoryId === categoryIdInt) {
                categoryFound = true;
                return { ...item, [categoryValue]: rawValue };
            } else {
                return item;
            }
        });
    
        if (categoryFound) {
            // Update the existing item with new price value
            dispatch(setPricesForm({ equipment: updatedEquipment }));
        } else {
            // Add a new item with categoryId and price value
            dispatch(setPricesForm({
                equipment: [
                    ...pricesForm.equipment,
                    { categoryId: categoryIdInt, [categoryValue]: rawValue },
                ] as PricesEquipmentType[],
            }));
        }
    };
    

    // Set new value of input fines in redux
    const handleInputFines = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        let name = event.target.name;

        // If input is number, add a new item or update the item
        if (/^\d*\.?\d*$/.test( event.target.value )) {
            let priceValue = (event.target?.value) ? toNumber(event.target?.value) : null;

            dispatch(setPricesForm({ fines:{ ...pricesForm.fines, [name]: priceValue } } ));
        }
    }

    // Check if input with equipments is empty and if it is, delete whole row from form in redux
    useEffect(() => {
        // Delete categoryId from empty equipment
        if ( pricesForm ) {
            pricesForm.equipment.map( (item: PricesEquipmentType) => {
                const keys = Object.keys(item);
                const id = Number(item.categoryId);

                if ( keys.includes('categoryId') && keys.length === 1 ) {
                    dispatch(deleteForm( id ));
                }
                return keys
            })
        }
    },[ pricesForm.equipment ] )

    // Check if input with fines is empty and if it is, delete [key: value] from form in redux
    useEffect(() => {
        // Delete categoryId from empty equipment
        if ( pricesForm ) {
            // Delete doorLefOpen from empty fines
            if ( pricesForm.fines &&
                typeof pricesForm.fines.doorLeftOpened === 'string' && 
                pricesForm.fines.doorLeftOpened === '' ) { 
                dispatch(deleteForm( 'doorLefOpen' ));
            } 
            if ( pricesForm.fines &&
                typeof pricesForm.fines.reservationLateCancellation === 'string' && 
                pricesForm.fines.reservationLateCancellation === '' ) { 
                dispatch(deleteForm( 'reservationLateCancellation' ));
            }
        }
    },[ pricesForm.fines ] )
    
    // get prices after open page
    useEffect(() => {
        getPrices();
    },[] )

    // get prices after edit prices
    useEffect(() => {
        if ( refresh.refresh === true ) getPrices();
    },[ refresh.refresh ] )
    
    // Get prices
    const getPrices = async () => {
        const cancelToken = axios.CancelToken.source()
        try{
            const response = await apiClient.get(`/v1/pricing`,{
                cancelToken:cancelToken.token
            });

            if (response) {
                const responseEquipment = response.data.equipment.map( 
                    (item: unknown) => extractPricesEquipment( intl )( item as PricesEquipmentExtractType ) );
                const responseFines = response.data.fines;
                dispatch(setPrices( responseEquipment, responseFines ));
                if (isError === true) {setIsError(false)};
                // console.log(response.data);
            } else {
                console.log("No response received.");
            }
        } catch (err) {
            if (axios.isCancel(err)) {
                console.log("canceled")
            } else {
                // todo:handle error
            }
        }
        setLocalLoading( false );
        if ( refresh.refresh === true ) dispatch(setRefresh( { refresh: false } ))
        return () => cancelToken.cancel()
    }

    // Edit prices
    const editPrices = async ( equipment: PricesEquipmentArray, fines: PricesFinesType ) => {
        const cancelToken = axios.CancelToken.source()

        try {
            await apiClient.patch(`/v1/pricing`,{
            equipment: equipment,
            fines: fines,

            cancelToken:cancelToken.token
            }
            ).then( ( response ) => {
            if ( response ) {
                dispatch(setRefresh( { refresh: true } )) 
                enqueueSnackbar(`${intl.formatMessage({id:'pricesAlert.edit.true'})}`, { variant: 'success' });
            }
        });
        } catch ( error ){
            console.log( 'err' )
            enqueueSnackbar(`${intl.formatMessage({id:'pricesAlert.edit.false'})}`, { variant: 'error' });
        }

        return () => cancelToken.cancel()
    }

    const safeParseFloat = (value: string | number) => {
        const parsed = parseFloat(value.toString());
        return isNaN(parsed) ? value : parsed; // Return original value if parsing fails
    };

    const handleEditPrices = () => {

        // prepare data before edit ( change string to number )
        const formEquipment = pricesForm.equipment.map((item: PricesEquipmentType) => {
            const updatedItem: { [key: string]: string | number } = {};
        
            Object.keys(item).forEach(key => {
              updatedItem[key] = safeParseFloat(item[key]);
            });
        
            return updatedItem;
          });

        const updatedEquipment = pricesEquipment.map((item: PricesEquipmentType) => ({
            ...item,
            ...formEquipment.find((e: PricesEquipmentType) => e.categoryId === item.categoryId)
        }));
        
        const formFines = pricesForm.fines;
        const updatedFines = {
            ...pricesFines,
            ...formFines
        };

        editPrices( updatedEquipment, updatedFines );

        // delete form data after edit prices
        dispatch(deleteFormItems( 'equipment' ));
        dispatch(deleteFormItems( 'fines' ));
    }

    const isVisible = ( pricesForm.equipment.length > 0 || Object.keys(pricesForm.fines).length > 0 );

    const handleRefresh = () => {
        dispatch(setRefresh( { refresh: true } ));
    }
    
    if ( isError ) {
    return <ErrorComponent onPress={handleRefresh} />;
    }

    if ( localLoading === true ) {
        return <LoadingComponent />
    }

    return (
        <MDBox
            sx={{ marginLeft: "max(18.7rem)", display: "flex",
            flexDirection: "row", alignItems: "center",
            paddingRight: "1rem",
            minWidth: "55rem", maxWidth: "60rem", height: "100vh", }}
        >
            <Card
                sx={{ borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                    position: "sticky", top: "1%", width: "75%"}}
            >
                
                <MDBox
                    display="flex"
                    flexDirection="column"
                    p={2} m={0} sx={{ listStyle: "none", }}
                >    

                    {/* Header */}
                    <MDBox p={1} pb={0}
                        sx= {{ flex: "1 100%", }} 
                        > 
                        <MDBox 
                            sx={{ display: "flex", }}
                        >
                            <MDTypography variant="h5">{intl.formatMessage({id: 'priceList.title'})}</MDTypography>
                        </MDBox>
                    </MDBox>

                    <Grid container pb={0} px={1} pt={4} mt={0}
                        sx={{ width:"100%", display: "flex",
                        flexDirection: "row",
                        // gap:"1%", 
                        }}
                    >

                        <MDTypography 
                            sx={{ fontWeight: "500", fontSize:"0.9rem", width:"28%" }} 
                            variant="button"                   
                        >
                            {intl.formatMessage({id: 'priceList.item'})}
                        </MDTypography> 

                        <MDTypography
                            sx={{ 
                                display: "flex", justifyContent:"right",
                                fontWeight: "500", fontSize:"0.9rem", width:"18%" }} 
                            variant="button"                   
                        >
                            {intl.formatMessage({id: 'priceList.rentOneHour'})}
                        </MDTypography>

                        <MDTypography
                            sx={{ display: "flex", justifyContent:"right",
                                fontWeight: "500", fontSize:"0.9rem", width:"18%" }} 
                            variant="button"                   
                        >
                            {intl.formatMessage({id: 'priceList.rentNextHour'})}
                        </MDTypography> 

                        <MDTypography 
                            sx={{ display: "flex", justifyContent:"right",
                                fontWeight: "500", fontSize:"0.9rem", width:"18%" }} 
                            variant="button"                   
                        >
                            {intl.formatMessage({id: 'priceList.damage'})}
                        </MDTypography>

                        <MDTypography 
                            sx={{ display: "flex", justifyContent:"right",
                                fontWeight: "500", fontSize:"0.9rem", width:"17%" }} 
                            variant="button"                   
                        >
                            {intl.formatMessage({id: 'priceList.lost'})}
                        </MDTypography>

                        <Divider sx={{ width:"100%", height:"0.06rem", 
                            opacity:"0.6", margin:"0", marginTop:"1rem"}} />

                    </Grid>
                

                    <Grid container pb={3} px={1} pt={0} mt={0}
                        sx={{ width:"100%", display: "flex",
                        flexDirection: "row",
                        // gap:"3%", 
                        }} 
                    >
                    
                        {/* Items columns */}  
                        
                        <MDBox 
                            sx={{ 
                                display: "flex", flexDirection: "column",
                                justifyContent:"space-between", width:"28%",
                                height: "100%",
                                mt: "1.2rem", lineHeight: "1.473rem",
                                '& > *': {
                                    marginBottom: '0.2rem',
                                },
                            }} 
                        >

                            { pricesEquipment.map( (item: PricesEquipmentType) => (

                            <MDTypography 
                                key={item.categoryId}
                                className= "rowHeight"
                                sx={{ fontWeight: "500", fontSize:"0.9rem",
                                display: "flex", alignItems: "center",
                            }} 
                                variant="button"                   
                                >
                                {intl.formatMessage({id: `priceList.${item.categoryName}`})}
                            </MDTypography> 
                            ))}

                            <MDTypography
                                className= "rowHeight"
                                sx={{ fontWeight: "500", fontSize:"0.9rem",
                                display: "flex", alignItems: "center",
                            }} 
                                variant="button"                   
                                >
                                {intl.formatMessage({id: `priceList.openContainer`})}
                            </MDTypography> 
                          
                            <MDTypography
                                className= "rowHeight"
                                sx={{ fontWeight: "500", fontSize:"0.9rem",
                                display: "flex", alignItems: "center",
                            }} 
                                variant="button"                   
                                >
                                {intl.formatMessage({id: `priceList.reservationFine`})}
                            </MDTypography> 
                        
                        </MDBox> 
                        <Divider orientation="vertical" sx={{ width:"0.06rem", height:"inherit", 
                            opacity:"0.6", margin:"0", marginTop:"1rem",}} />


                        {/* Rent 1 hour */}          
                        <MDBox 
                            sx={{ display: "flex", flexDirection: "column", 
                                width:"18%", 
                                mt: "1.2rem", 
                                alignItems: "stretch",
                                '& > *': {
                                    // marginBottom: '4%',
                                },
                            }} 
                        >   

                            { pricesEquipment.map( (item: PricesEquipmentType) => (
                            <MDBox 
                                key={item.categoryId}
                                className= "PriceInput rowHeight"
                                sx={{ display: "flex", flexDirection: "row", 
                                    justifyContent:"right", alignItems:"center",
                                }}
                            >        
                                <FormField 
                                    className="formPrice"
                                    sx={{ 
                                        width:"2rem", 
                                    }}
                                    placeholder={ item.firstHourFee ? item.firstHourFee.toString() : '0' }
                                    value={ inputContentsEquipments( item.categoryId, `firstHourFee` ) }
                                    onChange={ handleInputPrice }
                                    name={ `${item.categoryId}.firstHourFee` }
                                />
                                <MDTypography variant="button" 
                                    fontWeight="regular" 
                                    color="text"
                                >
                                    &nbsp;{intl.formatMessage({id: `currency`})}
                                </MDTypography>
                            </MDBox>
                            ))}
                            
                        </MDBox> 

                        {/* Rent next hour */}
                        <MDBox 
                            sx={{ display: "flex", flexDirection: "column", 
                                width:"18%", 
                                mt: "1.2rem", alignItems: "stretch",
                            '& > *': {
                                    // marginBottom: '4%',
                                },
                            }} 
                        >   

                            { pricesEquipment.map( (item: PricesEquipmentType) => (
                            <MDBox 
                                key={item.categoryId}
                                className= "PriceInput rowHeight"
                                sx={{ display: "flex", flexDirection: "row", 
                                    justifyContent:"right", alignItems:"center", }}
                            >        
                                <FormField 
                                    className="formPrice"
                                    sx={{ 
                                        width:"2rem", 
                                    }} 
                                    placeholder={ item.nextHourFee ? item.nextHourFee.toString() : '0' }
                                    value={ inputContentsEquipments( item.categoryId, `nextHourFee` ) }
                                    onChange={ handleInputPrice }
                                    name={ `${item.categoryId}.nextHourFee` }
                                />
                                <MDTypography variant="button" 
                                    fontWeight="regular" 
                                    color="text">
                                    &nbsp;{intl.formatMessage({id: `currency`})}
                                </MDTypography>
                            </MDBox>
                            ))}
                            
                        </MDBox> 

                        {/* Damage */}
                        <MDBox 
                            className= "rowHeight"
                            sx={{ display: "flex", flexDirection: "column", 
                                width:"18%", 
                                mt: "1.2rem", 
                                alignItems: "stretch",
                                '& > *': {
                                    // marginBottom: '4%',
                                },
                            }} 
                        >

                            { pricesEquipment.map( (item: PricesEquipmentType) => (
                            <MDBox
                                key={item.categoryId}
                                className= "PriceInput rowHeight"
                                sx={{ display: "flex", flexDirection: "row", 
                                    justifyContent:"right", alignItems:"center",
                                }}
                            >        
                                <FormField 
                                    className="formPrice"
                                    sx={{ width:"2rem", }} 
                                    placeholder={ item.damageFine ? item.damageFine.toString() : '0' }
                                    value={ inputContentsEquipments( item.categoryId, `damageFine` ) }
                                    onChange={ handleInputPrice }
                                    name={ `${item.categoryId}.damageFine` }
                                />
                                <MDTypography variant="button" 
                                    fontWeight="regular" 
                                    color="text">
                                    &nbsp;{intl.formatMessage({id: `currency`})}
                                </MDTypography>
                            </MDBox>
                            ))}
                            
                        </MDBox> 

                        {/* Lost */}
                        <MDBox 
                            sx={{ display: "flex", flexDirection: "column", 
                                width:"17%", 
                                mt: "1.2rem", 
                                alignItems: "stretch",
                                '& > *': {
                                // marginBottom: '4%',
                                },
                            }} 
                        >   

                            { pricesEquipment.map( (item: PricesEquipmentType) => (
                            <MDBox 
                                key={item.categoryId}
                                className= "PriceInput rowHeight"
                                sx={{ display: "flex", flexDirection: "row", 
                                    justifyContent:"right", alignItems:"center",
                                }}
                            >        
                                <FormField 
                                    className="formPrice"
                                    sx={{ 
                                        width:"2rem", 
                                    }} 
                                    placeholder={ item.lostFine ? item.lostFine.toString() : '0' }
                                    value={ inputContentsEquipments( item.categoryId, `lostFine` ) }
                                    onChange={ handleInputPrice }
                                    name={ `${item.categoryId}.lostFine` }
                                />
                                <MDTypography variant="button" 
                                    fontWeight="regular" 
                                    color="text">
                                    &nbsp;{intl.formatMessage({id: `currency`})}
                                </MDTypography>
                            </MDBox>
                            ))}

                            <MDBox 
                                className= "PriceInput rowHeight"
                                sx={{ display: "flex", flexDirection: "row", 
                                    justifyContent:"right", alignItems:"center",
                                }}
                            >        
                                <FormField 
                                    className="formPrice"
                                    sx={{ 
                                        width:"2rem", 
                                    }} 
                                    placeholder={ pricesFines.doorLeftOpened ? pricesFines.doorLeftOpened.toString() : '' }
                                    // inputRef={ inputContainer }
                                    // value={ inputContentsFines() }
                                    value={ inputContentsFines( `doorLeftOpened` ) }
                                    onChange={ handleInputFines }
                                    name={ `doorLeftOpened` }
                                />
                                <MDTypography variant="button" 
                                    fontWeight="regular" 
                                    color="text">
                                    &nbsp;{intl.formatMessage({id: `currency`})}
                                </MDTypography>
                            </MDBox>
                            
                            <MDBox 
                                className= "PriceInput rowHeight"
                                sx={{ display: "flex", flexDirection: "row", 
                                    justifyContent:"right", alignItems:"center",
                                }}
                            >        
                                <FormField 
                                    className="formPrice"
                                    sx={{ 
                                        width:"2rem", 
                                    }} 
                                    placeholder={ pricesFines.reservationLateCancellation ? pricesFines.reservationLateCancellation.toString() : '' }
                                    // inputRef={ inputReservation }
                                    value={ inputContentsFines( `reservationLateCancellation` ) }
                                    onChange={ handleInputFines }
                                    name={ `reservationLateCancellation` }
                                />
                                <MDTypography variant="button" 
                                    fontWeight="regular" 
                                    color="text">
                                    &nbsp;{intl.formatMessage({id: `currency`})}
                                </MDTypography>
                            </MDBox> 
                            
                        </MDBox>                      
                        
                    </Grid>
        

                    <MDBox sx={{ 
                        display:"flex", flexDirection:"row", justifyContent:"flex-end", 
                        width: "100%", paddingRight: "8px", paddingBottom: "8px" }}>
                        <MDButton 
                            variant="contained" 
                            color="success" 
                            sx={{
                                ...isVisible ? styles.visibleButton : styles.hiddenButton,
                                width: "6rem",
                              }}
                            onClick={ handleEditPrices }
                        >
                            {intl.formatMessage({id: 'button.change'})}
                        </MDButton>
                    </MDBox>                
                </MDBox>
        
            </Card>
        </MDBox>
    )
}

export default injectIntl(PriceList)
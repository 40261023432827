import * as React from 'react';
import {SnackbarProvider} from "notistack";
// import {makeStyles} from "@material-ui/core/styles";
// import {snackbarStyles} from "../../configFiles/template";

interface Props {
    children: any,
}

const WithSnackbar = ({children}: Props) => {

    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            autoHideDuration={2000}
        >
            {children}
        </SnackbarProvider>
    );
};

export default WithSnackbar;
import React, { MouseEvent, ChangeEvent, useRef, useEffect, useState } from 'react';

import { injectIntl, IntlShape } from 'react-intl';

// axios
import axios from 'axios';
import { apiClient } from 'api-client';


// alerts
import { useSnackbar } from "notistack";

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setItem, setItemForm } from 'redux/actions/inventoryActions';
import { setRefresh } from 'redux/actions/pageActions';
import { extractDataItem } from 'common/extractData';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import FormField from "components/FormField";
import LoadingComponent from 'components/LoadingComponent';

// icon
import CategoryIcon from '@mui/icons-material/Category';

// enumns
import { isActiveItemEnum, enumItemIsActive } from 'common/enums/DevicesStateEnum';

// types
import { ItemEditData } from 'types/typesRedux';

type Props = {
  intl: IntlShape,
  itemId: number,
  onEditItem: (bool: boolean) => void,
  onCloseWindow: (bool: boolean) => void,
}

const EditItem = ( {intl, itemId, onEditItem, onCloseWindow}: Props) => {
  
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [localLoading, setLocalLoading] = useState<boolean>(true);

  const item = useSelector( (state: ItemEditData) => state.item.item );
  const itemForm = useSelector( (state: ItemEditData) => state.item.itemForm );

  const variant = ( item && item.categoryVariant && item.categoryVariant !== undefined ) ? item.categoryVariant.map( (item: string) =>  item ) : null;

  // set data from inputs to redux
  const handleIsActive = ( e: ChangeEvent<{}>, newValue: string ) => {
    let isActive = newValue ? newValue : item.isActive;
    let modifiedStatus = isActive === `${intl.formatMessage({id:'tableInventory.statusTrue'})}` ? true : false;
    dispatch(setItemForm( { ...itemForm, 'isActive': modifiedStatus } ));
  }
 
  const handleVariant = ( e: ChangeEvent<{}>, newValue: string ) => {
    dispatch(setItemForm( { ...itemForm, 'variant': newValue } ));
  }
 
  const handleNotes = ( e: ChangeEvent<HTMLInputElement> ) => {
    dispatch(setItemForm( { ...itemForm, 'notes': e.target.value } ));
  }

  // get item
  useEffect(() => {
    dispatch(setItemForm( {
      isActive: null,
      notes: '',
      variant: '',
      } 
    ));
    getItem( itemId );
  }, [itemId]);


  const closeWindow = () => {
    onCloseWindow( false );
    dispatch(setItemForm( {
      isActive: null,
      notes: '',
      variant: '',
    } 
  ));
  } 

  // If submit, edit item
  const handleSubmit = async (e: MouseEvent<HTMLButtonElement> ) => {
    e.preventDefault();
    if ( ( itemForm.isActive !== null ) || itemForm.variant || itemForm.notes ) {
      editItem( itemId ); 
      // send parent close modal
      onEditItem( true );         
    } else {
      enqueueSnackbar(`${intl.formatMessage({id:'inventoryAlert.edit.empty'})}`, { variant: 'error' });
    }
  };

  // Handle get item for edit modal/////////////////////////////
  const getItem = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source()

    try {
      const response = await apiClient.get(`/v1/items/${id}`,{
        cancelToken:cancelToken.token
      });

      if (response) {
        let responseData = extractDataItem(intl)( response.data );
        dispatch(setItem( responseData ));
        setLocalLoading( false );
        // console.log( response.data )
      }
    } catch ( err ){
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        // todo:handle error
      }
    }
    return () => cancelToken.cancel()
  } 

  // Handle edit item /////////////////////////////
  const editItem = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source();

    try {
      await apiClient.patch(`/v1/items/${id}`,{
        isActive: itemForm.isActive === false || itemForm.isActive === true ? itemForm.isActive : enumItemIsActive(intl)(item.isActive),
        notes: itemForm.notes ? itemForm.notes : item.notes,
        variant: itemForm.variant ? itemForm.variant : item.variant,
        categoryId: item.categoryId,

        cancelToken:cancelToken.token
        }
      ).then( ( response ) => {
        if ( response ) {
          enqueueSnackbar(`${intl.formatMessage({id:'inventoryAlert.edit.true'})}`, { variant: 'success' });
          dispatch(setRefresh( { refreshInventory: true } ));
          dispatch(setItemForm( {
            isActive: null,
            notes: '',
            variant: '',
          } 
        ));
        }
    });
    } catch ( err ){
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        enqueueSnackbar(`${intl.formatMessage({id:'inventoryAlert.edit.false'})}`, { variant: 'error' });
      }
    };
    return () => cancelToken.cancel();
  }
  


  if ( localLoading === true ) {
    return <LoadingComponent />
  }

  return (
    <Grid container sx={{ position: 'fixed', top: '0%', left: '0%',
      backgroundColor:"rgba( 0, 0, 0, 0.5 ) !important", 
      width:"100vw", height:"100vh", zIndex:"1300" }}>

      <Card
          sx={ { 
            minWidth: "17rem", width: "20rem", maxWidth: "22rem",
            position: 'fixed',
            top: '50%', left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
      >        

        <MDBox        
            display="flex"
            flexDirection="column"
            p={2}
            m={0}
            sx={{ listStyle: "none" }}
        >    

          <MDBox sx= {{ display: "flex",
                  flexDirection: "row", gap: "1rem" }} >
            <MDBox
                sx={{ 
                  backgroundColor: "#17C063",
                  p: 1, mt: -4, ml: 0,
                  borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl, }}
                display="grid"
                justifyContent="center"
                alignItems="center"
                // bgColor="success"
                bgColor="#17C063"
                color="white"
                width="4.5rem"
                height="4.5rem"
                shadow="md"
                borderRadius="lg"
                variant="gradient"  
                fontSize= "2.2rem"        
                >
                <Icon sx={{marginBottom:"0.8rem"}} ><CategoryIcon /></Icon>
            </MDBox>

            <MDTypography variant="h6">
              {intl.formatMessage({id:'inventory.edit.title'})}
            </MDTypography>
                            
          </MDBox>
          
          <MDBox sx={{ marginBottom: "0.5rem", marginTop: "0.9rem", }}>
            <MDBox 
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-between",
                    gap:"4rem"
                }}
            >
              <MDTypography variant="button" >
              {intl.formatMessage({id:'inventory.edit.type'})}:
              </MDTypography>
              <MDTypography variant="button" 
                sx={ { } }
                fontWeight="regular" 
                color="text">
                  { item.name ? item.name : '-'}
              </MDTypography>
            </MDBox>
          </MDBox>

          <Grid item xs={12} md={12} 
            sx={ {                         
              mt: "0.6rem"
            } }>
            <MDTypography 
              variant="button" >
              {intl.formatMessage({id:'inventory.edit.status'})}
            </MDTypography>  
            <Autocomplete             
              options={isActiveItemEnum(intl)}
              onChange={ handleIsActive }
              sx={{ marginTop: "-0.5rem"  }}
              disableClearable
              renderInput={(params) => (
                <FormField {...params} 
                  InputLabelProps={{ shrink: true }}
                  // inputRef={isActiveInput} 
                  placeholder={ item.isActive ? item.isActive : ''}
                /> )}
            />
          </Grid>
          
          { variant && variant.length !== 0 && (
            <Grid item xs={12} md={12} 
            sx={ {                         
              mt: "0.6rem",
            } }>
              <MDTypography 
                variant="button" >
                {intl.formatMessage({id:'inventory.edit.variant'})}
              </MDTypography>  
              <Autocomplete             
                options={ variant }
                onChange={ handleVariant }
                sx={{ marginTop: "-0.5rem"  }}
                renderInput={(params) => (
                  <FormField {...params} 
                    InputLabelProps={{ shrink: true }}
                    // inputRef={variantInput} 
                    placeholder={ item.variant ? item.variant : ''}
                  /> )}
              />
            </Grid>
          )}          

          <Grid item xs={12} md={12} 
            sx={{
              display: "flex", flexDirection: "column", marginTop:"1rem"

            }}>
            <MDTypography variant="button" >
              {intl.formatMessage({id:'inventory.edit.notes'})}
            </MDTypography>           
            <MDInput 
              type="text"
              multiline
              rows={3}
              name= 'notes'
              placeholder={item.notes ? item.notes : ''}
              // inputRef={textInput}
              // onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, 'description')}
              onChange={handleNotes}
              />
          </Grid>  

          <MDBox 
            sx={{
                display: "flex", flexDirection: "row", justifyContent: "flex-end", 
                gap: "0.5rem", maxWidth: "100%", marginBottom: "0.5rem", marginTop: "1rem",
            }}
          >
            <MDButton 
                variant="outlined" 
                color="success" 
                // onClick={() => navigate(-1)}
                onClick={ closeWindow }
              >
                {intl.formatMessage({id:'button.cancel'})}
            </MDButton>
            <MDButton 
                variant="contained" 
                color="success" 
                // sx={{
                //   visibility: isVisible ? "visible" : "hidden", 
                // }}
                onClick={ handleSubmit }                  
              >
                {intl.formatMessage({id:'button.save'})}
            </MDButton>
          </MDBox>
  
        </MDBox>
      </Card>
    </Grid>
  )
};

export default injectIntl(EditItem);

import React from 'react'

import { injectIntl, IntlShape } from 'react-intl';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";



type Props = {
  intl: IntlShape,
  isBlockUser?: boolean,
  onCloseWindow: (bool: boolean) => void,
  onBtnBlock: (bool: boolean) => void  
}

const BlockUser = ( { intl, isBlockUser, onCloseWindow, onBtnBlock }: Props) => {

  const closeWindow = () => {
    onCloseWindow( false );
  }

  const submitBlock = () => {
    onBtnBlock( true );
  }

  return (
    <Grid container sx={{ position: 'fixed', top: '0%', left: '0%',
      backgroundColor:"rgba( 0, 0, 0, 0.5 ) !important", 
      width:"100vw", height:"100vh", zIndex:"1300" }}>
      <Card 
        sx={ { 
          minWidth: '26rem',
          width: 'auto',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          // backgroundColor: "#5ada55",
        } }
      >
        <MDBox 
          className='delete'
          sx={ {
            display: "flex",
            flexDirection: "column" ,
            justifyContent: "center" ,
            alignItems: "center" ,
            marginLeft: "0",
            // flexWrap: "wrap",
          } }
          >
          <MDBox p={3}>
            { isBlockUser ? (
              <MDTypography variant="h5">{intl.formatMessage({id:'blockUser'})}</MDTypography>
            ) : (
              <MDTypography variant="h5">{intl.formatMessage({id:'unblockUser'})}</MDTypography>
            ) }
          </MDBox>
        
          <MDBox 
            sx={ { 
              display:"flex",
              paddingBottom:"1rem", 
              gap:"0.8rem"
              
            } } 
          >
            <MDButton 
              variant="gradient" 
              color="light" 
              size="small"
              onClick={closeWindow}
            >
              {intl.formatMessage({id:'button.cancel'})}
            </MDButton>

            <MDButton 
              variant="gradient" 
              color="error" 
              size="small"
              onClick={submitBlock}
            >
              { isBlockUser ? (
                intl.formatMessage({id:'button.block'})
              ) : (
                intl.formatMessage({id:'button.unblock'})
              )}
            </MDButton>
          </MDBox>
          
        </MDBox>
      </Card>  
    </Grid>
  )
}

export default injectIntl(BlockUser)
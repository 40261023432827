import React, { useState, useEffect, useRef } from 'react'

import { useNavigate, } from "react-router-dom";

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setRefresh } from 'redux/actions/pageActions';
import { setUsersOffset, setUsersLimit } from 'redux/actions/usersActions';

// Material UI
import { Grid } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';

// components
import QueryTableDevices from 'components-lib/QueryTable/QueryTableDevices';
import RemoveItem from 'components-lib/RemoveItem'
import BlockUser from 'components-lib/BlockUser'

// language
import { injectIntl, IntlShape } from 'react-intl';

// notifications
import { useSnackbar } from "notistack";

// enums
import { blockedAtEnum } from 'common/enums/DevicesStateEnum';

// types
import { usersData } from 'types/typesRedux';
import { ColumnWidths } from 'types/types';
import { Columns } from 'types/tableTypes';

type User = {
  id: number;
  blockedAt: string;
};

type Props = {
  intl: IntlShape;
  initialRowLimit: number;
}

const UsersTable = ({intl, initialRowLimit}: Props) => {

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const currentLocale = intl.locale;

  // redux
  const data = useSelector( (state: usersData) => state.allUsers )

  const [ isDeleteUser, setIsDeleteUser ] = useState<boolean>( false );
  const [ isBlockUser, setIsBlockUser ] = useState<boolean>( false );
  const [ isUnblockUser, setIsUnblockUser ] = useState<boolean>( false );

  const [ userId, setUserId ] = useState<number>(null);

  // Handle delete user
  const deleteUser = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source();
    try {  
        const response = await apiClient.delete(`/v1/users/${id}`,{
        cancelToken:cancelToken.token
      });
      if ( response ) {
        dispatch(setRefresh( { refreshUsers: true } ));
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.removeUser'})}`, { variant: 'success' });
        // console.log(response, 'devices'); 
      }

    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(`${intl.formatMessage({id:'userAlert.removeUserFalse'})}`, { variant: 'error' });
    }
  }

  // Handle block user
  const blockUser = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source();
    try {
      await apiClient.put(`/v1/users/${id}/block`, {
        cancelToken:cancelToken.token
      }
      ).then( ( response ) => {
        dispatch(setRefresh( { refreshUsers: true } ));
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.block'})}`, { variant: 'success' });
      });
    } catch ( error ) {
        console.log( 'err' );
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.blockError'})}`, { variant: 'error' });
      }    
    return () => cancelToken.cancel();
  }

  // Unblock user
  const unblockUser = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source();

    try {
      await apiClient.delete(`/v1/users/${id}/block`, {
        cancelToken:cancelToken.token
      }
      ).then( ( response ) => {
        dispatch(setRefresh( { refreshUsers: true } ));
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.unblock'})}`, { variant: 'success' });
      });
    } catch ( error ){
        console.log( 'err' );
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.unblockError'})}`, { variant: 'error' });
      }
    return () => cancelToken.cancel();
  }

  // go to edit page of user
  const handleEditBtn = ( id: number ) => {
    navigate( (`/admin/users/user-edit/${id}`), { state: {
      id: id,
      }} 
    );
  }

  // go to profile page of user
  const handleDetailItem = ( id: number ) => {
    navigate( (`/admin/users/user-profile/${id}`), { state: {
      id: id,
      }}
    );
  }
 
  // open modal window remove user and set id
  const handleRemoveBtn = ( bool: boolean, id: number ) => {
    if ( bool === true ) {
      setUserId( id );
      setIsDeleteUser( true );
    }
  }
 
  // Handle modal window if click icon block/unblock
  const handleBlockItem = ( bool: boolean, id: number ) => {
    let users = data.users;
    let status = users.filter((user: User ) => id === user.id).map((user: User ) => user.blockedAt)
    if ( bool === true ) {
      setUserId( id );
      if ( status[0] === intl.formatMessage({id:'tableUsers.blockedAt.block'}) ) {
        setIsUnblockUser( true );
      } else if ( status[0] === intl.formatMessage({id:'tableUsers.blockedAt.null'}) ) {
        setIsBlockUser( true );
      }
    }
  }

  // handle close modal window
  const handleCloseWindow = ( bool: boolean ) => {
    if (bool === false){ 
      setIsBlockUser(false);
      setIsUnblockUser(false);
      setIsDeleteUser(false);
    }
  }

  // Handle remove user btn in modal window
  const handleRemoveItem = ( bool: boolean ) => {
    deleteUser( userId );    
    setIsDeleteUser( false );
  }
  
  // Handle block/unblock user btn in modal window
  const handleBtnBlock = () => {
    if ( isBlockUser ) {
      blockUser( userId );    
      setIsBlockUser( false );
    } else if ( isUnblockUser ) {
      unblockUser( userId ); 
      setIsUnblockUser( false );
    }
  }

  // Handle offset
  const handleOffset = ( tableOffset: number ) => {
    dispatch(setUsersOffset( tableOffset ));
  }
 
  // Handle limit
  const handleLimit = ( tableLimit: number ) => {
    dispatch(setUsersLimit( tableLimit ));
    localStorage.setItem('rowLimitUsers', tableLimit.toString());
  }
  
  const getColumnMinWidth = (columnId: string, locale: string): string => {
    const minWidths: ColumnWidths = {
      id: { default: "5.6rem", hu: "7rem" },
      blockedAt: { default: "7.8rem", hu: "7.2rem" },
      phone: { default: "8.3rem", hu: "8.3rem" },
      countryName: { default: "8.5rem", hu: "8.5rem" },
    };
  
    const columnConfig = minWidths[columnId];
    if (columnConfig) {
      return columnConfig[locale] || columnConfig.default;
    } else {
      return "auto";
    }
  };

  const [columns, setColumns] = useState<Columns[]>([
    { Header: intl.formatMessage( {id: 'tableUsers.id'} ), 
      accessor: "id", 
      width: "auto",
      minWidth: getColumnMinWidth("id", currentLocale),
      maxWidth: "6rem",
      columnFilter: 'number',
      input: 'input',
    },
    { Header: intl.formatMessage( {id: 'tableUsers.firstName'} ), 
      accessor: "firstName", 
      width: "auto",
      minWidth: "6rem",
      columnFilter: 'string',
      input: 'input',
    },
    { Header: intl.formatMessage({id: 'tableUsers.lastName'}), 
      accessor: "lastName", 
      width: "auto",
      minWidth: "7.5rem",
      columnFilter: 'string',
      input: 'input',
    },
    { Header: intl.formatMessage({id: 'tableUsers.email'}), 
      accessor: "email", 
      width: "auto", 
      minWidth: "12%",
      columnFilter: 'string',
      input: 'input',
    },
    { Header: intl.formatMessage({id: 'tableUsers.phone'}), 
      accessor: "phone",
      width: "auto", 
      minWidth: getColumnMinWidth("phone", currentLocale),
      columnFilter: 'string',
      input: 'input',
    },
    { Header: intl.formatMessage({id: 'tableUsers.country'}), 
    accessor: `countryName`, 
    width: "auto", 
    minWidth: getColumnMinWidth("countryName", currentLocale),
    columnFilter: 'string',
    input: 'input',
    },
    { Header: intl.formatMessage({id: 'tableUsers.blockedAt'}), 
      accessor: "blockedAt", 
      width: "auto",
      minWidth: getColumnMinWidth("blockedAt", currentLocale),
      input: 'autocomplete',
      filterOptions: Object.values(blockedAtEnum(intl)).map((item) => ({
        value: item.filterKey,
        label: item.label,
        key: item.filterValue
      })
      )
    },
    { Header: intl.formatMessage({id: 'table.actions'}), 
      accessor: "Action", 
      width: "auto",
      minWidth: "5%",
      input: 'title',
      clearIcon: true,
    },
  ]);

  useEffect(() => {
    const handleResize = () => {
      const maxWidth = 1320;
      const emailColumn = {
          Header: intl.formatMessage({ id: 'tableUsers.email' }),
          accessor: "email",
          width: "auto",
          minWidth: "15%",
          columnFilter: 'string',
          input: 'input',
      };
      const lastNameIndex = columns.findIndex(column => column.accessor === "lastName");
      const isEmailColumnPresent = columns.some(column => column.accessor === "email");

      if (window.innerWidth > maxWidth && !isEmailColumnPresent) {
          // set column email after lastName
          setColumns(currentColumns => [
              ...currentColumns.slice(0, lastNameIndex + 1),
              emailColumn,
              ...currentColumns.slice(lastNameIndex + 1)
          ] as Columns[] );
      } else if (window.innerWidth <= maxWidth && isEmailColumnPresent) {
          // delete column email, if exist andwindow width is less or equal maxWidth
          setColumns(currentColumns => currentColumns.filter(column => column.accessor !== "email"));
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [columns, intl]);

  return (

    <Grid>
      <Grid item >
        <QueryTableDevices
          title= {intl.formatMessage( {id: 'tableUsers.title'} )}
          tableData={ data.users }
          pagePagination={true}
          totalCount={ data.totalCount }
          offset={ data.offset }
          rowLimit={ initialRowLimit }         
          columns={columns}
          button={ [{
            // add: `${intl.formatMessage({id: 'button.addItem'})}`,  // null | string  
            add: null,  // null | string  
            detail: true, // boolean
            edit: true, // boolean
            delete: true, // boolean
            block: true, // boolean
          }] }
          icon= { <PeopleIcon /> }
          onOffset={ handleOffset }
          onLimit={ handleLimit }
          // onAddItem={ addUser }
          onDetailItem={ handleDetailItem } 
          onEditBtn={ handleEditBtn }
          onBlockItem={ handleBlockItem }
          onRemoveBtn={ handleRemoveBtn }
        />

        { isDeleteUser && (
          <RemoveItem 
            onCloseWindow={ handleCloseWindow }
            onRemoveItem={ handleRemoveItem }
            title={ 'user' }
          />
        )}
       
        { ( isBlockUser || isUnblockUser ) && (
          <BlockUser 
            isBlockUser={ isBlockUser }
            onCloseWindow={ handleCloseWindow }
            onBtnBlock={ handleBtnBlock }
          />
        )}

      </Grid>
    </Grid>
  );
};

export default injectIntl(UsersTable);
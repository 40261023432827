import React, { MouseEvent, ChangeEvent, useState } from 'react';

import { injectIntl, IntlShape } from 'react-intl';

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// alerts
import { useSnackbar } from "notistack";

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setReservationForm } from 'redux/actions/reservationsActions';
import { setRefresh } from 'redux/actions/pageActions';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "components/FormField";

// icon
import MenuBookIcon from '@mui/icons-material/MenuBook';

// enumns
import { reservationStatusEditEnum } from 'common/enums/DevicesStateEnum';
import { reservationStatusApprovedEditEnum } from 'common/enums/DevicesStateEnum';
import { reservationFineEditEnum } from 'common/enums/DevicesStateEnum';

// types
import { ReservationData, ReservationDataForm } from 'types/typesRedux';

type Props = {
  intl: IntlShape,
  onEditReservation?: (bool: boolean) => void,
  onCloseWindow?: (bool: boolean) => void,
}

const EditReservation = ( {intl, onEditReservation, onCloseWindow}: Props) => {
  
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const reservation = useSelector( (state: ReservationData) => state.reservationDetail.reservation );
  const reservationForm = useSelector( (state: ReservationDataForm) => state.reservationDetail.reservationForm );

  const [ reservationStatus, setReservationStatus ] = useState<string>('');

  // set data from inputs to redux
  const handleFine = ( e: ChangeEvent<{}>, newValue: string ) => {
    let issueFine = newValue ? newValue : null;
    let modifiedFine = issueFine === `${intl.formatMessage({id:'reservationEdit.addFine'})}` ? true : false;
    dispatch(setReservationForm( { ...reservationForm, 'issueFine': modifiedFine } ));    
  }
  
  
  const handleStatus = ( e: ChangeEvent<{}>, newValue: string ) => {
    let status = newValue ? newValue : reservation.status;
    let modifiedStatus = handleStatusAPI(status);
    dispatch(setReservationForm( { ...reservationForm, 'status': modifiedStatus } ));
    setReservationStatus(modifiedStatus)
  }

  // return value for API
  const handleStatusAPI = ( status: string ) => {
    if ( status === intl.formatMessage({id:'reservationEdit.pending'}) ) {
      return 'pending';
    } else if ( status === intl.formatMessage({id:'reservationEdit.rejected'}) ) {
      return 'rejected';
    }  else if ( status === intl.formatMessage({id:'reservationEdit.approved'}) ) {
      return 'approved';
    }  else if ( status === intl.formatMessage({id:'reservationEdit.cancelled'}) ) {
      return 'cancelled';
    }
  };

  // delete data from form and send 'false' parent to close window
  const closeWindow = () => {
    onCloseWindow( false );
    dispatch(setReservationForm( {
      status: '',
      issueFine: null,
    } 
  ));
  } 

  // If submit, edit reservation
  const handleSubmit = async (e: MouseEvent<HTMLButtonElement> ) => {
    e.preventDefault();
    if ( reservationStatus && reservationStatus !== 'cancelled' ) {
      editReservation( reservation.id );
      // send parent close modal
      onEditReservation( true );    
    } else if ( reservationStatus === 'cancelled' ) {
      if( reservationForm.issueFine === null) {
        enqueueSnackbar(`${intl.formatMessage({id:'reservationEditAlert.emptyFine'})}`, { variant: 'error' });
      } else {
        editReservation( reservation.id );
        // send parent close modal
        onEditReservation( true );
      }
    } else {
      enqueueSnackbar(`${intl.formatMessage({id:'reservationEditAlert.empty'})}`, { variant: 'error' });
    }
  };

  // Handle edit reservation /////////////////////////////
  const editReservation = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source()

    // Create payload with status as a string
    let payload: { status: string, issueFine?: boolean } = {
      status: reservationForm.status,
    }

    if (reservationForm.issueFine) {
        payload.issueFine = reservationForm.issueFine;
      }

    try {
      await apiClient.patch(`/v1/reservations/${id}`, payload, {
        cancelToken:cancelToken.token
        }
      ).then( ( response ) => {
        if ( response ) {
          enqueueSnackbar(`${intl.formatMessage({id:'reservationEditAlert.success'})}`, { variant: 'success' });
          dispatch(setRefresh( { refresh: true } ));
          dispatch(setReservationForm( {
            status: '',
            issueFine: null,
          } 
        ));
        }
    });
    } catch ( err ){
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        enqueueSnackbar(`${intl.formatMessage({id:'reservationEditAlert.error'})}`, { variant: 'error' });
      }
    };
    return () => cancelToken.cancel();
  }

  return (
    <Grid container sx={{ position: 'fixed', top: '0%', left: '0%',
      backgroundColor:"rgba( 0, 0, 0, 0.5 )", 
      width:"100vw", height:"100vh", zIndex:"1200" }}>

          <Card
              sx={ { 
                minWidth: 1/5,
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              } }
          >        

            <MDBox        
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{ listStyle: "none" }}
            >    

              <MDBox sx= {{ display: "flex",
                      flexDirection: "row", gap: "1rem" }} >
                <MDBox
                    sx={{ 
                    p: 1, mt: -4, ml: 0,
                    borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl, }}
                    display="grid"
                    justifyContent="center"
                    alignItems="center"
                    bgColor="success"
                    color="white"
                    width="4.5rem"
                    height="4.5rem"
                    shadow="md"
                    borderRadius="lg"
                    variant="gradient"  
                    fontSize= "2.2rem"        
                    >
                    <Icon sx={{marginBottom:"0.8rem"}} ><MenuBookIcon /></Icon>
                </MDBox>

                {/* Header */}
                <MDTypography variant="h6">
                  {intl.formatMessage({id:'reservationEdit.title'})}
                </MDTypography>
                                
              </MDBox>
              {/* Id */}
              <MDBox sx={{ marginBottom: "0.5rem", marginTop: "0.9rem", }}>
                <MDBox 
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        // justifyContent: "space-between",
                        gap:"4rem"
                    }}
                >
                  <MDTypography variant="button" >
                  {intl.formatMessage({id:'reservationEdit.id'})}:
                  </MDTypography>
                  <MDTypography variant="button" 
                    sx={ { } }
                    fontWeight="regular" 
                    color="text">
                      { reservation.id ? reservation.id : '-'}
                  </MDTypography>
                </MDBox>
              </MDBox>

              {/* Status */}
              <Grid item xs={12} md={12} 
                sx={ {                         
                  mt: "0.6rem"
                } }>
                <MDTypography 
                  variant="button" >
                  {intl.formatMessage({id:'reservationDetail.status'})}
                </MDTypography> 
                <Autocomplete             
                  options={reservation.status === intl.formatMessage({id:'tableReservations.approved'}) ? 
                  reservationStatusApprovedEditEnum(intl) : reservationStatusEditEnum(intl) }
                  onChange={ handleStatus }
                  sx={{ marginTop: "-0.5rem"}}
                  disableClearable
                  renderInput={(params) => (
                    <FormField {...params} 
                      InputLabelProps={{ shrink: true }}
                      // inputRef={isActiveInput} 
                      placeholder={ reservation.status ? reservation.status : ''}
                    /> )}
                />
              </Grid>

              {/* Fine */}
              { reservationStatus === 'cancelled' && (
                
              <Grid item xs={12} md={12} 
                sx={ {                         
                  mt: "0.6rem"
                } }>
                <MDTypography 
                  variant="button" >
                  {intl.formatMessage({id:'reservationEdit.fine'})}
                </MDTypography>  
                <Autocomplete
                  options={reservationFineEditEnum(intl)}
                  onChange={ handleFine }
                  sx={{ marginTop: "-0.5rem"  }}
                  disableClearable
                  renderInput={(params) => (
                    <FormField {...params} 
                      InputLabelProps={{ shrink: true }}
                      // inputRef={isActiveInput} 
                      // placeholder={ reservation.isActive ? reservation.isActive : ''}
                      placeholder= 'Áno / Nie'
                    /> 
                  )}
                />
              </Grid>
              )}
   

              <MDBox 
                sx={{
                    display: "flex", flexDirection: "row", justifyContent: "flex-end", 
                    gap: "0.5rem", maxWidth: "100%", marginBottom: "0.5rem", marginTop: "3rem",
                }}
              >
                <MDButton 
                    variant="outlined" 
                    color="success" 
                    // onClick={() => navigate(-1)}
                    onClick={ closeWindow }
                  >
                    {intl.formatMessage({id:'button.cancel'})}
                </MDButton>
                <MDButton 
                    variant="contained" 
                    color="success" 
                    // sx={{
                    //   visibility: isVisible ? "visible" : "hidden", 
                    // }}
                    onClick={ handleSubmit }                  
                  >
                    {intl.formatMessage({id:'button.save'})}
                </MDButton>
              </MDBox>
      
            </MDBox>
          </Card>

        {/* </Grid> */}
    </Grid>
  )
};

export default injectIntl(EditReservation);
import { ActionTypes } from 'redux/actions/actionType';

// types
import { 
    InventoryState,
    SetInventoryAction,
    ItemState,
    SetItemAction,
    ItemsState,
    SetItemsAction,
    ItemsInOrderState,
    SetItemsInOrderAction,
} from 'types/typesRedux';

const inventoryState: InventoryState = {
    inventory: [],
    totalCount: 0,
    offset: 0,
    limit: 10,
    sort: 'id:desc',
};

export const inventoryReducer = (state= inventoryState, action: SetInventoryAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_INVENTORY:
            return { 
                ...state,
                inventory: action.payload.inventory,
                totalCount: action.payload.totalCount};
        case ActionTypes.SET_INVENTORY_OFFSET:
            return { ...state, offset: action.payload.offset };
        case ActionTypes.SET_INVENTORY_LIMIT:
            return { ...state, limit: action.payload.limit };
        case ActionTypes.RESET_OFFSET:
            return { ...state, offset: 0 };
        default:
            return state;
    }
};

// Item in edit window
const itemState: ItemState = {
    item: [],

    itemForm: {
        isActive: null,
        notes: '',
        variant: '',
    }
};

export const itemReducer = (state= itemState, action: SetItemAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_ITEM:
            return { ...state, item: action.payload,};
        case ActionTypes.SET_ITEM_FORM:
            return { ...state, itemForm: action.payload };
        default:
            return state;
    }
};

// Items in container
const itemsState: ItemsState = {
    itemsInContainer: [],
    totalCount: 0,
    offset: 0,
    limit: 10,
    sort: 'id:desc',
};

export const itemsReducer = (state= itemsState, action: SetItemsAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_ITEMS:
            return { 
                ...state,
                itemsInContainer: action.payload.itemsInContainer ,
                totalCount: action.payload.totalCount};
        case ActionTypes.SET_ITEMS_OFFSET:
            return { ...state, offset: action.payload.offset };
        case ActionTypes.SET_ITEMS_LIMIT:
            return { ...state, limit: action.payload.limit };
        case ActionTypes.DELETE_ITEM:
            return { ...state, itemsInContainer: [] };
        default:
            return state;
    }
};

// Items in order
const itemsOrderState: ItemsInOrderState = {
    itemsInOrder: [],
    totalCount: 0,
    offset: 0,
    limit: 10,
    sort: 'id:desc',
};

export const itemsOrderReducer = (state= itemsOrderState, action: SetItemsInOrderAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_ITEMS_ORDER:
            return { 
                ...state,
                itemsInOrder: action.payload.itemsInOrder ,
                totalCount: action.payload.totalCount};
        case ActionTypes.SET_ITEMS_ORDER_OFFSET:
            return { ...state, offset: action.payload.offset };
        case ActionTypes.SET_ITEMS_ORDER_LIMIT:
            return { ...state, limit: action.payload.limit };
        default:
            return state;
    }
};
import React, { useState, useEffect } from 'react'

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentsByUser, setPaymentsUserOffset, setPaymentsUserLimit } from 'redux/actions/paymentsActions';
import { setRefresh } from 'redux/actions/pageActions';

// language
import { injectIntl, IntlShape } from 'react-intl';

// components
import LoadingComponent from 'components/LoadingComponent';
import QueryTableDevices from 'components-lib/QueryTable/QueryTableDevices';

import { Grid } from '@mui/material';

// extracted data
import { extractDataPayments } from 'common/extractData';

// types
import { PaymentsTableType } from 'types/types';
import { PaymentsByUserData, Refresh, FilterState } from 'types/typesRedux';

type Props = {
  intl: IntlShape;
  userId: number;
  isErrorPayments: boolean;
  onErrorLayout: ( tableName: string, bool: boolean ) => void;
}

const PaymentsList = ( {intl, userId, isErrorPayments, onErrorLayout}: Props ) => {

  const dispatch = useDispatch();

  // redux
  const data = useSelector( (state: PaymentsByUserData) => state.allPaymentsByUser )
  const filters = useSelector( (state: FilterState) => state.filters);
  const refresh = useSelector( (state: Refresh) => state.handleRefresh);

  // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
  const storedRowLimit = parseInt(localStorage.getItem('rowLimitPaymentsUser'));
  const initialRowLimit = storedRowLimit && !isNaN(storedRowLimit) ? storedRowLimit : data.limit;

  const [localLoading, setLocalLoading] = useState<boolean>(true);
  const tableName = intl.formatMessage( {id: 'tablePayments.user.title'} );

  const getPaymentsByUser = async ( params = {} ) => {

    const cancelToken = axios.CancelToken.source()

      const defaultParams = {
        offset: data ? data.offset : 0,
        limit: initialRowLimit,
        sort: ( filters[`${tableName} sort`] &&
          filters[`${tableName} sort`].sort !== undefined ) 
          ? filters[`${tableName} sort`].sort 
          : data.sort,
      };
  
      // Merge provided params with default params
      const mergedParams = { ...defaultParams, ...params };
  
      try{
        const response = await apiClient.get(`/v1/users/${userId}/payments`,{
          params: mergedParams,
          cancelToken:cancelToken.token
        });
  
        if (response) {
          const responseData = response.data.items.map( 
            ( payments: unknown ) => extractDataPayments( intl )( payments as PaymentsTableType ));
          const totalCount = response.data.totalCount;
          dispatch(setPaymentsByUser( responseData, totalCount ));
          if ( refresh.refresh === true ) dispatch(setRefresh( { refresh: false } ))
          if ( isErrorPayments === true )  onErrorLayout( 'paymnents', false )
        } else {
          console.log("No response received.");
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("canceled");
        } else {
          // todo:handle error
        }
        onErrorLayout( 'paymnents', true )
      }
      setLocalLoading( false );
      return () => cancelToken.cancel()
    };

  useEffect(() => {
    dispatch(setRefresh( { refresh: false } ))
    getPaymentsByUser();
  },[] ) 

  useEffect(() => {
    if ( refresh.refresh === true ) {
      dispatch(setRefresh( { refresh: false } ));
      getPaymentsByUser( filters[tableName] );
    }
  }, [ refresh.refresh ])

  // Handle offset
  const handleOffset = ( tableOffset: number ) => {
    dispatch(setPaymentsUserOffset( tableOffset ));
  }
  
  // Handle limit
  const handleLimit = ( tableLimit: number ) => {
    dispatch(setPaymentsUserLimit( tableLimit ));
    localStorage.setItem('rowLimitPaymentsUser', tableLimit.toString());
  }
    

  return (

    <Grid container>
      { localLoading ? (
      <LoadingComponent /> ) : (

        <Grid
         item xs={12} sm={12} md={12} lg={12}
        >

          { data.totalCount > 0 && 
            <QueryTableDevices
              title= {tableName}
              tableData={ data.payments }
              pagePagination={true}
              totalCount={ data.totalCount }
              offset={ data.offset }
              rowLimit={ initialRowLimit }   
              columns= {[
                { Header: intl.formatMessage( {id: 'tablePayments.id'} ), 
                accessor: "id", 
                width: "auto",
                minWidth: "5%",
                input: 'title',
                },
                { Header: intl.formatMessage( {id: 'tablePayments.status'} ), 
                accessor: "status", 
                width: "auto",
                minWidth: "10%",
                input: 'title',
                },
                { Header: intl.formatMessage( {id: 'tablePayments.amount'} ), 
                accessor: "amount", 
                width: "auto",
                minWidth: "5%",
                input: 'title',
                },
                // { Header: intl.formatMessage({id: 'table.actions'}), 
                // accessor: "Action", 
                // width: "auto",
                // minWidth: "10%",
                // input: 'title',
                // clearIcon: true,
                // },
              ]}
              button={ [{
                add: null ,
                detail: false,
                edit: false,
                delete: false,
                block: false,
              }] }
              icon= { false }
              onOffset={ handleOffset }
              onLimit={ handleLimit }
              onAddItem={ null }
              onEditBtn={ null }
              onBlockItem={ null }
              onRemoveBtn={ null }
              marginLeft={ false }
            />
          }

        </Grid>
      )}
      
    </Grid>
  );
};

export default injectIntl(PaymentsList);
import messages from 'locales/messages/sk';

interface LanguageConfig {
    locale: string;
    messages: Record<string, string>;
  }
  
const skConfig: LanguageConfig = {
    locale: 'sk',
    messages
  };
  
export default skConfig;
import { IntlShape } from 'react-intl';

import { formatDateTime } from 'utils'
import { handleContainerStatus } from 'utils'

// types
import { 
    Users, 
    User,
    UserLoggedType,
    InventoryTableType, 
    ItemEditQueryType, 
    ContainersTableType,
    ContainerType,
    ItemsInContainerType,
    OrdersTableType,
    OrderDetailType,
    PaymentsTableType,
    ReservationsTableType,
    ReservationDetailType,
    ItemsInOrderType,
    FinesTableType,
    PricesEquipmentExtractType,
} from 'types/types';

// users ////////////////////////////////////////////////////////

// users table
export const extractDataUsers = (intl: IntlShape) => ( user: Users ) => {
    let countryName = user.country ? user.country.name : '';
    let countryNative = user.country ? user.country.nativeName : '';

    return {
        id: user?.id ?? null,
        email: user?.email ?? '',
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        phone: user?.phone ?? '',
        countryName: countryName,
        countryNative: countryNative,
        blockedAt:
        user.blockedAt === null
            ? `${intl.formatMessage({ id: 'tableUsers.blockedAt.null' })}`
            : `${intl.formatMessage({ id: 'tableUsers.blockedAt.block' })}`,
    };
};

// user logged in
export const extractDataUserLoggedIn = (intl: IntlShape) => ( user: UserLoggedType ) => {
    const { pin, email, firstName, lastName, phone, country } = user;

    return {
        pin: pin ?? '',
        email: email ?? '',
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        phone: phone ?? '',
        country: country ?? '',
    }
}

// user profile
export const extractDataUser = (intl: IntlShape) => ( user: User ) => {
    const { id, email, firstName, lastName, phone, address, city,
        postal, country, registeredAt, role, isFreeOfCharge, blockedAt, } = user;  

    return {
        id,
        email,
        firstName,
        lastName,
        phone,
        address,
        city,
        postal,
        country,
        registeredAt,
        role,
        isFreeOfCharge:
            isFreeOfCharge === false
                ? `${intl.formatMessage({ id: 'userProfile.freeOfCharge.false' })}`
                : `${intl.formatMessage({ id: 'userProfile.freeOfCharge.true' })}`,
        blockedAt:
            blockedAt === null
            ? `${intl.formatMessage({ id: 'tableUsers.blockedAt.null' })}`
            : `${intl.formatMessage({ id: 'tableUsers.blockedAt.block' })}`,
    };
  };


// payments ////////////////////////////////////////////////////////

// payments table
export const extractDataPayments = (intl: IntlShape) => ( payments: PaymentsTableType ) => {
    const { id, type, user, status, lastName, amount, finishedAt, createdAt } = payments;

    return {
        id: id ?? null,
        type: paymentType( type, intl),
        user: user ?? '',
        status: statusPayment( status, intl ),
        lastName: lastName ?? '',
        amount: amount ?? '',
        createdAt: formatDateTime( createdAt ),
        finishedAt: formatDateTime( finishedAt ),
        // checkoutUrl: payments.checkoutUrl,
    };
};


// orders ////////////////////////////////////////////////////////

// orders table
export const extractDataOrders = (intl: IntlShape) => ( orders: OrdersTableType ) => {
    const { id, user, createdAt, completedAt, status, price, paymentStatus, startPlace, finishPlace, } = orders;

    const formattedCreatedAt = formatDateTime( createdAt );
    const formattedcompletedAt = completedAt ? formatDateTime( completedAt ) : '';
    return {
        id: id ?? null,
        user: user ?? '',
        createdAt: formattedCreatedAt,
        completedAt: formattedcompletedAt,
        status: statusOrder( status, intl ),
        price: price ?? '',
        paymentStatus: statusPayment( paymentStatus, intl ),
        startPlace: startPlace ?? '',
        finishPlace: finishPlace ?? '',
    };
};

// orders table in user profile
export const extractDataOrdersByUser = (intl: IntlShape) => ( orders: OrdersTableType ) => {
    const { id, user, createdAt, completedAt, status, price, paymentStatus, startPlace, finishPlace, } = orders;

    const formattedCreatedAt = formatDateTime( createdAt );
    const formattedcompletedAt = completedAt ? formatDateTime( completedAt ) : '';
    return {
        id,
        // user,
        createdAt: formattedCreatedAt,
        completedAt: formattedcompletedAt,
        status: statusOrder( status, intl ),
        price,
        paymentStatus,
        startPlace,
        finishPlace,
    };
};

// order detail
export const extractDataOrder = (intl: IntlShape) => ( editOrder: OrderDetailType ) => {
    const { 
        id, user, status, price, isFreeOfCharge, 
        startPlace, finishPlace, paymentStatus, 
        createdAt, completedAt,
    } = editOrder;

    const formattedCreatedAt = formatDateTime( createdAt );
    const formattedcompletedAt = completedAt ? formatDateTime( completedAt ) : '-';
    
    return {
      id,
      user,
      status: statusOrder( status, intl ),
      price,
      startPlace,
      finishPlace,
      paymentStatus,
      createdAt: formattedCreatedAt,
      completedAt: formattedcompletedAt,
      isFreeOfCharge:
      isFreeOfCharge === false
          ? `${intl.formatMessage({ id: 'userProfile.freeOfCharge.false' })}`
          : `${intl.formatMessage({ id: 'userProfile.freeOfCharge.true' })}`,
    };
};

// items in order detail
export const extractDataItemsOrder = (intl: IntlShape) => ( editOrder: ItemsInOrderType ) => {
    const { id, name, photoUrl, price, rentPlace, returnPlace, status, } = editOrder;
    
    let categoryName = name ? nameInventory(name, intl) : '';
    let statusTranslate = ( status === 'rented' )
    ? `${intl.formatMessage({ id: 'equipmentsOrder.rented' })}`
    : `${intl.formatMessage({ id: 'equipmentsOrder.returned' })}`;  

    return {
      id,
      name: categoryName,
      photoUrl,
      price,
      rentPlace,
      returnPlace,
      status: statusTranslate,   
    };
};

// reservations ////////////////////////////////////////////////////////

// reservations table
export const extractDataReservations = (intl: IntlShape) => ( reservations: ReservationsTableType ) => {
    const { id, user, status, boatsCount, startAt, place, fine, } = reservations;

    const formattedstartAt = formatDateTime( startAt );

    return {
        id,
        user,
        startAt: formattedstartAt,
        status: statusReservations( status, intl ),
        boatsCount,
        place,
        fine,
    };
};

// reservation detail
export const extractDataReservation = (intl: IntlShape) => ( reservations: ReservationDetailType ) => {
    const { id, status, boatsCount, startAt, createdAt, cancelledAt, place, user, fine } = reservations;

    return {
        id,
        status: statusReservations( status, intl ),
        boatsCount,
        startAt: formatDateTime( startAt ),
        createdAt: formatDateTime( createdAt ), 
        cancelledAt: cancelledAt ? formatDateTime( cancelledAt ) : '-', 

        place: place?.name,  
        
        userFirstName: user?.firstName ?? '',
        userLastName: user?.lastName ?? '',
        userPhone: user?.phone ?? '',
        userEmail: user?.email ?? '',

        fineStatus: fine ? statusFines(fine.status, intl) : '',
        fineAmount: fine?.amount ?? 0,
        fineDescription: fine?.description ?? '',
        fineCreatedAt: fine ? formatDateTime(fine.createdAt) : '',
    };
};


// fines ////////////////////////////////////////////////////////

// fines table
export const extractDataFines = (intl: IntlShape) => ( fines: FinesTableType ) => {
    const { id, status, amount, description } = fines;
    
    return {
      id,
      status: statusFines( status, intl ),
      amount,
      description,      
    };
};

// items ////////////////////////////////////////////////////////

// items table
export const extractDataInventory = (intl: IntlShape) => ( inventory: InventoryTableType ) => {
    const { id, notes, variant, isActive, category, container } = inventory;

    let categoryId = category?.id || '';
    let containerId = container?.id || '';
    let containerName = container?.name || '';
    let categoryName = category ? nameInventory(category.name, intl) : '';
    let isActiveInventory = ( isActive === true )
      ? `${intl.formatMessage({ id: 'tableInventory.statusTrue' })}`
      : `${intl.formatMessage({ id: 'tableInventory.statusFalse' })}`;  
    
    return {
      id,
      variant,
      notes,
      isActive: isActiveInventory,
      categoryId,
      name: categoryName,
      containerId,
      containerName,
      batteryStatus: `${intl.formatMessage({ id: 'tableInventory.batteryStatusFull' })}`,
    };
};

// edit item
export const extractDataItem = (intl: IntlShape) => ( editItem: ItemEditQueryType ) => {
    const { id, notes, variant, isActive, category, container } = editItem;

    let categoryVariant = category ? category.variants : [];
    let containerName = container ? container.name : '';
    let categoryName = category ? nameInventory(category.name, intl) : '';
    let categoryId = category ? category.id : '';
    let isActiveInventory = ( isActive === true )
      ? `${intl.formatMessage({ id: 'tableInventory.statusTrue' })}`
      : `${intl.formatMessage({ id: 'tableInventory.statusFalse' })}`;  
    
    return {
      id,
      name: categoryName,
      variant,
      isActive: isActiveInventory,
      notes,
      categoryId: categoryId,
      container: containerName,
      categoryVariant,
    };
};

// containers ////////////////////////////////////////////////////////

// containers table
export const extractDataContainers = (intl: IntlShape) => ( containers: ContainersTableType ) => {
    const { id, name, isEnabled, capacity, equipmentCount, boatsCount, doorStatusA, doorStatusB, updatedAt } = containers;

    let isEnabledContainer = ( isEnabled === true )
    ? `${intl.formatMessage({ id: 'tableContainers.isEnabledTrue' })}`
    : `${intl.formatMessage({ id: 'tableContainers.isEnabledFalse' })}`;

    let doorA = doorStatus(doorStatusA, intl);
    let doorB = doorStatus(doorStatusB, intl);

    let containerStatusUpdatedAt = containerStatus(handleContainerStatus( updatedAt ), intl);


    return {
      id,
      name,
      capacity,
      equipmentCount,
      boatsCount,
      isEnabled: isEnabledContainer,
      doorStatusA: doorA,
      doorStatusB: doorB,
      status: containerStatusUpdatedAt,
      updatedAt,
    };
};

// container detail
export const extractDataContainer = (intl: IntlShape) => ( container: ContainerType ) => {
    const { id, name, isEnabled, capacity, boatsCount, equipmentCount, doorStatusA, doorStatusB, updatedAt } = container;

    let enabled = isEnabled === true 
        ? `${intl.formatMessage({ id: 'tableContainers.isEnabledTrue' })}`
        : `${intl.formatMessage({ id: 'tableContainers.isEnabledFalse' })}`;

    let doorA = doorStatus(doorStatusA, intl);
    let doorB = doorStatus(doorStatusB, intl);

    let containerStatusUpdatedAt = containerStatus(handleContainerStatus( updatedAt ), intl);

    return {
        id,
        isEnabled: enabled,
        name,
        capacity,
        boatsCount,
        equipmentCount,
        doorStatusA: doorA,
        doorStatusB: doorB,
        status: containerStatusUpdatedAt,
        updatedAt,
    };
};

// container detail - items table
export const extractDataItems = (intl: IntlShape) => ( item: ItemsInContainerType ) => {
    const { id, uid, variant, notes, isActive, createdAt, updatedAt, category, } = item;

    // let name = category ? category.name : null;
    let categoryId = category ? category.id : null;
    let categoryName = category ? nameInventory(category.name, intl) : ''
    

    return{ 
        id, 
        uid, 
        variant,
        notes, 
        isActive, 
        createdAt, 
        updatedAt, 
        name: categoryName,
        categoryId,
    }
}

// prices ////////////////////////////////////////////////////////

// prices table
export const extractPricesEquipment = (intl: IntlShape) => ( item: PricesEquipmentExtractType ) => {
    const { category, firstHourFee, nextHourFee, lostFine, damageFine } = item;

    let categoryId = category ? category.id : null;
    let categoryName = category ? category.name : null;

    return{ 
        categoryId, 
        categoryName, 
        firstHourFee,
        nextHourFee, 
        lostFine, 
        damageFine,
    }
}


// translations ////////////////////////////////////////////////////////

// equipments
const nameInventory = ( name: string, intl: IntlShape ) => {
    if ( name === 'kayak' ) {
        return `${intl.formatMessage({ id: 'tableInventory.kayak' })}`;
    } else if ( name === 'vest' ) {
        return `${intl.formatMessage({ id: 'tableInventory.vest' })}`;
    } else if ( name === 'helmet' ) {
        return `${intl.formatMessage({ id: 'tableInventory.helmet' })}`;
    } else if ( name === 'bag' ) {
        return `${intl.formatMessage({ id: 'tableInventory.bag' })}`;
    } else if ( name === 'paddle' ) {
        return `${intl.formatMessage({ id: 'tableInventory.paddle' })}`;
    }
};  

// status of payments
const statusPayment = ( data: string, intl: IntlShape ) => {
    if ( data === 'pending' ) {
        return `${intl.formatMessage({ id: 'tablePayments.status.pending' })}`
    } else if ( data === 'processing' ) {
        return `${intl.formatMessage({ id: 'tablePayments.status.processing' })}`
    } else if ( data === 'successful' ) {
        return `${intl.formatMessage({ id: 'tablePayments.status.successful' })}`
    } else if ( data === 'expired' ) {
        return `${intl.formatMessage({ id: 'tablePayments.status.expired' })}`
    } else if ( data === 'failed' ) {
        return `${intl.formatMessage({ id: 'tablePayments.status.failed' })}`
    }
};  

// status of fines
const statusFines = ( status: string, intl: IntlShape ) => {
    if ( status === 'paid' ) {
        return `${intl.formatMessage({ id: 'fines.detail.paid' })}`
    } else if ( status === 'awaiting_payment' ) {
        return `${intl.formatMessage({ id: 'fines.detail.awaiting_payment' })}`
    }
}

// status of orders
const statusOrder = ( status: string, intl: IntlShape ) => {
    if ( status === 'in_progress' ) {
        return `${intl.formatMessage({ id: 'order.detail.in_progress' })}`
    } else if ( status === 'awaiting_payment' ) {
        return `${intl.formatMessage({ id: 'order.detail.awaiting_payment' })}`
    } else if ( status === 'completed' ) {
        return `${intl.formatMessage({ id: 'order.detail.completed' })}`
    } else if ( status === 'cancelled' ) {
        return `${intl.formatMessage({ id: 'order.detail.cancelled' })}`
    }
}; 

// status of reservations
const statusReservations = ( status: string, intl: IntlShape ) => {
    if ( status === 'pending' ) {
        return `${intl.formatMessage({ id: 'tableReservations.pending' })}`
    } else if ( status === 'approved' ) {
        return `${intl.formatMessage({ id: 'tableReservations.approved' })}`
    } else if ( status === 'rejected' ) {
        return `${intl.formatMessage({ id: 'tableReservations.rejected' })}`
    } else if ( status === 'cancelled' ) {
        return `${intl.formatMessage({ id: 'tableReservations.cancelled' })}`
    }
};

// payment type
const paymentType = ( type: string, intl: IntlShape ) => {
    if ( type === 'standard' ) {
        return `${intl.formatMessage({ id: 'tablePayments.type.standard' })}`
    } else if ( type === 'verification' ) {
        return `${intl.formatMessage({ id: 'tablePayments.type.verification' })}`
    } else if ( type === 'new_card' ) {
        return `${intl.formatMessage({ id: 'tablePayments.type.new_card' })}`
    } else if ( type === 'fine' ) {
        return `${intl.formatMessage({ id: 'tablePayments.type.fine' })}`
    }
};

// door status
const doorStatus = ( doorStatus: string, intl: IntlShape ) => {
    if ( doorStatus === 'closed' ) {
        return `${intl.formatMessage({ id: 'tableContainers.door.close' })}`
    } else if ( doorStatus === 'opened' ) {
        return `${intl.formatMessage({ id: 'tableContainers.door.open' })}`;
    }
};

// container status
const containerStatus = ( dateOnline: boolean, intl: IntlShape ) => {
    if ( dateOnline === true ) {
        return `${intl.formatMessage({ id: 'tableContainers.status.online' })}`
    } else if ( dateOnline === false ) {
        return `${intl.formatMessage({ id: 'tableContainers.status.offline' })}`;
    }
};

// import { DateRangePicker } from "materialui-daterange-picker";
import { DateRangePicker } from "components/DateRangePicker";

// MUI
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';

// components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// language
import { injectIntl, IntlShape } from 'react-intl';

// types
import { DateRange } from 'types/tableTypes';

type Props = {
  intl: IntlShape;
  column?: string;
  isTimeLine?: boolean;
  isMaxDate?: boolean;

  onDateRange?: ( date: DateRange, column: string ) => void;
  onSubmitDate?: ( bool: boolean, column: string ) => void;
  onCloseWindow: ( bool: boolean ) => void;
};

const Timeline: React.FunctionComponent<Props> = ({ 
  intl,
  column, 
  isTimeLine,
  isMaxDate,

  onDateRange,
  onSubmitDate,
  onCloseWindow,
  }: Props) => {

  const toggle = () => onCloseWindow(false);

  const handleDateSubmit = () => {    
    onSubmitDate( true, column )    
  };

  const handleInputDateRange = ( dateRange: DateRange ) => {
    onDateRange( dateRange, column )
  };

  return (
         
    <Grid container sx={{ position: 'fixed', top: '0%', left: '0%',
      backgroundColor:"rgba( 0, 0, 0, 0.5 ) !important", 
      width:"100vw", height:"100vh", zIndex:"1300" }}>

    <Grid
      className="containerPicker"
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: 'translate(-50%, -50%)',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#fff",
        width: "auto",
        height: "34.5rem",
        padding: "0 2rem",
        borderRadius: "0.75rem",
        zIndex: "5",
        boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      }}
    >
      <MDBox
      sx={{
        display: "flex", flexDirection: "row", width: "100%"
      }}
      >
        <MDTypography
          sx={{
            display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "80%",
            marginTop: "2rem", marginBottom: "1rem", paddingLeft: "1.5rem", 
          }}
        >
          {intl.formatMessage( {id: 'datePicker'} ) }
        </MDTypography>

        <MDBox
          sx={{
            display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "20%",
            marginTop: "1.5rem", marginBottom: "1rem", paddingRight: "1.5rem", cursor: "pointer"
          }}
        >
          <ClearIcon />
        </MDBox>
      </MDBox>      
        
      <DateRangePicker
        open={isTimeLine}
        toggle={toggle}
        maxDate={ !isMaxDate ? null : new Date()}
        onChange={(newDate: DateRange) => handleInputDateRange(newDate)}  
      />

        <MDButton 
          variant="gradient" 
          color="success" 
          size="small"
          onClick={handleDateSubmit}                
          sx={{ 
            marginTop: "-0rem", marginBottom: "2rem", cursor: "pointer",
            minHeight: "2.5rem", minWidth: "64px", 
          }}
        >
          { intl.formatMessage( {id: 'button.dateRange'} ) }
        </MDButton>

    </Grid>
    </Grid>
  );
};

export default injectIntl(Timeline);
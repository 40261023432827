

// language
import { injectIntl, IntlShape } from 'react-intl';

// redux
import { useSelector } from 'react-redux';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// types
import { ReservationData, } from 'types/typesRedux';

interface Props {
  intl: IntlShape,
  onSubmitEdit?: (bool: boolean) => void,
}

function FineInfo( { intl, onSubmitEdit } :Props ): JSX.Element {

  const reservation = useSelector( (state: ReservationData) => state.reservationDetail.reservation )

     
  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox        
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >    

        <MDBox p={1} pb={0}
          sx= {{ flex: "1 100%", }} > 

          {/* Header */}
          <MDBox 
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <MDTypography variant="h5">
              {intl.formatMessage({id:'reservationDetailFine.title'})}
            </MDTypography>
          </MDBox>
        </MDBox>


        <Grid container pb={3} px={1} pt={0} mt={0}
          sx={{ width:"100%", }} >

          {/* fine status + fine amount */}
          <Grid 
            container
            xs={12} sm={12} p={0} mt={0}
            sx={ { 
              display: "flex",
              flexDirection: "row",
              justifyContent:'space-between',
            } } 
            >   
            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
                width:'50%',
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "0.6rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                     
                } } 
                variant="button"                   
                >
                {intl.formatMessage({id:'reservationDetailFine.status'})}
              </MDTypography>              
              <MDTypography variant="button" 
                sx={ { marginTop: "0.2rem",} }
                fontWeight="regular" 
                color="text">
                  { reservation.fineStatus ?? '-'}
              </MDTypography>
            </MDBox>

            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
                width:'35%',
            } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "0.6rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                     
                } } 
                variant="button"                   
                >
                {intl.formatMessage({id:'reservationDetailFine.amount'})}
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                { reservation.fineAmount ? reservation.fineAmount + ' ' + intl.formatMessage({id:'currency'}) : '-'}
              </MDTypography>
            </MDBox>

          </Grid>   

          {/* description */}
          <Grid 
            container
            xs={12} sm={12} p={0} mt={0}
            sx={ { 
              display: "flex",
              flexDirection: "row",
              // alignContent:'space-between',
            } } 
            >   
            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
                width:'50%',
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "0.6rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                     
                } } 
                variant="button"                   
                >
                {intl.formatMessage({id:'reservationDetailFine.description'})}
              </MDTypography>              
              <MDTypography variant="button" 
                sx={ { marginTop: "0.2rem",} }
                fontWeight="regular" 
                color="text">
                  { reservation.fineDescription ?? '-'}
              </MDTypography>
           
              <MDTypography 
                sx={ {                         
                  mt: "1.2rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                     
                } } 
                variant="button"                   
                >
                {intl.formatMessage({id:'reservationDetailFine.createdAt'})}
              </MDTypography>              
              <MDTypography variant="button" 
                sx={ { marginTop: "0.2rem",} }
                fontWeight="regular" 
                color="text">
                  { reservation.fineCreatedAt ?? '-'}
              </MDTypography>
            </MDBox>

          </Grid>  

        </Grid>
        
      </MDBox>
    </Card>
  );
}

export default injectIntl(FineInfo);

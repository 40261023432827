import { IntlShape } from 'react-intl';

// users table
export const blockedAtEnum = (intl: IntlShape) => ( {
    true: { 
        label: intl.formatMessage({id:'tableUsers.blockedAt.null'}),
        filterKey: 'blockedAt[isnull]',
        filterValue: 'true',
    },
    false: { 
        label: intl.formatMessage({id:'tableUsers.blockedAt.block'}),
        filterKey: 'blockedAt[isnull]',
        filterValue: 'false',
    },
});

// Items table
export const itemsEnum = (intl: IntlShape) => ({
    kayak: { 
        label: intl.formatMessage({id:'tableInventory.kayak'}),
        filterKey: 'category[name][contains]',
        filterValue: 'Kayak',
    },
    paddle: { 
        label: intl.formatMessage({id:'tableInventory.paddle'}),
        filterKey: 'category[name][contains]',
        filterValue: 'paddle',
    },
    vest: { 
        label: intl.formatMessage({id:'tableInventory.vest'}),
        filterKey: 'category[name][contains]',
        filterValue: 'vest',
    },
    helmet: { 
        label: intl.formatMessage({id:'tableInventory.helmet'}),
        filterKey: 'category[name][contains]',
        filterValue: 'helmet',
    },
    bag: { 
        label: intl.formatMessage({id:'tableInventory.bag'}),
        filterKey: 'category[name][contains]',
        filterValue: 'bag',
    },
});

// Items order table
export const itemsOrderEnum = (intl: IntlShape) => ({
    kayak: { 
        label: intl.formatMessage({id:'tableInventory.kayak'}),
        filterKey: 'name',
        filterValue: 'kayak',
    },
    paddle: { 
        label: intl.formatMessage({id:'tableInventory.paddle'}),
        filterKey: 'name',
        filterValue: 'paddle',
    },
    vest: { 
        label: intl.formatMessage({id:'tableInventory.vest'}),
        filterKey: 'name',
        filterValue: 'vest',
    },
    helmet: { 
        label: intl.formatMessage({id:'tableInventory.helmet'}),
        filterKey: 'name',
        filterValue: 'helmet',
    },
    bag: { 
        label: intl.formatMessage({id:'tableInventory.bag'}),
        filterKey: 'name',
        filterValue: 'bag',
    },
});

export const isActiveEnum = (intl: IntlShape) => ({
    true: { 
        label: intl.formatMessage({id:'tableInventory.statusTrue'}),
        filterKey: 'isActive',
        filterValue: 'true',
    },
    false: { 
        label: intl.formatMessage({id:'tableInventory.statusFalse'}),
        filterKey: 'isActive',
        filterValue: 'false',
    },
});

export const isActiveItemEnum =(intl: IntlShape) => ([
    intl.formatMessage({id:'tableInventory.statusFalse'}),
    intl.formatMessage({id:'tableInventory.statusTrue'}),
])
   


// container status
export const containerStatusEnum = (intl: IntlShape) => ( [
    intl.formatMessage({id:'tableContainers.isEnabledTrue'}), 
    intl.formatMessage({id:'tableContainers.isEnabledFalse'})] );


// payments table /////////////////////////////////////
// status payments
export const paymentsStatusEnum = (intl: IntlShape) => ({
    pending: { 
        label: intl.formatMessage({id:'tablePayments.status.pending'}),
        filterKey: 'status',
        filterValue: 'pending',
    },
    processing: { 
        label: intl.formatMessage({id:'tablePayments.status.processing'}),
        filterKey: 'status',
        filterValue: 'processing',
    },
    successful: { 
        label: intl.formatMessage({id:'tablePayments.status.successful'}),
        filterKey: 'status',
        filterValue: 'successful',
    },
    expired: { 
        label: intl.formatMessage({id:'tablePayments.status.expired'}),
        filterKey: 'status',
        filterValue: 'expired',
    },
    failed: { 
        label: intl.formatMessage({id:'tablePayments.status.failed'}),
        filterKey: 'status',
        filterValue: 'failed',
    },
});


// type payments
export const paymentsTypeEnum = (intl: IntlShape) => ({
    standard: { 
        label: intl.formatMessage({id:'tablePayments.type.standard'}),
        filterKey: 'type',
        filterValue: 'standard',
    },
    verification: { 
        label: intl.formatMessage({id:'tablePayments.type.verification'}),
        filterKey: 'type',
        filterValue: 'verification',
    },
    new_card: { 
        label: intl.formatMessage({id:'tablePayments.type.new_card'}),
        filterKey: 'type',
        filterValue: 'new_card',
    },
    fine: { 
        label: intl.formatMessage({id:'tablePayments.type.fine'}),
        filterKey: 'type',
        filterValue: 'fine',
    },
});



// Orders table ///////////////////////////////////////////////////////////////
// order status
export const ordersStatusEnum = (intl: IntlShape) => ({
    in_progress: { 
        label: intl.formatMessage({id:'order.detail.in_progress'}),
        filterKey: 'status',
        filterValue: 'in_progress',
    },
    awaiting_payment: { 
        label: intl.formatMessage({id:'order.detail.awaiting_payment'}),
        filterKey: 'status',
        filterValue: 'awaiting_payment',
    },
    completed: { 
        label: intl.formatMessage({id:'order.detail.completed'}),
        filterKey: 'status',
        filterValue: 'completed',
    },
    cancelled: { 
        label: intl.formatMessage({id:'order.detail.cancelled'}),
        filterKey: 'status',
        filterValue: 'cancelled',
    },
});

// payments status
// payments table
export const paymentsStatusOrderEnum = (intl: IntlShape) => ({
    pending: { 
        label: intl.formatMessage({id:'tablePayments.status.pending'}),
        filterKey: 'paymentStatus',
        filterValue: 'pending',
    },
    processing: { 
        label: intl.formatMessage({id:'tablePayments.status.processing'}),
        filterKey: 'paymentStatus',
        filterValue: 'processing',
    },
    successful: { 
        label: intl.formatMessage({id:'tablePayments.status.successful'}),
        filterKey: 'paymentStatus',
        filterValue: 'successful',
    },
    expired: { 
        label: intl.formatMessage({id:'tablePayments.status.expired'}),
        filterKey: 'paymentStatus',
        filterValue: 'expired',
    },
    failed: { 
        label: intl.formatMessage({id:'tablePayments.status.failed'}),
        filterKey: 'paymentStatus',
        filterValue: 'failed',
    },
});


export const orderEditEnum = (intl: IntlShape) => ( [
    intl.formatMessage({id:'order.detail.cancel'}), 
    intl.formatMessage({id:'order.detail.close'})] 
);

export const orderAwaitingPaymentEnum = (intl: IntlShape) => ( [
    intl.formatMessage({id:'order.detail.cancel'})] 
);

export const enumItemIsActive = (intl: IntlShape) => ( isActive: string ) => {
    return isActive === intl.formatMessage({id:'tableInventory.statusTrue'}) ? true : false;
}

// Fines table
export const finesStatusEnum = (intl: IntlShape) => ({
    awaiting_payment: { 
        label: intl.formatMessage({id:'fines.detail.awaiting_payment'}),
        filterKey: 'category[name][contains]',
        filterValue: 'awaiting_payment',
    },
    paid: { 
        label: intl.formatMessage({id:'fines.detail.paid'}),
        filterKey: 'category[name][contains]',
        filterValue: 'paid',
    },
});

// Reservations table
export const reservationsStatusEnum = (intl: IntlShape) => ({
    pending: { 
        label: intl.formatMessage({id:'tableReservations.pending'}),
        filterKey: 'status',
        filterValue: 'pending',
    },
    rejected: { 
        label: intl.formatMessage({id:'tableReservations.rejected'}),
        filterKey: 'status',
        filterValue: 'rejected',
    },
    approved: { 
        label: intl.formatMessage({id:'tableReservations.approved'}),
        filterKey: 'status',
        filterValue: 'approved',
    },
    cancelled: { 
        label: intl.formatMessage({id:'tableReservations.cancelled'}),
        filterKey: 'status',
        filterValue: 'cancelled',
    },
});


// Reservations edit status
export const reservationStatusEditEnum = (intl: IntlShape) => ( [
    // intl.formatMessage({id:'reservationEdit.pending'}), 
    intl.formatMessage({id:'reservationEdit.rejected'}), 
    intl.formatMessage({id:'reservationEdit.approved'}),
    // intl.formatMessage({id:'reservationEdit.cancelled'})
] 
);

// Reservations edit status if approved
export const reservationStatusApprovedEditEnum = (intl: IntlShape) => ( [
    intl.formatMessage({id:'reservationEdit.cancelled'})] 
);

// Reservations edit
export const reservationFineEditEnum = (intl: IntlShape) => ( [
    intl.formatMessage({id:'reservationEdit.addFine'}), 
    intl.formatMessage({id:'reservationEdit.noAddFine'}),
]);


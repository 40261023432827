import React, { JSXElementConstructor, Key, ReactElement } from "react"
import { Routes, Route, Navigate } from "react-router-dom";

import BasicLayout from "components/PageLayout";

import getProjectRoutes from "routes";
import paths from 'paths';

// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import bgImage from "assets/images/sign-in.png";

import { injectIntl } from "react-intl";

interface Props {
    intl: any,
    // adress: string,
  }

const Auth = ( {intl} : Props) => {
  
    const routes = getProjectRoutes( {intl} );

    const getRoutes = (allRoutes: any): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
        adress: string;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if ( route.adress === '/authentication' ) {
          return <Route path={route.route} element={route.component} key={route.key} />;
        }

        return null;
      }
    );
    
    return (

      <BasicLayout image={bgImage}>
        <Routes>
            {getRoutes(routes)}
            <Route path="*" element={ <Navigate to={`/authentication${paths.auth.signIn}`} /> } />
        </Routes>
      </BasicLayout>
    );
}

export default injectIntl(Auth);


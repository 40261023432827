
import React from 'react'

import { injectIntl, IntlShape } from 'react-intl';

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

type Props = {
  intl: IntlShape,
  isFreeOfCharge?: boolean,
  onCloseWindow: (bool: boolean) => void,
  onBtnCharge: (bool: boolean) => void  
}

const FreeOfCharge = ( { intl, isFreeOfCharge, onCloseWindow, onBtnCharge }: Props) => {

  const closeWindow = () => {
    onCloseWindow( false );
  }

  const submitBlock = () => {
    onBtnCharge( true );
  }

  return (
    <Card 
        sx={ { 
          width: 1/3,
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex:'10'
          // backgroundColor: "#5ada55",
        } }
    >
          <MDBox 
            className='delete'
            sx={ {
              display: "flex",
              flexDirection: "column" ,
              justifyContent: "center" ,
              alignItems: "center" ,
              marginLeft: "0",
              // flexWrap: "wrap",
            } }
            >
            <MDBox p={3}>
              { isFreeOfCharge ? (
                <MDTypography variant="h5">{intl.formatMessage({id:'unblockCharges'})}</MDTypography>                
              ) : (
                <MDTypography variant="h5">{intl.formatMessage({id:'blockCharges'})}</MDTypography>
              ) }
            </MDBox>
          
            <MDBox 
              sx={ { 
                display:"flex",
                paddingBottom:"1rem", 
                gap:"0.8rem"
                
              } } 
            >
              <MDButton 
                variant="gradient" 
                color="light" 
                size="small"
                onClick={closeWindow}
              >
                {intl.formatMessage({id:'button.cancel'})}
              </MDButton>

              <MDButton 
                variant="gradient" 
                color="error" 
                size="small"
                onClick={submitBlock}
              >
                { isFreeOfCharge ? (
                  intl.formatMessage({id:'button.deactive'})
                ) : (
                  intl.formatMessage({id:'button.active'})
                )}
              </MDButton>
            </MDBox>
            
          </MDBox>
        </Card>  
  )
}

export default injectIntl(FreeOfCharge)
import React, { ReactNode } from 'react';

import ReactDOM, { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// redux
import { Provider } from "react-redux";
import store from 'redux/store'

import { IntlProvider } from 'react-intl';

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from "context";

import App from './App';

import skConfig from 'locales/config/sk';
import enConfig from 'locales/config/en';
import huConfig from 'locales/config/hu';

import { getSelectedLanguage } from "utils";

import WithSnackbar from "components-lib/WithSnackbar";

const root = createRoot(document.getElementById("root"));

const Context = React.createContext(null);

const IntlProviderWrapper = ({ children }: { children: ReactNode }) => {
    const selectedLanguageStored = getSelectedLanguage() === 'hu' ? huConfig :
                                   getSelectedLanguage() === 'en' ? enConfig : 
                                   skConfig;

    const [selectedLanguage, setSelectedLanguage] = React.useState(selectedLanguageStored);   

    const handleLanguageChange = ( locale: string ) => {
        if (locale === 'sk') {
            return setSelectedLanguage(skConfig);
        } else if (locale === 'en') {
            return setSelectedLanguage(enConfig);
        } else if (locale === 'hu') {
            return setSelectedLanguage(huConfig);
        } else {
            return setSelectedLanguage(skConfig);
        }
    }

    return (
        <Context.Provider
            value={{
                handleLanguageChange: handleLanguageChange,
                selectedLanguage: selectedLanguage.locale
            }}
        >
            <IntlProvider
                locale={selectedLanguage.locale}
                messages={selectedLanguage.messages}
                defaultLocale="sk"
            >
                {children}
            </IntlProvider>
        </Context.Provider>
    );
};
export { Context as IntlContext };

root.render(
    <IntlProviderWrapper>
        <WithSnackbar>
            <BrowserRouter>
                    <MaterialUIControllerProvider>
                        <Provider store={ store } >
                            <App />
                        </Provider>
                    </MaterialUIControllerProvider>
            </BrowserRouter>
        </WithSnackbar>
    </IntlProviderWrapper>
);

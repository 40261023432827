import React, { useState, useEffect, } from 'react'

import { apiClient } from "api-client"

import { useNavigate, useParams, } from "react-router-dom";

import axios from 'axios';


// redux
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'redux/actions/usersActions';
import { setRefresh } from 'redux/actions/pageActions';
import { setOrdersUserOffset } from 'redux/actions/ordersActions';
import { setPaymentsUserOffset } from 'redux/actions/paymentsActions';

// notification
import { useSnackbar } from "notistack";

// language
import { injectIntl, IntlShape } from 'react-intl';

// components
import LoadingComponent from 'components/LoadingComponent';
import ErrorComponent from 'components/ErrorComponent';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// components-lib
import RemoveItem from 'components-lib/RemoveItem'
import BlockUser from 'components-lib/BlockUser'
import FreeOfCharge from 'components-lib/FreeOfCharge'
import PersonalInfo from "components-lib/PersonalInfo";
import OrdersList from "components-lib/OrdersList";
import PaymentsList from 'components-lib/PaymentsList';

// types
import { User, } from 'types/types';
import { Refresh } from 'types/typesRedux';

// extracted data
import { extractDataUser } from 'common/extractData';

// style table
// import { useStyleContext } from 'context/styleContext';

// css
import '../../../css/mediaQuery.css';

type Props = {
  intl: IntlShape,
}

type userData = {
  userDetail: { user: User };
}

const UserProfile = ( { intl, }: Props) => {

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const { id } = useParams();

    // data from redux
    const refresh = useSelector((state: Refresh) => state.handleRefresh);
    const data = useSelector( (state: userData) => state.userDetail.user )

    const [localLoading, setLocalLoading] = useState<boolean>(true);
    
    const [ isError, setIsError ] = useState({
      profile: false,
      orders: false,
      payments: false,
    })

    const [ userId, setUserId ] = useState<number>(null);
    const [ isBlockUser, setIsBlockUser ] = useState<boolean>( false );
    const [ isUnblockUser, setIsUnblockUser ] = useState<boolean>( false );
    const [ isFreeOfCharge, setIsFreeOfCharge ] = useState<boolean>( false );
    const [ isNotFreeOfCharge, setIsNotFreeOfCharge ] = useState<boolean>( false );
    const [ isDeleteUser, setIsDeleteUser ] = useState<boolean>( false );

  // Get user
  const getUser = async ( id: number ) => {

    const cancelToken = axios.CancelToken.source()

    try {
      const response = await apiClient.get(`/v1/users/${id}`, {
        cancelToken:cancelToken.token
      });

      if ( response ) {
        const responseData = extractDataUser(intl)( response.data );
        dispatch(setUser( responseData ));
        if ( refresh.refreshUser ) dispatch(setRefresh( { refreshUser: false } ));
        if ( refresh.refresh === true ) dispatch(setRefresh( { refresh: false } ));
        // console.log(response.data)
        if (isError.profile === true) {setIsError({...isError, profile: false})};
      }
    } catch ( error ){
      console.log( 'err' );
      setIsError({...isError, profile: true});
    }
    setLocalLoading( false );
    return () => cancelToken.cancel();
  }  

  // Block user
  const blockUser = async ( id: number ) => {

    const cancelToken = axios.CancelToken.source()

    try {
      await apiClient.put(`/v1/users/${id}/block`, {
        cancelToken:cancelToken.token
      }
      ).then( ( response ) => {
        dispatch(setRefresh( { refreshUser: true } ))
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.block'})}`, { variant: 'success' });
      });
    } catch ( err ){
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        // todo:handle error
      }
        console.log( 'err' )
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.blockError'})}`, { variant: 'error' });
      }    
    return () => cancelToken.cancel()
  }

  // Unblock user
  const unblockUser = async ( id: number ) => {

    const cancelToken = axios.CancelToken.source()

    try {
      await apiClient.delete(`/v1/users/${id}/block`, {
        // cancelToken:cancelToken.token
      }
      ).then( ( response ) => {
        dispatch(setRefresh( { refreshUser: true } ))
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.unblock'})}`, { variant: 'success' });
      });
    } catch ( err ){
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        // todo:handle error
      }
        console.log( 'err' )
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.unblockError'})}`, { variant: 'error' });
      }
    return () => cancelToken.cancel()
  }

  // FreeOfCharge user
  const chargeUser = async ( id: number, isFreeOfCharge: boolean ) => {

    const cancelToken = axios.CancelToken.source()

    try {
      await apiClient.patch(`/v1/users/${id}`, {
        isFreeOfCharge:isFreeOfCharge,
        cancelToken:cancelToken.token,
      }
      ).then( ( response ) => {
        dispatch(setRefresh( { refreshUser: true } ))
        if ( isFreeOfCharge === true ) {
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.freeCharge'})}`, { variant: 'success' });
        } else if ( isFreeOfCharge === false ) {
          enqueueSnackbar(`${intl.formatMessage({id:'userAlert.charge'})}`, { variant: 'success' });
        }
      });
    } catch ( error ){
        console.log( 'err' )
        if ( isFreeOfCharge === true ) {
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.freeChargeError'})}`, { variant: 'error' });
      } else if ( isFreeOfCharge === false ) {
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.chargeError'})}`, { variant: 'error' });
      }
      }
    return () => cancelToken.cancel()
  }

  // Handle delete user
  const deleteUser = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source();
    try {  
      const response = await apiClient.delete(`/v1/users/${id}`,{
        cancelToken:cancelToken.token
      });
      if ( response ) {
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.removeUser'})}`, { variant: 'success' });
        // console.log(response, 'devices'); 
        handleHomePage();
      }

    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(`${intl.formatMessage({id:'userAlert.removeUserFalse'})}`, { variant: 'error' });
    }
    return () => cancelToken.cancel()
  }

  // after open page user profile set id and get user data from api
  useEffect(() => {
    dispatch(setOrdersUserOffset( 0 ));
    dispatch(setPaymentsUserOffset( 0 ));
    let userId = parseInt(id)
    setUserId( userId )
    getUser( userId )
  },[id]);


  useEffect(() => {
    if ( refresh.refreshUser === true ) {
      getUser( userId );
    }
  }, [ refresh.refreshUser ])
  
  useEffect(() => {
    if ( refresh.refresh === true ) {
      getUser( userId );
    }
  }, [ refresh.refresh ])


  // If click remove btn open pop-up window
  const handleRemoveBtn = ( ) => {
    setIsDeleteUser( true );
  }

  // Handle remove user btn in modal window
  const handleRemoveItem = ( bool: boolean ) => {
    if ( bool === true ) {
      deleteUser( userId );    
      setIsDeleteUser( false );
      
    }
  }


  // Handle modal window if click icon block/unblock
  const handleBlockItem = ( bool: boolean ) => {
    let status = data.blockedAt
    if ( bool === true ) {
      if ( status === intl.formatMessage({id:'tableUsers.blockedAt.block'}) ) {
        setIsUnblockUser( true )
      } else if ( status === intl.formatMessage({id:'tableUsers.blockedAt.null'}) ) {
        setIsBlockUser( true );
      }
    } 
  }

  // Handle modal window if click btn freeOfCharge
  const handleBlockCharge = ( bool: boolean ) => {
    let status = data.isFreeOfCharge
    if ( bool === true ) {
      if ( status === intl.formatMessage({id:'userProfile.freeOfCharge.false'}) ) {
        setIsNotFreeOfCharge( true )        
      } else if ( status === intl.formatMessage({id:'userProfile.freeOfCharge.true'}) ) {
        setIsFreeOfCharge( true );
      }
    } 
  }

  // Handle block/unblock user in modal window
  const handleBtnCharge = () => {
    if ( isFreeOfCharge ) {
      chargeUser( userId, false )
      setIsFreeOfCharge( false );
    } else if ( isNotFreeOfCharge ) {
      chargeUser( userId, true ) 
      setIsNotFreeOfCharge( false );
    }
  }

  // If click on btn cancel close pop-up window
  const handleCloseWindow = ( bool: boolean ) => {
    if ( bool === false ){ 
      setIsBlockUser( false )
      setIsUnblockUser( false )
      setIsDeleteUser( false )
      setIsFreeOfCharge( false );
      setIsNotFreeOfCharge( false )
    }
  }

  // Handle block/unblock user in modal window
  const handleBtnBlock = () => {
    if ( isBlockUser ) {
      blockUser( userId )    
      setIsBlockUser( false );
    } else if ( isUnblockUser ) {
      unblockUser( userId )    
      setIsUnblockUser( false );
    }
  }

  // handle click on edit icon
  const handleSubmitEdit = ( bool: boolean ) => {
    if ( bool === true ) {
      navigate( ( `/admin/users/user-edit/${userId}` ), {state:{ id }} );
    }
  }

  const handleHomePage = () => {
    let refreshUsers = 'refreshUsers';
    navigate( (`/admin/users`), { state: refreshUsers } );
  }

  const handleErrorLayout = ( tableName: string, bool: boolean ) => {
    if ( tableName === 'orders') {
      if( bool === true ) {
        setIsError({ ...isError, orders: true })        
      } else if ( bool === false ) {
        setIsError({ ...isError, orders: false })
      }
    } else if ( tableName === 'payments') {
      
    }
  }

  const handleRefresh = () => {
    dispatch(setRefresh( { refresh: true } ));
  }


  if ( isError.profile || isError.orders || isError.payments ) {
    return <ErrorComponent onPress={handleRefresh}/>
  }

  if ( localLoading ) {
    return <LoadingComponent />
  }

  return (
    <MDBox
    sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", width: "auto",
    display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "1rem",
    maxWidth: "78rem",
  }}
    >
      <MDBox container
        sx={{
          pb: "1rem", display: "flex", paddingRight: "1rem",
          direction: "row", gap: "1rem", width: "100%", 
        }} >          

        <MDBox item width={7} xs={1.3}
          sx= {{
            display: "flex",    
            justifyContent: "flex-start",  
            width: "33%",                      
          }} >
          <MDButton 
            variant="outlined" 
            color="success" 
            onClick={handleHomePage}
            >
            {intl.formatMessage({id:'button.back'})}
          </MDButton>
        </MDBox>
        
        <MDBox 
          sx= {{
            display: "flex", justifyContent: "flex-end", 
            gap: "1rem", width: "67%",           
          }} >

          <MDButton 
            variant="gradient" 
            color={( data && data.isFreeOfCharge === intl.formatMessage({id:'userProfile.freeOfCharge.false'}) ) ? "success" : "error" }  
            sx={{ 
              width: "16rem" 
            }}
            onClick={() => handleBlockCharge( true )}
          >
            {/* {intl.formatMessage({id:'button.block'})} */}
            { ( data && data.isFreeOfCharge === intl.formatMessage({id:'userProfile.freeOfCharge.false'}) ) 
              ? intl.formatMessage({id:'button.blockCharges'})                  
              : intl.formatMessage({id:'button.unblockCharges'}) }
          </MDButton>  

          <MDButton 
            variant="gradient" 
            color={( data && data.blockedAt === intl.formatMessage({id:'tableUsers.blockedAt.null'}) ) ? "error" : "success" } 
            sx={{ 
              width: "16rem" 
            }}
            onClick={() => handleBlockItem( true )}
          >
            {/* {intl.formatMessage({id:'button.block'})} */}
            { ( data && data.blockedAt === intl.formatMessage({id:'tableUsers.blockedAt.null'}) ) 
              ? intl.formatMessage({id:'button.blockUser'})
              : intl.formatMessage({id:'button.unblockUser'}) }                  
          </MDButton>                    

          <MDButton variant="gradient" 
            color="error" 
            sx={{ 
              width: "16rem" 
            }}
            onClick={handleRemoveBtn}
          >
            {intl.formatMessage({id:'button.delete'})}
          </MDButton>
        </MDBox>     

      </MDBox>
      
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "column",
          }} >

            <MDBox sx={{ width: "100%", pb: "1rem", pr: "1rem"}}>
              <PersonalInfo 
                onSubmitEdit={handleSubmitEdit}
              />              
            </MDBox>

        <MDBox 
          className= "containerUser"
          id='containerUser'
          sx={{
            // display: "flex", 
            // flexDirection: "row",
            width: "100%",
            marginRight: "0rem", pb: "1rem",           
            }} >

          <MDBox
            sx={{
              minWidth: "65%",
              width: "auto",
              maxWidth: "100%",
              marginRight: "0rem", pb: "1rem",           
              }}
          >
            <OrdersList 
              userId = { userId }
              isErrorOrders = { isError.orders }
              onErrorLayout={handleErrorLayout}
            />
          </MDBox>

          <MDBox sx={{ 
            minWidth: "35%", 
            width: "auto",
            maxWidth: "32rem", 
            }}
          >
            <PaymentsList
              userId = { userId }
              isErrorPayments = { isError.payments }
              onErrorLayout={handleErrorLayout}
            />
          </MDBox>
          
        </MDBox>


          { isDeleteUser && (
            <RemoveItem 
              onCloseWindow={ handleCloseWindow }
              onRemoveItem={ handleRemoveItem }
              title={ 'user' }
            />
          )}
        
          { (isBlockUser || isUnblockUser) && (
            <BlockUser 
              isBlockUser={ isBlockUser }
              onCloseWindow={ handleCloseWindow }
              onBtnBlock={ handleBtnBlock }
            />
          )}  
          
          { (isFreeOfCharge || isNotFreeOfCharge) && (
            <FreeOfCharge 
              isFreeOfCharge={ isFreeOfCharge }
              onCloseWindow={ handleCloseWindow }
              onBtnCharge={ handleBtnCharge }
            />
          )}  
          
      </MDBox>
    </MDBox>
  )
}

export default injectIntl(UserProfile)
import { ActionTypes } from "redux/actions/actionType";

// types
import { 
    InventoryTableType,
    ItemForm,
    ItemEditType,
    ItemsInContainerType,
} from "types/types";

// Inventory table
export const setInventory = ( inventory: InventoryTableType, totalCount: number ) => {
    return {
        type: ActionTypes.SET_INVENTORY,
        payload: { inventory, totalCount }
    }
};

export const setInventoryOffset = ( offsetChange: number )  => {
    return {
        type: ActionTypes.SET_INVENTORY_OFFSET,
        payload: { offset: offsetChange }
    }
  };
  
export const setInventoryLimit = ( limitChange: number ) => {
    return {
        type: ActionTypes.SET_INVENTORY_LIMIT,
        payload: { limit: limitChange }
}                    
};


// Item in edit window
export const setItem = ( item: ItemEditType ) => {
    return {
        type: ActionTypes.SET_ITEM,
        payload: item,
    }
};

export const setItemForm = ( updatedItemForm: ItemForm ) => {
    return {
      type: ActionTypes.SET_ITEM_FORM,
      payload: updatedItemForm,
    };
  };


// Items in container
export const setItems = ( itemsInContainer: ItemsInContainerType, totalCount: number ) => {
    return {
        type: ActionTypes.SET_ITEMS,
        payload: { itemsInContainer, totalCount }
    }
};

export const setItemsOffset = ( offsetChange: number )  => {
    return {
        type: ActionTypes.SET_ITEMS_OFFSET,
        payload: { offset: offsetChange }
    }
};
  
export const setItemsLimit = ( limitChange: number ) => {
    return {
        type: ActionTypes.SET_ITEMS_LIMIT,
        payload: { limit: limitChange }
    }                    
};


// Items in order
export const setItemsOrder = ( itemsInOrder: ItemsInContainerType, totalCount: number ) => {
    return {
        type: ActionTypes.SET_ITEMS_ORDER,
        payload: { itemsInOrder, totalCount }
    }
};

export const setItemsOrderOffset = ( offsetChange: number )  => {
    return {
        type: ActionTypes.SET_ITEMS_ORDER_OFFSET,
        payload: { offset: offsetChange }
    }
  };
  
export const setItemsOrderLimit = ( limitChange: number ) => {
    return {
        type: ActionTypes.SET_ITEMS_ORDER_LIMIT,
        payload: { limit: limitChange }
    }                    
};


import React, { useState, useEffect } from 'react'

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setPayments, setPaymentsOffset, setPaymentsLimit } from 'redux/actions/paymentsActions';
import { setRefresh } from 'redux/actions/pageActions';

// language
import { injectIntl, IntlShape } from 'react-intl';

// components
import QueryTableDevices from 'components-lib/QueryTable/QueryTableDevices';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import LoadingComponent from 'components/LoadingComponent';
import ErrorComponent from 'components/ErrorComponent';

// Material UI
import { Grid } from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';

// extracted data
import { extractDataPayments } from 'common/extractData';

import { paymentsStatusEnum, paymentsTypeEnum } from 'common/enums/DevicesStateEnum';

// types
import { PaymentsTableType, ColumnWidths } from 'types/types';
import { FilterState, Refresh, allPaymentsTableData } from 'types/typesRedux';

type Props = {
  intl: IntlShape;
}

const PaymentsTable = ( {intl}: Props ) => {

  const dispatch = useDispatch();

  const currentLocale = intl.locale;

  // redux
  const data = useSelector( (state: allPaymentsTableData) => state.allPayments )
  const filters = useSelector( (state: FilterState) => state.filters);
  const refresh = useSelector( (state: Refresh) => state.handleRefresh);


  // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
  const storedRowLimit = parseInt(localStorage.getItem('rowLimitPayments'));
  const initialRowLimit = storedRowLimit && !isNaN(storedRowLimit) ? storedRowLimit : data.limit;

  const [localLoading, setLocalLoading] = useState(true);
  const [isError, setIsError ] = useState<boolean>(false);
  const tableName = intl.formatMessage( {id: 'tablePayments.title'} );


  // Get payments from API
  const getPayments = async ( params = {} ) => {

    const cancelToken = axios.CancelToken.source()

    const defaultParams = {
      offset: data ? data.offset : 0,
      limit: initialRowLimit,
      sort: ( filters[`${tableName} sort`] &&
        filters[`${tableName} sort`].sort !== undefined ) 
        ? filters[`${tableName} sort`].sort 
        : data.sort,
    };
  
    // Merge provided params with default params
    const mergedParams = { ...defaultParams, ...params };

    try{
      const response = await apiClient.get("/v1/payments",{
        params: mergedParams,
        cancelToken:cancelToken.token
      });

      if (response) {
        const responseData = response.data.items.map( 
          ( payments: unknown ) => extractDataPayments( intl )( payments as PaymentsTableType ));
        const totalCount = response.data.totalCount;
        dispatch(setPayments( responseData, totalCount ));
        if (isError === true) {setIsError(false)};
          // console.log( response.data.items )
        } else {
        console.log("No response received.");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        // todo:handle error
      }
      setIsError(true)
    }      
    setLocalLoading( false );
    handleRefreshPage();
    return () => cancelToken.cancel()
  };

  // If refresh is true set them to false
  const handleRefreshPage = () => {
    if ( refresh.refresh === true ) { dispatch(setRefresh( { refresh: false } )) };
    if ( refresh.refreshLanguage === true ) { dispatch(setRefresh( { refreshLanguage: false } )) };
  }

  // Get data after open page
  useEffect(() => {
    getPayments();
  },[] ) 

  // refresh if refresh is true
  useEffect(() => {
    if ( refresh.refresh === true ) {
      getPayments( filters[tableName] );
    }
  }, [ refresh.refresh ])

  // Refresh when change language
  useEffect(() => {
    if ( refresh.refreshLanguage === true ) {
      getPayments( filters[tableName] );
    }
  },[ refresh.refreshLanguage ] )

  // Handle offset table
  const handleOffset = ( tableOffset: number ) => {
    dispatch(setPaymentsOffset( tableOffset ));
  }
  
  // Handle limit table
  const handleLimit = ( tableLimit: number ) => {
    dispatch(setPaymentsLimit( tableLimit ));
    localStorage.setItem('rowLimitPayments', tableLimit.toString());
  }

   // /*
  // */ Handle date for export csv
  //
  
  // If click btn handle export data
  const handleDateCSV = () => {
    getCSV()
  }

  // get csv
  const getCSV = async () => {
    const cancelToken = axios.CancelToken.source()

    try {
      const response = await apiClient.get('/v1/payments/export', {
        // responseType: 'blob', // Binary Large Object
        cancelToken:cancelToken.token
      });
  
      // Create a temporary URL to download the CSV file
      const csvData = response.data;
      const url = window.URL.createObjectURL(new Blob([csvData]));
  
      // Create a link element and set the URL as the href attribute
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payments.csv');
  
      // Append the link to the document and click it to initiate the download
      document.body.appendChild(link);
      link.click();
  
      // Clean up by removing the temporary URL object
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log('Error');
    }
    return () => cancelToken.cancel()
  };   

  const getColumnMinWidth = (columnId: string, locale: string): string => {
    const minWidths: ColumnWidths = {
      id: { default: "5.6rem", hu: "7rem" },
      user: { default: "9rem", hu: "9rem" },
      createdAt: { default: "8rem", hu: "8rem" },
      type: { default: "8.1rem", hu: "8.5rem" },
      status: { default: "10rem", hu: "11rem" },
      amount: { default: "1%", hu: "1%" },
    };
  
    const columnConfig = minWidths[columnId];
    if (columnConfig) {
      return columnConfig[locale] || columnConfig.default;
    } else {
      return "auto";
    }
  };

  const handleRefresh = () => {
    dispatch(setRefresh( { refresh: true } ));
  }

  if ( isError ) {
    return <ErrorComponent onPress={handleRefresh} />;
  }

  return (
    <Grid>
      { localLoading ? (
      <LoadingComponent /> ) : (

        <Grid
          sx={{ maxWidth: "82rem", }}
          >

          <MDBox 
            sx={{ display:"flex", flexDirection:"row", justifyContent:"flex-end",
              marginTop:"1rem", marginRight:"1rem", }}>
            <MDButton 
              variant="gradient" 
              color="success"
              onClick={handleDateCSV}
              width={4} xs={2}>
                {intl.formatMessage( {id: 'tablePayments.exportPayments'} )}
            </MDButton>
          </MDBox>
          <QueryTableDevices
            title= {tableName}
            tableData={ data.payments }
            pagePagination={true}
            totalCount={ data.totalCount }
            offset={ data.offset }
            rowLimit={ initialRowLimit }   
            columns= {[
              { Header: intl.formatMessage( {id: 'tablePayments.id'} ), 
              accessor: "id", 
              width: "auto",
              minWidth: getColumnMinWidth("id", currentLocale),
              maxWidth: "6rem",
              columnFilter: 'number',
              input: 'input',
              },
              { Header: intl.formatMessage( {id: 'tablePayments.user'} ), 
              accessor: "user", 
              width: "auto",
              minWidth: getColumnMinWidth("user", currentLocale),
              columnFilter: 'string',
              input: 'input',
              },
              { Header: intl.formatMessage( {id: 'tablePayments.createdAt'} ), 
              accessor: "createdAt", 
              width: "auto",
              minWidth: getColumnMinWidth("createdAt", currentLocale),
              input: 'datum',
              },
              { Header: intl.formatMessage({id: 'tablePayments.type'}), 
                accessor: "type", 
                width: "auto",
                minWidth: getColumnMinWidth("type", currentLocale),
                input: 'autocomplete',
                filterOptions: Object.values(paymentsTypeEnum(intl)).map((item) => {
                  return ({
                    value: item.filterKey,
                    label: item.label,
                    key: item.filterValue
                  })
                })                  
              },
              { Header: intl.formatMessage({id: 'tablePayments.status'}), 
                accessor: "status", 
                width: "auto",
                minWidth: getColumnMinWidth("status", currentLocale),
                input: 'autocomplete',
                filterOptions: Object.values(paymentsStatusEnum(intl)).map((item) => {
                  return ({
                    value: item.filterKey,
                    label: item.label,
                    key: item.filterValue
                  })
                })                  
              },
              { Header: intl.formatMessage( {id: 'tablePayments.amount'} ), 
              accessor: "amount", 
              width: "auto",
              minWidth: getColumnMinWidth("amount", currentLocale),
              columnFilter: 'number',
              input: 'title',
              },
              { Header: '', 
              accessor: "Action", 
              width: "auto",
              minWidth: "0.1%",
              input: 'title',
              clearIcon: true,
              },
            ]}
            button={ [{
                add: null ,
                detail: false,
                edit: false,
                delete: false,
                block: false,
            }] }
            icon= { <EuroIcon /> }
            onOffset={ handleOffset }
            onLimit={ handleLimit }
            onAddItem={ null }
            onEditBtn={ null }
            onBlockItem={ null }
            onRemoveBtn={ null }
            marginLeft={ true }
            maxDate={ true }
          />

        </Grid>
      )}
      
    </Grid>
  );
};

export default injectIntl(PaymentsTable);
import { ActionTypes } from "redux/actions/actionType";

import { Users, User, UserForm, UserLoggedType } from 'types/types';

export const setUsers = ( users: Users, totalCount: number ) => {
    return {
        type: ActionTypes.SET_USERS,
        payload: { users, totalCount }
    }
};

export const setUsersOffset = ( offsetChange: number )  => {
  return {
      type: ActionTypes.SET_USERS_OFFSET,
      payload: { offset: offsetChange }
  }
};

export const setUsersLimit = ( limitChange: number ) => {
  return {
      type: ActionTypes.SET_USERS_LIMIT,
      payload: { limit: limitChange }
  }                    
};


export const setUser = ( user: User ) => {
    return {
        type: ActionTypes.SET_USER,
        payload: user,
    }
};


export const setLoggedUser = ( userLogged: UserLoggedType ) => {
    return {
        type: ActionTypes.SET_LOGGED_USER,
        payload: userLogged,
    }
};


export const deleteUser = () => ({
    type: ActionTypes.DELETE_USER,
  }
);


export const setUserForm = ( updatedUserForm: UserForm ) => {
    return {
      type: ActionTypes.SET_USER_FORM,
      payload: updatedUserForm,
    };
  };


import React, { useState, useEffect } from 'react'

import { injectIntl, IntlShape } from 'react-intl';

import { useSelector } from 'react-redux';

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// types
import { UserDetailData } from 'types/typesRedux'

// css
import '../../css/mediaQuery.css'

interface Props {
  intl: IntlShape,
  onSubmitEdit?: (bool: boolean) => void,
}

function PersonalInfo( { intl, onSubmitEdit } :Props ): JSX.Element {

  const user = useSelector( (state: UserDetailData) => state.userDetail.user )

     
  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
        minWidth: "30rem",
        // marginRight: "1rem",
      }}
    >
        <MDBox        
            display="flex" 
            flexDirection="column"
            p={2} 
            m={0}
            sx={{ listStyle: "none" }}
        >    

            <MDBox 
            p={1} 
            pb={0}
            sx= {{ flex: "1 100%", }} > 

                {/* Header */}
                <MDBox 
                    sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                    }}>
                    <MDTypography variant="h5">
                    {intl.formatMessage({id:'userProfile.personalInfo'})}
                    </MDTypography>

                    <MDTypography  variant="body2" color="secondary">
                    <Tooltip 
                        title= {intl.formatMessage({id:'button.change'})}
                        placement="top"
                        onClick={ e => onSubmitEdit(true)} >
                        <IconButton
                        className="edit"
                        aria-label="Edit"
                        size="small" >
                        <EditIcon />
                        </IconButton>
                    </Tooltip>
                    </MDTypography>
                </MDBox>

                <MDTypography variant="button" >
                    {intl.formatMessage({id:'userProfile.id'})}
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{ user.id ? user.id : '-'}
                </MDTypography>


            </MDBox>

            <Divider orientation="horizontal" sx={{ width:"100%", height:"0.1rem", 
                opacity:"0.6", margin:"0", marginTop:"0.5rem",}} />


            <MDBox 
                // container 
                className= "containerPersonal"
                pb={3} 
                pl={1} 
                pt={0} mt={0}
                sx={{ width:"100%", 
                // display:"flex", 
            }} 
            >
            
                <Grid
                     className= "containerNameTel"
                    sx={{ 
                        display: "flex",
                        flexDirection: "row",
                        minWidth:"40%",
                        width: "auto",
                    }} 
                >

                    {/* User First name + email */}
                    <Grid
                        className= "containerUserName"
                        sx={{ 
                        display: "flex",
                        flexDirection: "column",
                        // minWidth:"50%",
                        width: "auto",
                        // maxWidth:"auto",
                        paddingRight: "1rem",
                        }} 
                    >
                    
                        <MDBox 
                            sx={ { 
                                display: "flex",
                                flexDirection: "column",
                                mt: "1.2rem",
                                lineHeight: "1.625",
                                letterSpacing: "0.00938em",
                        } } 
                        >
                            <MDTypography 
                                sx={ {                         
                                // mt: "0.6rem", 
                                fontWeight: "500",
                                fontSize:"0.7rem",                     
                                } } 
                                variant="button"                   
                                >
                                {intl.formatMessage({id:'userProfile.firstname'})}
                            </MDTypography>              
                            <MDTypography variant="button" 
                                sx={ { marginTop: "0.2rem",} }
                                fontWeight="regular" 
                                color="text">
                                { user.firstName ? user.firstName : '-'}
                            </MDTypography>
                        </MDBox>

                        <MDBox 
                            sx={ { 
                                display: "flex",
                                flexDirection: "column",
                                mt: "1.2rem",
                                lineHeight: "1.625",
                                letterSpacing: "0.00938em",
                            } } 
                        >
                            <MDTypography 
                                sx={ {                         
                                // mt: "0.6rem", 
                                fontWeight: "500",
                                fontSize:"0.7rem",                     
                                } } 
                                variant="button"                   
                                >
                                {intl.formatMessage({id:'userProfile.email'})}
                            </MDTypography>              
                            <MDTypography variant="button" 
                            sx={ { marginTop: "0.2rem",} }
                            fontWeight="regular" 
                            color="text">
                                { user.email ? user.email : '-'}
                            </MDTypography>
                        </MDBox>

                    </Grid> 
                
                    {/* User Surname + Phone number */}          
                    <Grid 
                        sx={ { 
                        display: "flex",
                        flexDirection: "column",
                        minWidth:"50%",
                        width: "auto",
                        maxWidth: "auto",
                        paddingRight: "1rem",
                        } } 
                    >  

                        <MDBox 
                            sx={ { 
                                display: "flex",
                                flexDirection: "column",
                                mt: "1.2rem",
                                lineHeight: "1.625",
                                letterSpacing: "0.00938em",
                            } } 
                        >
                            <MDTypography 
                                sx={ {                         
                                // mt: "0.6rem", 
                                fontWeight: "500",
                                fontSize:"0.7rem",                     
                                } } 
                                variant="button"                   
                                >
                                {intl.formatMessage({id:'userProfile.surname'})}
                            </MDTypography>              
                            <MDTypography variant="button" 
                                sx={ { marginTop: "0.2rem",} }
                                fontWeight="regular" 
                                color="text">
                                { user.lastName ? user.lastName : '-'}
                            </MDTypography>
                        </MDBox>

                        <MDBox 
                            sx={ { 
                                display: "flex",
                                flexDirection: "column",
                                mt: "1.2rem",
                                lineHeight: "1.625",
                                letterSpacing: "0.00938em",
                            } } 
                            >
                            <MDTypography 
                                sx={ {                         
                                // mt: "0.6rem", 
                                fontWeight: "500",
                                fontSize:"0.7rem",                     
                                } } 
                                variant="button"                   
                                >
                                {intl.formatMessage({id:'userProfile.mobil'})}
                            </MDTypography>              
                            <MDTypography variant="button" 
                                sx={ { marginTop: "0.2rem",} }
                                fontWeight="regular" 
                                color="text">
                                { user.phone ? user.phone : '-'}
                            </MDTypography>
                        </MDBox>

                    </Grid>   
                </Grid>

                <Grid
                    className= "containerInfo"
                    sx={{
                        display: "flex", flexDirection: "row",
                        // minWidth: "57%",
                        width: "auto",
                        maxWidth: "57%",
                    }}
                >
                    {/* Street + City */}
                    <Grid 
                        className= "containerStreet"
                        sx={{ 
                            display: "flex",
                            flexDirection: "column",
                            // minWidth:"6rem",
                            // width: "auto",
                            width: "inherit",
                            maxWidth: "auto",
                            paddingRight: "1rem",
                        }} 
                    >
                        
                        <MDBox 
                            sx={ {
                                display: "flex",
                                flexDirection: "column",
                                mt: "1.2rem",
                                lineHeight: "1.625",
                                letterSpacing: "0.00938em",
                                width:'100%',
                            } }
                        >
                            <MDTypography 
                                sx={ {                         
                                // mt: "0.6rem", 
                                fontWeight: "500",
                                fontSize:"0.7rem",                     
                                } } 
                                variant="button"                   
                                >
                                {intl.formatMessage({id:'userProfile.street'})}
                            </MDTypography>              
                            <MDTypography variant="button" 
                                sx={ { marginTop: "0.2rem",} }
                                fontWeight="regular" 
                                color="text">
                                { user.address ? user.address : '-'}
                            </MDTypography>
                        </MDBox>

                        <MDBox 
                            sx={ { 
                                display: "flex",
                                flexDirection: "column",
                                mt: "1.2rem",
                                lineHeight: "1.625",
                                letterSpacing: "0.00938em",
                            } } 
                        >
                            <MDTypography 
                                sx={ {                         
                                // mt: "0.6rem", 
                                fontWeight: "500",
                                fontSize:"0.7rem",                     
                                } } 
                                variant="button"                   
                                >
                                {intl.formatMessage({id:'userProfile.city'})}
                            </MDTypography>              
                            <MDTypography variant="button" 
                            sx={ { marginTop: "0.2rem",} }
                            fontWeight="regular" 
                            color="text">
                                { user.city ? user.city : '-'}
                            </MDTypography>
                        </MDBox>

                    </Grid>  

                    {/* Postal + Country */}
                    <Grid container 
                        className= "containerPostal"
                        sx={{ 
                            display: "flex",
                            flexDirection: "column",
                            // minWidth:"6rem",
                            width: "auto",
                            paddingRight: "1rem",
                        }} 
                    >   
                        
                        <MDBox 
                            sx={ { 
                                display: "flex",
                                flexDirection: "column",
                                mt: "1.2rem",
                                lineHeight: "1.625",
                                letterSpacing: "0.00938em",
                                width:'100%',
                            } } 
                        >
                            <MDTypography 
                                sx={ {                         
                                // mt: "0.6rem", 
                                fontWeight: "500",
                                fontSize:"0.7rem",                     
                                } } 
                                variant="button"                   
                                >
                                {intl.formatMessage({id:'userProfile.postal'})}
                            </MDTypography>              
                            <MDTypography variant="button" 
                                sx={ { marginTop: "0.2rem",} }
                                fontWeight="regular" 
                                color="text">
                                { user.postal ? user.postal : '-'}
                            </MDTypography>
                        </MDBox>

                        <MDBox 
                            sx={ { 
                                display: "flex",
                                flexDirection: "column",
                                mt: "1.2rem",
                                lineHeight: "1.625",
                                letterSpacing: "0.00938em",
                            } } 
                        >
                            <MDTypography 
                                sx={ {                         
                                // mt: "0.6rem", 
                                fontWeight: "500",
                                fontSize:"0.7rem",                     
                                } } 
                                variant="button"                   
                                >
                                {intl.formatMessage({id:'userProfile.country'})}
                            </MDTypography>              
                            <MDTypography variant="button" 
                                sx={ { marginTop: "0.2rem",} }
                                fontWeight="regular" 
                                color="text">
                                { user.country ? user.country.nativeName : '-' }
                            </MDTypography>
                        </MDBox>

                    </Grid> 

                    {/* Status + free of charge*/}
                    <Grid
                        className= "containerStatus"
                        sx={{ 
                            display: "flex",
                            flexDirection: "column",
                            // minWidth:"33%",
                            width: "auto",
                        }}
                    >            
                        <MDBox 
                        sx={ { 
                            display: "flex",
                            flexDirection: "column",
                            mt: "1.2rem",
                            lineHeight: "1.625",
                            letterSpacing: "0.00938em",
                            width:'100%',
                        } } 
                        >
                        <MDTypography 
                            sx={ {                         
                            // mt: "1.2rem", 
                            fontWeight: "500",
                            fontSize:"0.7rem",                       
                            } } 
                            variant="button"                   
                            >
                            {intl.formatMessage({id:'userProfile.status'})}
                        </MDTypography>    
                    
                        { user.blockedAt === `${intl.formatMessage({id:'tableUsers.blockedAt.null'})}` ? (
                            <MDTypography variant="button" 
                            sx={ { marginTop: "0.2rem", color: "#4CAF50", } }
                            fontWeight="regular">
                            { user.blockedAt }
                            </MDTypography>
                        ) : user.blockedAt === `${intl.formatMessage({id:'tableUsers.blockedAt.block'})}` ? (
                            <MDTypography variant="button" 
                            sx={ { marginTop: "0.2rem", color: "#F44334", } }
                            fontWeight="regular">
                            { user.blockedAt }
                            </MDTypography>
                        ) : '' }

                        </MDBox>

                        <MDBox 
                            sx={ { 
                                display: "flex",
                                flexDirection: "column",
                                mt: "1.2rem",
                                lineHeight: "1.625",
                                letterSpacing: "0.00938em",
                            } } 
                        >
                        <MDTypography 
                            sx={ {                         
                            // mt: "0.6rem", 
                            fontWeight: "500",
                            fontSize:"0.7rem",                     
                            } } 
                            variant="button"                   
                            >
                            {intl.formatMessage({id:'userProfile.freeOfCharge'})}
                        </MDTypography>      

                        { user.isFreeOfCharge === `${intl.formatMessage({id:'userProfile.freeOfCharge.false'})}` ? (
                            <MDTypography variant="button" 
                            sx={ { marginTop: "0.2rem", color: "#4CAF50", } }
                            fontWeight="regular">
                            { user.isFreeOfCharge }
                            </MDTypography>
                        ) : user.isFreeOfCharge === `${intl.formatMessage({id:'userProfile.freeOfCharge.true'})}` ? (
                            <MDTypography variant="button" 
                            sx={ { marginTop: "0.2rem", color: "#F44334", } }
                            fontWeight="regular">
                            { user.isFreeOfCharge }
                            </MDTypography>
                        ) : '' }

                        </MDBox>

                    </Grid>          
                </Grid>

            </MDBox>
        
        </MDBox>


    </Card>
  );
}

export default injectIntl(PersonalInfo);

import React, { MouseEvent, ChangeEvent, useRef, useEffect, useState } from 'react';

import { injectIntl, IntlShape } from 'react-intl';

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// alerts
import { useSnackbar } from "notistack";

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setFinesForm } from 'redux/actions/finesActions';
// import { setOrder } from 'redux/actions/ordersActions';
import { setRefresh } from 'redux/actions/pageActions';
import { extractDataOrder } from 'common/extractData';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import FormField from "components/FormField";
import LoadingComponent from 'components/LoadingComponent';

// icon
import ListIcon from '@mui/icons-material/List';

import DOMPurify from "dompurify";

// types
import { OrderData, FinesDataForm } from 'types/typesRedux';

type Props = {
  intl: IntlShape,
  orderId: number,
  onAddFine?: (bool: boolean) => void,
  onCloseWindow: (bool: boolean) => void,
}

const AddFine = ( {intl, orderId, onAddFine, onCloseWindow}: Props) => {
  
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const order = useSelector( (state: OrderData) => state.orderDetail.order );
  const finesForm = useSelector( (state: FinesDataForm) => state.allFines.finesForm );

 // set data from inputs to redux
  const handleInputDescription = ( e: ChangeEvent<HTMLInputElement>, newValue: string ) => {
    const fieldName = e.target.name;

    newValue = DOMPurify.sanitize( newValue );

    dispatch(setFinesForm({
      ...finesForm,
      [fieldName]: newValue,
    })
    )
  }; 

  const handleInputAmount = ( e: ChangeEvent<HTMLInputElement>, newValue: string ) => {
    const fieldName = e.target.name;

    // Validate if the input is a number for the 'amount' field
    if (fieldName === 'amount' && !/^\d*$/.test(newValue)) {
      return;
    }

    dispatch(setFinesForm({
      ...finesForm,
      [fieldName]: parseInt(newValue),
    })
    )
  }; 

  const closeWindow = () => {
    onCloseWindow( false );
    dispatch(setFinesForm( {
      amount: null,
      description: '',
    }
    ));
  } 

  // Handle fine /////////////////////////////
  const createFine = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source()

    try {
      const response = await apiClient.post(`/v1/orders/${id}/fines`,{
        amount: finesForm.amount,
        description: finesForm.description,
        cancelToken:cancelToken.token
      });

      if (response) {
        dispatch(setRefresh( {refreshFines: true} ));
        enqueueSnackbar(`${intl.formatMessage({id:'finesAlert.add.true'})}`, { variant: "success" });
      }
    } catch ( err ){
      enqueueSnackbar(`${intl.formatMessage({id:'finesAlert.add.false'})}`, { variant: "error" });
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        // todo:handle error
      }
    }
    return () => cancelToken.cancel()
  }

  const handleSubmit = () => {	
    if ( finesForm.amount && finesForm.amount !== null && finesForm.description && finesForm.description !== '' ) {
      createFine( orderId );
      onCloseWindow( false )
    } else if ( !finesForm.description || finesForm.description === '' || !finesForm.amount || finesForm.amount === null ) {
      enqueueSnackbar(`${intl.formatMessage({id:'finesAlert.addDescriptionAmount.false'})}`, { variant: "error" });
    } 
  }


  // if ( localLoading === true ) {
  //   return <LoadingComponent />
  // }

  return (
    <Grid container sx={{ position: 'fixed', top: '0%', left: '0%',
      backgroundColor:"rgba( 0, 0, 0, 0.5 ) !important", 
      width:"100vw", height:"100vh", zIndex:"1300" }}>

          <Card
              sx={ { 
                minWidth: "24rem", width: "auto", maxWidth: "27rem",
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                // backgroundColor: "#5ada55",
              } }
          >        

            <MDBox        
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{ listStyle: "none" }}
            >    

              <MDBox sx= {{ display: "flex",
                      flexDirection: "row", gap: "1rem" }} >
                <MDBox
                    sx={{ 
                      backgroundColor: "#17C063",
                      p: 1, mt: -4, ml: 0,
                      borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
                    }}
                    display="grid"
                    justifyContent="center"
                    alignItems="center"
                    // bgColor="success"
                    color="white"
                    width="4.5rem"
                    height="4.5rem"
                    shadow="md"
                    borderRadius="lg"
                    variant="gradient"  
                    fontSize= "2.2rem"        
                    >
                    <Icon sx={{marginBottom:"0.8rem"}} ><ListIcon /></Icon>
                </MDBox>

                <MDTypography variant="h6">
                  {intl.formatMessage({id:'fines.addFine'})}
                </MDTypography>
                                
              </MDBox>

              <MDBox sx={{ marginBottom: "0.5rem", marginTop: "0.9rem", }}>
                <MDBox 
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width:"100%",
                        gap:"4rem"
                    }}
                >
                  <MDTypography variant="button" >
                  {intl.formatMessage({id:'order.detail.idOrder'})}:
                  </MDTypography>
                  <MDTypography variant="button" 
                    sx={ { } }
                    fontWeight="regular" 
                    color="text">
                      { order.id ? order.id : '-'}
                  </MDTypography>
                </MDBox>
              </MDBox>              
              
              <MDBox sx={{ marginBottom: "0.5rem", marginTop: "0.9rem", }}>
                <MDBox 
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width:"100%",
                        gap:"4rem"
                    }}
                >
                  <MDTypography variant="button" >
                  {intl.formatMessage({id:'order.detail.user'})}:
                  </MDTypography>
                  <MDTypography variant="button" 
                    sx={ { } }
                    fontWeight="regular" 
                    color="text">
                      { order.user ? order.user : '-'}
                  </MDTypography>
                </MDBox>
              </MDBox>
              
              <MDBox sx={{ marginBottom: "0.5rem", marginTop: "0.9rem", }}>
                <MDBox 
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width:"100%",
                        gap:"4rem",
                    }}
                >
                  <MDTypography variant="button" >
                  {intl.formatMessage({id:'tableOrders.price'})}:
                  </MDTypography>

                  <MDBox
                     sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      width:"100%",
                      gap:"0rem",
                  }}
                  >
                    <FormField 
                      name="amount"
                      // placeholder= {intl.formatMessage({id:'tableOrders.amountFine'})}
                      value={ finesForm.amount ? finesForm.amount : '' }
                      // inputProps={{ pattern: '^[0-9]*$' }}
                      onChange={ (e: ChangeEvent<HTMLInputElement>) => handleInputAmount(e, e.target.value) }
                      className="input"
                      sx={{ width: "20%", textAlign: 'right' }}
                    />
                    <MDTypography 
                      color="text"
                      variant="button"
                    sx={{ 
                      width: "max-content",
                      padding: '4px 0 5px',
                    }}>{intl.formatMessage({id:'currency'})}</MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>

              <Grid item xs={12} md={12} 
                sx={{
                  display: "flex", flexDirection: "column", marginTop:"1rem"

                }}>
                <MDTypography variant="button" >
                  {intl.formatMessage({id:'fines.detail.description'})}
                </MDTypography>           
                <MDInput 
                  type="text"
                  name='description'
                  multiline
                  rows={3} 
                  // value= { finesForm.description ? finesForm.description : '' }
                  // placeholder={item.notes ? item.notes : '-'}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputDescription(e, e.target.value)}
                  />
              </Grid>  
             
              {/* { variant.length !== 0 && (
                <Grid item xs={12} md={12} 
                sx={ {                         
                  mt: "0.6rem",
                } }>
                  <MDTypography 
                    variant="button" >
                    {intl.formatMessage({id:'inventory.edit.variant'})}
                  </MDTypography>  
                  <Autocomplete             
                    options={ variant }
                    onChange={ handleInputItem }
                    sx={{ marginTop: "-0.5rem"  }}
                    renderInput={(params) => (
                      <FormField {...params} 
                        InputLabelProps={{ shrink: true }}
                        inputRef={variantInput} 
                        placeholder={ order.variant ? order.variant : ''}
                      /> )}
                  />
                </Grid>
              )} */}
              

              {/* <Grid item xs={12} md={12} 
                sx={{
                  display: "flex", flexDirection: "column", marginTop:"1rem"

                }}>
                <MDTypography variant="button" >
                  {intl.formatMessage({id:'inventory.edit.notes'})}
                </MDTypography>           
                <MDInput 
                  type="text"
                  multiline
                  rows={5} 
                  placeholder={order.notes ? order.notes : '-'}
                  // inputRef={textInput}
                  // onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, 'description')}
                  onChange={handleInputItem}
                  />
              </Grid>   */}

              <MDBox 
                sx={{
                    display: "flex", flexDirection: "row", justifyContent: "flex-end", 
                    gap: "0.5rem", maxWidth: "100%", marginBottom: "0.5rem", marginTop: "1rem",
                }}
              >
                <MDButton 
                    variant="outlined" 
                    color="success" 
                    // onClick={() => navigate(-1)}
                    onClick={ closeWindow }
                  >
                    {intl.formatMessage({id:'button.cancel'})}
                </MDButton>
                <MDButton 
                    variant="contained" 
                    color="success" 
                    // sx={{
                    //   visibility: isVisible ? "visible" : "hidden", 
                    // }}
                    onClick={ handleSubmit }         
                  >
                    {intl.formatMessage({id:'button.save'})}
                </MDButton>
              </MDBox>
      
            </MDBox>
          </Card>

        {/* </Grid> */}
    </Grid>
  )
};

export default injectIntl(AddFine);
import React, { useState, useEffect } from 'react'

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

import { useNavigate, } from "react-router-dom";

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setOrders, setOrdersOffset, setOrdersLimit } from 'redux/actions/ordersActions';
import { setItemsOrderOffset } from 'redux/actions/inventoryActions';
import { setFinesOffset } from 'redux/actions/finesActions';
import { setRefresh } from 'redux/actions/pageActions';

// language
import { injectIntl, IntlShape } from 'react-intl';

// components
import QueryTableDevices from 'components-lib/QueryTable/QueryTableDevices';
import LoadingComponent from 'components/LoadingComponent';
import ErrorComponent from 'components/ErrorComponent';

// Material UI
import { Grid } from '@mui/material';

// icon
import ListIcon from '@mui/icons-material/List';

// extracted data
import { extractDataOrders } from 'common/extractData';

import { ordersStatusEnum, paymentsStatusOrderEnum } from 'common/enums/DevicesStateEnum';

// types
import { OrdersTableType, ColumnWidths } from 'types/types';
import { FilterState, Refresh, OrdersData } from 'types/typesRedux';
import { Columns } from 'types/tableTypes';

type Props = {
  intl: IntlShape;
}

const OrdersTable = ( {intl}: Props ) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const currentLocale = intl.locale;

  // redux
  const data = useSelector( (state: OrdersData) => state.allOrders )
  const filters = useSelector( (state: FilterState) => state.filters);
  const refresh = useSelector( (state: Refresh) => state.handleRefresh);

  // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
  const storedRowLimit = parseInt(localStorage.getItem('rowLimitOrders'));
  const initialRowLimit = storedRowLimit && !isNaN(storedRowLimit) ? storedRowLimit : data.limit;

  const [localLoading, setLocalLoading] = useState<boolean>(true);
  const [isError, setIsError ] = useState<boolean>(false);
  const tableName = intl.formatMessage( {id: 'tableOrders.title'} );

  // get all orders
  const getOrders = async ( params = {} ) => {

    const cancelToken = axios.CancelToken.source()

      const defaultParams = {
        offset: data ? data.offset : 0,
        limit: initialRowLimit,
        sort: ( filters[`${tableName} sort`] &&
          filters[`${tableName} sort`].sort !== undefined ) 
          ? filters[`${tableName} sort`].sort 
          : data.sort,
      };
  
      // Merge provided params with default params
      const mergedParams = { ...defaultParams, ...params };
  
      try{
        const response = await apiClient.get("/v1/orders",{
          params: mergedParams,
          cancelToken:cancelToken.token
        });
  
        if (response) {
          const responseData = response.data.items.map( 
            ( orders: unknown ) => extractDataOrders( intl )( orders as OrdersTableType ));
          const totalCount = response.data.totalCount;
          dispatch(setOrders( responseData, totalCount ));
            // console.log( response.data.items );
          if (isError === true) {setIsError(false)};
        } else {
          console.log("No response received.");
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("canceled");
        } else {
          // todo:handle error
        }
        setIsError(true)
      }        
      setLocalLoading( false );
      handleRefreshPage()
    return () => cancelToken.cancel()
  };

  // If refresh is true set them to false
  const handleRefreshPage = () => {
    if ( refresh.refresh === true ) { dispatch(setRefresh( { refresh: false } )) };
    if ( refresh.refreshLanguage === true ) { dispatch(setRefresh( { refreshLanguage: false } )) };
  }

  useEffect(() => {
    getOrders( filters[tableName] );
  },[] ) 

  useEffect(() => {
    if ( refresh.refresh === true ) {
      getOrders( filters[tableName] );
    }
  }, [ refresh.refresh ])

  // Refresh when change language
  useEffect(() => {
    if ( refresh.refreshLanguage === true ) {
      getOrders( filters[tableName] );
    }
  },[ refresh.refreshLanguage ] )

  // go to profile page of order and set offset to 0
  const handleDetailBtn = ( id: number ) => {
    navigate( (`/admin/orders/order-detail/${id}`), { state: {
      id: id,
      }} 
    );
    dispatch(setItemsOrderOffset( 0 ));
    dispatch(setFinesOffset( 0 ));
  }

  // Handle offset
  const handleOffset = ( tableOffset: number ) => {
    dispatch(setOrdersOffset( tableOffset ));
  }
  
  // Handle limit
  const handleLimit = ( tableLimit: number ) => {
    dispatch(setOrdersLimit( tableLimit ));
    localStorage.setItem('rowLimitOrders', tableLimit.toString());
  }

  const getColumnMinWidth = (columnId: string, locale: string): string => {
    const minWidths: ColumnWidths = {
      id: { default: "5.6rem", hu: "7rem" },
      user: { default: "9rem", hu: "9rem" },
      createdAt: { default: "8rem", hu: "8rem" },
      completedAt: { default: "8rem", hu: "8rem" },
      status: { default: "9.8rem", hu: "11.5rem" },
      paymentStatus: { default: "9rem", hu: "11rem" },
      price: { default: "3%", hu: "1%" },
      Action: { default: "2%", hu: "1%" },
    };
  
    const columnConfig = minWidths[columnId];
    if (columnConfig) {
      return columnConfig[locale] || columnConfig.default;
    } else {
      return "auto";
    }
  };


  const [columns, setColumns] = useState<Columns[]>([
    { Header: intl.formatMessage( {id: 'tableOrders.id'} ), 
      accessor: "id", 
      width: "auto",
      minWidth: getColumnMinWidth("id", currentLocale),
      maxWidth: "6rem",
      columnFilter: 'number',
      input: 'input',
      },
      { Header: intl.formatMessage( {id: 'tableOrders.user'} ), 
      accessor: "user", 
      width: "auto",
      minWidth: getColumnMinWidth("user", currentLocale),
      maxWidth: "auto",
      columnFilter: 'string',
      input: 'input',
      },
      { Header: intl.formatMessage( {id: 'tableOrders.createdAt'} ), 
      accessor: "createdAt", 
      width: "auto",
      minWidth: getColumnMinWidth("createdAt", currentLocale),
      maxWidth: "auto",
      columnFilter: 'string',
      input: 'datum',
      },
      { Header: intl.formatMessage( {id: 'tableOrders.completedAt'} ), 
      accessor: "completedAt", 
      width: "auto",
      minWidth: getColumnMinWidth("completedAt", currentLocale),
      maxWidth: "auto",
      columnFilter: 'string',
      input: 'datum',
      },
      { Header: intl.formatMessage({id: 'tableOrders.status'}), 
      accessor: "status", 
      width: "auto",
      minWidth: getColumnMinWidth("status", currentLocale),
      maxWidth: "auto",
      input: 'autocomplete',
      filterOptions: Object.values(ordersStatusEnum(intl)).map((item) => {
        return ({
          value: item.filterKey,
          label: item.label,
          key: item.filterValue
        })
      })
      },
      { Header: intl.formatMessage( {id: 'tableOrders.price'} ), 
      accessor: "price", 
      width: "auto",
      minWidth: getColumnMinWidth("price", currentLocale),
      maxWidth: "auto",
      columnFilter: 'number',
      input: 'title',
      },
      { Header: intl.formatMessage({id: 'table.actions'}), 
      accessor: "Action", 
      width: "auto",
      minWidth: getColumnMinWidth("Action", currentLocale),
      maxWidth: "auto",
      input: 'title',
      clearIcon: true,
      },
  ]);

  useEffect(() => {
    const handleResize = () => {
      const maxWidth = 1300;
      const paymentsColumn = { 
        Header: intl.formatMessage({id: 'tableOrders.paymentStatus'}), 
        accessor: "paymentStatus", 
        width: "auto",
        minWidth: getColumnMinWidth("paymentStatus", currentLocale),
        maxWidth: "9rem",
        input: 'autocomplete',
        filterOptions: Object.values(paymentsStatusOrderEnum(intl)).map((item) => {
          return ({
            value: item.filterKey,
            label: item.label,
            key: item.filterValue
          })
        })                  
      };
      const lastNameIndex = columns.findIndex(column => column.accessor === "status");
      const isPaymentColumnPresent = columns.some(column => column.accessor === "paymentStatus");
  
      if (window.innerWidth > maxWidth && !isPaymentColumnPresent) {
          // set column email after lastName
          setColumns(currentColumns => [
              ...currentColumns.slice(0, lastNameIndex + 1),
              paymentsColumn,
              ...currentColumns.slice(lastNameIndex + 1)
          ] as Columns[]);
      } else if (window.innerWidth <= maxWidth && isPaymentColumnPresent) {
          // delete column email, if exist andwindow width is less or equal maxWidth
          setColumns(currentColumns => currentColumns.filter(column => column.accessor !== "paymentStatus"));
      }
  };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [columns, intl]);

  
  const handleRefresh = () => {
    dispatch(setRefresh( { refresh: true } ));
  }

  if ( isError ) {
    return <ErrorComponent onPress={handleRefresh} />;
  }

  return (
    <Grid>
      { localLoading ? (
      <LoadingComponent /> ) : (

        <Grid
          sx={{ 
            maxWidth: "89rem",
            width: 'auto',
          }}
        >

          <QueryTableDevices
            title= { tableName }
            tableData={ data.orders }
            pagePagination={ true }
            totalCount={ data.totalCount }
            offset={ data.offset }
            rowLimit={ initialRowLimit }  
            columns={columns}
            button={ [{
                add: null,
                detail: true,
                edit: false,
                delete: false,
                block: false,
                picture: false,
            }] }
            icon= { <ListIcon /> }
            onOffset={ handleOffset }
            onLimit={ handleLimit }
            onAddItem={ null }
            onDetailItem={ handleDetailBtn } 
            onEditBtn={ null }
            onBlockItem={ null }
            onRemoveBtn={ null }
            onPictureItem={ null }
            marginLeft={ true }
            maxDate={ true }
          />

        </Grid>
      )}     
    </Grid>
  );
};

export default injectIntl(OrdersTable);
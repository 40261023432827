import { ActionTypes } from 'redux/actions/actionType';
import { 
    ReservationsState, 
    SetReservationsAction,
    ReservationDetailState,
    SetReservationAction,
} from 'types/typesRedux';


// reservations data table
const reservationsState: ReservationsState = {
    reservations: [],
    totalCount: 0,
    offset: 0,
    limit: 10,
    sort: 'id:desc',
};

export const reservationsReducer = (state= reservationsState, action: SetReservationsAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_RESERVATIONS:
            return { 
                ...state, 
                reservations: action.payload.reservations,
                totalCount: action.payload.totalCount};
        case ActionTypes.SET_RESERVATIONS_OFFSET:
            return { ...state, offset: action.payload.offset };
        case ActionTypes.SET_RESERVATIONS_LIMIT:
            return { ...state, limit: action.payload.limit };
        case ActionTypes.RESET_OFFSET:
            return { ...state, offset: 0 };
        default:
            return state;
    }
};

// reservations data table
const reservationState: ReservationDetailState = {
    reservation: [],

    reservationForm: {
        status: '',
        issueFine: null,

    }
};


export const reservationReducer = (state = reservationState, { type, payload }: SetReservationAction )  => {
    switch ( type ) {
        case ActionTypes.SET_RESERVATION_BY_ID:
            return { ...state, reservation: payload };
        case ActionTypes.SET_RESERVATION_FORM:
            return { ...state, reservationForm: payload };
        default:
            return state;
    }
};
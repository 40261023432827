import React, { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setItemsOffset, setItemsLimit } from 'redux/actions/inventoryActions';
import { setRefresh } from 'redux/actions/pageActions';

// language
import { injectIntl, IntlShape } from 'react-intl';

// components
import QueryTableDevices from 'components-lib/QueryTable/QueryTableDevices';
import EditItem from 'components-lib/EditItem';

// Material UI
import { Grid } from '@mui/material';

// types
import { ItemsContainerData } from 'types/typesRedux';
import { ColumnWidths } from 'types/types';

// Enum
import { itemsEnum } from 'common/enums/DevicesStateEnum';


type Props = {
  intl: IntlShape;
  initialRowLimit: number;
}

const ItemsTable = ({intl, initialRowLimit}: Props) => {

  const dispatch = useDispatch();

  const currentLocale = intl.locale;

  // take data from redux
  const items = useSelector( (state: ItemsContainerData) => state.itemsInContainer )

  const tableName = intl.formatMessage( {id: 'tableItems.title'} );
  const [ isEditItem, setIsEditItem ] = useState<boolean>( false );

  const [ itemId, setItemId ] = useState<number>();

  // If click icon edit open modal window
  const handleEditBtn = ( id: number ) => {    
    setIsEditItem( true );
    setItemId( id );
  }

  // If submit btn edit in modal window edit item
  const handleEditItem = ( bool: boolean ) => {
    if ( bool === true ) {
      setIsEditItem( false );
      dispatch(setRefresh( { refreshInventory: true } ))
    }
  }

  // If submit cancel btn close modal window
  const handleCloseWindow = ( bool: boolean ) => {
    if (bool === false){ 
      setIsEditItem( false );
    }
  }

  // Handle offset
  const handleOffset = ( tableOffset: number ) => {
    dispatch(setItemsOffset( tableOffset ));
  }
  
  // Handle limit
  const handleLimit = ( tableLimit: number ) => {
    dispatch(setItemsLimit( tableLimit ));
    localStorage.setItem('rowLimitItemsInContainer', tableLimit.toString());
  }

  const getColumnMinWidth = (columnId: string, locale: string): string => {
    const minWidths: ColumnWidths = {
      id: { default: "5.6rem", hu: "7rem" },
      name: { default: "5.6rem", hu: "6.2rem" },
      variant: { default: "5.6rem", hu: "3rem" },
      notes: { default: "5.6rem", hu: "5.6rem" },
    };
  
    const columnConfig = minWidths[columnId];
    if (columnConfig) {
      return columnConfig[locale] || columnConfig.default;
    } else {
      return "auto";
    }
  };

  return (
    <Grid 
      // item xs={12} sm={12} md={12} lg={12}
    >
      <QueryTableDevices
        title= {tableName}
        tableData={ items.itemsInContainer }
        pagePagination={true}
        totalCount={ items.totalCount }
        offset={ items.offset }
        rowLimit={ initialRowLimit }
        marginLeft={false}  
        columns= {[
          { Header: intl.formatMessage( {id: 'tableItems.id'} ), 
            accessor: "id", 
            minWidth: getColumnMinWidth("id", currentLocale),
            width: "auto",
            maxWidth: "6rem",
            columnFilter: 'number',
            input: 'input',
          },
          { Header: intl.formatMessage( {id: 'tableInventory.type'} ), 
          accessor: "name", 
          width: "auto",
          minWidth: getColumnMinWidth("name", currentLocale),
          input: 'autocomplete',
          filterOptions: Object.values(itemsEnum(intl)).map((item) => ({
            value: item.filterKey,
            label: item.label,
            key: item.filterValue
          })
          )
          },
          { Header: intl.formatMessage( {id: 'tableInventory.variant'} ), 
            accessor: "variant", 
            width: "auto",
            minWidth: getColumnMinWidth("variant", currentLocale),
            columnFilter: 'string',
            input: 'title',
          },
          { Header: intl.formatMessage( {id: 'tableInventory.notes'} ), 
            accessor: "notes", 
            minWidth: getColumnMinWidth("notes", currentLocale),
            width: 'auto',
            columnFilter: 'string',
            input: 'title',
          },
          { Header: intl.formatMessage({id: 'table.actions'}), 
            accessor: "Action", 
            width: "auto",
            minWidth: "5%",
            input: 'title',
            clearIcon: true,
          },
        ] }

        button={ [{
          add: null,  // null | string 
          detail: false, // boolean
          edit: true, // boolean
          delete: false, // boolean
          block: false, // boolean
        }] }

        icon= {false}
        onOffset={ handleOffset }
        onLimit={ handleLimit }

        onAddItem={ null }
        onRemoveBtn={ null }
        onEditBtn={ handleEditBtn }
      />

      { isEditItem && (
        <EditItem 
          onCloseWindow={ handleCloseWindow }
          onEditItem={ handleEditItem }
          itemId={ itemId }
          // title={ 'user' }
        />
      )}

    </Grid>
  );
};

export default injectIntl(ItemsTable);
import React, { useState, useEffect } from 'react'

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

import { useNavigate, } from "react-router-dom";

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setOrdersByUserId, setOrdersUserOffset, setOrdersUserLimit } from 'redux/actions/ordersActions';
import { setRefresh } from 'redux/actions/pageActions';

import { injectIntl, IntlShape } from 'react-intl';

// components
import QueryTableDevices from 'components-lib/QueryTable/QueryTableDevices';
import LoadingComponent from 'components/LoadingComponent';

import { Grid } from '@mui/material';

// extracted data
import { extractDataOrdersByUser } from 'common/extractData';

// types
import { OrdersTableType } from 'types/types';
import { FilterState, Refresh, OrdersDataByUser } from 'types/typesRedux';

type Props = {
  intl: IntlShape;
  userId: number;
  isErrorOrders: boolean;
  onErrorLayout: ( order: string, bool: boolean ) => void;
}

const OrdersList = ( { intl, userId, isErrorOrders, onErrorLayout }: Props ) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // redux
  const data = useSelector( (state: OrdersDataByUser) => state.allOrdersByUser )
  const filters = useSelector( (state: FilterState) => state.filters);
  const refresh = useSelector( (state: Refresh) => state.handleRefresh);


  // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
  const storedRowLimit = parseInt(localStorage.getItem('rowLimitOrdersUser'));
  const initialRowLimit = storedRowLimit && !isNaN(storedRowLimit) ? storedRowLimit : data.limit;

  const [localLoading, setLocalLoading] = useState<boolean>(true);
  const tableName = intl.formatMessage( {id: 'tableOrders.user.title'} );

  const getOrdersById = async ( userId: number, params = {} ) => {

    const cancelToken = axios.CancelToken.source()

      const defaultParams = {
        offset: data ? data.offset : 0,
        limit: initialRowLimit,
        sort: ( filters[`${tableName} sort`] &&
          filters[`${tableName} sort`].sort !== undefined ) 
          ? filters[`${tableName} sort`].sort 
          : data.sort,
      };
  
      // Merge provided params with default params
      const mergedParams = { ...defaultParams, ...params };
  
      try{
        const response = await apiClient.get(`v1/users/${userId}/orders`,{
          params: mergedParams,
          cancelToken:cancelToken.token
        });

        if (response) {
          const responseData = response.data.items.map( 
            ( orders: unknown ) => extractDataOrdersByUser( intl )( orders as OrdersTableType ));
            const totalCount = response.data.totalCount;
          dispatch(setOrdersByUserId( responseData, totalCount ));
          // console.log( response.data.items );
          if ( refresh.refresh === true ) dispatch(setRefresh( { refresh: false } ))
          if ( isErrorOrders === true ) {onErrorLayout( 'orders', false )}
        } else {
          console.log("No response received.");
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("canceled");
        } else {
          // todo:handle error
        }
        onErrorLayout('orders', true )
      }        
      setLocalLoading( false );
      return () => cancelToken.cancel()
    };

  useEffect(() => {
    dispatch(setRefresh( { refresh: false } ))
    getOrdersById(userId);
  },[] ) 

  useEffect(() => {
    if ( refresh.refresh === true ) {
      dispatch(setRefresh( { refresh: false } ));
      getOrdersById( userId, filters[tableName] );
    }
  }, [ refresh.refresh ])

  // go to profile page of order
  const handleDetailBtn = ( id: number ) => {
    navigate( (`/admin/orders/order-detail/${id}`), { state: {
      id: id,
      }} 
    );
  }

  // Handle offset
  const handleOffset = ( tableOffset: number ) => {
    dispatch(setOrdersUserOffset( tableOffset ));
  }
  
  // Handle limit
  const handleLimit = ( tableLimit: number ) => {
    dispatch(setOrdersUserLimit( tableLimit ));
    localStorage.setItem('rowLimitOrdersUser', tableLimit.toString());
  }
    

  return (

    <Grid container>
      { localLoading ? (
      <LoadingComponent /> ) : (

        <Grid item xs={12} sm={12} md={12} lg={12}>

          { data.totalCount > 0 && 
            <QueryTableDevices
              title= {tableName}
              tableData={ data.orders }
              pagePagination={true}
              totalCount={ data.totalCount }
              offset={ data.offset }
              rowLimit={ initialRowLimit }  
              marginLeft= { false } 
              columns= {[
                { Header: intl.formatMessage( {id: 'tableOrders.id'} ), 
                accessor: "id", 
                width: "auto",
                minWidth: "5%",
                input: 'title',
                },
                { Header: intl.formatMessage( {id: 'tableOrders.createdAt'} ), 
                accessor: "createdAt", 
                width: "auto",
                minWidth: "10%",
                input: 'title',
                },
                { Header: intl.formatMessage( {id: 'tableOrders.completedAt'} ), 
                accessor: "completedAt", 
                width: "auto",
                minWidth: "10%",
                input: 'title',
                },
                { Header: intl.formatMessage( {id: 'tableOrders.status'} ), 
                accessor: "status", 
                width: "auto",
                minWidth: "15%",
                input: 'title',
                },
                { Header: intl.formatMessage( {id: 'tableOrders.price'} ), 
                accessor: "price", 
                width: "auto",
                minWidth: "3%",
                input: 'title',
                },
                { Header: intl.formatMessage({id: 'table.actions'}), 
                accessor: "Action", 
                width: "auto",
                minWidth: "3%",
                input: 'title',
                clearIcon: true,
                },
            ]}
            button={ [{
                add: null ,
                detail: true,
                edit: false,
                delete: false,
                block: false,
            }] }
            icon= { false }
            onOffset={ handleOffset }
            onLimit={ handleLimit }
            onAddItem={ null }
            onDetailItem={ handleDetailBtn } 
            onEditBtn={ null }
            onBlockItem={ null }
            onRemoveBtn={ null }
          />
          }


        </Grid>
      )}
      
    </Grid>
  );
};

export default injectIntl(OrdersList);
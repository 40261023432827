import { ActionTypes } from 'redux/actions/actionType';
import { AnnouncementState, SetAnnouncementAction } from 'types/typesRedux';



const announcementState: AnnouncementState = {
    announcement: {
        content: null,
        createdAt: null,
        updatedAt: null
    },

    announcementForm: {
        content: '',
    }
};

export const announcementReducer = (state= announcementState, action: SetAnnouncementAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_ANNOUNCEMENT:
            return { 
                ...state, 
                announcement: action.payload };
        case ActionTypes.SET_ANNOUNCEMENT_FORM:
            return { ...state, announcementForm: action.payload };
        default:
            return state;
    }
};




import { Dispatch } from 'redux';

import { deleteFilters } from 'redux/actions/tableActions';

export const handleDeleteFilters = ( dispatch: Dispatch, tableItemsName: string ) => {
    dispatch(deleteFilters(tableItemsName + ' sort'));
    dispatch(deleteFilters(tableItemsName));
    dispatch(deleteFilters(tableItemsName + 'Autocomplete'));
}


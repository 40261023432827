import React, { useState, useEffect } from 'react'

// react router
import { useNavigate, useParams, } from "react-router-dom";

// axios
import axios from 'axios';
import { apiClient } from "api-client"

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setReservationsById, setReservationForm } from 'redux/actions/reservationsActions';
import { setRefresh } from 'redux/actions/pageActions';
import { setOrderForm } from 'redux/actions/ordersActions';

// components
import LoadingComponent from 'components/LoadingComponent';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// components-lib
import ReservationInfo from "components-lib/ReservationInfo";
import FineInfo from "components-lib/FineInfo";
import EditReservation from 'components-lib/EditReservation';

// notification
import { useSnackbar } from "notistack";

// language
import { injectIntl, IntlShape } from 'react-intl';

// types
import { Refresh, ReservationData } from 'types/typesRedux';

// exracted data
import { extractDataReservation } from 'common/extractData';

// css
import '../../../css/mediaQuery.css'

type Props = {
  intl: IntlShape,
}

const ReservationDetail = ( { intl, }: Props) => {

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const { id } = useParams();

    // data from redux
    const refresh = useSelector((state: Refresh) => state.handleRefresh);
    const reservation = useSelector( (state: ReservationData) => state.reservationDetail.reservation );

    const [localLoading, setLocalLoading] = useState<boolean>(true);

    const [ reservationId, setReservationId ] = useState<number>(null);

    const [ isEdit, setIsEdit ] = useState<boolean>(false);

  // Get reservation
  const getReservation = async ( id: number ) => {

    const cancelToken = axios.CancelToken.source()

    try {
      const response = await apiClient.get(`/v1/reservations/${id}/detail`,{
        cancelToken:cancelToken.token
      });
      setLocalLoading( false );
      // console.log( response.data )

      if ( response ) {
        const responseData = extractDataReservation(intl)( response.data );
        dispatch(setReservationsById( responseData ));
        // console.log( response.data )
        setLocalLoading( false );
      }
    } catch ( error ){
      console.log( 'err' )
    }
    return () => cancelToken.cancel()
  }  

  // Handle add fine /////////////////////////////
  const addFine = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source()

    try {
      await apiClient.post(`/v1/reservations/${id}/fine`, {
        cancelToken:cancelToken.token
        }
      ).then( ( response ) => {
        if ( response ) {
          enqueueSnackbar(`${intl.formatMessage({id:'reservationEditAlert.fine'})}`, { variant: 'success' });
          dispatch(setRefresh( { refresh: true } ));
        }
    });
    } catch ( err ){
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        enqueueSnackbar(`${intl.formatMessage({id:'reservationEditAlert.fineError'})}`, { variant: 'error' });
      }
    };
    return () => cancelToken.cancel();
  }

  // after open page reservation detail set id and get reservation data from api
  useEffect(() => {
    dispatch(setOrderForm( { status: '' } ));
    let reservationId = parseInt(id)
    setReservationId( reservationId )
    getReservation( reservationId )
  },[id]);

  useEffect(() => {
    if ( refresh.refresh === true ) {
      getReservation( reservationId );
      dispatch(setRefresh( { refresh: false } ))
    }
  }, [ refresh.refresh ])

  // handle close modal window
  const handleCloseWindow = ( bool: boolean ) => {
    if (bool === false){ 
      setIsEdit(false);
    }
  }

  // Open modal window
  const handleModalEdit = ( data: string ) => {
    // before open modal remove form data
    dispatch(setReservationForm( {
      status: '',
      issueFine: null,
    } ));

    if ( data === intl.formatMessage({id:'button.change'}) ) {
      setIsEdit( true );
    } else if ( data === intl.formatMessage({id:'button.addFine'}) ) {
      addFine( reservationId );      
    }
  }

  // Receive from modal fine is added close modal 
  const handleEditReservation = ( bool: boolean ) => {
    setIsEdit( false );
  }

  // go to reservations table
  const handleHomePage = () => {
    navigate( `/admin/reservations`);
  }

  if ( localLoading ) {
    return <LoadingComponent />
  }

  return (
    <MDBox
      sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", width: "auto",
      display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "1rem"
      }}
    >
      <MDBox container
        sx={{
          pb: "1rem", display: "flex", paddingRight: "1rem",
          direction: "row", gap: "1rem", width: "100%", 
        }} >          

        <MDBox item width={7} xs={1.3}
          sx= {{
            display: "flex",    
            justifyContent: "flex-start",  
            width: "33%",                      
          }} >
          <MDButton 
            variant="outlined" 
            color="success" 
            onClick={handleHomePage}
            // onClick={ backUsersList }
            >
            {intl.formatMessage({id:'button.back'})}
          </MDButton>
        </MDBox>

      </MDBox>       
      
      <MDBox
        className= "containerReservations"
        sx={{
          width: "100%",
          }} >

          <MDBox sx={{ minWidth: "23rem", width: "auto", maxWidth: "28rem", pb: "1rem", pr: "1rem" }}>
            <ReservationInfo 
              onModalEdit={handleModalEdit}
            />          
          </MDBox>

          <MDBox sx={{ minWidth: "18rem", width: "auto", maxWidth: "20rem", pb: "1rem", pr: "1rem" }}>
            { ( reservation.fineStatus || reservation.fineCreatedAt ) && (
              <FineInfo />
            )}
          </MDBox>        

        { isEdit && (
          <EditReservation
            onCloseWindow={ handleCloseWindow }
            onEditReservation={ handleEditReservation }
          />
        )}

      </MDBox>
    </MDBox>
  )
}

export default injectIntl(ReservationDetail)
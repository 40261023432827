import React, { useState, useEffect, useMemo, JSXElementConstructor, Key, ReactElement } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "layouts/navigation/Sidenav";
import Configurator from "layouts/navigation/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React TS routes
import getProjectRoutes from "routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
// import brandWhite from "assets/images/kayakWhite.png";
import brandWhite from "assets/images/kajakyIcon.png";
// import brandDark from "assets/images/kayaky.png";
import brandDark from "assets/images/kajakyIcon.png";

// language
import { injectIntl, IntlShape } from "react-intl";

import { userIsLogged, expireDateToken, processLogout } from 'utils';

// import routes from "routes";

interface Props {
    intl: IntlShape,
}

const Admin = ( {intl}: Props ) => {

    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;

    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [rtlCache, setRtlCache] = useState(null);
    const { pathname } = useLocation();
    
    const routes = getProjectRoutes( {intl} );

    const isLogIn = userIsLogged();
    const validDateToken = expireDateToken();
    
    // Cache for the rtl
    useMemo(() => {
        const pluginRtl: any = rtlPlugin;
        const cacheRtl = createCache({
        key: "rtl",
        stylisPlugins: [pluginRtl],
        });

        setRtlCache(cacheRtl);
    }, []);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
        setMiniSidenav(dispatch, false);
        setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
        setMiniSidenav(dispatch, true);
        setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes: any): any =>
        allRoutes.map(
        (route: {
            collapse: any;
            route: string;
            component: ReactElement<any, string | JSXElementConstructor<any>>;
            key: Key;
            adress: any;
        }) => {
            if (route.collapse) {
            return getRoutes(route.collapse);
            }

            if ( route.route && isLogIn && validDateToken ) {
                return <Route path={route.route} element={route.component} key={route.key} />;
            }

            if ( !isLogIn && !validDateToken ) {
                processLogout()
                return <Route path="*" element={<Navigate to="/authentication/sign-in" />
            } /> } else if ( !validDateToken ) {
                processLogout()
                return <Route path="*" element={<Navigate to="/authentication/sign-in" state={{ token: 'expiredToken' }} />
            } /> } else if ( !isLogIn ) {
                processLogout()
                return <Route path="*" element={<Navigate to="/authentication/sign-in" state={{ token: 'invalidToken' }} />
            } /> }

            return null;
        }
    );

    const configsButton = (
        <MDBox
        className="configButtons"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3.25rem"
        height="3.25rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="2rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
        onClick={handleConfiguratorOpen}
        >
        <Icon fontSize="small" color="inherit">
            settings
        </Icon>
        </MDBox>
    );

    const getRoute = () => {
        return window.location.pathname !== "/admin/full-screen-maps";
      };

    return (
    
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {/* {layout === "dashboard" && ( */}
            { pathname.includes('admin') && isLogIn && validDateToken && (

            <div>
                <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  routes={routes}

                />
                 {/* <Configurator />
          {configsButton} */}
            </div>
            )}            

            {layout === "vr" && <Configurator /> }
            <Routes>
                {getRoutes( routes )}
                <Route index element={<Navigate to="/admin/users" />} />
            </Routes>

        </ThemeProvider>
    
      );
}

export default injectIntl(Admin);
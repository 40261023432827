import { combineReducers } from 'redux';

import { filterReducer, } from 'redux/reducers/tableReducer';

import { announcementReducer } from 'redux/reducers/announcementReducer';

import { usersReducer, userReducer, userLoggedReducer, } from 'redux/reducers/usersReducer';

import { 
    inventoryReducer, 
    itemsReducer, 
    itemReducer,
    itemsOrderReducer, 
} from 'redux/reducers/inventoryReducer';
import { containersReducer, containerReducer } from 'redux/reducers/containersReducer';
import { paymentsReducer, paymentsByUserReducer } from 'redux/reducers/paymentsReducer';
import { 
    ordersReducer, 
    orderReducer,
    ordersByUserIdReducer,
} from 'redux/reducers/ordersReducer';

import { reservationsReducer, reservationReducer } from 'redux/reducers/reservationsReducer';

import { finesReducer, } from 'redux/reducers/finesReducer';

import { pricesReducer, } from 'redux/reducers/priceReducer';

import { refreshReducer, } from 'redux/reducers/pageReducer';

export const reducers = combineReducers ({
    appAnnouncement: announcementReducer,

    allUsers: usersReducer,  
    userDetail: userReducer,
    userLogged: userLoggedReducer,

    allInventory: inventoryReducer,
    itemsInContainer: itemsReducer,
    itemsInOrder: itemsOrderReducer,
    item: itemReducer,

    containers: containersReducer,
    container: containerReducer,
    
    allPayments: paymentsReducer,
    allPaymentsByUser: paymentsByUserReducer,
    
    allOrders: ordersReducer,
    allOrdersByUser: ordersByUserIdReducer,
    orderDetail: orderReducer,

    allReservations: reservationsReducer,
    reservationDetail: reservationReducer,

    allFines: finesReducer,

    pricesList: pricesReducer,

    filters: filterReducer,

    handleRefresh:refreshReducer,
});

export default reducers;
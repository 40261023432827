import React from 'react'

import { injectIntl, IntlShape } from 'react-intl';

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


type Props = {
  intl: IntlShape,
  title: string,
  onCloseWindow: (bool: boolean) => void,
  onRemoveItem: (bool: boolean) => void,
}

const RemoveItem = ( { intl, title, onCloseWindow, onRemoveItem }: Props) => {

  const handleDelete = () => {    
    onRemoveItem( true )
  }

  const closeWindow = () => {
    onCloseWindow( false );
  }

  const handleTitle = () => {
    if ( title === 'user' ) {
      return `${intl.formatMessage({id:'user.remove'})}`;
    } else if ( title === 'fine' ) {
      return `${intl.formatMessage({id:'reservationDetailModal.fine'})}`;
    } else if ( title === 'announcement' ) {
      return `${intl.formatMessage({id:'appAnnouncement.remove'})}`;
    }
  }

  const handleBtnText = () => {
    if ( title === 'fine' ) {
      return `${intl.formatMessage({id:'button.add'})}`;
    } else {
      return `${intl.formatMessage({id:'button.delete'})}`;
    }
  }

  return (
    <Grid container sx={{ position: 'fixed', top: '0%', left: '0%',
      backgroundColor:"rgba( 0, 0, 0, 0.5 ) !important", 
      width:"100vw", height:"100vh", zIndex:"1300" }}>
      <Card 
        sx={ { 
          minWidth: '26rem',
          width: 'auto',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          // backgroundColor: "#5ada55",
          zIndex:"1300"
        } }
      >
        <MDBox 
          className='delete'
          sx={ {
            display: "flex",
            flexDirection: "column" ,
            justifyContent: "center" ,
            alignItems: "center" ,
            marginLeft: "0",
            // flexWrap: "wrap",
          } }
          >
          <MDBox p={3}>
            { handleTitle() }              
          </MDBox>
        
          <MDBox 
            sx={ { 
              display:"flex",
              paddingBottom:"1rem", 
              gap:"0.8rem"
              
            } } 
          >
            <MDButton 
              variant="outlined" 
              color="success" 
              size="small"
              onClick={closeWindow}
            >
              {intl.formatMessage({id:'button.cancel'})}
            </MDButton>

            <MDButton 
              variant="gradient" 
              color="error" 
              size="small"
              onClick={handleDelete}
            >
              { handleBtnText() }
            </MDButton>
          </MDBox>
          
        </MDBox>          
      </Card>
    </Grid>
  )
}

export default injectIntl(RemoveItem)
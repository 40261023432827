/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FormEvent, useState, useRef, useEffect } from "react";

import { injectIntl, IntlShape } from "react-intl";

import { apiClient } from "api-client";

import { useLocation } from "react-router-dom";


// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import DOMPurify from "dompurify";

import { useSnackbar } from "notistack";

import { IntlContext } from "index";

import '../../../css/language.css'

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";

type Props = {
  intl: IntlShape,
}

type Intlcontext = {
  locale?: string,
  messages?: Record<string, string>,
  selectedLanguage: string,
  handleLanguageChange: (newLocale: string) => {},
}

function SignInBasic( {intl}: Props ): JSX.Element {

  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();

  const error = location.state?.token;

  useEffect(() => {
    if ( error === 'expiredToken' ) {
      enqueueSnackbar(intl.formatMessage({id:'userAlert.signIn.expiredToken'}), { variant: 'error' });
    } else if ( error === 'invalidToken' ) {
      enqueueSnackbar(intl.formatMessage({id:'userAlert.signIn.invalidToken'}), { variant: 'error' });
    }      
  },[ error ]);


  const emailInput = useRef<HTMLInputElement>(null)

  const [isLogin, setIsLogin] = useState( true );

  const [email, setEmail] = useState('');

  const sendMagicLink = async (email: string): Promise<void> => {
    try {
      // Send a POST request to the API endpoint
      const response = await apiClient.post('/v1/auth/magiclink', {
        destination: email,
        target: "admin",
        callbackUrl: `${window.location.origin}/authentication/sign-in/magiclink`
        }
      );
      console.log('Magic link sent successfully!');
      // console.log(response);
      setIsLogin( false )
      // setIsLogout( false )
    
    } catch ( err: any ){    
      if ( !err?.response ) {
        enqueueSnackbar(intl.formatMessage({id:'userAlert.signIn.server'}), { variant: 'error' });
      } else if ( err.response?.status === 405 ) {
        enqueueSnackbar(intl.formatMessage({id:'userAlert.signIn.wrongRole'}), { variant: 'error' });
      } else {
        enqueueSnackbar(intl.formatMessage({id:'userAlert.signIn.failed'}), { variant: 'error' });
      }

    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
  
    let email = DOMPurify.sanitize( emailInput.current?.value );
    setEmail( email );   
  };

  const handleSubmit = async (e: FormEvent ) => {
    e.preventDefault();

    sendMagicLink( email );
  };

  const handleLanguage = ( lang: string ) => {
    localStorage.setItem( 'lang', lang );
  }

  return (
    // <BasicLayout image={bgImage}>
    <IntlContext.Consumer>{(intlContextProps: Intlcontext) => (
      <Card style={{ width: "23rem" }}>
        <MDBox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            { intl.formatMessage( {id: 'authentification.signIn'} ) }
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>

          { isLogin && (
          <MDBox 
            component="form" 
            role="form"
            onSubmit={handleSubmit}
            >
            <MDBox mb={2}>
              <MDInput
                type="email" 
                label={ intl.formatMessage( {id: 'authentification.email'} ) }
                fullWidth
                inputRef={emailInput}
                onChange={handleEmailChange} />
            </MDBox>
            
            <MDBox mt={4} mb={1}>
              <MDButton 
                variant="gradient" 
                color="success" 
                fullWidth
                type="submit">
                { intl.formatMessage( {id: 'authentification.signIn'} ) }
              </MDButton>
            </MDBox>
          
            {/* <MDBox className='languagesWrapper'
              sx={{ display:"flex", flexDirection:"row", width:"100%",
                  justifyContent:"center", gap:"0.5rem",}}
            >
              <MDTypography
                sx={{ cursor:"pointer" }}
                className={`${intlContextProps.selectedLanguage === 'sk' ? 'selected' : 'unselected'} language`}
                onClick={() => {
                  intlContextProps.handleLanguageChange('sk');
                  handleLanguage('sk')
                }}
              >
                SK
              </MDTypography>
              <MDTypography
                sx={{ cursor:"pointer" }}
                className={`${intlContextProps.selectedLanguage === 'en' ? 'selected' : 'unselected'} language`}
                onClick={() => {
                  intlContextProps.handleLanguageChange('en');
                  handleLanguage('en')
                }}
              >
                EN
              </MDTypography>
            </MDBox> */}
          </MDBox>
          ) }

          { !isLogin && (
          <MDBox 
            align= "center"
            >
            <MDBox mb={2}>
              <MDTypography fontWeight= "medium" >
                { intl.formatMessage( {id: 'userAlert.signIn.alert'} ) }
              </MDTypography>
            </MDBox>            
           
          </MDBox>
          ) }

          

        </MDBox>
      </Card>
  )}
  </IntlContext.Consumer>
)
}

export default injectIntl(SignInBasic);

import { ActionTypes } from "redux/actions/actionType";
import { PricesEquipmentType, PricesFinesType } from "types/types";
import { PricesFormType } from "types/typesRedux";


export const setPrices = ( pricesEquipment: PricesEquipmentType, pricesFines: PricesFinesType ) => {
  return {
      type: ActionTypes.SET_PRICES,
      payload: { pricesEquipment, pricesFines }
  }
};

export const setPricesForm = (finesName: PricesFormType ) => {
  return {
    type: ActionTypes.SET_PRICES_FORM,
    payload: finesName,
  };
};

export const deleteFormItem = ( 
    propertyName: string | number, 
    filterName: string | number,
    categoryIdToDelete: string | number,
  ) => ({
    type: ActionTypes.DELETE_PRICE_FORM,
    payload: propertyName, filterName, categoryIdToDelete
});

export const deleteFormItems = ( propertyName: string ) => ({
  type: ActionTypes.DELETE_PRICES_FORM,
  payload: propertyName,
}
); 

export const deleteForm = ( propertyName: number | string ) => ({
  type: ActionTypes.DELETE_FORM_ITEMS,
  payload: propertyName,
}
); 

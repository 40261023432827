import React, { useState, useEffect } from 'react'

import { useNavigate, useParams, } from "react-router-dom";

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// language
import { injectIntl, IntlShape } from 'react-intl';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setRefresh } from 'redux/actions/pageActions';
import { setContainer } from 'redux/actions/containersActions';
import { setItems } from 'redux/actions/inventoryActions';
// Functions
import { handleDeleteFilters } from 'context/tableContext';

// items table
import ItemsTable from 'pages/containers/ContainerDetail/itemsTable';

// components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import LoadingComponent from 'components/LoadingComponent';

// components-lib
import ContainerInfo from "components-lib/ContainerInfo";

// extracted data
import { extractDataContainer, extractDataItems } from 'common/extractData';

// types
import { ContainerType, ItemsInContainerType } from 'types/types';
import { ItemsContainerData, FilterState, Refresh } from 'types/typesRedux';

// css
import '../../../css/mediaQuery.css'


type Props = {
  intl: IntlShape,
}

const ContainerDetail = ( { intl, }: Props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  // redux hooks
  const items = useSelector( (state: ItemsContainerData) => state.itemsInContainer )
  const filters = useSelector( (state: FilterState) => state.filters);
  const refresh = useSelector( (state: Refresh) => state.handleRefresh);

  // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
  const storedRowLimit = parseInt(localStorage.getItem('rowLimitItemsInContainer'));
  const initialRowLimit = storedRowLimit && !isNaN(storedRowLimit) ? storedRowLimit : items.limit;

  const [ containerId, setContainerId ] = useState<number>(null);
  const tableItemsName = intl.formatMessage( {id: 'tableItems.title'} );
  const [ localLoading, setLocalLoading ] = useState<{ container: boolean; items: boolean }>({
    container: true,
    items: true, 
    }
  );
 
  // /*
  // */ Handle container
  //

  // Get container
  const getContainer = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source()
    try{
      const response = await apiClient.get(`/v1/containers/${id}`,{
        cancelToken:cancelToken.token
      });

      if (response) {
        const responseData = extractDataContainer(intl)(response.data);
        dispatch(setContainer( responseData as ContainerType ));
        setLocalLoading(prevLoading => ({
          ...prevLoading,
          container: false,
        }));
        // console.log(response.data)
      } else {
        console.log("No response received.");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("canceled")
      } else {
        // todo:handle error
      }
    }
    handleRefreshPage();
    return () => cancelToken.cancel();
  }
// category[name]:asc

const transformSort = (sort: string) => {
  if ( sort.includes('name') ) {
    // Split the string using ":" as the delimiter
    let parts = sort.split(":");
    // parts will be an array with two elements: ["name", "asc"]
    let propertyName = parts[0];
    let sortOrder = parts[1];
    return `category[${propertyName}]:${sortOrder}`;
  } else { 
    return sort }
}

  // Get Items in container
  const getItems = async ( id: number, params = {} ) => {
    const cancelToken = axios.CancelToken.source()
    const sortType = ( filters[`${tableItemsName} sort`] && 
    filters[`${tableItemsName} sort`].sort !== undefined ) 
    ? filters[`${tableItemsName} sort`].sort 
    : items.sort;

    const defaultParams = {
      offset: items ? items.offset : 0,
      limit: initialRowLimit,
      sort: transformSort( sortType )
    };

    // Merge provided params with default params
    const mergedParams = { ...defaultParams, ...params };

    try{
      const response = await apiClient.get(`/v1/containers/${id}/items`,{
        params: mergedParams,
        cancelToken:cancelToken.token
      });

      if (response) {
        const responseData = response.data.items.map((item: unknown) => extractDataItems(intl)(item as ItemsInContainerType));
        const totalCount = response.data.totalCount;
        dispatch(setItems( responseData, totalCount ));
        setLocalLoading(prevLoading => ({
          ...prevLoading,
          items: false,
        }));
      } else {
        console.log("No response received.");
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        // todo:handle error
      }
    } 
    handleRefreshPage();
    return () => cancelToken.cancel();
  }

  // If refresh is true set them to false
  const handleRefreshPage = () => {
    if ( refresh.refresh === true ) { dispatch(setRefresh( { refresh: false } )) };
    if ( refresh.refreshContainer === true ) { dispatch(setRefresh( { refreshContainer: false } )) };
    if ( refresh.refreshInventory === true ) { dispatch(setRefresh( { refreshInventory: false } )) };
    if ( refresh.refreshLanguage === true ) { dispatch(setRefresh( { refreshLanguage: false } )) };
  }

  // if have id get data
  useEffect(() => {
    let Id = parseInt(id)
    if (Id) {
      setContainerId( Id );
      getContainer( Id );
      getItems( Id )
    }
  },[id]);


  useEffect(() => {
    if ( refresh.refresh === true ) {
      getItems( containerId, filters[tableItemsName] );
    }
  }, [ refresh.refresh ])

  // Refresh when change language
  useEffect(() => {
    if ( refresh.refreshLanguage === true ) {
      getItems( containerId, filters[tableItemsName] );
      getContainer( containerId );
    }
  },[ refresh.refreshLanguage ] )
  
  useEffect(() => {
    if ( refresh.refreshContainer === true ) {
      getContainer( containerId );
    }
  }, [ refresh.refreshContainer ])

  useEffect(() => {
    if ( refresh.refreshInventory === true ) {
      getItems( containerId );
    }
  }, [ refresh.refreshInventory ])

  // go to containers table
  const handleBtnBack = () => {
    handleDeleteFilters( dispatch, tableItemsName)
  }

  return (
    <>
      <MDBox
        sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", width: "auto",
        display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "1rem"
        }}
      >
        <MDBox item width={7} xs={1.3} 
          sx= {{ display: "flex", justifyContent: "flex-start", width: "100%", pb: "1rem",}} 
        >
          <MDButton 
            variant="outlined" 
            color="success" 
            onClick={() => { handleBtnBack(); navigate(-1) }}
          >
            {intl.formatMessage({id:'button.back'})}
          </MDButton>
        </MDBox>

        <MDBox 
          className= "containerContainer"
          sx={{ 
           width:"100%", gap:"1rem" 
          }}>
          { ( localLoading.container ) ? (
          <LoadingComponent /> ) : (
            <MDBox
              sx={{  
                minWidth: "20rem",     
                width: "28%",
                maxWidth: "24rem",
                pb: "1rem",
              }}>
              <ContainerInfo />     
            </MDBox>
            )} 

          { ( localLoading.items ) ? (
          <LoadingComponent /> ) : (
            <MDBox
              sx={{       
                minWidth: "30rem",     
                width: "auto",
                maxWidth: "35rem",
                pb: "1rem",
              }}>
              <ItemsTable 
                initialRowLimit={initialRowLimit}
              />
            </MDBox>
          )} 

        </MDBox>      

      </MDBox>
    </>
  )
}

export default injectIntl(ContainerDetail)
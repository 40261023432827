import { ActionTypes } from "redux/actions/actionType";
import { ReservationsTableType, ReservationExtractData, ReservationForm } from "types/types";

export const setReservations = ( reservations: ReservationsTableType, totalCount: number ) => {
    return {
        type: ActionTypes.SET_RESERVATIONS,
        payload: { reservations, totalCount }
    }
};

export const setReservationsById = ( reservation: ReservationExtractData ) => {
    return {
        type: ActionTypes.SET_RESERVATION_BY_ID,
        payload: reservation,
    }
};

export const setReservationsOffset = ( offsetChange: number )  => {
    return {
        type: ActionTypes.SET_RESERVATIONS_OFFSET,
        payload: { offset: offsetChange }
    }
};
  
export const setReservationsLimit = ( limitChange: number ) => {
    return {
        type: ActionTypes.SET_RESERVATIONS_LIMIT,
        payload: { limit: limitChange }
    }                    
};

export const setReservationForm = ( updatedReservationForm: ReservationForm ) => {
    return {
      type: ActionTypes.SET_RESERVATION_FORM,
      payload: updatedReservationForm,
    };
  };

import React, { useState, useEffect, useRef, ChangeEvent } from 'react'

import { useNavigate, useParams } from "react-router-dom";

import axios from 'axios';

import { apiClient } from "api-client"

import { injectIntl, IntlShape } from 'react-intl';

import { useSnackbar } from "notistack";

import LoadingComponent from 'components/LoadingComponent';

import '../../../css/editUser.css';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setUserForm } from 'redux/actions/usersActions';
import { deleteAllFilters } from 'redux/actions/tableActions';

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "components/FormField";

// types
import { UserEditData, UserFormData } from 'types/typesRedux';


type Props = {
  intl: IntlShape,
}

const UserEdit = ({ intl, }: Props) => {

  const navigate = useNavigate();

  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const user = useSelector( (state: UserEditData) => state.userDetail.user )  
  const userForm = useSelector( (state: UserFormData) => state.userDetail.userForm )  

  const nameInput = useRef<HTMLInputElement>(null);
  const surnameInput = useRef<HTMLInputElement>(null);
  const addressInput = useRef<HTMLInputElement>(null);
  const cityInput = useRef<HTMLInputElement>(null);
  const postalInput = useRef<HTMLInputElement>(null);

  const [localLoading, setLocalLoading] = useState<boolean>(true);

  const [ userId, setUserId ] = useState<number>(null);

  // /*
  // */ Handle user 
  //

  // Get user

  const getUser = async ( id: number ) => {

    const cancelToken = axios.CancelToken.source()

    try {
      const response = await apiClient.get(`/v1/users/${id}`,{
        cancelToken:cancelToken.token
      });
      setLocalLoading( true );

      if (response) {
        dispatch(setUser( response.data ));
        setLocalLoading( false );

        dispatch(setUserForm( {
          firstname: response.data.firstName,
          surname: response.data.lastName,
          street: response.data.address,
          city: response.data.city,
          postal: response.data.postal,
       } ));
        // console.log( response.data )
      }
    } catch ( error ){
      console.log( 'err' )
    }

    return () => cancelToken.cancel()
  }   

  // Edit user
  const editUser = async ( ) => {
    const cancelToken = axios.CancelToken.source()

    try {
      await apiClient.patch(`/v1/users/${userId}`,{
        firstName: userForm.firstname,
        lastName: userForm.surname,
        address: userForm.street,
        city: userForm.city,
        postal: userForm.postal,

        cancelToken:cancelToken.token
        }
      ).then( ( response ) => {
        if ( response ) {
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.editUser.true'})}`, { variant: 'success' });
        dispatch(deleteAllFilters());
        handleProfilePage();
      }
    });
    } catch ( error ){
        console.log( 'err' )
        enqueueSnackbar(`${intl.formatMessage({id:'userAlert.editUser.false'})}`, { variant: 'error' });
      }

    return () => cancelToken.cancel()
  }

  // submit btn edit, edit user and go to profile
  const handleEdit = () => {
    if ( userForm.firstname && userForm.surname && userForm.street && userForm.city && userForm.postal ) {
      editUser();
    } else {
      enqueueSnackbar(`${intl.formatMessage({id:'userAlert.editUser.emptyField'})}`, { variant: 'error' });
    }
  }

  // after open page user edit set id and get user data from api
  useEffect(() => {
    let userId = parseInt(id)
    setUserId( userId )
    getUser( userId )
  },[id]);


  const handleProfilePage = () => {
    let id = userId;
    navigate( `/admin/users/user-profile/${userId}`, {state:{ id }} )
  }

  // set data from inputs to redux
  const handleInputUser = (inputName: string, event:  React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    dispatch(setUserForm( {
        ...userForm,
        [inputName]: value
     } ));
  };

  if ( localLoading ) {
    return <LoadingComponent />
  }

  return (
    <MDBox         
      sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", marginTop: "1rem",
      marginBottom: "1rem", width: "min-content", minWidth: "30%", height: "100vh",
      display: "flex", flexDirection: "column", justifyContent: "center",      
      }} 
    >
        
      <Card id="basic-info" 
        sx={{ overflow: "visible", width: "100%", }} 
      >
        <MDBox p={3}>
          <MDTypography variant="h5">
            {intl.formatMessage({id:'userEdit.personalInfo'})}
          </MDTypography>
        </MDBox>      

        <MDBox container pb={3} px={3}>
          <Grid container spacing={1}>           

            <Grid item xs={8} md={8}>
              <MDTypography variant="button" className="title-input">
                {intl.formatMessage({id:'userProfile.firstname'})}
              </MDTypography>
              <FormField 
                value={ userForm.firstname ?? userForm.firstname }
                // placeholder={ user.firstName ? user.firstName : '-' }
                inputRef={nameInput}
                onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handleInputUser('firstname', event)}
                className="input"
              />
            </Grid>
        
            <Grid item xs={8} md={8} >
              <MDTypography variant="button" className="title-input" >
                {intl.formatMessage({id:'userProfile.surname'})}
              </MDTypography>
              <FormField 
                value={ userForm.surname ?? userForm.surname }
                // placeholder={ user.lastName ? user.lastName : '-' }
                inputRef={surnameInput}
                onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handleInputUser('surname', event)}
                className="input"
              />
            </Grid>
            
            {/* <Grid item xs={8} md={8} >
              <MDTypography variant="button" className="title-input" >
                {intl.formatMessage({id:'userProfile.surname'})}
              </MDTypography>
              <FormField 
                placeholder={ user.lastName ? user.lastName : '-' }
                inputRef={surnameInput}
                onChange={handleInputUser}
              />
            </Grid> */}

            <Grid item xs={8} md={8} >
              <MDTypography variant="button" className="title-input" >
                {intl.formatMessage({id:'userProfile.street'})}
              </MDTypography>
              <FormField 
                name="Ulica"
                value={ userForm.street ?? userForm.street }
                // placeholder={ user.address ? user.address : '-' }
                inputProps={{ type: "Ulica" }}
                inputRef={addressInput}
                onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handleInputUser('street', event)}
                className="input"
              />
            </Grid>

            <Grid item xs={12} md={12} 
              sx={{ display:"flex", flexDirections:"row", gap:"2rem", }}
            >
              <Grid item xs={6} md={6} >
                <MDTypography variant="button" className="title-input" >
                  {intl.formatMessage({id:'userProfile.city'})}
                </MDTypography>
                <FormField 
                  name="Mesto"
                  value={ userForm.city ?? userForm.city }
                  // placeholder={ user.city ? user.city : '-' }
                  inputProps={{ type: "Mesto" }}
                  inputRef={cityInput}
                  onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handleInputUser('city', event)}
                  className="input"
                />
              </Grid>
            
              <Grid item xs={6} md={6} >
                <MDTypography variant="button" className="title-input" >
                  {intl.formatMessage({id:'userProfile.postal'})}
                </MDTypography>
                <FormField 
                  name="PSČ"
                  value={ userForm.postal ?? userForm.postal }
                  // placeholder={ user.postal ? user.postal : '-' }
                  inputProps={{ type: "PSČ" }}
                  inputRef={postalInput}
                  onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handleInputUser('postal', event)}
                  className="input"
                />
              </Grid>
            </Grid>                  
            
          </Grid>
        </MDBox>

        <MDBox container 
          sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", 
              gap: "1rem", paddingRight: "1rem", paddingBottom: "1rem",
          }}       
        >   
          <MDButton 
            variant="outlined" 
            color="success" 
            sx={{ 
              height: "50%",                
          }}    
            onClick={() => navigate(-1)}              
          >
            {intl.formatMessage({id:'button.back'})}
          </MDButton>        

          <MDButton 
            variant="gradient" 
            color="success" 
          //   sx={{ height: "50%",
          //     visibility: isVisible ? "visible" : "hidden", 
          //   }}
            onClick={handleEdit}
          >
            {intl.formatMessage({id:'button.change'})}
          </MDButton>

        </MDBox>

      </Card>
    </MDBox>
  )
}

export default injectIntl(UserEdit);

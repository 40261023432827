
import { Theme } from "@mui/material/styles";

import { useSelector } from 'react-redux';

import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import MDBox from "components/MDBox";

// types
import { FilterState } from 'types/typesRedux';

interface Props {
  tableName: string,
  align: "left" | "center" | "right",
  inputLabel: string,
  columnId: string,
  activeColumn: string,
  sortDirection: string,

  onAscDesc: ( column: string, sort: 'asc' | 'desc' ) => void,
  onClearDateTime?: ( bool: boolean, column: string | number ) => void;
  onDateRangePicker: ( bool: boolean, column: string ) => void;
}

const TitleDatum = ({
  tableName, 
  align, 
  inputLabel, 
  columnId, 
  activeColumn, 
  sortDirection,

  onAscDesc,
  onClearDateTime,
  onDateRangePicker,
}: Props) => {

  const filterState = useSelector((state: FilterState) => state.filters);

  const handleClearFilter = ( column: string ) => {
    onClearDateTime( true, column );  
  }
  
  const handleDatePicker = ( column: string ) => {
    onDateRangePicker( true, column )
  }


  return (
    <MDBox
    className={inputLabel}
    display="flex" flexDirection="row"
    right={align !== "right" ? "16px" : 0}
    left={align === "right" ? "-5px" : "unset"}
    sx={({ typography: { size } }: any) => ({
      fontSize: size.lg,
      width: "100%",
    })}
    >
      <MDBox
        component="th"
        py={1.5}
        sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,      
        position: "relative",
        textAlign: "left",
        opacity: "0.7",
        background: "transparent",
        color: "#7b809a",
        fontSize: "0.75rem",
        fontWeight: "700",
        wordWrap:"unset",
        width: "100%",
        height: "100%",
        paddingLeft: "0.5rem",
        })}
      >
        { inputLabel }
      </MDBox>

        { ( filterState[tableName] !== undefined && ( filterState[tableName][`${columnId}[lte]`] || filterState[tableName][`${columnId}[gte]`]) ) && ( 
      <IconButton 
        sx={{ paddingLeft: "0rem", paddingRight: "0.5rem", }}
        edge="end" onClick={() => handleClearFilter( columnId )}>
        <ClearIcon>clear_icon_component</ClearIcon>
      </IconButton>
    )} 

    <MDBox 
      sx={{  
        height: "inherit", display: "flex", 
        alignItems: "center", justifyContent: "center", paddingLeft: "0.3rem", opacity: "0.7",
        "&:hover": {
          opacity: 1,
          cursor: "pointer",
        },
        }} >
      <IconButton 
        onClick={ () => handleDatePicker( columnId )}
        sx={{ 
          cursor: "pointer", padding: "0", 
        }}>
        <CalendarTodayIcon sx={{ cursor: "pointer" }} />
      </IconButton>
    </MDBox>  
   
    <MDBox
      sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "0rem",
      lineHeight: "0rem",
      scale: '0.9',
      }}
    >
      <MDBox
        sx={{ '&:hover': { opacity: 1, }, }}
        color={activeColumn === String(columnId) && sortDirection === 'asc' ? 'text' : 'secondary'}
        opacity={activeColumn === String(columnId) && sortDirection === 'asc' ? 1 : 0.5}
        style={{ cursor: 'pointer' }}
        onClick={() => onAscDesc(String(columnId), 'asc')}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
        </svg>
      </MDBox>
      <MDBox
        sx={{ '&:hover': { opacity: 1, }, }}
        color={activeColumn === String(columnId) && sortDirection === 'desc' ? 'text' : 'secondary'}
        opacity={activeColumn === String(columnId) && sortDirection === 'desc' ? 1 : 0.5}
        style={{ cursor: 'pointer' }}
        onClick={() => onAscDesc(String(columnId), 'desc')}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
        </svg>
      </MDBox>
    </MDBox>

  </MDBox>
  )
};

export default TitleDatum;
import { ActionTypes } from "redux/actions/actionType";

// types
import { 
    FilterState,
} from "types/typesRedux";


export const setInputFilters = ( filter: FilterState ) => {
    return {
        type: ActionTypes.SET_FILTERS,
        payload: filter,
    }
}

export const deleteFilter = ( propertyName: string, filterName: string | number ) => ({
    type: ActionTypes.DELETE_FILTER,
    payload: propertyName,
    filterName,
});

export const deleteFilters = ( propertyName: string ) => ({
    type: ActionTypes.DELETE_FILTERS,
    payload: propertyName,
  }
);   

export const deleteAllFilters = () => ({
    type: ActionTypes.DELETE_ALL_FILTERS,
  }
);

export const resetOffset = () => ({
    type: ActionTypes.RESET_OFFSET,
});


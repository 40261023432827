
import React from 'react';

import { useMemo, useEffect, useState, useCallback, useRef, useContext } from "react";

// react-table components
import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";

// lodash library
import _debounce from 'lodash/debounce';

// @mui material components
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";

// icons
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import PhotoIcon from '@mui/icons-material/Photo';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "components/reactTable/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/reactTable/DataTable/DataTableBodyCell";

// language
import { injectIntl, IntlShape } from 'react-intl';

// types
import { ButtonConfig } from 'types/tableTypes';

// css
import '../../../css/DataTable.css';

// Declaring props types for DataTable
interface Props {
  intl: IntlShape;
  entriesPerPage?:
    | false
    | {
        defaultValue: number;
        entries: number[];
      };
  showTotalEntries?: boolean;
  tableColumns: { [key: string]: any }[];
  tableRows: { [key: string]: any }[];
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  noEndBorder?: boolean;

  title?: string;
  button?: ButtonConfig[];
  isPicture?: string[] | null[] | undefined;
  onAddItem?: ( bool: boolean ) => void;
  onBlockItem?: ( bool: boolean, id: number ) => void;
  onRemoveBtn?: ( bool: boolean, id: number ) => void;
  onEditBtn?: ( id: number ) => void;
  onDetailItem?: ( id: number ) => void;
  onPictureItem?: ( photoUrl: string ) => void;
  onDateRangePicker?: ( bool: boolean, column: string ) => void;
  
  pagePagination?: boolean,
  rowLimit: number;
  totalCount: number;
  actualPage: number;
  numberOfPages: number;
  newInputPage: number;
  activeColumn:string; 
  sortDirection:string; 
  onRowLimit: ( num: number ) => void;
  onNextPage: ( boolValue: boolean ) => void;
  onPreviousPage: ( boolValue: boolean ) => void;
  onBtnPage: ( key: number ) => void;
  onSortColumn: ( column: string, ascDesc: string ) => void;
  onInputPage: ( num: number ) => void;
  onInputBounce: ( bool: boolean ) => void;
  onSearch: ( search: string | number, column: string, label: string ) => void;
  onAutocomplete: ( label: string | number, column: string ) => void;
  onClearInput: ( bool: boolean, column: string | number ) => void; 
  onClearDateTime: ( bool: boolean, column: string | number ) => void; 
  onClearInputs: ( bool: boolean ) => void; 
  onEnterTrue: ( bool: boolean ) => void;
}

function DataTable({
  intl,
  entriesPerPage,
  showTotalEntries,
  pagination,
  noEndBorder,
  tableColumns,
  tableRows,
  title,
  button,
  isPicture,
  onAddItem,
  onBlockItem,
  onRemoveBtn,
  onEditBtn,
  onDetailItem,
  onPictureItem,
  onDateRangePicker,

  pagePagination,
  rowLimit,
  totalCount,
  actualPage,
  numberOfPages,
  newInputPage,
  activeColumn, 
  sortDirection, 
  onRowLimit,
  onNextPage,
  onPreviousPage,
  onBtnPage,
  onSortColumn, 
  onInputPage,
  onInputBounce,
  onSearch,
  onAutocomplete,
  onClearInput,
  onClearDateTime,
  onClearInputs,
  onEnterTrue,
}: Props): JSX.Element {
  let defaultValue: number;
  let entries: number[];

  if (entriesPerPage) {
    defaultValue = rowLimit ;
    entries = entriesPerPage.entries ? entriesPerPage.entries : [10, 25, 50, 100];
  }

  const columns = useMemo<any>(() => tableColumns, [tableColumns]);
  const data = useMemo<any>(() => tableRows, [tableRows]);
 
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    setPageSize,
    setGlobalFilter,
    state: { pageSize, globalFilter },
  }: any = tableInstance;

  const pageOptions: any = []  

  for (let i = 0; i <= numberOfPages-1; i++) {
    pageOptions.push(i);
  }

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: number) => {setPageSize(value);
  }

  // Render the paginations
  const renderPagination = pageOptions.map((option: number) => (
    <MDPagination
      item
      className={ option }
      key={ option }
      onClick={
        ( (e: React.MouseEvent<HTMLButtonElement>) => handleBtnNumber( e, option ) )
      }
      active={ ( actualPage  ) === option }
      // style={{ backgroundColor: actualPage === option ? "#4CAF50" : "initial" }}
    >
      { option + 1 }
    </MDPagination>     

  ));

  const handleBtnNumber = ( e: React.MouseEvent<HTMLButtonElement>, key: number ) => {
    e.preventDefault();
    onBtnPage( key );
  }

  // React.ChangeEvent<HTMLInputElement>
  // Search input value state
  // const [search, setSearch] = useState(globalFilter);

  // // Search input state handle
  // const onSearchChange = useAsyncDebounce((value: any) => {
  //   setGlobalFilter(value || undefined);
  // }, 100);

  // Setting the entries starting point
  const entriesStart = actualPage === 0 ? actualPage + 1 : (+actualPage) * (+rowLimit) + 1 ;

  // Setting the entries ending point
  let entriesEnd;

  if (actualPage === 0) {
    entriesEnd = (+rowLimit);
  } else if ( (+actualPage +1 ) === pageOptions.length ) {
    entriesEnd = totalCount;      
  } else {
    entriesEnd = (+rowLimit) * (actualPage + 1);      
  }

  // after change row limit send to parent number
  const getRowLimit = ( limit: number ) => {
    onRowLimit(limit)
  }  
  
  const SubmitNextPage = () => {
    onNextPage( true )
  }
  
  const SubmitPreviousPage = () => {
    onPreviousPage( true )
  }

  const toEntries = ( entries: number ) => {
    return entries
  }
 
  // 
  // Handle input value page pagination
  // 
  
  const inputValue = useRef<HTMLInputElement>(null)
    
  const [ pageValue, setPageValue ] = useState(null)

  // send parent new value from input page pagination  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement> ) => {
    const inputvalue = event.target.value;
    // If input is number, add a new item or update the item
    if (/^\d*\.?\d*$/.test( inputvalue )) {
      let newValue = inputvalue === "" ? 1 : parseInt(inputvalue, 10);;

      onInputPage(newValue);
    }
  };

  const debounceFunction = useMemo(
    () => _debounce((pageValueParam) => {
      onEnterTrue( true );
      setPageValue(null)
    }, 500),
    []
  );

  useEffect(() => {
    if ( pageValue !== null ) {
        debounceFunction( pageValue )
    }
  },[ pageValue ] )


  // 
  // Handle icons click
  // 

  // If click icon add send parent true
  const handleClickAdd= () => {
    onAddItem( true )
  }

  // If click icon block send parent true and id of item
  const handleClickBlock = ( id: string ) => {
    let itemId = parseInt( id );
    onBlockItem( true, itemId );
  }
 
  // If click icon delete send parent true and id of item
  const handleClickRemove= ( id: string ) => {
    let itemId = parseInt( id )
    onRemoveBtn( true, itemId )
  }
      
  // If click icon edit send parent id of item
  const handleEditItem= ( id: number ) => {
    onEditBtn( id )
  }
   
  // If click icon detail send parent id of item
  const handleDetailItem = ( id: number ) => {
    onDetailItem( id )
  }
 
  // If click icon picture send parent url of item
  const handleClickPicture = ( photoUrl: string ) => {
    onPictureItem( photoUrl )
  }


  // 
  // handle style of pagination layout
  // 

  // styles
  const [flexDirection, setFlexDirection] = useState('row');

  // Handle style of pagination
  const tableRef = useRef<HTMLDivElement>(null);
  const customStyle = tableRef.current?.offsetWidth;

  useEffect(() => {
    setFlexDirection(handlePaginationOption());
  }, [customStyle, pageOptions]);

  const handlePaginationOption = () => {
    if ( pageOptions.length > 3 && pageOptions.length <= 6 && customStyle < 590 ) {
      return "column";
    } else if ( pageOptions.length > 6 && customStyle > 425 && customStyle < 512 ) {
      return 'column';
    } else {
      return customStyle < 512 ? 'column' : 'row';
    }
  }

  // useEffect(() => {
    // const checkWidth = () => {       
    //   changeStyle(`${title}`, tableRef.current?.offsetWidth);
    // };

    // if (tableRef.current) checkWidth(); 
    // window.addEventListener('resize', checkWidth);

    // return () => {
    //     window.removeEventListener('resize', checkWidth);
    // };
  // }, []);

  return (

    <TableContainer sx={{ boxShadow: "none", paddingX: "0.5rem" }}
    ref={tableRef}
    > 

      {/* Table header      */}
      <Table {...getTableProps()}>
        <MDBox component="thead"
        >
          {headerGroups.map((headerGroup: any, key: string) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>

              {headerGroup.headers.map((column: any, key: any) => (

                <DataTableHeadCell
                  key={key}
                  tableName={ title }
                  // {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  {...column.getHeaderProps( column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  minWidth={column.minWidth ? column.minWidth : "auto"}                  
                  maxWidth={column.maxWidth ? column.maxWidth : "auto"}                  
                  align={column.align ? column.align : "left"}               
                  activeColumn={activeColumn}
                  sortDirection={sortDirection}
                  onSortColumn={ onSortColumn }
                  onClearInput={ onClearInput }
                  onClearDateTime={ onClearDateTime }
                  onClearInputs={ onClearInputs }
                  onInputBounce={ onInputBounce }
                  onSearch={ onSearch }
                  onAutocomplete={ onAutocomplete }
                  onDateRangePicker={ onDateRangePicker }
                >
                  {column}
                  
                </DataTableHeadCell>
                       
              ))}
            </TableRow>
          ))}
        </MDBox>


        <TableBody {...getTableBodyProps()} >
          
          {page.map((row: any, key: any) => {
            prepareRow(row);
            return (
              <TableRow
                className=""
                key={key}
                {...row.getRowProps()}
              >
            {row.cells.map((cell: any, key: any) => (
              <DataTableBodyCell
                key={key}
                noBorder={noEndBorder && rows.length - 1 === key}
                align={ cell.column.align ? cell.column.align : 'left' }
                {...cell.getCellProps()}
                data-row-index={key}
                style={{ 
                  align: 'center',
                }}
                cell={cell}
              >

                {/* Render different content based on the column name */}
                  {cell.column.id === 'Action' ? (
                    <>                
                      <MDBox 
                        position="relative" padding="0rem 0.7rem 0rem 0rem" 
                        top="0"
                        marginLeft="-0.4rem"
                      >
                          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">

                            { Array.isArray(button) && button[0].detail === true && (
                              <MDBox position="relative" align="left" padding="0rem 0rem 0rem 0rem" top="0">                               
                                <Tooltip title={ intl.formatMessage( {id: 'button.detail'} ) }>
                                  <IconButton 
                                    aria-label="Detail" 
                                    size="small"
                                    onClick={ (e) => handleDetailItem(row.values.id) }
                                  >
                                    <ArrowForwardIcon />
                                  </IconButton>
                                </Tooltip>
                              </MDBox> 
                            )}

                            { Array.isArray(button) && button[0].edit === true && (
                              <MDBox position="relative" align="left" padding="0rem 0rem 0rem 0rem" top="0">                               
                                <Tooltip title={ intl.formatMessage( {id: 'button.change'} ) }>
                                  <IconButton 
                                    aria-label="Edit" 
                                    size="small"
                                    onClick={ (e) => handleEditItem( row.values.id ) }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </MDBox> 
                            )}
                            
                            { Array.isArray(button) && button[0].block === true && (
                              <MDBox position="relative" align="left" padding="0rem 0rem 0rem 0rem" top="0">                               
                                <Tooltip title={intl.formatMessage( {id: 'button.blockUser'})}>
                                  <IconButton 
                                    aria-label="Block" 
                                    size="small"
                                    onClick={ (e) => handleClickBlock(row.values.id)}
                                  >
                                    <LockIcon />
                                  </IconButton>
                                </Tooltip>
                              </MDBox>
                            )}

                            { Array.isArray(button) && button[0].delete === true && (
                              <MDBox position="relative" align="left" padding="0rem 0rem 0rem 0rem" top="0">                               
                                <Tooltip title={intl.formatMessage( {id: 'button.delete'})}>
                                  <IconButton 
                                    aria-label="Remove" 
                                    size="small"
                                    onClick={ (e) => handleClickRemove(row.values.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </MDBox> 
                            )}
                            
                            { Array.isArray(button) && button[0].picture === true && 
                              cell.row.values.name === intl.formatMessage({ id: 'tableInventory.kayak' }) && 
                              ( 
                              <MDBox position="relative" align="left" padding="0rem 0rem 0rem 0rem" top="0">                               
                                <Tooltip title={intl.formatMessage( {id: 'button.picture'})}>
                                  <IconButton 
                                    aria-label="Picture"
                                    size="small"
                                    onClick={ (e) => handleClickPicture(row.original.photoUrl)}
                                  >
                                    <PhotoIcon />
                                  </IconButton>
                                </Tooltip>
                              </MDBox> 
                            )}

                          </Stack>
                        </MDBox>
                    </>
                ) : cell.value === `${intl.formatMessage({id:'tableUsers.blockedAt.null'})}` ||
                    cell.value === `${intl.formatMessage({id:'tableContainers.status.online'})}`
                ? (
                  <MDBox sx={{ display:"flex", flexDirection:"row", gap:"0.5rem", }} >
                    <MDBox style={{ color: "#4CAF50" }} >
                      &#x25cf;
                    </MDBox>
                    <MDBox sx={{ color: '#7b809a' }} >
                      {cell.value}
                    </MDBox>
                  </MDBox>
                ) : cell.value === `${intl.formatMessage({id:'tableUsers.blockedAt.block'})}` ||
                    cell.value === `${intl.formatMessage({id:'tableContainers.status.offline'})}`
                 ? (
                  <MDBox sx={{ display:"flex", flexDirection:"row", gap:"0.5rem", }} >
                    <MDBox style={{ color: "#FB8C00" }} >
                      &#x25cf;
                    </MDBox>
                    <MDBox style={{ color: "#7b809a" }} >
                      {cell.value}
                    </MDBox>
                  </MDBox>
                ) : cell.value === `Loď` && isPicture && !isPicture[0]
                  ? (
                    <MDBox sx={{ display:"flex", flexDirection:"row", gap:"0.5rem", }} >
                      <MDBox style={{ color: "#FB8C00" }} >
                        &#x25cf;
                      </MDBox>
                      <MDBox style={{ color: "#7b809a" }} >
                        {cell.value}
                      </MDBox>
                    </MDBox>
                  ) : (
                  // Render cell content for other columns
                  cell.render('Cell')
                )}
              
              </DataTableBodyCell>
            ))}
          </TableRow>
            );
          })}
        </TableBody>
      </Table>

      { pagePagination ? (
      <MDBox
        display="flex"
        // flexDirection={ { xs: "column", sm: "row" } }
        // sx={{ flexDirection: customStyle ? 'column' : "row" }}
        sx={{ flexDirection: flexDirection }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox
            sx={{ marginBottom: customStyle ? '1.0rem' : '0px' }}
          >
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              { intl.formatMessage( {id: 'table.showing'} ) }&nbsp;
                { (entriesStart > totalCount ) ? totalCount : entriesStart }
                &nbsp;{ intl.formatMessage( {id: 'table.to'} ) }&nbsp;
                { (entriesEnd > totalCount ) ? totalCount : entriesEnd } 
                <span> </span>{ intl.formatMessage( {id: 'table.of'} ) }&nbsp;{totalCount}&nbsp;{ intl.formatMessage( {id: 'table.items'} ) }
            </MDTypography>
          </MDBox>
        )}
 
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            { actualPage >= 1 && newInputPage >= 1 && (
              <MDPagination item onClick={() => {
                SubmitPreviousPage()}}>
                <ArrowBackIosNewIcon sx={{ fontWeight: "bold" }}>chevron_left</ArrowBackIosNewIcon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="3rem" mx={1}>
                <MDInput
                  inputRef={ inputValue }
                  value={ newInputPage }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event)
                    setPageValue( event.target.value )
                  }}   
                  // onKeyDown={handleKeyPress}
                />
              </MDBox>
            ) : (
              renderPagination
            )}            
            {/* {canNextPage && ( */}
            { pageOptions.length > 1 && ( (actualPage + 1) < pageOptions.length ) &&(
              <MDPagination item 
                onClick={() => {
                  SubmitNextPage()} }
              >
                <ArrowForwardIosIcon sx={{ fontWeight: "bold" }}>chevron_right</ArrowForwardIosIcon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>

                
      ) : <MDBox sx={{ height:"2rem"}}> </MDBox> }   

      { ( entriesPerPage ) && pagePagination ? (
        <MDBox display="flex" justifyContent="space-between" 
              alignItems="center" px={3} pb={3} pt={1}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                  getRowLimit(newValue)
                  toEntries(newValue)
                  // countOfPages(newValue)
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;{ intl.formatMessage( {id: 'table.itemsPerPage'} ) }
              </MDTypography>
            </MDBox>
          )}

          { Array.isArray(button) && button[0].add && (
            <MDButton
                variant="contained"
                color="success"
                onClick={ handleClickAdd }
                >
                {button[0].add}
            </MDButton>
          )}
        </MDBox>
      ) : null }

    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [ 10, 25, 50 ] },
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "success" },
  noEndBorder: false,
} as Props;

export default injectIntl(DataTable);

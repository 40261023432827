/* eslint-disable import/prefer-default-export */

import {
  addDays,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
} from 'date-fns';

import { IntlShape } from 'react-intl';

export const getDefaultRanges = (date: Date, dateEndDay: Date, intl: IntlShape): any => [
  {
    label: intl.formatMessage({id:'datePicker.today'}),
    startDate: date,
    endDate: dateEndDay,
  },
  {
    label: intl.formatMessage({id:'datePicker.yesterday'}),
    startDate: addDays(date, -1),
    endDate: addDays(dateEndDay, -1),
  },
  {
    label: intl.formatMessage({id:'datePicker.thisWeek'}),
    startDate: startOfWeek(date),
    endDate: endOfWeek(dateEndDay),
  },
  {
    label: intl.formatMessage({id:'datePicker.lastWeek'}),
    startDate: startOfWeek(addWeeks(date, -1)),
    endDate: endOfWeek(addWeeks(dateEndDay, -1)),
  },
  {
    label: intl.formatMessage({id:'datePicker.last7days'}),
    startDate: addWeeks(date, -1),
    endDate: dateEndDay,
  },
  {
    label: intl.formatMessage({id:'datePicker.thisMonth'}),
    startDate: startOfMonth(date),
    endDate: endOfMonth(dateEndDay),
  },
  {
    label: intl.formatMessage({id:'datePicker.lastMonth'}),
    startDate: startOfMonth(addMonths(date, -1)),
    endDate: endOfMonth(addMonths(dateEndDay, -1)),
  },
];

// export const defaultRanges = getDefaultRanges(new Date());



export const ActionTypes = {

    SET_ANNOUNCEMENT: "SET_ANNOUNCEMENT",
    SET_ANNOUNCEMENT_FORM: "SET_ANNOUNCEMENT_FORM",
    
    SET_LOGGED_USER: "SET_LOGGED_USER",
    SET_USERS: "SET_USERS",
    SET_USER: "SET_USER",
    DELETE_USER: 'DELETE_USER',
    SET_USER_FORM: "SET_USER_FORM",
    SET_USERS_OFFSET: "SET_USERS_OFFSET",
    SET_USERS_LIMIT: "SET_USERS_LIMIT",

    SET_INVENTORY: "SET_INVENTORY",
    SET_INVENTORY_OFFSET: "SET_INVENTORY_OFFSET",
    SET_INVENTORY_LIMIT: "SET_INVENTORY_LIMIT",

    SET_ITEM: "SET_ITEM",
    SET_ITEM_FORM: "SET_ITEM_FORM",
    SET_ITEMS: "SET_ITEMS",
    SET_ITEMS_OFFSET: "SET_ITEMS_OFFSET",
    SET_ITEMS_LIMIT: "SET_ITEMS_LIMIT",
    DELETE_ITEM: "DELETE_ITEM",

    SET_ITEMS_ORDER: "SET_ITEMS_ORDER",
    SET_ITEMS_ORDER_OFFSET: "SET_ITEMS_ORDER_OFFSET",
    SET_ITEMS_ORDER_LIMIT: "SET_ITEMS_ORDER_LIMIT",
    
    SET_CONTAINERS: "SET_CONTAINERS",
    SET_CONTAINER: "SET_CONTAINER",

    SET_PAYMENTS: "SET_PAYMENTS",
    SET_PAYMENTS_BY_USER: "SET_PAYMENTS_BY_USER",
    SET_PAYMENTS_OFFSET: "SET_PAYMENTS_OFFSET",
    SET_PAYMENTS_LIMIT: "SET_PAYMENTS_LIMIT",
    SET_PAYMENTS_USER_OFFSET: "SET_PAYMENTS_USER_OFFSET",
    SET_PAYMENTS_USER_LIMIT: "SET_PAYMENTS_USER_LIMIT",
    
    SET_ORDERS: "SET_ORDERS",
    SET_ORDERS_BY_USER_ID: "SET_ORDERS_BY_USER_ID",
    SET_ORDERS_OFFSET: "SET_ORDERS_OFFSET",
    SET_ORDERS_LIMIT: "SET_ORDERS_LIMIT",
    SET_ORDERS_USER_OFFSET: "SET_ORDERS_USER_OFFSET",
    SET_ORDERS_USER_LIMIT: "SET_ORDERS_USER_LIMIT",
    SET_ORDER: "SET_ORDER",
    SET_ORDER_FORM: "SET_ORDER_FORM",

    SET_RESERVATIONS: "SET_RESERVATIONS",
    SET_RESERVATION_BY_ID: "SET_RESERVATION_BY_ID",
    SET_RESERVATIONS_OFFSET: "SET_RESERVATIONS_OFFSET",
    SET_RESERVATIONS_LIMIT: "SET_RESERVATIONS_LIMIT",
    SET_RESERVATION_FORM: "SET_RESERVATION_FORM",

    SET_FINES: "SET_FINES",
    SET_FINES_OFFSET: "SET_FINES_OFFSET",
    SET_FINES_LIMIT: "SET_FINES_LIMIT",
    SET_FINES_FORM: "SET_FINES_FORM",

    SET_PRICES: "SET_PRICES",
    SET_PRICES_FORM: "SET_PRICES_FORM",
    DELETE_PRICE_FORM: "DELETE_PRICE_FORM",
    DELETE_PRICES_FORM: "DELETE_PRICES_FORM",

    DELETE_FORM_ITEMS: "DELETE_FORM_ITEMS",

    SET_FILTERS: "SET_FILTERS",
    DELETE_FILTER: "DELETE_FILTER",
    DELETE_FILTERS: "DELETE_FILTERS", 
    DELETE_ALL_FILTERS: "DELETE_ALL_FILTERS",
    RESET_REFRESH: "RESET_REFRESH",

    // HANDLE_TABLE: "HANDLE_TABLE",

    RESET_OFFSET: "RESET_OFFSET",
    
    SET_REFRESH: "SET_REFRESH",
};



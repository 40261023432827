
import { ReactElement, useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// components
import DataTable from "components/reactTable/DataTable";
import Timeline from 'components-lib/Timeline';

import { injectIntl, IntlShape } from 'react-intl';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setInputFilters, deleteFilter } from 'redux/actions/tableActions';
import { setRefresh } from 'redux/actions/pageActions';
// Functions
import { handleDeleteFilters } from 'context/tableContext';

// types
import { FilterState, Refresh } from 'types/typesRedux';
import { ButtonConfig, Columns, DateRange } from 'types/tableTypes';



type Props = {
  intl: IntlShape;
  title: string;
  tableData: any;
  isPicture?: string[] | null[] | undefined;
  pagePagination: boolean;
  totalCount?: number;
  offset?: number;
  rowLimit?: number;
  columns: Columns[];
  button?: ButtonConfig[];
  icon?: ReactElement | false;
  marginLeft?: boolean;
  maxDate?: boolean;

  onOffset?: ( offset: number ) => void;
  onLimit?: ( limit: number ) => void;
  onAddItem?: ( bool: boolean ) => void;
  onDetailItem?: ( id: number ) => void;
  onEditBtn?: ( id: number ) => void;
  onBlockItem?: ( bool: boolean, id: number ) => void;
  onRemoveBtn?: ( bool: boolean, id: number ) => void;
  onPictureItem?: ( photoUrl: string ) => void;
}

function QueryTableDevices(
    { intl, 
      title, 
      tableData, 
      isPicture,
      pagePagination,
      totalCount,
      offset, 
      rowLimit,
      columns, 
      button, 
      icon, 
      marginLeft,
      maxDate,

      onOffset,
      onLimit,
      onAddItem, 
      onDetailItem,
      onEditBtn,
      onBlockItem, 
      onRemoveBtn, 
      onPictureItem,
     } :Props
): JSX.Element {

  const dispatch = useDispatch();

  const filterState = useSelector((state: FilterState) => state.filters);
  const refresh = useSelector( (state: Refresh) => state.handleRefresh);

  const [ activeColumn, setActiveColumn ] = useState<string>('id');
  const [ sortDirection, setSortDirection ] = useState<string>('desc');
  const [ page, setPage ] = useState<number>(0);
  const [ numberOfPages, setNumberOfPages ] = useState<number>(null);

  const [ isNextPage, setIsNextPage ] = useState<boolean>(false);
  const [ isPreviousPage, setIsPreviousPage ] = useState<boolean>(false);
  const [ isBtnPage, setIsBtnPage ] = useState<boolean>(false);

  // date range picker
  const [ isTimeLine, setIsTimeLine ] = useState<boolean>( false );

  // /*
  // */ Handle search 
  //   

  // Set data to redux and refresh table with filters
  const handleSearch = ( search: string | number, column: string ) => {

    const updatedData = {
      ...filterState[title],
    };    
  
    // Check if the search value is not empty, and add or remove the key accordingly
    if (search !== '') {
      updatedData[column] = search;
    } else {
      delete updatedData[column];
    }
  
    const updatedFilters = {
      [title]: updatedData,
    };
    
    onOffset( 0 );
    dispatch(setInputFilters(updatedFilters));
  };

  // handle Autocomplete
  const handleAutocomplete = ( label: string | number, column: string ) => {
    const updatedDataAutocomplete = { ...filterState[title + 'Autocomplete'] };

      // Check if the search value is not empty, and add or remove the key accordingly
    if (label !== '') {
      updatedDataAutocomplete[column] = label;
    } else {
      delete updatedDataAutocomplete[column];
    }

    const updatedFiltersAutocomplete = {
      [title + 'Autocomplete']: updatedDataAutocomplete,
    };

    dispatch(setInputFilters(updatedFiltersAutocomplete));
  }
  
  // debounce if write to filter
  const handleInputBounce = ( bool: boolean ) => {
    if ( bool === true && refresh.refresh === false ) {
      dispatch(setRefresh( { refresh: true } ));
    }
  }

  // handle sort - set column and sort to redux
  const handleSortColumn = ( column: string, ascDesc: string ) => {
    dispatch( setInputFilters({
        ...filterState, // Preserve other properties
        [title + ' sort']: {
          ...filterState[title], // Preserve other data properties
          sort: column + ':' + ascDesc
        },
      })
    );

    if ( refresh.refresh === false ) dispatch(setRefresh( { refresh: true } ));

    // set name of column and asc or desc for active arrow sort
    setActiveColumn(column)
    setSortDirection(ascDesc);
  }

  // If have sort in redux, set active column and sort direction
  useEffect(() => {
    if ( filterState[title + ' sort'] && filterState[title + ' sort'].sort !== undefined ) {
      let sort = filterState[title + ' sort'].sort.split(':');
      setActiveColumn(sort[0]);
      setSortDirection(sort[1]);
    }
  }, [filterState[title + ' sort']])

  // handle remove filter after click on input delete icon
  const handleClearInput = ( bool: boolean, children: string | number, titleFilter: string = title  ) => {
    if ( bool === true ) { 
      setPage( 0 );
      setNewInputValue( 1 );
      onOffset( 0 );

      dispatch(deleteFilter(titleFilter, children));
      dispatch(deleteFilter(titleFilter + 'Autocomplete', children));
      dispatch(setRefresh( { refresh: true } ))   
    }
  }

  // handle remove date filter after click delete icon
  const handleClearDateTime = ( bool: boolean, children: string | number, titleFilter: string = title  ) => {
    if ( bool === true ) { 
      setPage( 0 );
      setNewInputValue( 1 );
      onOffset( 0 );

      dispatch(deleteFilter(titleFilter, `${children}[lte]`));
      dispatch(deleteFilter(titleFilter, `${children}[gte]`));
      dispatch(setRefresh( { refresh: true } ))   
    }
  }

  // if click cross icon sort back acc id desc
  const handleClearInputs = ( bool: boolean ) => {
    if ( bool === true ) {
      setPage( 0 );
      setNewInputValue( 1 )
      onOffset( 0 );

      handleDeleteFilters( dispatch, title );

      dispatch(setRefresh({ refresh: true }));

      setActiveColumn('id')
      setSortDirection('desc');
    }
  }

  // Handle search date range picker ///////////////////////////////////
  
  // Set columnName for date range picker
  const [ columnName, setColumnName ] = useState<string>( '' );
  
  // after click calendar icon set column and open modal
  const handleDateRangePicker = ( bool: boolean, column: string ) => {
    if ( bool === true ) { 
      setIsTimeLine( true )
      setColumnName( column )
    }
  }

  // handle date range from picker
  const handleDateRange = (date: DateRange) => {
    
    if (date && date.endDate) {
      const startDate = date.startDate;
      const endDate = date.endDate;
      handleUpdateDateRange(startDate, endDate, columnName);
    } else if ( date && date.startDate ) {
      // If endDate is not defined, set it to same day at 23:59:59
      const startDate = new Date(date.startDate);
      const endDate = new Date(date.startDate);

      endDate.setHours(23, 59, 59, 999);
      handleUpdateDateRange(startDate, endDate, columnName);
    }
  };

  // update date and set at redux
  const handleUpdateDateRange = (startDate: Date, endDate: Date, column: string) => {
    const updatedData = { ...filterState[title] };
    
    let columnStart = `${column}[gte]`;
    let columnEnd = `${column}[lte]`;
  
    if (startDate) {
      updatedData[columnStart] = startDate.toISOString();
    } else {
      delete updatedData[columnStart];
    }
  
    if (endDate) {
      updatedData[columnEnd] = endDate.toISOString();
    } else {
      delete updatedData[columnEnd];
    }
  
    const updatedFilters = { [title]: updatedData };
    onOffset(0);
    dispatch(setInputFilters(updatedFilters));
  };

  // handle submit date range and close modal
  const handleOnSubmitDate = () => {
    setIsTimeLine( false )
    dispatch(setRefresh( { refresh: true } ));
  }

  // Handle close window date range picker
  const handleCloseWindow = ( bool: boolean ) => {
    if (bool === false){ 
      setIsTimeLine( false )
      dispatch(deleteFilter(title, `${columnName}[lte]`));
      dispatch(deleteFilter(title, `${columnName}[gte]`));
    }
  }

  // /*
  // */ Handle setting page 
  // 
  
  // Handle number of pages
  useEffect(() => {
    let numOfPages = Math.ceil( totalCount / rowLimit );
    setNumberOfPages( numOfPages );
  },[ rowLimit, totalCount ]) 

  // take row limit from child and send parent
  const handleRowLimit = (totalItems: number) => {
    onLimit( totalItems );
    onOffset( 0 );
    dispatch(setRefresh( { refresh: true } ));

    setPage( 0 )
    setNewInputValue( 1 )
  }

  // Handle actual page
  const setActualPage = () => {
    let actualPage =Math.ceil( offset / rowLimit );
    setPage( actualPage )
    setNewInputValue( +actualPage + 1 )
  }

  // after click on btn arrow next set offset
  const handleNextPage = ( boolValue: boolean ) => {
    if ( boolValue === true ){
      let offsetValue = (+offset) + (+rowLimit)
      let actualPage = +page + 1 ;
      onOffset( offsetValue );
      setIsNextPage( true );
      setNewInputValue((+actualPage + 1));   
    }
  }

  // refresh if click on arrow next and have offset /////
  useEffect( () => {
    if ( isNextPage === true ) {
      setIsNextPage(false);   
      dispatch(setRefresh( { refresh: true } ))
      setActualPage()
    }
    if ( offset ) { setActualPage(); }
  }, [offset, page]);


  // after click on btn arrow previous set offset
  const handlePreviousPage = ( boolValue: boolean ) => {
    if ( boolValue === true && offset > 0 ){
      let offsetValue = +offset - rowLimit
      let actualPage = +page - 1 ;
      onOffset( offsetValue );
      setIsPreviousPage( true );  
      setNewInputValue((+actualPage + 1));
    }
  }  

  // refresh if click on arrow previous///////////////////////////////////////
  useEffect( () => {
    if ( isPreviousPage === true ) {
      setIsPreviousPage(false);       
      dispatch(setRefresh( { refresh: true } ))
      setActualPage()
    }
  }, [offset, page]);
 
  // after click on btn number set offset
  const handleBtnPage = ( btnPage: number ) => {
      let offsetValue = btnPage * rowLimit;
      onOffset( offsetValue );
      setIsBtnPage( true );         
  }

  // refresh if click on btn number////////////////////////////////////////////////
  useEffect( () => {
    if ( isBtnPage === true ) {
      setIsBtnPage(false);       
      dispatch(setRefresh( { refresh: true } ))
      setActualPage()
    }
  }, [isBtnPage]);

  // 
  /////////////////// handle page if length pages > 6
  // 
  const [ newInputPage, setNewInputValue ] = useState( page + 1)
  const [ isEnter, setIsEnter ] = useState<boolean>(false)  

  // take from child new number in input
  const handleInputPage = ( num: any ) => {
    // if num is bigger then number of pages set biggest page
    if ( num > numberOfPages ) {
      handleDebounceInput( true, numberOfPages );
    } else {
      handleDebounceInput( true, num );
    }
  }

  // after stop writen to input set enter true
  const setEnterTrue = (bool: boolean) => {
    setIsEnter( true );
  }

  // if debounce, render entries acc input number
  const handleDebounceInput = ( bool: boolean, value: any ) => {
    if ( bool === true ) {
      let newPage = +value - 1 ;
      let newOffset = +newPage * rowLimit ;
      setPage( newPage );
      setNewInputValue( newPage + 1 );
      if ( pagePagination === true ) {
        onOffset( newOffset );
      }      
      // setIsEnter(true);
    }
  } 

  // If enter is true set table page with actual page number
  useEffect( () => {
    if ( isEnter === true ) {
      setIsEnter( false );
      setActualPage();
      dispatch(setRefresh( { refresh: true } ));
    }
  }, [isEnter]);

  // set number of page, e.g: after back from detail page /////
  useEffect( () => {
    setActualPage();
  }, [offset]);


  return (

    <MDBox
      className= "table"
      sx={{ 
        marginLeft: marginLeft !== false ? "max(18.7rem)" : "0rem", 
        paddingRight: "1rem", 
        display: "flex", flexDirection: "column", justifyContent: "center", 
        marginTop: icon !== false ? "2rem" : "0rem",
        marginBottom: "1rem", 
      }}
    >

      {/* <Grid display="flex" justifyContent="flex-end" 
        sx={{ pb: "1rem" }}
      >
        <MDButton 
          variant="gradient" 
          color="success"
          onClick={addNewUser}
          width={4} xs={2}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;Pridať používateľa
        </MDButton>                    
      </Grid>       */}
        
      <Card>
        <MDBox sx={{ display:"flex", flexDirection: "row", 
          padding: "1.5rem 1.5rem 0.7rem 1.7rem", gap:"1rem"}} 
          >

          { icon !== false ? (
          <MDBox
            sx={{ 
            p: 1, mt: "-2.5rem", ml: 0,
            borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl, 
            // backgroundColor: "#F0B346",
            backgroundColor: "#17C063",
            }}
            display="grid"
            justifyContent="center"
            alignItems="center"
            // bgColor="success"
            color="white"
            width="4.5rem"
            height="4.5rem"
            shadow="md"
            borderRadius="lg"
            variant="gradient"  
            fontSize= "2.2rem"        
            >
            <Icon sx={{marginBottom:"0.8rem"}} >{icon}</Icon>
          </MDBox>
          ) : null }

          <MDBox lineHeight={1}>            
            <MDTypography variant="h5" fontWeight="medium">
                {title}
            </MDTypography>            
          </MDBox>
        </MDBox>

        <DataTable           
          title={ title }
          tableColumns={ columns } 
          tableRows={ tableData }
          button={ button }   
          isPicture={ isPicture }       
          onAddItem={ onAddItem }
          onBlockItem={ onBlockItem }
          onRemoveBtn={ onRemoveBtn }
          onEditBtn= { onEditBtn }
          onDetailItem= { onDetailItem }
          onPictureItem= { onPictureItem }
          onDateRangePicker= { handleDateRangePicker }

          pagePagination={pagePagination}
          rowLimit={ rowLimit }
          totalCount={ totalCount }
          actualPage={ page }
          numberOfPages={ numberOfPages }
          newInputPage={ newInputPage }
          activeColumn={activeColumn}
          sortDirection={sortDirection}
          onRowLimit={ handleRowLimit }
          onNextPage={ handleNextPage }
          onPreviousPage={ handlePreviousPage }
          onBtnPage={ handleBtnPage }
          onSortColumn={ handleSortColumn }
          onInputPage={ handleInputPage }
          onInputBounce={ handleInputBounce }
          onSearch={ handleSearch }
          onAutocomplete={ handleAutocomplete }
          onClearInput={ handleClearInput }
          onClearDateTime={ handleClearDateTime }
          onClearInputs={ handleClearInputs }
          onEnterTrue={setEnterTrue}
        />        
      </Card>

      { ( isTimeLine ) && 
        <Timeline
          isTimeLine={ isTimeLine }
          column={ columnName }
          isMaxDate={ maxDate }
          onCloseWindow={ handleCloseWindow }
          onDateRange={ handleDateRange }
          onSubmitDate={ handleOnSubmitDate }
        />    
      } 
    </MDBox>
  );
}

export default injectIntl(QueryTableDevices);
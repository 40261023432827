import { ActionTypes } from 'redux/actions/actionType';
import { FinesState, SetFinesAction } from 'types/typesRedux';



const finesState: FinesState = {
    fines: [],
    totalCount: 0,
    offset: 0,
    limit: 10,
    sort: 'id:desc',

    finesForm: {
        amount: null,
        description: '',
    }
};

export const finesReducer = (state= finesState, action: SetFinesAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_FINES:
            return { 
                ...state, 
                fines: action.payload.fines,
                totalCount: action.payload.totalCount};
        case ActionTypes.SET_FINES_OFFSET:
            return { ...state, offset: action.payload.offset };
        case ActionTypes.SET_FINES_LIMIT:
            return { ...state, limit: action.payload.limit };
        case ActionTypes.SET_FINES_FORM:
            return { ...state, finesForm: action.payload };
        default:
            return state;
    }
};



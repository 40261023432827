import React, { ChangeEvent, useState, useEffect, useRef } from 'react';

// language
import { injectIntl, IntlShape } from 'react-intl';

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// alerts
import { useSnackbar } from "notistack";

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setAnnouncementForm } from 'redux/actions/announcementActions';
import { setRefresh } from 'redux/actions/pageActions';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// icon
import AnnouncementIcon from '@mui/icons-material/Announcement';

// sanitize
import DOMPurify from "dompurify";

// types
// import { Announcement } from 'types/types';
import { AnnouncementData, AnnouncementDataForm } from 'types/typesRedux';

type Props = {
  intl: IntlShape,
  onCloseWindow?: (bool: boolean) => void,
}

const AddAnnouncement = ( {intl, onCloseWindow}: Props) => {

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  // redux hooks
  const announcement = useSelector( (state: AnnouncementData) => state.appAnnouncement.announcement );
  const announcementForm = useSelector( (state: AnnouncementDataForm) => state.appAnnouncement.announcementForm );

  const [ input, setInput ] = useState('');

  const surnameInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch(setAnnouncementForm({
      content: announcement.content
    })
    )
  },[])

  // Handle add announcement
  const addAnnouncement = async ( announcement: string ) => {
    const cancelToken = axios.CancelToken.source();
    try {
      await apiClient.put(`/v1/announcement`, {
          content: announcement,
          cancelToken:cancelToken.token
      }
      ).then( ( response ) => {
          enqueueSnackbar(`${intl.formatMessage({id:'appAnnouncementAlert.add.true'})}`, { variant: 'success' });
          dispatch(setRefresh( { refreshAnnouncement: true } ));
      });
    } catch ( error ) {
        console.log( 'err' );
        enqueueSnackbar(`${intl.formatMessage({id:'appAnnouncementAlert.add.false'})}`, { variant: 'error' });
    }    
    return () => cancelToken.cancel();  
  }

  // set data from inputs to redux
  const handleInputDescription = ( e: ChangeEvent<HTMLInputElement>, newValue: string ) => {

    newValue = DOMPurify.sanitize( newValue );

    dispatch(setAnnouncementForm({
      content: newValue,
    })
    )
  };

  // handle close modal window
  const closeWindow = () => {
    onCloseWindow( false );
    dispatch(setAnnouncementForm( {
      content: '',
    }
    ));
  } 

  // handle submit 
  const handleSubmit = () => {
    if ( announcementForm.content ) {
      addAnnouncement( announcementForm.content );
      onCloseWindow( false );
    } else {
      enqueueSnackbar(`${intl.formatMessage({id:'appAnnouncementAlert.emptyInput'})}`, { variant: 'error' });
    }
  }

  return (
    <Grid container sx={{ position: 'fixed', top: '0%', left: '0%',
      backgroundColor:"rgba( 0, 0, 0, 0.5 ) !important", width:"100vw", height:"100vh", zIndex:"1300" }}>

      <Card
        sx={ { 
          minWidth: "17rem", width: "20rem", maxWidth: "22rem",
          position: 'fixed',
          top: '50%', left: '50%',
          transform: 'translate(-50%, -50%)',
        } }
      >        

        <MDBox        
          display="flex"
          flexDirection="column"
          p={2} m={0}
          sx={{ listStyle: "none" }}
        >    

          <MDBox sx= {{ display: "flex",
            flexDirection: "row", gap: "1rem" }} >
            <MDBox
              sx={{ 
                backgroundColor: "#17C063",
                p: 1, mt: -4, ml: 0,
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl, }}
              display="grid"
              justifyContent="center"
              alignItems="center"
              // bgColor="success"
              color="white"
              width="4.5rem"
              height="4.5rem"
              shadow="md"
              borderRadius="lg"
              variant="gradient"  
              fontSize= "2.2rem"        
              >
              <Icon sx={{marginBottom:"0.8rem"}} ><AnnouncementIcon /></Icon>
            </MDBox>

            <MDTypography variant="h6">
              { announcement?.content ? intl.formatMessage({id:'appAnnouncement.titleEdit'}) :
                intl.formatMessage({id:'appAnnouncement.titleAdd'})}
            </MDTypography>
                            
          </MDBox>

          <Grid item xs={12} md={12} 
            sx={{
              display: "flex", flexDirection: "column", marginTop:"1rem"

            }}>
            <MDTypography variant="button" >
              {intl.formatMessage({id:'fines.detail.description'})}
            </MDTypography>           
            <MDInput 
              type="text"
              name='description'
              multiline
              rows={4} 
              autoFocus
              value={announcementForm?.content ?? announcementForm?.content}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputDescription(e, e.target.value)}
              />
          </Grid>

          <MDBox 
            sx={{
                display: "flex", flexDirection: "row", justifyContent: "flex-end", 
                gap: "0.5rem", maxWidth: "100%", marginBottom: "0.5rem", marginTop: "1rem",
            }}
          >
            <MDButton 
                variant="outlined" 
                color="success" 
                onClick={ closeWindow }
                // sx={{ background: theme.palette.background.default }}
              >
                {intl.formatMessage({id:'button.cancel'})}
            </MDButton>
            <MDButton 
                variant="contained" 
                color="success" 
                onClick={ handleSubmit }         
              >
                {intl.formatMessage({id:'button.save'})}
            </MDButton>
          </MDBox>
  
        </MDBox>
      </Card>
    </Grid>
  )
};

export default injectIntl(AddAnnouncement);
import React from "react";

// react-router components
import { Routes, Route } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";

import { injectIntl } from 'react-intl';
import Admin from "layouts/Admin";

import PublicRoute from "components-lib/PublicRoute";

import Magic from "pages/authentication/sign-in/magicLink";


const App = injectIntl(({ intl }) => {  


  return (
    // <Provider store={store}>
      <ThemeProvider theme={theme} >
        <CssBaseline />
        <Routes>
          <Route path="/*" element={<PublicRoute />} />
          <Route path="/authentication/*" element={<PublicRoute />} />
          <Route path="/authentication/sign-in/magiclink" element={<Magic />} />
          <Route path="/admin/*" element={<Admin />} />
        </Routes> 
      </ThemeProvider>
    // {/* </Provider> */}
  );

})

export default App;

import { ActionTypes } from "redux/actions/actionType";

// types
import { ContainersTableType, ContainerType } from 'types/types';


export const setContainers = ( containers: ContainersTableType, totalCount: number ) => {
    return {
        type: ActionTypes.SET_CONTAINERS,
        payload: {containers, totalCount}
    }
};

export const setContainer = ( container: ContainerType ) => {
    return {
        type: ActionTypes.SET_CONTAINER,
        payload: container,
    }
};
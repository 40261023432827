
import React, { useRef, useState, useEffect } from 'react';

// language
import { injectIntl, IntlShape } from 'react-intl';

// axios
import axios from 'axios';
import { apiClient } from 'api-client';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { setRefresh } from 'redux/actions/pageActions';
import { setOrderForm } from 'redux/actions/ordersActions';

// alerts
import { useSnackbar } from "notistack";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";

// utils
import { formatDateTime } from 'utils';

// enumns
import { orderEditEnum, orderAwaitingPaymentEnum } from 'common/enums/DevicesStateEnum';

// types
import { OrderData, OrderDataForm } from 'types/typesRedux';

interface Props {
  intl: IntlShape,
  onSubmitEdit?: (bool: boolean) => void,
}

function OrderInfo( { intl, onSubmitEdit } :Props ): JSX.Element {

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const order = useSelector( (state: OrderData) => state.orderDetail.order )
  const orderForm = useSelector( (state: OrderDataForm) => state.orderDetail.orderForm );

  const isActiveInput = useRef<HTMLInputElement>(null);

  const [ isNewStatus, setIsNewStatus ] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState('');

  // Handle close order /////////////////////////////
  const closeOrder = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source()

    try {
      await apiClient.post(`/v1/orders/${id}/close`,{
        cancelToken:cancelToken.token
        }
      ).then( ( response ) => {
        if ( response ) {
          enqueueSnackbar(`${intl.formatMessage({id:'orderAlert.closeOrder.true'})}`, { variant: 'success' });
          dispatch(setRefresh( { refreshOrder: true } ));
          dispatch(setOrderForm( { status: '' } ));
        }
    });
    } catch ( err ){
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        enqueueSnackbar(`${intl.formatMessage({id:'orderAlert.closeOrder.false'})}`, { variant: 'error' });
      }
    };

    setTimeout(() => {
      setAutocompleteValue(null);
    }, 100);
    
    return () => cancelToken.cancel();
  }

  // Handle close order /////////////////////////////
  const cancelOrder = async ( id: number ) => {
    const cancelToken = axios.CancelToken.source()

    try {
      await apiClient.post(`/v1/orders/${id}/cancel`,{
        cancelToken:cancelToken.token
        }
      ).then( ( response ) => {
        if ( response ) {
          enqueueSnackbar(`${intl.formatMessage({id:'orderAlert.cancelOrder.true'})}`, { variant: 'success' });
          dispatch(setRefresh( { refreshOrder: true } ));
          dispatch(setOrderForm( { status: '' } ));
        }
    });
    } catch ( err ){
      if (axios.isCancel(err)) {
      } else {
        enqueueSnackbar(`${intl.formatMessage({id:'orderAlert.cancelOrder.false'})}`, { variant: 'error' });
      }
    };
    return () => cancelToken.cancel();
  }

  // set data from inputs to redux
  const handleInputItem = ( e: React.SyntheticEvent<Element, Event>, newValue: string) => {
    let status = newValue;
    dispatch(setOrderForm( {
        status: status,
      }
    ));
    setIsNewStatus(true);
  };  

  // If submit, edit order
  const handleChangeStatus = async () => {    
    if ( orderForm.status === `${intl.formatMessage({id:'order.detail.cancel'})}` ) {
      cancelOrder( order.id );
    } else if ( orderForm.status === `${intl.formatMessage({id:'order.detail.close'})}` ) {
      closeOrder( order.id );
    }
  };

  useEffect(() => {
    if ( isNewStatus ) {
      handleChangeStatus();
    }
  }, [ isNewStatus ]);

  // console.log(isActiveInput)
     
  return (
    <Card
      className="orderCard"
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
        minWidth: "22rem",
        width: "100%",
      }}
    >
      
      <MDBox        
        display="flex"
        flexDirection="column"
        p={2}
        sx={{ listStyle: "none" }}
      >    

        <MDBox p={1} pb={0}
          sx= {{ flex: "100%", }} > 

          {/* Header */}
          <MDBox 
            sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
            }}>
            <MDTypography variant="h5">
            {intl.formatMessage({id:'order.detail.title'})}
            </MDTypography>
          </MDBox>

          <MDTypography variant="button" >
              {intl.formatMessage({id:'order.detail.id'})}
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{ order.id ? order.id : '-'}
          </MDTypography>

        </MDBox>

        <Divider orientation="horizontal" sx={{ width:"100%", height:"0.1rem", 
            opacity:"0.6", margin:"0", marginTop:"0.5rem",}} />


        <MDBox 
          pb={3} px={1} pt={0} mt={0}
          className= "containerOrderInfo"
          sx={{ 
            width:"100%", display:"flex", 
            // flexDirections:"row"
          }}
        >

          <Grid
            sx={{ 
              display: "flex",
              flexDirection: "row",
              minWidth:"50%",
              width: "auto",
            }} 
          >

            {/* User name + free of charge */}
            <Grid
              sx={{ 
                display: "flex",
                flexDirection: "column",
                minWidth:"10rem",
                width: "50%",
                // maxWidth:"13rem",
              }} 
            >
            
              <MDBox 
                sx={ { 
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mt: "1.2rem",
                  lineHeight: "1.625",
                  letterSpacing: "0.00938em",
                } } 
              >
                <MDTypography 
                  sx={ {                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                  } } 
                  variant="button"                   
                >
                  {intl.formatMessage({id:'order.detail.user'}).toUpperCase()}
                </MDTypography>              
                <MDTypography variant="button" 
                  sx={ { marginTop: "0.2rem",} }
                    fontWeight="regular" 
                    color="text"
                >
                  { order.user ?? '-'}
                </MDTypography>
              </MDBox>

              <MDBox 
                sx={ { 
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mt: "1.2rem",
                  lineHeight: "1.625",
                  letterSpacing: "0.00938em",
                } } 
              >
                <MDTypography 
                  sx={{                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                  }} 
                  variant="button"                   
                >
                    {intl.formatMessage({id:'order.detail.isFreeOfCharge'}).toUpperCase()}
                </MDTypography>              
                <MDTypography variant="button" 
                  sx={ { marginTop: "0.2rem",} }
                    fontWeight="regular" 
                    color="text"
                >
                  { order.isFreeOfCharge ?? '-'}
                </MDTypography>
              </MDBox>

            </Grid> 
          
            {/* price + status */}          
            <Grid 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                minWidth:"10rem",
                width: "50%",
                // maxWidth:"15rem",
              }} 
            >

              <MDBox 
                  sx={ { 
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      mt: "1.2rem",
                      lineHeight: "1.625",
                      letterSpacing: "0.00938em",
                  } } 
              >
                <MDTypography 
                    sx={ {                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                    } } 
                    variant="button"                   
                    >
                    {intl.formatMessage({id:'order.detail.price'}).toUpperCase()}
                </MDTypography>              
                <MDTypography variant="button" 
                    sx={ { marginTop: "0.2rem",} }
                    fontWeight="regular" 
                    color="text">
                    { order.price ? + order.price + ' ' + intl.formatMessage({id:'currency'}) : '-' }
                </MDTypography>
              </MDBox>

              { order.status === `${intl.formatMessage({id:'order.detail.in_progress'})}` || 
              order.status === `${intl.formatMessage({id:'order.detail.awaiting_payment'})}` 
              ? (
              <Grid item 
                sx={ {                         
                  mt: "0.6rem",
                  mb: "0.4rem",
                  width:"80%",
                } }>
                <MDTypography
                   sx={ {                         
                    // mt: "0.6rem", 
                    fontWeight: "500",
                    fontSize:"0.7rem",                     
                    } } 
                    variant="button"  
                >
                  {intl.formatMessage({id:'order.detail.status'})}
                </MDTypography>  
                <Autocomplete
                  value={autocompleteValue}
                  options={
                    order.status === `${intl.formatMessage({id: 'order.detail.awaiting_payment'})}`
                      ? orderAwaitingPaymentEnum(intl)
                      : orderEditEnum(intl)
                  }
                  onChange={ handleInputItem }
                  sx={{ marginTop: "-0.5rem", width: "auto", maxWidth: "9rem"  }}
                  clearIcon 
                  ListboxProps= {{
                    sx: {
                      "& li": { minWidth: "8rem !important", width: "auto", 
                                marginLeft: "-0.75rem", marginRight: "0rem", fontSize: "0.85rem !important", borderRadius: "none", },
                    }
                  }}
                  renderInput={(params) => (
                    <FormField {...params} 
                      className= "AutocompleteStatusOrder"
                      InputLabelProps={{ shrink: true }}
                      inputRef={isActiveInput} 
                      // value={autocompleteValue}
                      placeholder={ order.status ? order.status : ''}
                      sx={{
                        '& .MuiInputBase-root': {
                          paddingRight: '22px !important',
                        }
                      }}
                    /> )}
                />
              </Grid>
            ) : ( 

              <MDBox 
                sx={{ 
                  display: "flex", flexDirection: "column",
                  mt: "1.2rem", lineHeight: "1.625",
                  letterSpacing: "0.00938em",
                }} 
              >
                <MDTypography 
                  sx={{                         
                    fontWeight: "500", fontSize:"0.7rem",                       
                  }} 
                  variant="button"                   
                  >
                  {intl.formatMessage({id:'order.detail.status'})}
                </MDTypography>              

                { order.status === `${intl.formatMessage({id:'order.detail.completed'})}` ? (
                <MDTypography variant="button" 
                  sx={ { marginTop: "0.2rem", color: "#4CAF50", } }
                  fontWeight="regular">
                  { order.status }
                </MDTypography>
                ) : order.status === `${intl.formatMessage({id:'order.detail.cancelled'})}` ? (
                  <MDTypography variant="button" 
                    sx={ { marginTop: "0.2rem", color: "#F44334", } }
                    fontWeight="regular">
                    { order.status }
                  </MDTypography>
                ) : null }
              </MDBox>

            )
          }

            </Grid>    
          </Grid>

          <Grid
            sx={{ 
              display: "flex",
              flexDirection: "row",
              minWidth:"50%",
              width: "auto",
            }} 
          > 
            {/* start place + finish place */}
            <Grid 
                sx={ { 
                display: "flex",
                flexDirection: "column",
                minWidth:"10rem",
                width: "50%",
                // maxWidth:"13rem",
                } } 
                >
                
                <MDBox 
                    sx={ {
                        display: "flex",
                        flexDirection: "column",
                        mt: "1.2rem",
                        lineHeight: "1.625",
                        letterSpacing: "0.00938em",
                        width:'100%',
                    } }
                >
                    <MDTypography 
                        sx={ {                         
                        // mt: "0.6rem", 
                        fontWeight: "500",
                        fontSize:"0.7rem",                     
                        } } 
                        variant="button"                   
                        >
                        {intl.formatMessage({id:'order.detail.startPlace'})}
                    </MDTypography>              
                    <MDTypography variant="button" 
                        sx={ { marginTop: "0.2rem",} }
                        fontWeight="regular" 
                        color="text">
                        { order.startPlace ?? '-'}
                    </MDTypography>
                </MDBox>

                <MDBox 
                    sx={ { 
                        display: "flex",
                        flexDirection: "column",
                        mt: "1.2rem",
                        lineHeight: "1.625",
                        letterSpacing: "0.00938em",
                    } } 
                >
                    <MDTypography 
                        sx={ {                         
                        // mt: "0.6rem", 
                        fontWeight: "500",
                        fontSize:"0.7rem",                     
                        } } 
                        variant="button"                   
                        >
                        {intl.formatMessage({id:'order.detail.finishPlace'})}
                    </MDTypography>              
                    <MDTypography variant="button" 
                    sx={ { marginTop: "0.2rem",} }
                    fontWeight="regular" 
                    color="text">
                        { order.finishPlace ?? '-'}
                    </MDTypography>
                </MDBox>

            </Grid>  

            {/* Created order + Completed order */}
            <Grid container 
                sx={{ 
                display: "flex",
                flexDirection: "column",
                minWidth:"10rem",
                width: "50%",
                maxWidth:"15rem",
                }} 
                >   
                
                <MDBox 
                    sx={ { 
                        display: "flex",
                        flexDirection: "column",
                        mt: "1.2rem",
                        lineHeight: "1.625",
                        letterSpacing: "0.00938em",
                        width:'100%',
                    } } 
                >
                    <MDTypography 
                        sx={ {                         
                        // mt: "0.6rem", 
                        fontWeight: "500",
                        fontSize:"0.7rem",                     
                        } } 
                        variant="button"                   
                        >
                        {intl.formatMessage({id:'order.detail.createdAt'}).toUpperCase()}
                    </MDTypography>              
                    <MDTypography variant="button" 
                        sx={ { marginTop: "0.2rem",} }
                        fontWeight="regular" 
                        color="text">
                        { order.createdAt ?? '-'}
                    </MDTypography>
                </MDBox>

                <MDBox 
                    sx={ { 
                        display: "flex",
                        flexDirection: "column",
                        mt: "1.2rem",
                        lineHeight: "1.625",
                        letterSpacing: "0.00938em",
                    } } 
                >
                    <MDTypography 
                        sx={ {                         
                        // mt: "0.6rem", 
                        fontWeight: "500",
                        fontSize:"0.7rem",                     
                        } } 
                        variant="button"                   
                        >
                        {intl.formatMessage({id:'order.detail.completed'}).toUpperCase()}
                    </MDTypography>              
                    <MDTypography variant="button" 
                        sx={ { marginTop: "0.2rem",} }
                        fontWeight="regular" 
                        color="text">
                        {/* { formatDateTime(order.completedAt) ?? '-' } */}
                        { order.completedAt ?? '-' }
                    </MDTypography>
                </MDBox>

            </Grid>          
          </Grid>


        </MDBox>
        
      </MDBox>

    </Card>
  );
}

export default injectIntl(OrderInfo);

import { ActionTypes } from 'redux/actions/actionType';

// types
import { 
    UsersState, 
    SetUsersAction,
    UserState,
    SetUserAction,
    UserLoggedState,
    SetUserLoggedAction 
} from 'types/typesRedux';


// users data
const usersState: UsersState = {
    users: [],
    totalCount: 0,
    offset: 0,
    limit: 10,
    sort: 'id:desc',
};

export const usersReducer = (state= usersState, action: SetUsersAction ) => {
    switch ( action.type ) {
        case ActionTypes.SET_USERS:
            return { 
                ...state, 
                users: (action as SetUsersAction).payload.users,
                totalCount: (action as SetUsersAction).payload.totalCount};
        case ActionTypes.SET_USERS_OFFSET:
            return { ...state, offset: (action as SetUsersAction).payload.offset };
        case ActionTypes.SET_USERS_LIMIT:
            return { ...state, limit: (action as SetUsersAction).payload.limit };

        case ActionTypes.RESET_OFFSET:
            return { ...state, offset: 0 };
        default:
            return state;
    }
};

// user data
const userState: UserState = {
    user: [],

    userForm: {
        firstname: '',
        surname: '',
        street: '',
        city: '',
        postal: '',
    }
};

export const userReducer = (state = userState, { type, payload }: SetUserAction )  => {
    switch ( type ) {
        case ActionTypes.SET_USER:
            return { ...state, user: payload };
        case ActionTypes.DELETE_USER:
            return { ...state, user: [] };
        case ActionTypes.SET_USER_FORM:
            return { ...state, userForm: payload };
        default:
            return state;
    }
};

// Logged user data
const userLoggedState: UserLoggedState = {
    userLogged: [],
};

export const userLoggedReducer = (state= userLoggedState, { type, payload }: SetUserLoggedAction ) => {
    switch ( type ) {
        case ActionTypes.SET_LOGGED_USER:
            return { ...state, userLogged: payload };
        default:
            return state;
    }
};
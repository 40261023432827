
// redux
import { useDispatch, useSelector } from 'react-redux';
import { setRefresh } from 'redux/actions/pageActions';

// comnponents
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Autocomplete from '@mui/material/Autocomplete';

// language
import { injectIntl, IntlShape } from 'react-intl';

// css
import '../../css/autocomplete.css';

// types
import { FilterState } from 'types/typesRedux';

import Popper from '@mui/material/Popper';

// Define a custom popper component that accepts `style` as a prop
const CustomPopper = (props: any) => {
  const { style, ...other } = props;
  return <Popper {...other} style={{ ...style, width: props.style.width }} />;
};

type AutocompleteValue = {
  value: string;
  label: string;
  key: string;
}
type Props = {
  intl: IntlShape,
  filterOptions: any,
  tableName: string,
  align?: "left" | "center" | "right",
  inputLabel?: string,
  columnId?: string,
  activeColumn?: string,
  sortDirection?: string,

  onSearchString: ( search: string | number, title: string ) => void,
  onAutocomplete: ( label: string | number, title: string ) => void,
  onAscDesc: ( column: string, sort: 'asc' | 'desc' ) => void,
  onClearInput?: ( bool: boolean, column: string ) => void;
}

const AutoComplete = ({ 
  intl,
  filterOptions, 
  tableName,
  align, 
  inputLabel, 
  columnId, 
  activeColumn,
  sortDirection,

  onSearchString,
  onAutocomplete,
  onAscDesc, 
  onClearInput,
  }: Props) => {

  const dispatch = useDispatch();

  const filters = useSelector((state: FilterState) => state.filters);
  
  const inputContents = () => {
    let inputContent = filters[tableName + 'Autocomplete'] && filters[tableName + 'Autocomplete'][filterOptions[0].value];
    return inputContent;
  }

  const handleInput = ( e: React.SyntheticEvent<Element, Event>, newValue: AutocompleteValue ) => {
    if ( newValue.value === 'clear' ) {
      onClearInput( true, columnId );
      onClearInput( true, columnId + '[isnull]' );
      onClearInput( true, `category[${columnId}][contains]` );
    } else {
      onSearchString( newValue.key, newValue.value ); 
      onAutocomplete( newValue.label, newValue.value );  
      dispatch(setRefresh( { refresh: true } ))
    }
  };

  return (
    <MDBox      
      display="flex" flexDirection="row"
      right={align !== "right" ? "16px" : 0}
      left={align === "right" ? "-5px" : "unset"}
      sx={({ typography: { size } }: any) => ({
        fontSize: size.lg,
        width: "100%",
      })}
      className="autocomplete"
    >
      <Autocomplete
        options={[...filterOptions, { label: intl.formatMessage( {id: 'tableUsers.blockedAt.clear'} ), value: 'clear' }]}
        value={ inputContents() && inputContents() !== undefined ? inputContents() : '' }
        inputValue={ inputContents() && inputContents() !== undefined ? inputContents() : '' }
        onChange={handleInput}
        className={tableName}
        PopperComponent={(props) => (
          <CustomPopper {...props} style={{ width: 'auto' }} />
        )}
        sx={{ 
          width: '100%',   
        }}
        disableClearable
        ListboxProps= {{
          sx: {
            "& div": {  minWidth: "25rem !important", width: "100%"  },
            "& li": {  marginLeft: "-0.75rem", marginRight: "0rem", fontSize: "0.85rem !important", borderRadius: "none", },
            "& li:last-child": { color: "#000", backgroundColor: "#FF7866", borderRadius: "0px", fontWeight: "400"},
          }
        }}
        renderInput={(params: any) => (
          <MDInput
            className="autocomplete"
            {...params}
            right={align !== 'right' ? '16px' : 0}
            left={align === 'right' ? '-5px' : 'unset'}
            sx={{
              width: "100%",
              '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                marginRight: 0, 
                right: "5px",
              '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment:hover': {
                cursor: "pointer",
                /* Define other hover styles as needed */
              },
              },
              '& button': {
                paddingRight: "0rem",
                paddingLeft: "0rem",
              },
            }}
            inputProps={{
              ...params.inputProps,
              style: {
                paddingRight: '0', 
                paddingLeft: '0.3rem', 
                fontSize: '0.75rem', 
              },
              readOnly: true,
            }}
            size="small"
            label={inputLabel}
            variant="outlined"
          />
        )}
      />

      <MDBox
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "0rem",
          lineHeight: "0rem",
          scale: '0.9',
        }}
      >
        <MDBox       
          // className={`${sortDirection === 'asc' && activeColumn === children ? 'activeRola' : ''}`} 
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'asc' && activeColumn === columnId ? 'text' : 'secondary'}
          opacity={sortDirection === 'asc' && activeColumn === columnId ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => onAscDesc( String(columnId), 'asc')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
          </svg>
          {/* <Icon>arrow_drop_up</Icon> */}
        </MDBox>
        <MDBox
          // className={`${sortDirection === 'desc' && activeColumn === columnId ? 'activeRola' : ''}`}
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'desc' && activeColumn === columnId ? 'text' : 'secondary'}
          opacity={sortDirection === 'desc' && activeColumn === columnId ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => onAscDesc( String(columnId), 'desc')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          </svg>
          {/* <Icon>arrow_drop_down</Icon> */}
        </MDBox>

      </MDBox>
    </MDBox>
  )
}

export default injectIntl(AutoComplete);